import block from "./block.js";
import component from "./components/index.js"
import containerPricing2 from "./components/containerPricing2.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerPricing2", containerPricing2(opts));
  editor.DomComponents.addType("pricing2", component(opts));
    //block
  editor.BlockManager.add("pricing2", block(opts));
};