export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname: 'div',
              name: 'Div',
              attributes: { class: 'w-100 border-top h-40 h-sm-auto back-image pattern-padding' },
              components:[
                {
                type: 'divForm4'
                }
              ],
              styles: `
              .back-image{
              bacground-image: url("https://placehold.co/1920x456"); 
              background-size:cover; 
              }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};