export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: "Coluna Sobre",
              attributes: { class: 'column column-50 p-20 position-relative' },
              components: [
                {
                  type: 'decoration'
                },
                {
                  type: 'image', 
                  attributes: {class: 'image-sobre index-9',  src: 'https://placehold.co/433x397'}
                },
                
              ],
              styles: `
              .index-9{
                z-index:99;
              }
              .position-absolute{
                 position:absolute;
              }
             .image-sobre{
               width:400px; 
               height:400px; 
               position:absolute;
               top:30%;
               left: 10%;
             }
             
             .p-20{
              padding:20px;
             }
             .column{
              display:flex; 
              flex-direction:column;
              align-items:flex-start;
             }
             .column-50{
              width:50%; 
             }
             @media (max-width:992px){
              .column-50{
                width:100%; 
              }
              .image-sobre{
                width:100%; 
                height:100%; 
                position:static;
              }
             }
            `,
             },
          },
          view: {
      
          },
    };
  
    return component;
  };
  