import block from "./block.js";
import component from "./components/index.js"
import containerSupport2 from "./components/containerSupport2.js";
import rowSupport2 from "./components/rowSupport2.js";
import support2_comp from "./components/support2.js";
import supportItem2 from "./components/supportItem2.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("support2", component(opts));
  editor.DomComponents.addType("containerSupport2", containerSupport2(opts));
  editor.DomComponents.addType("rowSupport2", rowSupport2(opts));
  editor.DomComponents.addType("support2_comp", support2_comp(opts));
  editor.DomComponents.addType("supportItem2", supportItem2(opts));

    //block
  editor.BlockManager.add("support2", block(opts));
};