import block from "./block.js";
import component from "./components/index.js"
import containerGallery3 from "./components/containerGallery3.js";


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerGallery3", containerGallery3(opts));
  editor.DomComponents.addType("gallery3", component(opts));
    //block
  editor.BlockManager.add("gallery3", block(opts));
};