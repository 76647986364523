import block from "./block.js";
import component from "./components/index.js"
import contactMap from "./components/contactMap.js";
import containerContact from "./components/containerContact.js";


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("contactMap", contactMap(opts));
  editor.DomComponents.addType("containerContact", containerContact(opts));
  editor.DomComponents.addType("contact1", component(opts));
    //block
  editor.BlockManager.add("contact1", block(opts));
};