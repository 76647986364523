export default (opts = {}) => {
  const block = {
    category: 'Galeria',
    id: "gallery2",
    label: "Galeria (2)",
    fromPlugin: 'blocks',
    apper : 'out',
    media: '<svg class="preview-block" viewBox="0 0 266 150" fill="none" width="266"  height="150" ><path fill="#FFFFFF" d="M0 0h266v150H0z"></path><path fill="#E2E8F0" d="M71 84h62v38H71zM71 27h127v54H71zM136 84h62v38h-62z"></path><path d="M93 54.5a1.5 1.5 0 011.5-1.5h77a1.5 1.5 0 010 3h-77a1.5 1.5 0 01-1.5-1.5z" fill="#A0AEC0"></path><path d="M123 61.5a1.5 1.5 0 011.5-1.5h17a1.5 1.5 0 010 3h-17a1.5 1.5 0 01-1.5-1.5z" fill="#6366F1"></path><rect x="108" y="45" width="50" height="4" rx="2" fill="#4A5568"></rect><path d="M81 103.5a1.5 1.5 0 011.5-1.5h39a1.5 1.5 0 010 3h-39a1.5 1.5 0 01-1.5-1.5z" fill="#A0AEC0"></path><path d="M97 110.5a1.5 1.5 0 011.5-1.5h7a1.5 1.5 0 010 3h-7a1.5 1.5 0 01-1.5-1.5z" fill="#6366F1"></path><rect x="89" y="94" width="26" height="4" rx="2" fill="#4A5568"></rect><path d="M146 103.5a1.5 1.5 0 011.5-1.5h39a1.5 1.5 0 010 3h-39a1.5 1.5 0 01-1.5-1.5z" fill="#A0AEC0"></path><path d="M162 110.5a1.5 1.5 0 011.5-1.5h7a1.5 1.5 0 010 3h-7a1.5 1.5 0 01-1.5-1.5z" fill="#6366F1"></path><rect x="154" y="94" width="26" height="4" rx="2" fill="#4A5568"></rect></svg>',
    content: { type: 'gallery2' },
  };

  return block;
};