export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type: 'Container',
              name: "Container CTA",
              attributes: { class: 'container-cubo' },
              components: [
                {
                  type: 'div',
                  attributes: { class: 'flex sm-flex-col gap-30 items-center justify-between' },
                  components: [
                    {
                      type: 'title2', 
                      components: [
                        {
                          type:'text',
                          name:'H2',
                          components: 'Lorem ipsum dolor sit amet',
                          attributes: { class: 'principal-title' },
                        },
                      ],
                    },
                    {
                      type: 'button3', 
                    }
                  ]
                },
              ],
              styles: `
             .container-cubo{
                max-width: 1600px; 
             }
             .flex{
               display: flex; 
             }
            .gap-30{
              gap: 30px; 
            }
            .items-center{
              align-items: center; 
            }
             .principal-title{
            font-size: 30px;
            color: #363940;
            margin:0;
           }
            .justify-between{
               justify-content: space-between;
            }

             @media (max-width: 992px){
               .sm-flex-col{
                 flex-direction: column; 
               }
             }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};