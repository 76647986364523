import components from "../../topPage1/components";

export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type: 'Container',
              name: "Container Conteudos",
              attributes: { class: 'container-cubo flex gap-30 sm-flex-col' },
              components: [
                {
                  type:'default',
                  name: 'div',
                  attributes: { class: 'w-50 flex flex-col items-start justify-center'},
                  components: [
                    {
                      type: 'title2', 
                      components: [
                        {
                          type:'text',
                          name:'H2',
                          components: 'Lorem Ipsum',
                          attributes: { class: 'principal-title' },
                        },
                      ],
                    },
                  ]
                },
                {
                  type: 'default',
                  name: 'divider',
                  attributes: { class: 'divider-content4' },
                },
                {
                  type: 'div',
                  attributes: { class: 'w-50 flex flex-col items-start justify-center' },
                  components: [
                        {
                          type: 'text', 
                          attributes: { class: 'body-text' },
                          components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus. '
                        },
                        {
                          type: 'div', 
                          attributes: { class: 'flex sm-flex-col gap-15 mt-5' },
                          components: [
                            {
                              type: 'button3', 
                            },
                            {
                              type: 'link', 
                              attributes: { class: 'link-content5' },
                              components: 'Saiba mais'
                            }
                          ]
                        },
                  ]
                },
              ],
              styles: `
             .container-cubo{
                max-width: 1600px; 
             }
            .flex{
              display: flex; 
            }
            .flex-col{
              flex-direction: column; 
            }
            .items-start{
              align-items: flex-start; 
            }
            .items-center{
            align-items: flex-center;
            }
            .justify-center{
              justify-content: center; 
            }
            .gap-30{
              gap: 30px; 
            }
             .principal-title{
            font-size: 30px;
            color: #363940;
            margin:0;
           }
            .w-50{
              width: 50%; 
            }
            .body-text{
            font-size: 16px;
            color: #363940;
            margin-top:30px;
           }
            .gap-15{
              gap: 15px; 
            }
            .mt-5{
              margin-top: 20px; 
            }
            .link-content5{
              color: #6366F1; 
              font-size: 1rem; 
            }
            .divider-content4{
             width: 2px;
             height: 230px;
             background-image: linear-gradient(#e5e7eb 0%, #e5e7eb 100%); 
           }
            
            @media (max-width: 992px){
              .sm-flex-col{
                flex-direction: column; 
              }
              .w-50{
              width: 100%; 
            }
              .divider-content4{
             width: 100%;
             height: 2px;
           }
            }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};