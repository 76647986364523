export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type:'div', 
              name: "Card Diferenciais",
              attributes: { class:'card-differences-3'},
              components: [
                {
                  type: 'image', 
                  attributes: { class: 'icon-differences-3', src: 'https://placehold.co/60x60'}, 
                }, 
                {
                  type: 'text', 
                  content: 'Lorem ipsum dolor ', 
                  attributes: { class: 'title-differences-3'},
                },
              ],
              styles: `
              .card-differences-3{
                width: 44%; 
                display: flex; 
                align-items: center; 
              }
              .icon-differences-3{
                width: 60px; 
              }
              .title-differences-3{ 
                font-size: 18px; 
                color: #363940; 
                margin-left: 15px; 
              }
      
              @media (max-width: 992px){
                .card-differences-1{
                  width: 100%; 
                }
              }
           `,
             },
          },
          view: {
      
          },
};
  return component; 
};