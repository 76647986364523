export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              name: 'Div',
              attributes: { class: 'time-line' },
              components: [
                 {
                  type: 'itemTimeLine1', 
                 },
                 {
                  type: 'itemTimeLine1', 
                 },
                 {
                  type: 'itemTimeLine1', 
                 },
             
              ],
              styles: `
               .time-line{
                display:flex; 
                gap: 16px; 
                margin-top:16px;
                justify-content:center; 
               }
               @media (max-width:992px){
                .time-line{
                  flex-direction:column;
                }
               }
      
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};