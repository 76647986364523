export default (opts = {}) => {
    const block = {
      id: "image2",
      label: "2 Imagens",
      fromPlugin: 'image',
      apper : 'out',
      media:'<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 16 7"><path id="image-fill" d="M0,2A1,1,0,0,1,1,1H7A1,1,0,0,1,8,2V7A1,1,0,0,1,7,8H1A1,1,0,0,1,0,7ZM.5,6.5V7a.5.5,0,0,0,.5.5H7A.5.5,0,0,0,7.5,7V5.25L5.613,4.276a.25.25,0,0,0-.288.047L3.47,6.178,2.14,5.292a.25.25,0,0,0-.315.031ZM3,3.25a.75.75,0,1,0-.75.75A.75.75,0,0,0,3,3.25" transform="translate(-0.002 -1)"/><path id="image-fill-2" data-name="image-fill" d="M0,2A1,1,0,0,1,1,1H7A1,1,0,0,1,8,2V7A1,1,0,0,1,7,8H1A1,1,0,0,1,0,7ZM.5,6.5V7a.5.5,0,0,0,.5.5H7A.5.5,0,0,0,7.5,7V5.25L5.613,4.276a.25.25,0,0,0-.288.047L3.47,6.178,2.14,5.292a.25.25,0,0,0-.315.031ZM3,3.25a.75.75,0,1,0-.75.75A.75.75,0,0,0,3,3.25" transform="translate(7.998 -1)"/></svg>',
      content: { type: 'image2' },
    };
  
    return block;
  };