export default (opts = {}) => {
  const block = {
    id: "title2",
    label: "H2",
    fromPlugin: 'title',
    apper : 'out', 
    media: '<i class="bi bi-type-h2"></i>', 
    content: { type: "title2" },
  };

  return block;
};



