export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        type: 'Container',
        name: "Container",
        attributes: { class: 'container-cubo flex sm-flex-col gap-40' },
        components: `
        <div class="w-50 flex-col items-star">
           <h2 class="principal-title">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
           </h2>
           <div class="body-text">
              Sed tincidunt, elit non rhoncus egestas, dolor lectus pretium libero, in finibus urna justo vel augue. Mauris eros ligula, aliquet eget tincidunt sit amet.
           </div>
           <div class="flex sm-flex-col gap-20 mt-30">
            <div class="item-statistic-2">
               <h3 class="title-statistic-2">2.7K</h3>
               <p class="text-statistic-2">Usuarios</p>
            </div>
            <div class="item-statistic-2">
              <h3 class="title-statistic-2">1.8K</h3>
              <p class="text-statistic-2">Inscritos</p>
            </div>
            <div class="item-statistic-2">
               <h3 class="title-statistic-2">35</h3>
               <p class="text-statistic-2">Downloads</p>
            </div>
            <div class="item-statistic-2">
              <h3 class="title-statistic-2">4</h3>
              <p class="text-statistic-2">Produtos</p>
            </div>
          </div>
        </div>
        <div class="w-50">
           <img class="image-statistic-2" src="https://dummyimage.com/600x300" alt="stats">
        </div>
        `,
        styles: `
             .container-cubo{
                max-width: 1600px; 
             }
            .flex{
              display: flex; 
            }
            .gap-40{
             gap: 40px; 
            }
            .w-50{
              width: 50%; 
            }
            .items-start{
              align-items: flex-start; 
            }
            .mt-30{
              margin-top: 30px;
            }
            .principal-title{
            font-size: 30px;
            color: #363940;
            margin:0;
           }
           .body-text{
            font-size: 16px;
            color: #363940;
            margin-top:30px;
           }
           .gap-20{
             gap: 20px; 
           }
          .item-statistic-2{
            width: 25%; 
          }
          .title-statistic-2{
            font-size: 25px; 
            color: #000;
          }
          .text-statistic-2{
            font-size: 16px; 
            color: #707070; 
          }
          .image-statistic-2{
            width: 100%; 
            border-radius: 10px;
            object-fit: cover;
          }

        @media (max-width: 992px){
            .sm-flex-col{
              flex-direction: column; 
            } 
            .w-50{
              width: 100%; 
            } 
            .item-statistic-2{
            width: 100%; 
          }
          }
            `,
      },
    },
    view: {

    },
  };
  return component;
};