export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        type: 'Container',
        name: "Container Galeria",
        attributes: { class: 'container-cubo flex flex-col align-center' },
        components: [
          {
            type: 'div',
            attributes: { class: 'w-66 flex flex-col gap-16' },
            components: [
              {
                type: 'div',
                attributes: { class: 'flex flex-col align-center justify-center p-30 w-full bg-image h-340' },
                components: [
                  {
                    type: 'title2',
                    components: [
                      {
                        type: 'text',
                        name: 'H2',
                        components: 'Lorem Ipsum',
                        attributes: { class: 'principal-title' },
                      },
                    ],
                  },
                  {
                    type: 'text',
                    attributes: { class: 'body-text text-center' },
                    components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae'
                  },
                  {
                    type: 'link',
                    attributes: { class: 'more' },
                    components: 'Saiba mais'
                  }
                ]
              },
              {
                type: 'div',
                attributes: { class: 'flex sm-flex-col gap-16' },
                components: [
                  {
                    type: 'div',
                    attributes: { class: 'flex flex-col align-center justify-center p-30 w-50 bg-image h-340' },
                    components: [
                      {
                        type: 'title2',
                        components: [
                          {
                            type: 'text',
                            name: 'H2',
                            components: 'Lorem Ipsum',
                            attributes: { class: 'principal-title' },
                          },
                        ],
                      },
                      {
                        type: 'text',
                        attributes: { class: 'body-text text-center' },
                        components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae'
                      },
                      {
                        type: 'link',
                        attributes: { class: 'more' },
                        components: 'Saiba mais'
                      }
                    ]
                  },
                  {
                    type: 'div',
                    attributes: { class: 'flex flex-col align-center justify-center p-30 w-50 bg-image h-340' },
                    components: [
                      {
                        type: 'title2',
                        components: [
                          {
                            type: 'text',
                            name: 'H2',
                            components: 'Lorem Ipsum',
                            attributes: { class: 'principal-title' },
                          },
                        ],
                      },
                      {
                        type: 'text',
                        attributes: { class: 'body-text text-center' },
                        components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae'
                      },
                      {
                        type: 'link',
                        attributes: { class: 'more' },
                        components: 'Saiba mais'
                      }
                    ]
                  },
                ]
              }
            ]
          },
        ],
        styles: `
             .container-cubo{
                max-width: 1600px; 
             }
             .flex{
               display: flex; 
             }
            .flex-col{
              flex-direction: column; 
            }
            .gap-16{
              gap: 16px; 
            }
            .align-center{
              align-items: center; 
            }
            .justify-center{
              justify-content: center; 
            }
            .w-66{
              width: 66%; 
            }
            .p-30{
              padding: 30px; 
            }
            .w-full{
              width: 100%; 
            }
            .bg-image{
              background-image: url("https://dummyimage.com/820x340");
              background-size: cover; 
              background-repeat: no-repeat; 
              background-position: center center; 
            }
            .h-340{
              height: 340px; 
            }
            .principal-title{
            font-size: 30px;
            color: #363940;
            margin:0;
           }
            .body-text{
            font-size: 16px;
            color: #363940;
            margin-top:30px;
           }
          .text-center{
            text-align: center; 
          }
         .more{
            color: #6366f1;
            font-size: 16px; 
            text-decoration: none; 
            margin-top: 30px;
            display: block;
          }
        .w-50{
          width: 50%; 
        }
          @media (max-width: 992px){
            .w-66{
              width: 100%; 
            }
            .w-50{
              width: 100%; 
            }
            .sm-flex-col{
              flex-direction: column;
            }
          }
            `,
      },
    },
    view: {

    },
  };
  return component;
};