import component from "../section/component";

export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        style: {
          'display': 'flex',
          'gap': '20px',
          'flex-wrap': 'wrap'
        },
        components: [
          {
            type: 'video',
            style: {
              'width': '49%',
            },
          }, 
          {
            type: 'video',
            style: {
              'width': '49%',
            },
          },
          {
            type: 'video',
            style: {
              'width': '49%',
            },
          },
          {
            type: 'video',
            style: {
              'width': '49%',
            },
          },

        ],


      },

    },
    view: {},
  };
  return component;
};