import block from "./block.js";
import component from "./components/index.js"
import columnNumbers8 from "./components/columnNumbers8.js";
import columnImageNumbers8 from "./components/columnImageNumbers8.js";
import columnsNumbers8 from "./components/columnsNumbers8.js";
import containerNumbers8 from "./components/containerNumbers8.js";
import counters8 from "./components/counters8.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("bigNumber8", component(opts));
  editor.DomComponents.addType("columnNumbers8", columnNumbers8(opts));
  editor.DomComponents.addType("columnImageNumbers8", columnImageNumbers8(opts));
  editor.DomComponents.addType("columnsNumbers8", columnsNumbers8(opts));
  editor.DomComponents.addType("containerNumbers8", containerNumbers8(opts));
  editor.DomComponents.addType("counters8", counters8(opts));


    //block
  editor.BlockManager.add("bigNumber8", block(opts));
};