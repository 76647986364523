import block from "./block.js";
import component from "./components/index.js"
import containerContent2 from "./components/containerContent2.js";


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerContent2", containerContent2(opts));
  editor.DomComponents.addType("content2", component(opts));
    //block
  editor.BlockManager.add("content2", block(opts));
};