export default (opts = {}) => {
  const component =  {
    model: {
      defaults: {
        type:'Container',
        name: "Container Contato",
        attributes: { class: 'container-cubo flex justify-end sm-flex-col'},
        components: [
          {
            type:'default',
            attributes: { class: 'divform-contact1' },
            components: [
              {
                  type: 'title2',
                  components: [
                    {
                      type:'text',
                      name:'H2',
                      components: 'FeedBack',
                      attributes: { class: 'title-contact1' },
                    },
                  ],
              },
                {
                  type: 'text', 
                  attributes: { class: 'text-contact1' },
                  components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                },
                {
                  type: 'formNew'
                }
                
            ]
          }
        ],
        styles: `
       .container-cubo{
          max-width: 1600px; 
       }
       .flex{
         display: flex; 
       }
       .justify-end{
         justify-content: flex-end; 
       }
       .divform-contact1{
         width: 33%; 
         border-radius: 20px; 
         padding: 30px; 
         background-image: linear-gradient(#ffffff 0%, #ffffff 100%); 
         display: flex; 
         flex-direction: column; 
         align-items: flex-start;
         box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); 
         position: relative;
         z-index: 10;         
       }
       .title-contact1{
         color: #111827; 
         font-weight: 500; 
         font-size: 1.125rem; 
         line-height: 1.75rem; 
         margin-bottom: 0.25rem; 
       }
      .text-contact1{
        line-height: 1.625; 
        margin-bottom: 0.75rem; 
        font-size: 16px; 
        color: #4B5563; 
      }

       @media (max-width: 992px){
        .sm-flex-col{
          flex-direction: column; 
        }
        .divform-contact1{
          width: 100%; 
        }
       }
      `,
       },
    },
    view: {

    },
  };

  return component;
};
