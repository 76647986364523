export default (opts = {}) => {
  const block = {
    category: 'Estatísticas',
    id: "statistics1",
    label: "Estatísticas (1)",
    fromPlugin: 'blocks',
    apper : 'out',
    media: '<svg class="preview-block" viewBox="0 0 266 150" fill="none" width="266"  height="150" ><path fill="#FFFFFF" d="M0 0h266v150H0z"></path><rect x="45" y="66" width="26" height="10" rx="5" fill="#4A5568"></rect><rect x="43" y="80" width="30" height="4" rx="2" fill="#A0AEC0"></rect><rect x="95" y="66" width="26" height="10" rx="5" fill="#4A5568"></rect><rect x="93" y="80" width="30" height="4" rx="2" fill="#A0AEC0"></rect><rect x="145" y="66" width="26" height="10" rx="5" fill="#4A5568"></rect><rect x="143" y="80" width="30" height="4" rx="2" fill="#A0AEC0"></rect><rect x="195" y="66" width="26" height="10" rx="5" fill="#4A5568"></rect><rect x="193" y="80" width="30" height="4" rx="2" fill="#A0AEC0"></rect></svg>',
    content: { type: 'statistics1' },
  };

  return block;
};