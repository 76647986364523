export default (opts = {}) => {
  const component = {
    model: {
        defaults: {
          type:'div',
          name: 'Seção',
          attributes: { class: 'section main-section bg-neutro position-realtive' },
          components: [
            {
              type: 'principalContainer'
            },
            {
              type: 'scrollDown'
            }            
          ],
          styles: `
         .section{
           position:relative; 
           with:100vw; 
           padding: 75px 5%; 
         }
         .main-section{
          height: 100vh; 
          display:flex; 
          flex-direction: column; 
          justify-content: center;
          align-content:center;
         }
         .bg-neutro{
          background:#F6F7FA;
         }
         .position-relative{
          position: relative; 
         }
    
         @media (max-width: 992px){
          .main-section{
            height: auto;
          }
         }
         *{
          font-family: Roboto, Arial, sans-serif;
        }
        `,
        },
      },
      view: {
       
      },
  };

  return component;
};

