export default (opts = {}) => {
  const block = {
    category: 'CTA',
    id: "cta2",
    label: "CTA (2)",
    fromPlugin: 'blocks',
    apper : 'out',
    media: '<svg class="preview-block" fill="none" viewBox="0 0 266 150" width="266"  height="150" ><path fill="#FFFFFF" d="M0 0h266v150H0z"></path><rect x="151" y="40" width="93" height="70" rx="2" fill="#E2E8F0"></rect><rect x="20" y="61" width="86" height="5" rx="2.5" fill="#4A5568"></rect><rect x="20" y="70" width="66" height="5" rx="2.5" fill="#4A5568"></rect><rect x="20" y="79" width="106" height="4" rx="2" fill="#A0AEC0"></rect><rect x="20" y="87" width="97" height="4" rx="2" fill="#A0AEC0"></rect><rect x="160" y="91" width="75" height="10" rx="2" fill="#6366F1"></rect><rect x="160" y="76" width="75" height="10" rx="2" fill="#CBD5E0"></rect><rect x="160" y="61" width="75" height="10" rx="2" fill="#CBD5E0"></rect><rect x="160" y="50" width="40" height="4" rx="2" fill="#4A5568"></rect></svg>',
    content: { type: 'cta2' },
  };

  return block;
};