export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname: 'div',
              name: 'Coluna Topo',
              attributes: { class: 'column column-50 aling-item-xl-end aling-item-md-center p-20' },
              components: [
                {
                  type: 'divForm'
                }
              ],
              styles: `
              .aling-item-xl-end{
                align-items: end;
              }
              .p-20{
                padding:20px;
              }
              
              @media (max-width:992px){
                .aling-item-md-center{
                  align-items: center !important;
                }
              }
            `,
            },
          },
          view: {
      
          },
    };
  
    return component;
  };
  