export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div',
              name: 'Colunas Tempo', 
              attributes: { class: 'row-full' },
              components: [
                {
                  type: 'columnImageTimeLine2', 
                },
                {
                  type: 'columnTimeLine2', 
                },
                
              ],
              styles: `
             .row-full{
                display: flex; 
                flex-direction:row; 
                width:100vw; 
             }
             @media (max-width:992px){
              .row-full{
                flex-direction:column;
              }
             }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};