export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type: 'Container',
              name: "Container CTA",
              attributes: { class: 'container-cubo'},
              components: [
                {
                  type: 'div',
                  attributes: { class: 'flex flex-col items-center' },
                  components: [
                    {
                      type: 'title2', 
                      components: [
                        {
                          type:'text',
                          name:'H2',
                          components: 'Lorem ipsum dolor sit amet',
                          attributes: { class: 'principal-title' },
                        },
                      ],
                    },
                    {
                      type: 'text', 
                      attributes: { class: 'body-text' },
                      components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae'
                    }
                  ]
                },
                {
                  type: 'formNew'
                }
              ],
              styles: `
             .container-cubo{
                max-width: 1600px; 
             }
             .flex{
               display: flex; 
             }
             .flex-col{
               flex-direction: column; 
             }
             .items-center{
                align-items: center; 
             }
              .principal-title{
            font-size: 30px;
            color: #363940;
            margin:0;
           }
            .body-text{
            font-size: 16px;
            color: #363940;
            margin-top:30px;
           }
            .w-50{
              width: 50%;
            }

            @media (max-width: 992px){
              .w-50{
                width: 100%; 
              }
            }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};