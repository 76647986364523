import block from "./block.js";
import component from "./components/index.js"
import containerPortifolio2 from "./components/containerPortifolio2.js";
import divGrid1 from "./components/divGrid1.js";
import divGrid2 from "./components/divGrid2.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("customerPortifolio2", component(opts));
  editor.DomComponents.addType("containerPortifolio2", containerPortifolio2(opts));
  editor.DomComponents.addType("divGrid1", divGrid1(opts));
  editor.DomComponents.addType("divGrid2", divGrid2(opts));

    //block
  editor.BlockManager.add("customerPortifolio2", block(opts));
};