import block from "./block.js";
import component from "./components/index.js"
import columnImageTimeLine2 from "./components/columnImageTimeLine2.js";
import columnTimeLine2 from "./components/columnTimeLine2.js";
import columnsTimeLine2 from "./components/columnsTimeLine2.js";
import containerTimeLine2 from "./components/containerTimeLine2.js";


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("timeLine2", component(opts));
  editor.DomComponents.addType("columnImageTimeLine2", columnImageTimeLine2(opts));
  editor.DomComponents.addType("columnTimeLine2", columnTimeLine2(opts));
  editor.DomComponents.addType("columnsTimeLine2", columnsTimeLine2(opts));
  editor.DomComponents.addType("containerTimeLine2", containerTimeLine2(opts));

  //block
  editor.BlockManager.add("timeLine2", block(opts));
};