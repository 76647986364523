import section from "./section/index.js";
import container from "./container/index.js";
import column2 from "./columns2/index.js";
import columns3 from "./columns3/index.js";
import columns4 from "./columns4/index.js";
import button2 from "./button2/index.js";
import button from "./button/index.js";
import button3 from "./button3/index.js";
import button4 from "./button4/index.js";
import button5 from "./button5/index.js";
import button6 from "./button6/index.js";
import button7 from "./button7/index.js";
import button8 from "./button8/index.js";
import title from "./title/index.js";
import title2 from "./title2/index.js";
import title3 from "./title3/index.js";
import title4 from "./title4/index.js";
import title5 from "./title5/index.js";
import title6 from "./title6/index.js";
import textBlock from "./textBlock/index.js";
import textBlock2 from "./textBlock2/index.js";
import textBlock3 from "./textBlock3/index.js";
import textBlock4 from "./textBlock4/index.js";
import image from "./image/index.js";
import image2 from "./image2/index.js";
import image3 from "./image3/index.js";
import image4 from "./image4/index.js";
import image5 from "./image5/index.js";
import video from "./video/index.js";
import video2 from "./video2/index.js";
import video3 from "./video3/index.js";
import video4 from "./video4/index.js";
import link from "./link/index.js";
import counters from "./counters/index.js";
import socialIcons from "./socialicons/index.js";
import checkList from "./checkList/index.js";
import slideShow from "./slideShow/index.js";
import slideShow2 from "./slideShow2/index.js";
import slideShow3 from "./slideShow3/index.js";
import slideShow4 from "./slideShow4/index.js";
import floatWhats from "./floatWhats/index.js";
import slideShowImage from "./slideShowImage/index.js";
import slideShowImage2 from "./slideShowImage2/index.js";
import slideShowImage3 from "./slideShowImage3/index.js";
import slideShowImage4 from "./slideShowImage4/index.js";
import slideShowCard from "./slideShowCard/index.js";
import slideShowCard3 from "./slideShowCard3/index.js";
import slideShowCard4 from "./slideShowCard4/index.js";
import map from "./map/index.js";

export default (editor, opts) => {
    section(editor, opts)
    container(editor, opts)
    column2(editor, opts)
    columns3(editor, opts)
    columns4(editor, opts)
    button2(editor,opts)
    button(editor, opts)
    button3(editor,opts)
    button4(editor,opts)
    button5(editor,opts)
    button6(editor,opts)
    button7(editor,opts)
    button8(editor,opts)
    image(editor, opts)
    image2(editor,opts)
    image3(editor,opts)
    image4(editor,opts)
    image5(editor,opts)
    video(editor, opts)
    video2(editor,opts)
    video3(editor,opts)
    video4(editor,opts)
    link (editor, opts)
    counters(editor, opts)
    title(editor, opts)
    title2(editor, opts)
    title3(editor, opts)
    title4(editor,opts)
    title5(editor,opts)
    title6(editor,opts)
    textBlock(editor, opts)
    textBlock2(editor,opts)
    textBlock3(editor,opts)
    textBlock4(editor,opts)
    socialIcons(editor,opts)
    checkList(editor,opts)
    slideShow(editor,opts)
    slideShow2(editor,opts)
    slideShow3(editor,opts)
    slideShow4(editor,opts)
    slideShowImage(editor, opts)
    slideShowImage2(editor,opts)
    slideShowImage3(editor,opts)
    slideShowImage4(editor,opts)
    slideShowCard(editor,opts)
    slideShowCard3(editor,opts)
    slideShowCard4(editor,opts)
    floatWhats(editor,opts)
    map(editor, opts)

}