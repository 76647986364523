export default (opts = {}) => {
    const block = {
      id: "video",
      label: "Video",
      fromPlugin: 'video',
      apper : 'out',
      media: '<i class="bi bi-play-btn"></i>',
      content: { type: "video" },
    };
  
    return block;
  };
  