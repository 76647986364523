export default (opts = {}) => {
    const component = {
      model: {
        defaults: {
          tagname: 'div',
          name: "Div",
          attributes: { class: 'divmap2' },
          components: [
           {
            type: 'default',
            attributes: { class: 'mapcontact2'},
            components: [
              {
                type: 'map',
                style: {
                  'width':'100%',
                  'height':'100%'
                }
              }
            ]
           },
           {
            type: 'default', 
            attributes: { class: 'div-infoscontact2' }, 
            components: [
              {
                type: 'default', 
                attributes: { class: 'infoscontact2' },
                components: [
                  {
                    type: 'title3', 
                    components: [
                      {
                        type:'text',
                        name:'H3',
                        components: 'Endereço',
                        attributes: { class: 'titlemap-contact2' },
                      },
                    ],
                  },
                  {
                    type: 'link', 
                    attributes: { class: 'link-contact2' },
                    components: 'Rua. Lorem, 123'
                  },
                ]
              }, 
              {
                type: 'default', 
                attributes: { class: 'infoscontact2' },
                components: [
                  {
                    type: 'title3', 
                    components: [
                      {
                        type:'text',
                        name:'H3',
                        components: 'E-mail',
                        attributes: { class: 'titlemap-contact2' },
                      },
                    ],
                  },
                  {
                    type: 'link', 
                    attributes: { class: 'link-contact2' },
                    components: 'email@exemplo.com'
                  },
                  {
                    type: 'title3', 
                    attributes: { class: 'titlemap-contact2' },
                    components: [
                      {
                        type:'text',
                        name:'H3',
                        components: 'Telefone',
                      },
                    ],
                  },
                  {
                    type: 'link', 
                    attributes: { class: 'link-contact2' },
                    components: '(99) 99999-9999'
                  },
                ]
              },

            ]
           }
          ],
          styles: `
          .divmap2{
            width: 66%; 
            position: relative; 
            border-radius: 15px; 
            overflow-x: hidden; 
            overflow-y: hidden; 
            padding: 40px; 
            display: flex; 
            flex-direction: column; 
            justify-content: center; 
            align-items: center; 
          }
          .mapcontact2{
            position: absolute; 
            left: 0; 
            top: 0; 
            width: 100%;
            height: 100%; 
            filter: grayscale(); 
            opacity: 40%; 
          }
          .div-infoscontact2{
            background-image: linear-gradient(#ffffff 0%, #ffffff 100%);
            padding: 10px;
            border-radius: 10px; 
            display: flex; 
            gap: 15px; 
            width: 100%; 
            position: relative; 
            z-index: 10;
          }
          .infoscontact2{
             width: 50%; 
             display: flex;
             flex-direction: column; 
          }
          .titlemap-contact2{
           letter-spacing:  0.1em; 
           font-weight: 600; 
           font-size: 0.75rem; 
           line-height: 1rem; 
           color: #111827;
          }
          .link-contact2{
            line-height: 1.625; 
            color: #6366F1;
            font-size: 16px;
            margin-bottom: 20px; 
          }

          @media (max-width: 992px){
            .divmap2{
             width: 100%; 
            }
             .div-infoscontact2{
               flex-direction: column; 
              }
          .infoscontact2{
             width: 100%; 
          }
          }
        `,
        },
      },
      view: {
  
      },
    };
  
    return component;
  };
  