import block from "./block.js";
import component from "./components/index.js"
import containerContent3 from "./components/containerContent3.js";


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerContent3", containerContent3(opts));
  editor.DomComponents.addType("content3", component(opts));
    //block
  editor.BlockManager.add("content3", block(opts));
};