import block from "./block.js";
import component from "./components/index.js"
import containerFooter from "./components/containerFooter.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("footer", component(opts));
  editor.DomComponents.addType("containerFooter", containerFooter(opts));

    //block
  editor.BlockManager.add("footer", block(opts));
};