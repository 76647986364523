export default (opts = {}) => {
  const block = {
    category: 'Estatísticas',
    id: "statistics2",
    label: "Estatísticas (2)",
    fromPlugin: 'blocks',
    apper : 'out',
    media: '<svg class="preview-block" viewBox="0 0 266 150" fill="none" width="266"  height="150" ><path fill="#FFFFFF" d="M0 0h266v150H0z"></path><path d="M175.792 92h26.544a3.785 3.785 0 003.792-3.778V61.778A3.785 3.785 0 00202.336 58h-26.544A3.785 3.785 0 00172 61.778v26.444A3.785 3.785 0 00175.792 92zm0 0l20.856-20.778 9.48 9.445m-20.856-12.278a2.838 2.838 0 01-2.844 2.833 2.838 2.838 0 01-2.844-2.833 2.838 2.838 0 012.844-2.833 2.838 2.838 0 012.844 2.833z" stroke="#A0AEC0" stroke-width="3px" stroke-linecap="round" stroke-linejoin="round" fill="none"></path><rect x="20" y="46" width="70" height="5" rx="2.5" fill="#4A5568"></rect><rect x="20" y="57" width="98" height="4" rx="2" fill="#A0AEC0"></rect><rect x="20" y="65" width="87" height="4" rx="2" fill="#A0AEC0"></rect><rect x="20" y="88" width="17" height="7" rx="3.5" fill="#4A5568"></rect><rect x="20" y="99" width="20" height="4" rx="2" fill="#A0AEC0"></rect><rect x="46" y="88" width="17" height="7" rx="3.5" fill="#4A5568"></rect><rect x="46" y="99" width="20" height="4" rx="2" fill="#A0AEC0"></rect><rect x="72" y="88" width="17" height="7" rx="3.5" fill="#4A5568"></rect><rect x="72" y="99" width="20" height="4" rx="2" fill="#A0AEC0"></rect><rect x="98" y="88" width="17" height="7" rx="3.5" fill="#4A5568"></rect><rect x="98" y="99" width="20" height="4" rx="2" fill="#A0AEC0"></rect></svg>',
    content: { type: 'statistics2' },
  };

  return block;
};