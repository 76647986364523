import block from "./block.js";
import component from "./components/index.js"
import containerNumbers7 from "./components/containerNumbers7.js";
import divCounters7 from "./components/divCounters7.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("bigNumber7", component(opts));
  editor.DomComponents.addType("containerNumbers7", containerNumbers7(opts));
  editor.DomComponents.addType("divCounters7", divCounters7(opts));

    //block
  editor.BlockManager.add("bigNumber7", block(opts));
};