export default (opts = {}) => {
    const component = {
        model: { 
            defaults: {
              tagname: 'div',
              name: "Coluna Modelos",
              attributes: { class: 'column column-50 justify-center align-right' },
              components: [
                {
                  type: 'text',
                  content: 'Subtítulo',
                  name: 'Subtitulo',
                  attributes: { class: 'subtitle text-uppercase margin-right-50 m-sm-r-0' },
                },
                {
                  tagName: 'h2',
                  type: 'text',
                  name: 'Titulo',
                  components: 'Modelos de negócio',
                  attributes: { class: 'principal-title margin-right-50 m-sm-r-0' },
                  traits: [
                    {
                      type: "select",
                      options: [
                        { value: "h1", name: opts.labelN1 },
                        { value: "h2", name: opts.labelN2 },
                        { value: "h3", name: opts.labelN3 },
                        { value: "h4", name: opts.labelN4 },
                        { value: "h5", name: opts.labelN5 },
                        { value: "h6", name: opts.labelN6 },
                      ],
                      label: 'Tamanho',
                      name: "tagName",
                      changeProp: 1,
                    },
                    {
                      type: 'id',
                      name: 'id',
                      Label: 'id',
                    },
                  ],
                },
                {
                  type: 'div', 
                  attributes: {class: 'd-flex align-center margin-top-30'},
                  components: [
                    {
                      type: 'text',
                      content: 'Modelo de negócio',
                      attributes: { class: 'name-model'},
                    }, 
                    {
                      type: 'div', 
                      attributes: { class: 'd-flex align-center margin-left-10'},
                      components: [
                        {
                          type: 'text', 
                          attributes: { class: 'dot'}, 
                        }, 
                        {
                          type: 'text', 
                          attributes: { class: 'line'}, 
                        }, 
                      ],
                    }, 
                  ],
                },
                {
                  type: 'text',
                  content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et bibendum risus. Cras faucibus viverra dolor, at  ',
                  attributes: { class: 'body-text margin-right-50 m-sm-r-0 w-sm-100 w-40 margin-top-30 text-right' },
                },
              ],
              styles: `
               .principal-title{
                font-size: 30px; 
                color: #363940; 
                margin:0;
               }
               .body-text{
                font-size: 16px; 
                color: #363940; 
                margin-top:30px;
               }
               .subtitle{
                font-size: 13px; 
                color: #747474; 
               }
               .text-uppercase{
                text-transform: uppercase;
               }
              .justify-center{
                justify-content: center;
              }
              .align-center{
                align-items: center; 
              }
              .align-right{
                display: flex;
                flex-direction: column;
                align-items: flex-end !important;
              }
              .d-flex{
                display: flex; 
              }
              .w-40{
                width:40%; 
              }
              .margin-right-50{
                margin-right: 50px; 
              }
              .margin-left-10{
                margin-left: 10px;
              }
              .margin-top-30{
                margin-top:30px;
              }
              .name-model{
                font-size: 18px; 
                font-weight: 600; 
                color: #95A1BB; 
              }
              .dot{
                width:8px; 
                height: 8px; 
                background: #707070; 
                border-radius: 100%;
              }
              .line{
                width:50px; 
                height: 1px; 
                background: #707070; 
              }
              .text-right{
                text-align: right; 
              }
              @media (max-width:992px){
                .w-sm-100{
                  width:100% !important;
                }
                .m-sm-r-0{
                  margin-right: 0 !important; 
                }
              }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};