export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        type: 'Container',
        name: "Container CTA",
        attributes: { class: 'container-cubo flex sm-flex-col items-center gap-30' },
        components: [
          {
            type: 'div',
            attributes: { class: 'w-60 flex flex-col gap-20' },
            components: [
              {
                type: 'title2',
                components: [
                  {
                    type: 'text',
                    name: 'H2',
                    components: 'Lorem ipsum dolor sit amet',
                    attributes: { class: 'principal-title' },
                  },
                ],
              },
              {
                type: 'text',
                attributes: { class: 'body-text' },
                components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae'
              }
            ]
          },
          {
            type: 'div',
            attributes: { class: 'div-form-cta' },
            components: [
              {
                type: 'formNew'
              }
            ]
          },
        ],
        styles: `
             .container-cubo {
                 max-width: 1600px;
              }

              .items-center {
                 align-items: center;
              }

              .flex {
                display: flex;
              }

              .flex-col {
                flex-direction: column;
              }

              .w-60 {
                 width: 60%;
              }

              .gap-20 {
                 gap: 20px;
                }
              .gap-30{
                gap: 30px;
              }

               .principal-title{
            font-size: 30px;
            color: #363940;
            margin:0;
           }

              .body-text{
            font-size: 16px;
            color: #363940;
            margin-top:30px;
           }

              .div-form-cta {
                 width: 40%;
                 background-image: linear-gradient(#f3f4f6 0%, #f3f4f6 100%);
                 padding: 2rem;
                 border-radius: 0.5rem;
                 display: flex;
                 flex-direction: column;
                 align-items: center;
              }

              @media (max-width: 992px) {
                .sm-flex-col {
                  flex-direction: column;
                }

                .w-60 {
                  width: 100%;
                }

                .div-form-cta {
                   width: 100%;
                }
              }
            `,
      },
    },
    view: {

    },
  };
  return component;
};