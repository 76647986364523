export default (opts = {}) => {
    const block = {
      id: "textBlock3",
      label: "Alinhado a direita",
      fromPlugin: 'text',
      apper : 'out',
      media:'<i class="bi bi-text-right"></i>',
      content: { type: "textBlock3" },
    };
  
    return block;
  };
  

  
