import block from "./block.js";
import component from "./components/index.js"
import containerEvents1 from "./components/containerEvents1.js";
import divEvents1 from "./components/divEvents1.js";
import itemEvents1 from "./components/itemEvents1.js";
import rowEvents1 from "./components/rowEvents1.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("events1", component(opts));
  editor.DomComponents.addType("containerEvents1", containerEvents1(opts));
  editor.DomComponents.addType("divEvents1", divEvents1(opts));
  editor.DomComponents.addType("itemEvents1", itemEvents1(opts));
  editor.DomComponents.addType("rowEvents1", rowEvents1(opts));

    //block
  editor.BlockManager.add("events1", block(opts));
};