export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        type: 'Container',
        name: "Container Conteudos",
        attributes: { class: 'container-cubo' },
        components: [
          {
            type: 'div',
            attributes: { class: 'flex flex-col' },
            components: [
              {
                type: 'div',
                attributes: { class: 'divider-content8' },
                components: [
                  {
                    type: 'div',
                    attributes: { class: 'div-divider' },
                  }
                ]
              },
              {
                type: 'div',
                attributes: { class: 'flex sm-flex-col gap-30 justify-between' },
                style: {
                  'margin-top':'30px',
                  'margin-bottom':'50px'
                },
                components: [
                  {
                    type: 'title2',
                    components: [
                      {
                        type: 'text',
                        name: 'H2',
                        components: 'Lorem Ipsum',
                        attributes: { class: 'principal-title' },
                      },
                    ],
                  },
                  {
                    type: 'text',
                    attributes: { class: 'body-text w-50' },
                    components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus. Pellentesque eu finibus dolor. Aenean sit amet auctor est. Nunc et libero eu enim sollicitudin ornare. Sed arcu lectus, congue eget scelerisque eget'
                  },
                ]
              },
            ]
          },
          {
            type: 'div',
            attributes: { class: 'flex sm-flex-col gap-30' },
            components: [
              {
                type: 'div',
                attributes: { class: 'item-content8' },
                components: [
                  {
                    type: 'image',
                    attributes: { class: 'image-content8', src: 'https://dummyimage.com/1203x503' },
                  },
                  {
                    type: 'title2',
                    components: [
                      {
                        type: 'text',
                        name: 'H2',
                        components: 'Lorem Ipsum',
                        attributes: { class: 'title-item-content8' },
                      },
                    ],
                  },
                  {
                    type: 'text',
                    attributes: { class: 'text-item-content8' },
                    components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus. Pellentesque eu finibus dolor. Aenean sit amet auctor est. Nunc et libero eu enim sollicitudin ornare. Sed arcu lectus, congue eget scelerisque eget',
                  },
                  {
                    type: 'link',
                    attributes: { class: 'link-item-content8' },
                    components: 'Saiba mais'
                  }
                ]
              },
              {
                type: 'div',
                attributes: { class: 'item-content8' },
                components: [
                  {
                    type: 'image',
                    attributes: { class: 'image-content8', src: 'https://dummyimage.com/1203x503' },
                  },
                  {
                    type: 'title2',
                    components: [
                      {
                        type: 'text',
                        name: 'H2',
                        components: 'Lorem Ipsum',
                        attributes: { class: 'title-item-content8' },
                      },
                    ],
                  },
                  {
                    type: 'text',
                    attributes: { class: 'text-item-content8' },
                    components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus. Pellentesque eu finibus dolor. Aenean sit amet auctor est. Nunc et libero eu enim sollicitudin ornare. Sed arcu lectus, congue eget scelerisque eget',
                  },
                  {
                    type: 'link',
                    attributes: { class: 'link-item-content8' },
                    components: 'Saiba mais'
                  }
                ]
              },
              {
                type: 'div',
                attributes: { class: 'item-content8' },
                components: [
                  {
                    type: 'image',
                    attributes: { class: 'image-content8', src: 'https://dummyimage.com/1203x503' },
                  },
                  {
                    type: 'title2',
                    components: [
                      {
                        type: 'text',
                        name: 'H2',
                        components: 'Lorem Ipsum',
                        attributes: { class: 'title-item-content8' },
                      },
                    ],
                  },
                  {
                    type: 'text',
                    attributes: { class: 'text-item-content8' },
                    components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus. Pellentesque eu finibus dolor. Aenean sit amet auctor est. Nunc et libero eu enim sollicitudin ornare. Sed arcu lectus, congue eget scelerisque eget',
                  },
                  {
                    type: 'link',
                    attributes: { class: 'link-item-content8' },
                    components: 'Saiba mais'
                  }
                ]
              },
            ]
          }
        ],
        styles: `
             .container-cubo{
                max-width: 1600px; 
             }
             .flex{
               display: flex;
             }
            .flex-col{
              flex-direction: column; 
            }
            .divider-content8{
              background-image: linear-gradient(#e5e7eb 0%, #e5e7eb 100%); 
              width: 100%; 
              height: 2px;
            }
            .div-divider{
              width: 6rem; 
              height: 2px;
              background-image: linear-gradient(#6366F1 0%, #6366F1 100%);
            }
            .gap-30{
              gap: 30px; 
            }
            .item-content8{
              width: 33%; 
              display: flex;
              flex-direction: column; 
              align-items: flex-start; 
            }
            .image-content8{
              width: 100%; 
              height: 16rem;
              object-fit: cover; 
              border-radius: 0.5rem; 
            }
             .principal-title{
            font-size: 30px;
            color: #363940;
            margin:0;
           }
            .body-text{
            font-size: 16px;
            color: #363940;
            margin-top:30px;
           }
            .link-item-content8{
              color: #6366F1;
              font-size: 1rem; 
              margin-top :15px; 
            }
            .w-50{
              width: 50%; 
            }
            .justify-between{
                  justify-content: space-between
               }

 
            @media (max-width: 992px){
               .sm-flex-col{
                 flex-direction: column; 
               }
            .item-content8{
              width: 100%; 
            }
               .w-50{
              width: 100%; 
            }
            }
            `,
      },
    },
    view: {

    },
  };
  return component;
};