export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type: 'Container',
              name: "Container Numeros",
              attributes: { class: 'container-cubo align-center d-column' },
              components: [
                {
                    type: 'text',
                    content: 'Subtítulo',
                    name: 'Subtitulo',
                    attributes: { class: 'subtitle text-uppercase' },
                  },
                  {
                    tagName: 'h2',
                    type: 'text',
                    name: 'Titulo',
                    components: 'Big Numbers',
                    attributes: { class: 'principal-title' },
                    traits: [
                      {
                        type: "select",
                        options: [
                          { value: "h1", name: opts.labelN1 },
                          { value: "h2", name: opts.labelN2 },
                          { value: "h3", name: opts.labelN3 },
                          { value: "h4", name: opts.labelN4 },
                          { value: "h5", name: opts.labelN5 },
                          { value: "h6", name: opts.labelN6 },
                        ],
                        label: 'Tamanho',
                        name: "tagName",
                        changeProp: 1,
                      },
                      {
                        type: 'id',
                        name: 'id',
                        Label: 'id',
                      },
                    ],
                  },
                  {
                    type: 'text',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam viverra ex mauris, sed semper elit fermentum at. Nunc mattis tellus at maximus lacinia. Pellentesque felis lorem, scelerisque at ',
                    attributes: { class: 'body-text text-center w-60 w-sm-100' },
                  },
                  {
                    type:'divCounters7'
                  }, 
                {
                  type: 'button',
                },
      
              ],
              styles: `
             .container-cubo{
                max-width: 1600px; 
             }
             .align-center{
                align-items: center; 
             }
             .text-center{
              text-align: center; 
             }
             .d-column{
              display: flex; 
              flex-direction: column; 
             }
             .w-60{
                width: 60%; 
             }
             .body-text{
              font-size: 16px; 
              color: #95A1BB; 
              margin-top: 30px;
            }
            .principal-title{
              font-size: 30px; 
              color: #363940; 
              margin:0;
             }
             .subtitle{
              font-size: 13px; 
              color: #747474; 
             }
             .text-uppercase{
              text-transform: uppercase;
             }
             @media (max-width:992px){
                .w-sm-100{
                    width: 100% !important;
                }
             }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};