export default (opts = {}) => {
  const block = {
    id: "button2",
    label: "Simples",
    fromPlugin: 'button',
    apper : 'out', 
    media: '<img class="preview-block" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABbCAYAAACFziAsAAAABHNCSVQICAgIfAhkiAAAAxhJREFUeF7tnLtrVFEQh89FBRELNYWCio/GNgH/AAsfWKQVbAVLETsLC1EDqUSxFKy100pFwdrKJog2okQhFlELC0GTdU7MNuuD2czsyW/xu3C4N9kzc+Z+3529y3LZrqxuvV7vpB1es3HQxrb+/9mPlMAXy/7GxqWu6x7WlToTscH2V21crH+PdHmS/41Az16YsXG5CjliB89gte4Elq2C41XIIzs4se7lUEAl8LgKWbCDnfCQIPCxCqnvX2wiBBAiIqJfBkIQIkZArBw6BCFiBMTKoUMQIkZArBw6BCFiBMTKoUMQIkZArBw6BCFiBMTKoUMQIkZArBw6BCFiBMTKoUMQIkZArBw6BCFiBMTKoUMQIkZArBx3h9x6UsqPJbHqx6Scjfb09LljvmLdQs7eKYUn6nxQB2fVJ9hvn/HFDiXEl5JZfyKAELHrAiEIESMgVg4dghAxAmLl0CEIESMgVg4dghAxAmLl0CH/u5CpfaVMT5ayd0KMRMNyFr+Wcu95KS/e/b5o8w6ZPVXKxNaGZy+61PxiKVceCAjxXgGiHFPLqt+MD25ePmnf9noXTD1z0WQIERMjIYR7yK+rYv6T3UPuC7xlrXzKmrJPWTvELteG5Uh9ymp43mO5lPcem3ZTH0tKDYtGSEPYnqUQ4qHUcA5CGsL2LIUQD6WGcxDSELZnKYR4KDWcg5CGsD1LIcRDqeEchDSE7VlqJEI2b/IszZxBAt++j+Dp9w+fAR0hsHu7L9r9XZYvHbOiBBASJZgcj5BkoNF0CIkSTI5HSDLQaDqERAkmxyMkGWg0HUKiBJPjEZIMNJoOIVGCyfEISQYaTYeQKMHkeIQkA42mQ0iUYHI8QpKBRtMhJEowOR4hyUCj6RASJZgcj5BkoNF0CIkSTI5HSDLQaDqERAkmxyMkGWg0HUKiBJPjq5BXlvNQcl7SrY3A6yrkhsWeX1s8UckEblYhWyzpnI0DyclJNxyBlzb9cP0V7GJSdtnuuo3Tw+VgdhKBu5bnQtd1CytC+puJ2WPHR23sT1qINP8m8NZefmoi3ven/QRyGdppj1BSKwAAAABJRU5ErkJggg==">',
    content: { type: "button2" },
  };

  return block;
};

