export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname: 'div',
              name: "Div",
              attributes: { class: 'd-flex gap-16 d-sm-col wrap' },
              components: [
                 {
                  type: 'modelBusiness1',
                 },
                 {
                  type: 'modelBusiness1',
                 },
                 {
                  type: 'modelBusiness1',
                 },
                 {
                  type: 'modelBusiness1',
                 },
              ],
              styles: `
              .d-flex{
                display:flex;
              }
              .gap-16{
                gap:16px; 
              }
              .wrap{
                flex-wrap: wrap;
              }
      
              @media (max-width:992px){
                .d-sm-col{
                  flex-direction: column !important; 
                }
              }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};