export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type: 'Container',
              name: "Container Conteudos",
              attributes: { class: 'container-cubo' },
              components: [
                {
                  type: 'div',
                  attributes: { class: 'flex sm-flex-col gap-20' },
                  components: [
                    {
                      type: 'div', 
                      attributes: { class: 'item-content7' },
                      components: [
                        {
                           type: 'image', 
                           attributes: { class: 'image-content7', src: 'https://dummyimage.com/64x64' },
                        },
                        {
                          type: 'title2',      
                          components: [
                            {
                              type:'text',
                              name:'H2',
                              components: 'Lorem Ipsum',
                              attributes: { class: 'title-content7' },
                            },
                          ],
                        },
                        {
                          type: 'text', 
                          attributes: { class: 'text-content7' },
                          components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus. Pellentesque eu finibus dolor. Aenean sit amet auctor est. Nunc et libero eu enim sollicitudin ornare. Sed arcu lectus, congue eget scelerisque eget'
                        }, 
                        {
                          type: 'button3', 
                          attributes: { class: 'mt-12' },
                        }
                      ]
                    }, 
                    {
                      type: 'div', 
                      attributes: { class: 'item-content7' },
                      components: [
                        {
                           type: 'image', 
                           attributes: { class: 'image-content7', src: 'https://dummyimage.com/64x64' },
                        },
                        {
                          type: 'title2',      
                          components: [
                            {
                              type:'text',
                              name:'H2',
                              components: 'Lorem Ipsum',
                              attributes: { class: 'title-content7' },
                            },
                          ],
                        },
                        {
                          type: 'text', 
                          attributes: { class: 'text-content7' },
                          components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus. Pellentesque eu finibus dolor. Aenean sit amet auctor est. Nunc et libero eu enim sollicitudin ornare. Sed arcu lectus, congue eget scelerisque eget'
                        }, 
                        {
                          type: 'button3', 
                          attributes: { class: 'mt-12' },
                        }
                      ]
                    }, 
                  ]
                },
              ],
              styles: `
             .container-cubo{
                max-width: 1600px; 
             }
              .flex{
                display: flex; 
              }
              .item-content7{
                width: 50%; 
                display: flex; 
                flex-direction: column; 
                align-items: center;
              }
              .gap-20{
                gap: 20px; 
              }
              .image-content7{
                width: 16rem; 
                height: 16rem; 
                border-radius: 0.5rem; 
                object-fit: cover; 
              }
              .title-content7{
                 font-weight: 500; 
                 font-size: 1.5rem; 
                 line-height: 2rem; 
                 margin-top: 1.5rem;
                 color: #111827;
                 text-align: center;  
              }
              .text-content7{
                line-height: 1.625; 
                font-size: 1rem; 
                color: #4B5563;
                margin-top: 15px; 
                text-align: center; 
              }
              .mt-12{
                  margin-top: 48px; 
               }
            
            @media (max-width: 992px){
              .sm-flex-col{
                flex-direction: column; 
              }
              .item-content7{
                width: 100%; 
              }
            }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};