export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: 'Colunas Selos',
              attributes: { class: 'row-cubo row-sm-reverse' },
              components: [
                {
                  type: 'columnItemStamps2', 
                }, 
                {
                  type: 'columnStamps2', 
                },
              
              ],
              styles: `
             .row-cubo{
                display: flex; 
                flex-direction:row; 
                width:100%; 
             }
             @media (max-width:992px){
              .row-cubo{
                flex-direction:column;
              }
              .row-sm-reverse{
                flex-direction:column-reverse;
              }
             }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};