export default (opts = {}) => {
    const block = {
      id: "columns3",
      label: "3 Colunas",
      fromPlugin: 'layout',
      apper : 'out', 
      media: '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><path id="layout-three-columns" d="M0,2.813A2.813,2.813,0,0,1,2.813,0H27.188A2.813,2.813,0,0,1,30,2.813V27.188A2.813,2.813,0,0,1,27.188,30H2.813A2.812,2.812,0,0,1,0,27.188Zm2.813-.937a.937.937,0,0,0-.937.938V27.188a.938.938,0,0,0,.938.938H9.375V1.875ZM18.75,28.125V1.875h-7.5v26.25Zm1.875,0h6.563a.938.938,0,0,0,.938-.937V2.813a.938.938,0,0,0-.937-.937H20.625Z" fill="#92949f"/></svg>',
      content: { type: "columns3" },
    };
  
    return block;
  };
  

  
