export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        type: 'Container',
        name: "Container Preços",
        attributes: { class: 'container-cubo flex flex-col items-center' },
        components: [
          {
            type: 'div',
            attributes: { class: 'flex flex-col items-center' },
            components: [
              {
                type: 'title2', 
                components: [
                  {
                    type:'text',
                    name:'H2',
                    components: 'Preços',
                    attributes: { class: 'principal-title' },
                  },
                ],
              },
              {
                type: 'text', 
                attributes: { class: 'body-text w-80 text-center' },
                components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae'
              }
            ]
          },  
          {
            type: 'div',
            attributes: { class: 'w-80 overflow-auto mt-50' },
            components: [
              {
                type: 'table', 
                attributes: { class: 'table-price' },
                components: [
                  {
                    type: 'thead', 
                    components: [
                      {
                        type: 'row', 
                        components: [
                          {
                            type: 'cell',
                            tagname: 'th', 
                            attributes: { class: 'th-price' },
                            components: [
                              {
                                type: 'text', 
                                components: 'Planos',
                                attributes: { class: 'text-th-price' },
                              }
                            ]
                          },
                          {
                            type: 'cell',
                            tagname: 'th', 
                            attributes: { class: 'th-price' },
                            components: [
                              {
                                type: 'text', 
                                components: 'Velocidade',
                                attributes: { class: 'text-th-price' },
                              }
                            ]
                          },
                          {
                            type: 'cell',
                            tagname: 'th', 
                            attributes: { class: 'th-price' },
                            components: [
                              {
                                type: 'text', 
                                components: 'Armazenamento',
                                attributes: { class: 'text-th-price' },
                              }
                            ]
                          },
                          {
                            type: 'cell',
                            tagname: 'th', 
                            attributes: { class: 'th-price' },
                            components: [
                              {
                                type: 'text', 
                                components: 'Preço',
                                attributes: { class: 'text-th-price' },
                              }
                            ]
                          },
                        ]
                      }, 

                    ]
                  }, 
                  {
                    type: 'tbody', 
                    components: [
                      {
                        type: 'row',
                        components: [
                          {
                            type: 'cell',
                            tagname: 'td', 
                            attributes: { class: 'p-10' },
                            components: [
                              {
                                type: 'text',
                                components: 'Inicial',
                                attributes: { class: 'text-td-price' },
                              }
                            ]
                          },
                          {
                            type: 'cell',
                            tagname: 'td', 
                            attributes: { class: 'p-10' },
                            components: [
                              {
                                type: 'text',
                                components: '5mb/s',
                                attributes: { class: 'text-td-price' },
                              }
                            ]
                          },
                          {
                            type: 'cell',
                            tagname: 'td', 
                            attributes: { class: 'p-10' },
                            components: [
                              {
                                type: 'text',
                                components: '15Gb',
                                attributes: { class: 'text-td-price' },
                              }
                            ]
                          },
                          {
                            type: 'cell',
                            tagname: 'td', 
                            attributes: { class: 'p-10' },
                            components: [
                              {
                                type: 'text',
                                components: 'Grátis',
                                attributes: { class: 'text-td-price' },
                              }
                            ]
                          },
                        ]
                      },
                      {
                        type: 'row',
                        components: [
                          {
                            type: 'cell',
                            tagname: 'td', 
                            attributes: { class: 'p-10' },
                            components: [
                              {
                                type: 'text',
                                components: 'Inicial',
                                attributes: { class: 'text-td-price' },
                              }
                            ]
                          },
                          {
                            type: 'cell',
                            tagname: 'td', 
                            attributes: { class: 'p-10' },
                            components: [
                              {
                                type: 'text',
                                components: '5mb/s',
                                attributes: { class: 'text-td-price' },
                              }
                            ]
                          },
                          {
                            type: 'cell',
                            tagname: 'td', 
                            attributes: { class: 'p-10' },
                            components: [
                              {
                                type: 'text',
                                components: '15Gb',
                                attributes: { class: 'text-td-price' },
                              }
                            ]
                          },
                          {
                            type: 'cell',
                            tagname: 'td', 
                            attributes: { class: 'p-10' },
                            components: [
                              {
                                type: 'text',
                                components: 'Grátis',
                                attributes: { class: 'text-td-price' },
                              }
                            ]
                          },
                        ]
                      },
                      {
                        type: 'row',
                        components: [
                          {
                            type: 'cell',
                            tagname: 'td', 
                            attributes: { class: 'p-10' },
                            components: [
                              {
                                type: 'text',
                                components: 'Inicial',
                                attributes: { class: 'text-td-price' },
                              }
                            ]
                          },
                          {
                            type: 'cell',
                            tagname: 'td', 
                            attributes: { class: 'p-10' },
                            components: [
                              {
                                type: 'text',
                                components: '5mb/s',
                                attributes: { class: 'text-td-price' },
                              }
                            ]
                          },
                          {
                            type: 'cell',
                            tagname: 'td', 
                            attributes: { class: 'p-10' },
                            components: [
                              {
                                type: 'text',
                                components: '15Gb',
                                attributes: { class: 'text-td-price' },
                              }
                            ]
                          },
                          {
                            type: 'cell',
                            tagname: 'td', 
                            attributes: { class: 'p-10' },
                            components: [
                              {
                                type: 'text',
                                components: 'Grátis',
                                attributes: { class: 'text-td-price' },
                              }
                            ]
                          },
                        ]
                      },
                      {
                        type: 'row',
                        components: [
                          {
                            type: 'cell',
                            tagname: 'td', 
                            attributes: { class: 'p-10' },
                            components: [
                              {
                                type: 'text',
                                components: 'Inicial',
                                attributes: { class: 'text-td-price' },
                              }
                            ]
                          },
                          {
                            type: 'cell',
                            tagname: 'td', 
                            attributes: { class: 'p-10' },
                            components: [
                              {
                                type: 'text',
                                components: '5mb/s',
                                attributes: { class: 'text-td-price' },
                              }
                            ]
                          },
                          {
                            type: 'cell',
                            tagname: 'td', 
                            attributes: { class: 'p-10' },
                            components: [
                              {
                                type: 'text',
                                components: '15Gb',
                                attributes: { class: 'text-td-price' },
                              }
                            ]
                          },
                          {
                            type: 'cell',
                            tagname: 'td', 
                            attributes: { class: 'p-10' },
                            components: [
                              {
                                type: 'text',
                                components: 'Grátis',
                                attributes: { class: 'text-td-price' },
                              }
                            ]
                          },
                        ]
                      },
                      {
                        type: 'row',
                        components: [
                          {
                            type: 'cell',
                            tagname: 'td', 
                            attributes: { class: 'p-10' },
                            components: [
                              {
                                type: 'text',
                                components: 'Inicial',
                                attributes: { class: 'text-td-price' },
                              }
                            ]
                          },
                          {
                            type: 'cell',
                            tagname: 'td', 
                            attributes: { class: 'p-10' },
                            components: [
                              {
                                type: 'text',
                                components: '5mb/s',
                                attributes: { class: 'text-td-price' },
                              }
                            ]
                          },
                          {
                            type: 'cell',
                            tagname: 'td', 
                            attributes: { class: 'p-10' },
                            components: [
                              {
                                type: 'text',
                                components: '15Gb',
                                attributes: { class: 'text-td-price' },
                              }
                            ]
                          },
                          {
                            type: 'cell',
                            tagname: 'td', 
                            attributes: { class: 'p-10' },
                            components: [
                              {
                                type: 'text',
                                components: 'Grátis',
                                attributes: { class: 'text-td-price' },
                              }
                            ]
                          },
                        ]
                      },

                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'div',
            attributes: { class: 'flex sm-flex-col justify-between w-80 gap-15' },
            components: [
              {
                type: 'link', 
                attributes: { class: 'more' },
                components: 'Saiba mais'
              },
              {
                type: 'button3'
              }
            ]
          }
        ],
        styles: `
             .container-cubo{
                max-width: 1600px; 
             }
             .flex{
               display: flex; 
             }
             .flex-col{
               flex-direction: column; 
             }
              .justify-between{
                justify-content: space-between
              }
            .items-center{
              align-items: center
            }
            .principal-title{
            font-size: 30px;
            color: #363940;
            margin:0;
           }
           .overflow-auto{
             overflow-x: auto; 
             overflow-y: auto; 
           }
          .body-text{
            font-size: 16px;
            color: #363940;
            margin-top:30px;
           }
          .w-80{
            width: 80%; 
          }
          .gap-15{
            gap: 15px; 
          }
          .text-center{
            text-align: center; 
          }
          .table-price{
            table-layout: auto;
            text-align: left;
            width: 100%;
           }
          .th-price{
            background-image: linear-gradient(#f3f4f6 0%, #f3f4f6 100%);
            padding: 10px; 
          }
          .text-th-price{
            font-size: 16px; 
            color: #000;
          }
          .text-td-price{
            font-size: 16px; 
            color: #707070;
          }
          .p-10{
            padding: 10px; 
          }
          .more{
            color: #6366f1;
            font-size: 16px; 
            text-decoration: none; 
            margin-top: 30px;
            display: block;
          }
          .mt-50{
            margin-top: 50px;
          }

          @media (max-width: 992px){
            .w-80{
            width: 100%; 
          }
           .sm-flex-col{
             flex-direction: column; 
           }
          }

            `,
      },
    },
    view: {

    },
  };
  return component;
};