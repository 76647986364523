export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type:'div', 
              name: 'Card Serviços',
              attributes: { class:'card-service-3'},
              components: [
                {
                  type: 'div',
                  attributes: { class:'header-card-service'}, 
                  components :[
                    {
                      type: 'text',
                      content:  'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', 
                      attributes: { class:'title-service'}, 
                    },
                    {
                      type: 'image', 
                      attributes: { class: 'icon-card-service', src: 'https://placehold.co/20x20'},
                    },
                  ],
                },
                {
                  type: 'text',
                  content:  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et bibendum risus. Cras faucibus viverra dolor, at vehicula erat tempus vel. Sed a velit dictum, semper nulla at, volutpat dolor. In egestas tortor a tellus dignissim aliquet.', 
                  attributes: { class:'text-service'}, 
                },
              ],
              styles: `
               .card-service-3{
                padding:30px; 
                background: #E9E9E9; 
                width: 25%; 
               }
               .header-card-service{
                display:flex; 
                justify-content:center; 
               }
               .icon-card-service{
                width:20px; 
                margin-left: 30px; 
               }
               .title-service{
                font-size: 20px; 
                color: #363940; 
                font-weight: 500; 
               }
               .text-service{
                font-size: 16px; 
                color: #95A1BB; 
                margin-top: 20px; 
               }
      
               @media (max-width:992px){
                .card-service-3{
                  width:100%;
                }
               }
           `,
             },
          },
          view: {
      
          },
};
  return component; 
};