export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname: 'div',
              name: 'Coluna Topo',
              attributes: { class: 'w-100 position-relative h-60 h-sm-auto pattern-padding' },
              components: [
                {
                  tagname:'img',
                  type: 'image',
                  attributes: {class: 'logo', src: 'https://placehold.co/150x50'}, 
                },
                {
                  tagName: 'h1',
                  type:'text',
                  name:'Titulo Principal',
                  components: 'Topo da Landing Page',
                  attributes: { class: 'font-48 title-color font-medium w-60 w-sm-100' },
                  traits: [
                    {
                      type: "select",
                      options: [
                        { value: "h1", name: opts.labelN1 },
                        { value: "h2", name: opts.labelN2 },
                        { value: "h3", name: opts.labelN3 },
                        { value: "h4", name: opts.labelN4 },
                        { value: "h5", name: opts.labelN5 },
                        { value: "h6", name: opts.labelN6 },
                      ],
                      label: 'Tamanho',
                      name: "tagName",
                      changeProp: 1,
                    },
                    {
                      type:'id',
                      name:'id',
                      Label:'id',
                    }
                  ],
                },
                {
                  type: 'text',
                  content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et bibendum risus. Cras faucibus viverra dolor, at vehicula',
                  attributes: { class: 'subtitle-color font-18 margin-top-10 w-60 w-sm-100' },
                },
                {
                  type: 'divForm3'
                },
                {
                  type: 'scrollDown3'
                }
              ],
              styles: `
              .w-100{
                width:100%
              }
              .h-40{
                height: 40vh;
              }
              .h-60{
                height: 60vh;
              }
              .pattern-padding{
                padding:50px 5%;
              }
               .font-48{
                font-size:48px;
               }
               .font-18{
                font-size:18px; 
               }
               .title-color{
                color:#363940;
               }
               .subtitle-color{
                color:#95A1BB; 
               }
               .font-medium{
                font-weight: 500; 
               }
               .margin-top-10{
                margin-top:10px; 
               }
               .margin-bottom-50{
                margin-bottom:50px;
               }
               .logo{
                width:100px;
               }
            .w-60{
              width:60%;
            }
            @media (max-width:992px){
              .w-sm-100{
                width:100% !important; 
              }
              .h-sm-auto{
                height:auto !important; 
              }
            }
            `,
            },
          },
          view: {
      
          },
    };
    return component;
};