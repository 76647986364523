import block from "./block.js";
import component from "./components/index.js"
import containerCta2 from "./components/containerCta2.js";


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerCta2", containerCta2(opts));
  editor.DomComponents.addType("cta2", component(opts));
    //block
  editor.BlockManager.add("cta2", block(opts));
};