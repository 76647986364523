export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        type: 'Container',
        name: "Container Preços",
        attributes: { class: 'container-cubo' },
        components: `
        <div class="flex sm-flex-col gap-40">
   <div class="w-40 flex flex-col">
      <div class="flex gap-10 relative pb-12">
            <div class="line-step-1"></div>
            <img class="icon-step-1" src="https://dummyimage.com/50x50" alt="step">
         <div class="">
            <h2 class="title-step-1">Passo 1</h2>
            <p class="text-step-1">Morbi scelerisque dignissim leo in auctor</p>
         </div>
      </div>
      <div class="flex gap-10 relative pb-12">
         <div class="line-step-1"></div>
         <img class="icon-step-1" src="https://dummyimage.com/50x50" alt="step">
      <div class="">
         <h2 class="title-step-1">Passo 2</h2>
         <p class="text-step-1">Morbi scelerisque dignissim leo in auctor</p>
      </div>
   </div>
   <div class="flex gap-10 relative pb-12">
      <div class="line-step-1"></div>
      <img class="icon-step-1" src="https://dummyimage.com/50x50" alt="step">
   <div class="">
      <h2 class="title-step-1">Passo 3</h2>
      <p class="text-step-1">Morbi scelerisque dignissim leo in auctor</p>
   </div>
</div>
<div class="flex gap-10 relative pb-12">
   <div class="line-step-1"></div>
   <img class="icon-step-1" src="https://dummyimage.com/50x50" alt="step">
<div class="">
   <h2 class="title-step-1">Passo 4</h2>
   <p class="text-step-1">Morbi scelerisque dignissim leo in auctor</p>
</div>
</div>
<div class="flex gap-10 relative pb-12">
   <img class="icon-step-1" src="https://dummyimage.com/50x50" alt="step">
<div class="">
   <h2 class="title-step-1">Final</h2>
   <p class="text-step-1">Morbi scelerisque dignissim leo in auctor</p>
</div>
</div>
   </div>
   <img class="w-60"
      src="https://dummyimage.com/1200x500" alt="step">
</div>
        `,
        styles: `
             .container-cubo{
                max-width: 1600px; 
             }
            .flex{
               display: flex. 
              }
            .gap-40{
              gap: 40px; 
            }
            .w-40{
              width: 40%; 
            }
            .w-60{
            width: 60%; 
            }
            .relative{
              position: relative; 
            }
            .line-step-1{
              width: 1px; 
              height: 100%; 
              background-image: linear-gradient(#e5e7eb 0%, #e5e7eb 100%);
              position: absolute; 
              top: 0; 
              left: 25px; 
            }
            .icon-step-1{
              width: 50px; 
              height: 50px; 
              border-radius: 100%; 
              position: relative;
              z-index: 9;
            }
            .gap-10{
              gap: 10px; 
            }
            .title-step-1{
              font-size: 0.875rem;
              font-weight: 500;
              color: #111827;
              margin-bottom: 10px;  
            }
            .text-step-1{
              font-size: 16px; 
              color: #4b5563;
            }
            .pb-12{
              padding-bottom: 3rem;
            }

           @media (max-width: 992px){
             .sm-flex-col{
               flex-direction: column; 
             }
                .w-40{
              width: 100%; 
            }
                .w-60{
              width: 100%; 
            }
           }
            `,
      },
    },
    view: {

    },
  };
  return component;
};