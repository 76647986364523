export default (opts = {}) => {
  const block = {
    category: 'Preços',
    id: "pricing2",
    label: "Preços (2)",
    fromPlugin: 'blocks',
    apper : 'out',
    media: '<svg class="preview-block" viewBox="0 0 266 150" fill="none" width="266"  height="150" ><path fill="#FFFFFF" d="M0 0h266v150H0z"></path><rect x="110" y="20" width="46" height="5" rx="2.5" fill="#4A5568"></rect><rect x="87" y="29" width="92" height="4" rx="2" fill="#A0AEC0"></rect><rect x="106" y="37" width="55" height="4" rx="2" fill="#A0AEC0"></rect><rect x="50" y="57" width="167" height="11" rx="1" fill="#E2E8F0"></rect><rect x="55" y="61" width="39" height="3" rx="1.5" fill="#4A5568"></rect><rect x="55" y="121" width="19" height="3" rx="1.5" fill="#6366F1"></rect><rect x="108" y="61" width="24" height="3" rx="1.5" fill="#4A5568"></rect><rect x="145" y="61" width="30" height="3" rx="1.5" fill="#4A5568"></rect><rect x="188" y="61" width="20" height="3" rx="1.5" fill="#4A5568"></rect><rect x="55" y="74" width="26" height="2" rx="1" fill="#A0AEC0"></rect><rect x="108" y="74" width="12" height="2" rx="1" fill="#A0AEC0"></rect><path d="M216.5 82a.5.5 0 010 1h-166a.5.5 0 010-1h166z" fill="#E2E8F0"></path><rect x="145" y="74" width="13" height="2" rx="1" fill="#A0AEC0"></rect><rect x="188" y="74" width="20" height="2" rx="1" fill="#A0AEC0"></rect><rect x="55" y="89" width="39" height="2" rx="1" fill="#A0AEC0"></rect><rect x="108" y="89" width="17" height="2" rx="1" fill="#A0AEC0"></rect><path d="M216.5 97a.5.5 0 010 1h-166a.5.5 0 010-1h166z" fill="#E2E8F0"></path><rect x="145" y="89" width="18" height="2" rx="1" fill="#A0AEC0"></rect><rect x="188" y="89" width="13" height="2" rx="1" fill="#A0AEC0"></rect><rect x="55" y="104" width="33" height="2" rx="1" fill="#A0AEC0"></rect><rect x="108" y="104" width="14" height="2" rx="1" fill="#A0AEC0"></rect><path d="M216.5 112a.5.5 0 010 1h-166a.5.5 0 010-1h166z" fill="#E2E8F0"></path><rect x="182" y="119" width="31" height="10" rx="2" fill="#6366F1"></rect><rect x="145" y="104" width="18" height="2" rx="1" fill="#A0AEC0"></rect><rect x="188" y="104" width="20" height="2" rx="1" fill="#A0AEC0"></rect></svg>',
    content: { type: 'pricing2' },
  };

  return block;
};