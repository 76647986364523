import block from "./block.js";
import component from "./components/index.js"
import containerCta3 from "./components/containerCta3.js";


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerCta3", containerCta3(opts));
  editor.DomComponents.addType("cta3", component(opts));
    //block
  editor.BlockManager.add("cta3", block(opts));
};