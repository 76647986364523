export default (opts = {}) => {
  const component = {
      model: {
        defaults: {
          tagname: 'div',
          name: 'Colunas Topo',
          attributes: { class: 'row-cubo d-sm-column' },
          components: [
            {
              type: 'columnCall2'
            },
            {
              type: 'columnImageForm2'
            }           
          ], 
          styles: `
         .row-cubo{
            display: flex; 
            width:100%; 
         }
         @media (max-width:992px){
          .d-sm-column{
            flex-direction:column;
          }
         }
        `,
        },
      },
      view: {
  
      },
};
return component; 
}; 