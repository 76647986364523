export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type: 'Container',
              name: "Container Numeros",
              attributes: { class: 'container-cubo' },
              components: [
                {
                  type: 'columnsNumbers6',
                },
      
              ],
              styles: `
             .container-cubo{
                max-width: 1600px; 
             }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};