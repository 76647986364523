export default (opts = {}) => {
  const block = {
    category: 'Cabeçalho',
    id: "header2",
    label: "Cabeçalho (2)",
    fromPlugin: 'blocks',
    apper : 'out',
    media: '<svg class="preview-block" fill="none" viewBox="0 0 266 150" width="266"  height="150" ><path fill="#FFFFFF" d="M0 0h266v150H0z"></path><path stroke="#E2E8F0" d="M266 38.5H0" fill="none"></path><rect x="141" y="14" width="29" height="10" rx="2" fill="#CBD5E0"></rect><circle cx="29" cy="19" r="9" fill="#6366F1"></circle><rect x="74.132" y="17" width="16.604" height="4" rx="2" fill="#4A5568"></rect><rect x="95.264" y="17" width="16.604" height="4" rx="2" fill="#4A5568"></rect><rect x="116.396" y="17" width="16.604" height="4" rx="2" fill="#4A5568"></rect><rect x="53" y="17" width="16.604" height="4" rx="2" fill="#4A5568"></rect><path fill="#CBD5E0" d="M45 8h1v22h-1z"></path></svg>',
    content: { type: 'header2' },
  };

  return block;
};