import block from "./block.js";
import component from "./components/index.js"
import containerGallery from "./components/containerGallery.js";


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerGallery", containerGallery(opts));
  editor.DomComponents.addType("gallery1", component(opts));
    //block
  editor.BlockManager.add("gallery1", block(opts));
};