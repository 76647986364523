export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              name: 'Div',
              attributes: { class: 'time-line-3' },
              components: [
                 {
                  type: 'itemTimeLine3', 
                 },
                 {
                  type: 'itemTimeLine3', 
                 },
                 {
                  type: 'itemTimeLine3', 
                 },
                 {
                  type: 'itemTimeLine3', 
                 },
                 {
                  type: 'itemTimeLine3', 
                 },
             
              ],
              styles: `
               .time-line-3{
                display:flex; 
                gap: 40px; 
                margin-top:50px;
                justify-content:center; 
               }
               @media (max-width:992px){
                .time-line-3{
                  flex-wrap: wrap; 
                  gap: 16px;
                }
               }
      
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};