export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div',  
              name: "Div",
              attributes: { class: 'header-legal' },
              components: [
                {
                  tagname:'h3',
                  type:'text',
                  content: 'Lorem ipsum',
                  attributes: {class: 'title-legal'}, 
                  traits: [
                    {
                      type: "select",
                      options: [
                        { value: "h1", name: opts.labelN1 },
                        { value: "h2", name: opts.labelN2 },
                        { value: "h3", name: opts.labelN3 },
                        { value: "h4", name: opts.labelN4 },
                        { value: "h5", name: opts.labelN5 },
                        { value: "h6", name: opts.labelN6 },
                      ],
                      label: 'Tamanho',
                      name: "tagName",
                      changeProp: 1,
                    },
                  ],
                },
                {
                  type: 'image',
                  tagname:'img',
                  attributes: {class: 'icon-legal'},
                },
              ],
              styles: `
              .header-legal{
                display:flex; 
                justify-content: space-between;
                gap:16px; 
              }
              .title-legal{
                font-size: 20px; 
                color: #333333; 
                font-weight:500; 
              }
              .icon-legal{
                width:20px; 
                height: 24px; 
              }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};