import block from "./block.js";
import component from "./components/index.js"
import containerStatistics3 from "./components/containerStatistics3.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerStatistics3", containerStatistics3(opts));
  editor.DomComponents.addType("statistics3", component(opts));
    //block
  editor.BlockManager.add("statistics3", block(opts));
};