import block from "./block.js";
import component from "./components/index.js"
import containerContent8 from "./components/containerContent8.js";


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerContent8", containerContent8(opts));
  editor.DomComponents.addType("content8", component(opts));
    //block
  editor.BlockManager.add("content8", block(opts));
};