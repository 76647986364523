export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        tagname:'div', 
        name: "Item Blog",
        attributes: { class: 'blog-item2' },
        components: [
          {
            type:'default', 
            attributes: { class: 'div-item2' },
            components: [
              {
                type: "image",
                attributes: { class: "image-blog2", 
                src: "https://dummyimage.com/720x400" },
                alt: 'Blog'
              },
              {
                type: 'title2',
                components: [
                  {
                    type:'text',
                    name:'H2',
                    components: 'Categoria',
                    attributes: { class: 'category2' },
                  },
                ],
              }, 
              {
                type: 'title3',
                components: [
                  {
                    type:'text',
                    name:'H3',
                    components: 'Lorem Ipsum',
                    attributes: { class: 'title-blog2' },
                  },
                ],
              }, 
              {
                type: 'text', 
                attributes: { class: 'text-blog2' },
                components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus.',
              },
              {
                type: 'link', 
                attributes: { class: 'read2' },
                components: 'Leia mais',
              },      
            ]
        },
        ],
        styles: `
         .blog-item2{
           width: 33%; 
         }
         .div-item2{
          width: 100%; 
          height: 100%; 
          padding: 20px; 
          border-radius: 20px;
          border: 2px solid #E5E7EB;
          overflow-x: hidden;
          overflow-y: hidden;  
         }
         .image-blog2{
           width: 100%; 
           height: 12rem;
           border-radius: 10px; 
           object-fit: cover; 
         }
        .category2{
          letter-spacing: 0.1em;
          font-weight: 500; 
          font-size: 0.75rem;
          line-height: 1rem; 
          color: #9CA3AF; 
          margin-top: 20px; 
        }
        .title-blog2{
         font-size: 1.5rem; 
         line-height: 2rem; 
         font-weight: 500; 
         color: #111827; 
         margin-top: 10px; 
        }
        .text-blog2{
          color: #6B7280; 
          line-height: 1.625; 
          margin-top: 15px; 
          font-size: 16px;  
        }
        .read2{
            color: #6366F1; 
            font-size: 16px; 
            margin-top: 20px; 
            display: block;           
          }
         @media (max-width: 992px){
           .blog-item2{
           width: 100%; 
         }
         }
      `,
       },
    },
    view: {

    },
  };

  return component;
};
