import block from "./block.js";
import component from "./components/index.js"
import containerBlog3 from "./components/containerBlog3.js";
import columnsBlog3 from "./components/columnsBlog3.js";
import itemBlog3 from "./components/itemBlog3.js";


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("columnsBlog3", columnsBlog3(opts));
  editor.DomComponents.addType("containerBlog3", containerBlog3(opts));
  editor.DomComponents.addType("itemBlog3", itemBlog3(opts));
  editor.DomComponents.addType("blog3", component(opts));
    //block
  editor.BlockManager.add("blog3", block(opts));
};