import block from "./block.js";
import component from "./components/index.js"
import containerHeader2 from "./components/containerHeader2.js";


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerHeader2", containerHeader2(opts));
  editor.DomComponents.addType("header2", component(opts));
    //block
  editor.BlockManager.add("header2", block(opts));
};