export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname: 'div',
              name: 'Container Serviços',
              attributes: { class: 'container-cubo' },
              components: [
                {
                  type: 'rowCardService5',
                },
              ],
              styles: `
              .body-text{
                font-size: 16px; 
                color: #363940; 
                margin-top:30px;
               }
               .principal-title{
                font-size: 30px; 
                color: #363940;
                margin:0;
               }
                .container-cubo{
                  max-width: 1600px
                }
               .body-text{
                font-size: 16px; 
                color: #363940; 
                margin-top:30px;
               }
               .text-center{
                text-align: center;
               }
               .subtitle{
                font-size: 13px; 
                color: #747474; 
               }
               .text-uppercase{
                text-transform: uppercase;
               }
               .margin-top-20{
                margin-top:20px; 
              }
              .col-center{
                display:flex; 
                flex-direction:column; 
                align-items: center;
              }
              .w-70{
                width:70%; 
              }
              @media (max-width:992px){
                .w-sm-100{
                  width:100% !important; 
                }
              }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};