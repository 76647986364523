import component from "../section/component";

export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              style: {
                'display':'flex', 
                'gap':'20px'
              },
              components:[
                {
                  type:'image',
                  traits: [
                    {
                      type: 'text', 
                      name:'src',
                      label: 'link',
                      id:'src',
                    },
                    {
                      type: 'text', 
                      name:'alt',
                      id:'alt',
                    },
                    {
                      type:'id',
                      name:'id',
                      Label:'id',
                    },
                    {
                      type:'checkbox',
                      name:'loading',
                      id:'loading',
                      label: 'Lazy Load',
                      valueTrue: 'lazy',
                      valueFalse: ''
                    },
                  ],
                  style: {
                    'width': '50%',
                    'height': '100%', 
                    'object-fit': 'cover'
                  },
                },
                {
                  type:'div', 
                  style: {
                    'width': '50%',
                    'display':'flex',
                    'flex-direction':'column', 
                    'gap': '20px'
                  },
                  components: [
                    {
                      type:'image',
                      traits: [
                        {
                          type: 'text', 
                          name:'src',
                          label: 'link',
                          id:'src',
                        },
                        {
                          type: 'text', 
                          name:'alt',
                          id:'alt',
                        },
                        {
                          type:'id',
                          name:'id',
                          Label:'id',
                        },
                        {
                          type:'checkbox',
                          name:'loading',
                          id:'loading',
                          label: 'Lazy Load',
                          valueTrue: 'lazy',
                          valueFalse: ''
                        },
                      ],
                      style: {
                        'width': '100%',
                        'height': '300px', 
                        'object-fit': 'cover'
                      },
                    },
                    {
                      type:'image',
                      traits: [
                        {
                          type: 'text', 
                          name:'src',
                          label: 'link',
                          id:'src',
                        },
                        {
                          type: 'text', 
                          name:'alt',
                          id:'alt',
                        },
                        {
                          type:'id',
                          name:'id',
                          Label:'id',
                        },
                        {
                          type:'checkbox',
                          name:'loading',
                          id:'loading',
                          label: 'Lazy Load',
                          valueTrue: 'lazy',
                          valueFalse: ''
                        },
                      ],
                      style: {
                        'width': '100%',
                        'height': '300px', 
                        'object-fit': 'cover'
                      },
                    }
                  ]
                },
                
              ],
             
              
            },
            
          },
          view: {},
};
  return component; 
};