import block from "./block.js";
import component from "./components/index.js"
import columnInfoModel1 from "./components/columnInfoModel1.js";
import columnModel1 from "./components/columnModel1.js";
import columnsModel1 from "./components/columnsModel1.js";
import containerModel1 from "./components/containerModel1.js";
import modelBusiness1 from "./components/modelBusiness1.js";
import rowModelBusiness1 from "./components/rowModelBusiness1.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("businessModel1", component(opts));
  editor.DomComponents.addType("columnInfoModel1", columnInfoModel1(opts));
  editor.DomComponents.addType("columnModel1", columnModel1(opts));
  editor.DomComponents.addType("columnsModel1", columnsModel1(opts));
  editor.DomComponents.addType("containerModel1", containerModel1(opts));
  editor.DomComponents.addType("modelBusiness1", modelBusiness1(opts));
  editor.DomComponents.addType("rowModelBusiness1", rowModelBusiness1(opts));

    //block
  editor.BlockManager.add("businessModel1", block(opts));
};