export default (opts = {}) => {
  const script = function () {

    var acc = document.getElementsByClassName("accordion-header");
    var i;
    
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function() {
        if(this.classList.contains('is-open')){
          this.classList.remove("is-open");
        }
        else{
          this.classList.add("is-open");
        }
        var panel = this.nextElementSibling;
        if (panel.style.display === "block") {
          panel.style.display = "none";
        } else {
          panel.style.display = "block";
        }
      });
    } 
  };
    
    const component = {
        model: {
            defaults: {
              script, 
              name: "Div",
              attributes:{class:'faq'},
              components: [
                {
                  type: 'accordion1',
                },
                {
                  type: 'accordion1',
                },
                {
                  type: 'accordion1',
                },
                {
                  type: 'accordion1',
                },
              ],
              styles: `
              .faq{
                width:100%; 
                margin-top:50px;
              }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};