export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: "Div",
              attributes: { class: 'w-100 gap-20 d-flex  d-sm-column wrap margin-top-50' },
              components: [
               {
                type:'cardDifferences5',
               },
               {
                type:'cardDifferences5',
               },
               {
                type:'cardDifferences5',
               },
               {
                type:'cardDifferences5',
               },
              ],
              styles: `
               .d-flex{
                display:flex; 
                flex-direction:row; 
               }
               .gap-20{
                gap:20px;
               }
               .w-100{
                width:100%; 
               }
               .wrap{
                flex-wrap: wrap; 
               }
               .margin-top-50{
                margin-top: 50px; 
               }
               @media (max-width:992px){
                .d-sm-column{
                  flex-direction: column !important; 
                }
              }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};