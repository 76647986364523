export default (opts = {}) => {
  const block = {
    id: "button3",
    label: "Cantos arredondados",
    fromPlugin: 'button',
    apper : 'out', 
    media: '<img class="preview-block" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABbCAYAAACFziAsAAAABHNCSVQICAgIfAhkiAAABAZJREFUeF7tnEtIVGEUx/9XoyCLRAMlLHtQEQQmuAhq4SJ6EEFBD1oFQeuoTSJB0Qs3Ra2D2rTIgh6bHhC0CBdF0IMIC4pEKQt8RUWFOp3PmkKzvnNn7nxzhvu/cHH0++455/5+c7xzx8+J8GvLZDIb5OEx2RfKXpn9Ob8WlMCgRH8t+8Eoim66TJGIKJevR2Vvcd8XND2D/4tARgaOy37YCWmWB3fJqugERqWCtU7ILXmwrujlsABH4LYT0isPasjDBIH3Toj7/cXNCAEKMSIiWwaFUIgxAsbKYYdQiDECxsphh1CIMQLGymGHUIgxAsbKYYdQiDECxsphh1CIMQLGymGHUIgxAsbKYYdQiDECxsphh1CIMQLGymGHUIgxAsbKYYdQiDECxsrJqUM6XgJdfUDvEDDiFkBy+02gvAyonQXUzwZWLY4PJpaQ4RFZDXwVcCvrPnyMnyxNR9TMlJXrIufQZmCKW86u3GIJab0EDH0Fvg8ro6d82lQRUVkhy9q36kGohVx5CDx4BfR91gfnTKBKhKxcBGxp0tFQCznQDvRTho7qhFnVIqVth+5QlZBRuWi0Xpbu+KQLylnjCVTPAE5sA8oU/w6lEuLC7zlHzPkQOLtbdzSF6DjlPYtC8kaYbAAKSZZn3tEoJG+EyQagkGR55h2NQvJGmGyA4EIa64FNK4C51cmeSClFc/dp7feBR11/Vx1cSNt2wN0ApX3rlnfBj1w3IET7DEiDsMluorV8Ersx1CakkP8ToJACPENMdAivIT/NdvfLNeSagWvI2KusRnmVVVWAp1yJhDT1KqtEmBWtTO01NrFrSNHOtEQSU4gxURRCIcYIGCuHHUIhxggYKyfxDmmRRXJcdZKbZfemq7tx1mzql70n5WN+O99pQnLORALL5gD71+u4qIV0vgXO3+NiOR3WP7Mqp8sSqmZgSa3uSLUQF+5pN3ChAxj4ogue9llOxq7VwPI6PYlYQlzYx/LXsBtPgJ4BWYknq7v5IbPjYUeyOnFU/kWjTt7T29gANMzTy3AzYwvJhv/8DRhkp0xK23VGxbR4IrKzcxaSWzoe5SNAIT5CgccpJDBwXzoK8REKPE4hgYH70lGIj1DgcQoJDNyXjkJ8hAKPU0hg4L50FOIjFHicQgID96WjEB+hwOMUEhi4Lx2F+AgFHqeQwMB96SjERyjwOIUEBu5LRyE+QoHHKSQwcF86CvERCjxOIYGB+9JRiI9Q4HEKCQzcl45CfIQCjzshnZJzaeC8TDc5gRdOyGkZ20tCJgiccUJk4SOeyb7AREnpLeK5nHrT2AeXihS3WP6U7DvTy6OoZ35Rsu+Loqh33CfJihi3cH6N7POLWl56kr+RU70jInqyp/wDjIEEeGRyDmcAAAAASUVORK5CYII=">',
    content: { type: "button3" },
  };

  return block;
};

