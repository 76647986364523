export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type:'div', 
              name: "Card Diferenciais",
              attributes: { class:'card-differences-4'},
              components: [
                {
                  type: 'image', 
                  attributes: { class: 'icon-differences-4', src: 'https://placehold.co/60x60'}, 
                }, 
                {
                  type:  'div', 
                  attributes: { class: 'margin-left-15'}, 
                  components: [
                    {
                      type: 'text', 
                      content: 'Lorem ipsum dolor ', 
                      attributes: { class: 'title-differences-4'},
                    },
                    {
                      type: 'text', 
                      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', 
                      attributes: { class: 'desc-differences-4'},
                    }, 
                  ],
                },
              ],
              styles: `
              .card-differences-4{
                width: 44%; 
                display: flex; 
                align-items: center; 
              }
              .icon-differences-4{
                width: 60px; 
              }
              .margin-left-15{
                margin-left: 15px; 
              }
              .title-differences-4{ 
                font-size: 18px; 
                color: #363940; 
                margin-top: 15px; 
              }
              .desc-differences-4{
                font-size: 16px; 
                color: #95A1BB; 
                margin-top: 10px; 
              }
      
              @media (max-width: 992px){
                .card-differences-4{
                  width: 100%; 
                }
              }
           `,
             },
          },
          view: {
      
          },
};
  return component; 
};