import block from "./block.js";
import component from "./components/index.js"
import containerCta1 from "./components/containerCta1.js";


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerCta1", containerCta1(opts));
  editor.DomComponents.addType("cta1", component(opts));
    //block
  editor.BlockManager.add("cta1", block(opts));
};