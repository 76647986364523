export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: 'Coluna Depoimentos',
              attributes: { class: 'column column-50 pattern-padding justify-content-center' },
              components: [
                {
                  tagName: 'h2',
                  type:'text',
                  name:'Titulo',
                  components: 'Depoimentos de clientes',
                  attributes: {class: 'principal-title'}, 
                  traits: [
                    {
                      type: "select",
                      options: [
                        { value: "h1", name: opts.labelN1 },
                        { value: "h2", name: opts.labelN2 },
                        { value: "h3", name: opts.labelN3 },
                        { value: "h4", name: opts.labelN4 },
                        { value: "h5", name: opts.labelN5 },
                        { value: "h6", name: opts.labelN6 },
                      ],
                      label: 'Tamanho',
                      name: "tagName",
                      changeProp: 1,
                    },
                    {
                      type:'id', 
                      name:'id',
                      label:'Id',
                    }
                  ],
                },
                {
                  type:'text', 
                  content: 'Subtítulo', 
                  name:'Subtitulo',
                  attributes: {class:'subtitle text-uppercase margin-top-15'},
                }, 
                {
                    type:'image',
                    tagname:'img',
                    attributes:{class: 'customer-picture'},
                },
                {
                  type:'text', 
                  content:'Santiago Valentín',
                  attributes: {class:'customer-name'}, 
                },
                {
                  type:'text',
                  content:'Content Manager',
                  attributes: {class: 'subtitle-testimony text-uppercase'},
                },
                {
                  type:'text',
                  content:'The only moment, the only life we have is in the NOW. What happened a few moments or several years ago is gone, what will happen this evening, or next month when we go on holidays is not here yet.',
                  attributes: {class: 'text-testimony'}, 
                },
                {
                  type: 'button'
                }
              ],
              styles: `
               .principal-title{
                font-size: 30px; 
                color: #363940; 
                margin:0;
               }
               .column{
                display:flex; 
                flex-direction:column;
                align-items: flex-start;
               }
               .column-50{
                width:50%; 
               }
               .justify-content-center{
                justify-content:center; 
               }
               
               @media (max-width:992px){
                .column-50{
                  width:100%; 
                }
                .column{
                  align-items:center;
                }
               }
               .subtitle{
                font-size: 13px; 
                color: #747474; 
               }
               .text-uppercase{
                text-transform: uppercase;
               }
               .pattern-padding{
                 padding:75px 5%; 
               }
               .customer-picture{
                margin-top: 50px;
               }
               .customer-name{
                font-size:20px;
                font-weight: 500; 
                color: #363940; 
                margin-top:20px; 
               }
               .subtitle-testimony{
                font-size: 12px;
                font-weigth: 500; 
                color: #95A1BB; 
                margin-top:10px; 
               }
               .text-testimony{
                font-size:16px;
                color: #363940; 
                margin-top: 20px; 
               }
               .margin-top-15{
                margin-top:15px;
               }
      
            `,
             },
          },
          view: {
      
          },
    }; 
    return component; 
};