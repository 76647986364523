export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: "Div",
              components: [
                {
                  type:'text',
                  name:'Div',
                  attributes:{class:'decoration'},
                },
                
              ],
              styles: `
              .decoration {
                width: 400px;
                height: 400px;
                background-color: #cad1e1;
                position: absolute;
                left: 0;
                top: 75px;
              }
              @media (max-width:992px){
                .decoration{
                  width:80%; 
                  height:20%;
                }
              }
            `,
             },
          },
          view: {
      
          },
    };
  
    return component;
  };
  