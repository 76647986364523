export default (opts = {}) => {
    const block = {
      id: "columns4",
      label: "4 Colunas",
      fromPlugin: 'layout',
      apper : 'out', 
      media: '<svg xmlns="http://www.w3.org/2000/svg" width="29" height="28.035" viewBox="0 0 29 28.035"><g id="Grupo_23908" data-name="Grupo 23908" transform="translate(-173.998 -1200)"> <g id="Grupo_23906" data-name="Grupo 23906"><path id="layout-split" d="M0,5C0,2.791.839,1,1.875,1H13.122C14.157,1,15,2.791,15,5V25c0,2.209-.839,4-1.875,4H1.875C.839,29,0,27.209,0,25ZM7.967,3V27h5.155c.518,0,.937-.9.937-2V5c0-1.1-.42-2-.937-2ZM7.029,3H1.875c-.518,0-.937.9-.937,2V25c0,1.1.42,2,.937,2H7.029Z" transform="translate(174 1199)" fill="#92949f"/><path id="layout-split-2" data-name="layout-split" d="M0,5C-.034,2.861.8,1.071,1.874,1H13.122c1.069.071,1.908,1.861,1.875,4V25c.033,2.139-.805,3.929-1.875,4H1.874C.8,28.929-.034,27.139,0,25ZM7.967,3V27h5.155c.535-.036.954-.93.937-2V5c.017-1.07-.4-1.964-.937-2ZM7.03,3H1.874c-.535.036-.954.93-.937,2V25c-.017,1.07.4,1.964.937,2H7.03Z" transform="translate(174 1199)" fill="#92949f"/></g><g id="Grupo_23907" data-name="Grupo 23907" transform="translate(14)"><path id="layout-split-3" data-name="layout-split" d="M0,5C0,2.791.839,1,1.875,1H13.122C14.157,1,15,2.791,15,5V25c0,2.209-.839,4-1.875,4H1.875C.839,29,0,27.209,0,25ZM7.967,3V27h5.155c.518,0,.937-.9.937-2V5c0-1.1-.42-2-.937-2ZM7.029,3H1.875c-.518,0-.937.9-.937,2V25c0,1.1.42,2,.937,2H7.029Z" transform="translate(174 1199)" fill="#92949f"/><path id="layout-split-4" data-name="layout-split" d="M-.066,5c.439-2.015-1.98-3.929-.91-4h14.1c1.069.071,1.908,1.861,1.875,4V25c.033,2.139-.805,3.929-1.875,4l-13.942.035c-1.069-.071.785-1.9.819-4.035ZM7.967,3V27h5.155c.535-.036.954-.93.937-2V5c.017-1.07-.4-1.964-.937-2ZM7.03,3H1.874c-.535.036-.954.93-.937,2V25c-.017,1.07.4,1.964.937,2H7.03Z" transform="translate(174 1199)" fill="#92949f"/></g></g></svg>',
      content: { type: "columns4" },
    };
  
    return block;
  };
  
  
