export default (opts = {}) => {
  const script = function () {
    const intLib = function () {
      const swiper = new Swiper(".mySwiperCard4", {
        spaceBetween: 0,
        slidesPerView: 1,
        breakpoints: {
          640: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
        },
        navigation: {
          nextEl: ".button-next-7",
          prevEl: ".button-prev-7"
        }
      });
    };
    if ("undefined" == typeof Swiper) {
      const cdn = document.createElement("script");
      const sty = document.createElement("link");
      cdn.onload = intLib;
      sty.onload = intLib; 
      sty.href = "https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.css"
      sty.rel ="stylesheet"
      cdn.src = "https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.js";
      document.head.appendChild(cdn);
      document.head.appendChild(sty);
    }
    else {
      intLib();
    }
  };
  const component = {
    model: {
      defaults: {
        script,
        name: 'Slider',
        classes: ['swiper', 'mySwiperCard4'],
        style: {
          'width':'100%'
        }, 
        components: [
          {
            name: 'Conainer',
            classes: ['swiper-wrapper'],
            style: {
              'width':'100%'
            }, 
            components: [
              {
                name: 'Slide',
                classes: ['swiper-slide'],
                style: {
                  'display': 'flex',
                  'flex-direction': 'column',
                  'align-items': 'center',
                  'justify-content': 'center',
                  'padding':'20px', 
                  'background-color':'#fff', 
                  'border-radius':'10px', 
                  'display':'flex',
                  'flex-direction':'column', 
                  'align-items':'center',
                  'gap':'15px',
                  'box-shadow': '0px 5px 5px 0px rgba(0,0,0,0.37)'
                },
                components: [
                  {
                    type: 'image',
                    attributes: { src: "https://dummyimage.com/500x500"},
                    style: {
                      'width':'100%',
                      'object-fit':'cover',
                      'height':'200px',
                      'border-radius':'8px'
                    }
                  },
                  {
                    type:'text',
                    components: 'Lorem ipsum',
                    style: {
                      'font-size':'16px', 
                      'color':'#000', 
                    }
                  }
                ]
              },
              {
                name: 'Slide',
                classes: ['swiper-slide'],
                style: {
                  'display': 'flex',
                  'flex-direction': 'column',
                  'align-items': 'center',
                  'justify-content': 'center',
                  'padding':'20px', 
                  'background-color':'#fff', 
                  'border-radius':'10px', 
                  'display':'flex',
                  'flex-direction':'column', 
                  'align-items':'center',
                  'gap':'15px',
                  'box-shadow': '0px 5px 5px 0px rgba(0,0,0,0.37)'
                },
                components: [
                  {
                    type: 'image',
                    attributes: { src: "https://dummyimage.com/500x500"},
                    style: {
                      'width':'100%',
                      'object-fit':'cover',
                      'height':'200px',
                      'border-radius':'8px'
                    }
                  },
                  {
                    type:'text',
                    components: 'Lorem ipsum',
                    style: {
                      'font-size':'16px', 
                      'color':'#000', 
                    }
                  }
                ]
              },
              {
                name: 'Slide',
                classes: ['swiper-slide'],
                style: {
                  'display': 'flex',
                  'flex-direction': 'column',
                  'align-items': 'center',
                  'justify-content': 'center',
                  'padding':'20px', 
                  'background-color':'#fff', 
                  'border-radius':'10px', 
                  'display':'flex',
                  'flex-direction':'column', 
                  'align-items':'center',
                  'gap':'15px',
                  'box-shadow': '0px 5px 5px 0px rgba(0,0,0,0.37)'
                },
                components: [
                  {
                    type: 'image',
                    attributes: { src: "https://dummyimage.com/500x500"},
                    style: {
                      'width':'100%',
                      'object-fit':'cover',
                      'height':'200px',
                      'border-radius':'8px'
                    }
                  },
                  {
                    type:'text',
                    components: 'Lorem ipsum',
                    style: {
                      'font-size':'16px', 
                      'color':'#000', 
                    }
                  }
                ]
              },
              {
                name: 'Slide',
                classes: ['swiper-slide'],
                style: {
                  'display': 'flex',
                  'flex-direction': 'column',
                  'align-items': 'center',
                  'justify-content': 'center',
                  'padding':'20px', 
                  'background-color':'#fff', 
                  'border-radius':'10px', 
                  'display':'flex',
                  'flex-direction':'column', 
                  'align-items':'center',
                  'gap':'15px',
                  'box-shadow': '0px 5px 5px 0px rgba(0,0,0,0.37)'
                },
                components: [
                  {
                    type: 'image',
                    attributes: { src: "https://dummyimage.com/500x500"},
                    style: {
                      'width':'100%',
                      'object-fit':'cover',
                      'height':'200px',
                      'border-radius':'8px'
                    }
                  },
                  {
                    type:'text',
                    components: 'Lorem ipsum',
                    style: {
                      'font-size':'16px', 
                      'color':'#000', 
                    }
                  }
                ]
              },
              {
                name: 'Slide',
                classes: ['swiper-slide'],
                style: {
                  'display': 'flex',
                  'flex-direction': 'column',
                  'align-items': 'center',
                  'justify-content': 'center',
                  'padding':'20px', 
                  'background-color':'#fff', 
                  'border-radius':'10px', 
                  'display':'flex',
                  'flex-direction':'column', 
                  'align-items':'center',
                  'gap':'15px',
                  'box-shadow': '0px 5px 5px 0px rgba(0,0,0,0.37)'
                },
                components: [
                  {
                    type: 'image',
                    attributes: { src: "https://dummyimage.com/500x500"},
                    style: {
                      'width':'100%',
                      'object-fit':'cover',
                      'height':'200px',
                      'border-radius':'8px'
                    }
                  },
                  {
                    type:'text',
                    components: 'Lorem ipsum',
                    style: {
                      'font-size':'16px', 
                      'color':'#000', 
                    }
                  }
                ]
              },
            ]
          },
          {
            name: 'Próximo',
            classes: ['button-next-7'],
            style: {
              'position':'absolute',
              'right':'0',
              'top':'50%',
              'z-index':'9',
            },
            components: [
              {
                type: 'image', 
                attributes: { src: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAA3NCSVQICAjb4U/gAAAACXBIWXMAAADmAAAA5gEiXLpRAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAFRQTFRF////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAsI6hEwAAABt0Uk5TAAECAwwOGx4xPEJpgoabnp+goaKjpMDB5fz+/OuR+AAAAGBJREFUOMvt0rcOgEAMA9DQezvaHf7//2RghRihjHj1kxIpEbmSDr5LRMkGYFVEBQBY4keQByaa46WYDYQzEJOBGA3EQEUvTJSfARvBlvx7vS8COXLNniDa9V4kc7697U9vSxXnRfolUwAAAABJRU5ErkJggg=='}, 
              }
            ]
          },
          {
            name: 'Anterior',
            classes: ['button-prev-7'],
            style: {
              'position':'absolute',
              'left':'0',
              'top':'50%',
              'z-index':'9',
            },
            components: [
              {
                type: 'image', 
                attributes: { src: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAA3NCSVQICAjb4U/gAAAACXBIWXMAAADmAAAA5gEiXLpRAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAFRQTFRF////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAsI6hEwAAABt0Uk5TAAECAwwOGx4xPEJpgoabnp+goaKjpMDB5fz+/OuR+AAAAGJJREFUOMvtzskOgCAMRdEKzhOKE/j+/z/duJTWmC59K5J7kkL0MNMHX1B6xgE4Mr4DrdBjxfez+/unTqPQa6HLQDwhf5LMpCBmBeEVxKIg1pcilpJokoDsBmAnZnYILr/fF/LsFedf9J6UAAAAAElFTkSuQmCC'}, 
              }
            ]
          },
        ],
      },
      styles: `
              *{
                font-family: Roboto, Arial, sans-serif;
              }
             `,
    },
    view: {

    }

  };
  return component;
};