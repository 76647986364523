export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              name: 'Item Tempo',
              attributes: { class: 'item-time-line-3' },
              components: [
                 {
                   type:'image', 
                   tagName:'img',  
                   attributes:{class:'icon-time-line-3', src: 'https://placehold.co/126x126'}, 
                 },
                 {
                    type: 'div', 
                    attributes: { class: 'line-3'}, 
                 },
                 {
                    type: 'div', 
                    attributes: { class: 'dot-3'}
                 },
                 {
                    type: 'text', 
                    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                    attributes: { class: 'time-text-3'}
                 }
              ],
              styles: `
               .line-3{
                width: 3px; 
                height: 90px; 
                background: #363940; 
               }
              .dot-3{
                width: 8px; 
                height: 8px; 
                background: #363940; 
                border-radius: 100%; 
              }
              .icon-time-line-3{
                width: 126px; 
                height: 126px; 
                border-radius: 10px; 
              } 
              .item-time-line-3{
                width: 20%;
                display: flex;
                flex-direction: column;
                align-items: center;
              }
              .time-text-3{
                font-size: 16px; 
                text-align: center; 
                color: #363940; 
                margin-top: 15px; 
              }

              @media (max-width: 992px){
                .item-time-line-3{
                    width: 47%; 
                  }
              }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};