export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        type: 'Container',
        name: "Container Contato",
        attributes: { class: 'container-cubo flex flex-col align-center' },
        components: [
          {
            type: 'default',
            attributes: { class: 'flex flex-col align-center w-full' },
            components: [
              {
                type: 'title2',
                components: [
                  {
                    type:'text',
                    name:'H2',
                    components: 'Entre em contato',
                    attributes: { class: 'principal-title' },
                  },
                ],
              },
              {
                type: 'text',
                attributes: { class: 'text-contact3' },
                components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              },
            ]
          },
          {
            type: 'default',
            attributes: { class: 'divform-contact3' },
            components: [
              {
                type: 'formNew'
              }
            ]
          }
        ],
        styles: `
       .container-cubo{
          max-width: 1600px; 
       }
       .flex{
        display: flex;
       }
       .flex-col{
         flex-direction: column; 
       }
      .align-center{
        align-items: center; 
      }
      .principal-title{
            font-size: 30px;
            color: #363940;
            margin:0;
           }
      .text-contact3{
        width: 66%; 
        line-height: 1.625; 
        font-size: 1rem; 
        color: #000; 
        text-align: center; 
        margin-top: 20px; 
      }
      .divform-contact3{
        width: 50%; 
        display: flex; 
        flex-direction: column; 
        align-items: center;
        margin-top: 40px;
      }

      @media (max-width: 992px){
         .text-contact3{
           width: 100%; 
         }
        .divform-contact3{
        width: 100%; 
      }
      }
      `,
      },
    },
    view: {

    },
  };

  return component;
};
