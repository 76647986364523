export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type:'Container',
              name: 'Container Depoimentos',
              attributes: { class: 'container-cubo' },
              components: [
                {
                  type: 'columnsTestimony1'
                }
              ],
              styles: `
             .container-cubo{
                max-width: 1600px; 
             }
            `,
             },
          },
          view: {
      
          },
    }; 
    return component; 
};