export default (opts = {}) => {
  const block = {
    category: 'Conteúdos',
    id: "content5",
    label: "Conteúdos (5)",
    fromPlugin: 'blocks',
    apper : 'out',
    media: '<svg class="preview-block" viewBox="0 0 266 150" fill="none" width="266"  height="150" ><path fill="#FFFFFF" d="M0 0h266v150H0z"></path><path d="M128 44.5a.5.5 0 011 0v62a.5.5 0 01-1 0v-62z" fill="#CBD5E0"></path><rect x="20" y="69" width="70" height="5" rx="2.5" fill="#4A5568"></rect><rect x="20" y="78" width="92" height="5" rx="2.5" fill="#4A5568"></rect><path d="M144 60a2 2 0 012-2h75a2 2 0 110 4h-75a2 2 0 01-2-2zM144 68a2 2 0 012-2h88a2 2 0 110 4h-88a2 2 0 01-2-2zM144 76a2 2 0 012-2h60a2 2 0 110 4h-60a2 2 0 01-2-2z" fill="#A0AEC0"></path><path d="M190 89a2 2 0 012-2h20a2 2 0 110 4h-20a2 2 0 01-2-2z" fill="#6366F1"></path><rect x="144" y="84" width="40" height="10" rx="2" fill="#6366F1"></rect></svg>',
    content: { type: 'content5' },
  };

  return block;
};