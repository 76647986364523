export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type: 'Container',
              name: "Container CTA",
              attributes: { class: 'container-cubo flex items-center sm-flex-col gap-40'},
              components: [
                {
                  type: 'div',
                  attributes: { class: 'flex flex-col items-start' },
                  components: [
                    {
                      type: 'title2', 
                      components: [
                        {
                          type:'text',
                          name:'H2',
                          components: 'Lorem ipsum dolor sit amet',
                          attributes: { class: 'principal-title' },
                        },
                      ],
                    },
                    {
                      type: 'text', 
                      attributes: { class: 'body-text' },
                      components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                    }
                  ]
                },
                {
                  type: 'div', 
                  attributes: { class: 'flex items-center gap-20' },
                  components: [
                    {
                      type: 'button3'
                    },
                    {
                      type: 'button3'
                    },
                  ]
                }
              ],
              styles: `
             .container-cubo{
                max-width: 1600px; 
             }
            .flex{
              display: flex; 
            }
            .flex-col{
              flex-direction: column; 
            }
            .items-center{
               align-items: center; 
             }
            .items-start{
              align-items: flex-start;
            }
            .gap-40{
              gap: 40px;
            }
            .gap-20{
              gap: 20px;
            }
            .body-text{
            font-size: 16px;
            color: #363940;
            margin-top:30px;
           }
             .principal-title{
            font-size: 30px;
            color: #363940;
            margin:0;
           }
            
             @media (max-width:992px){
               .sm-flex-col{
                 flex-direction: column;
               }
             }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};