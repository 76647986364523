import block from "./block.js";
import component from "./components/index.js"
import columnSideVideo from "./components/columnSideVideo.js";
import columnSideVideo2 from "./components/columnSideVideo2.js";
import columnsSideVideo from "./components/columnsSideVideo.js";
import containerSideVideo from "./components/containerSideVideo.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("sideVideo", component(opts));
  editor.DomComponents.addType("columnSideVideo", columnSideVideo(opts));
  editor.DomComponents.addType("columnSideVideo2", columnSideVideo2(opts));
  editor.DomComponents.addType("columnsSideVideo", columnsSideVideo(opts));
  editor.DomComponents.addType("containerSideVideo", containerSideVideo(opts));

    //block
  editor.BlockManager.add("sideVideo", block(opts));
};