export default (opts = {}) => {
    const block = {
      id: "textBlock4",
      label: "Justificado",
      fromPlugin: 'text',
      apper : 'out',
      media:'<i class="bi bi-justify"></i>',
      content: { type: "textBlock4" },
    };
  
    return block;
  };
  

  
