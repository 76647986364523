import block from "./block.js";
import component from "./components/index.js"
import containerFooter2 from "./components/containerFooter2.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("footer2", component(opts));
  editor.DomComponents.addType("containerFooter2", containerFooter2(opts));
    //block
  editor.BlockManager.add("footer2", block(opts));
};