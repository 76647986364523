import component from "../section/component";

export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              style: {
                'display':'flex', 
                'gap':'20px'
              },
              components:[
                {
                  type:'video',
                 
                  style: {
                    'width': '50%',
                  },
                },
                {
                  type:'div', 
                  style: {
                    'width': '50%',
                    'display':'flex',
                    'flex-direction':'column', 
                    'gap': '20px'
                  },
                  components: [
                    {
                      type:'video',
                      style: {
                        'width': '100%',
                      },
                    },
                    {
                      type:'video',
                      style: {
                        'width': '100%',
                      },
                    }
                  ]
                },
                
              ],
             
              
            },
            
          },
          view: {},
};
  return component; 
};