import block from "./block.js";
import component from "./components/index.js"
import containerContent5 from "./components/containerContent5.js";


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerContent5", containerContent5(opts));
  editor.DomComponents.addType("content5", component(opts));
    //block
  editor.BlockManager.add("content5", block(opts));
};