import block from "./block.js";
import component from "./components/index.js"
import containerTeam2 from "./components/containerTeam2.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerTeam2", containerTeam2(opts));
  editor.DomComponents.addType("team2", component(opts));
    //block
  editor.BlockManager.add("team2", block(opts));
};