import block from "./block.js";
import component from "./components/index.js"
import containerContent7 from "./components/containerContent7.js";


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerContent7", containerContent7(opts));
  editor.DomComponents.addType("content7", component(opts));
    //block
  editor.BlockManager.add("content7", block(opts));
};