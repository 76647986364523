export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              name: 'Div Selos',
              attributes: { class: 'row-stamp-1' },
              components: [
                  {
                    type:'image',
                    tagName:'img',  
                    attributes:{class:'img-stamp-1', src:'https://placehold.co/260x86'},
                  },
                  {
                    type:'image',
                    tagName:'img', 
                    attributes:{class:'img-stamp-1', src:'https://placehold.co/260x86'},
                  },
                  {
                    type:'image',
                    tagName:'img', 
                    attributes:{class:'img-stamp-1', src:'https://placehold.co/260x86'},
                  },
                  {
                    type:'image',
                    tagName:'img', 
                    attributes:{class:'img-stamp-1', src:'https://placehold.co/260x86'},
                  },
             
              ],
              styles: `
               .row-stamp-1{
                display:flex; 
                gap: 16px; 
                margin-top:16px;
                flex-wrap: wrap;
                justify-content:center; 
               }
               .img-stamp-1{
                width: 23%; 
               }
      
               @media (max-width:992px){
                .img-stamp-1{
                  width:47%;
                }
               }
      
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};