export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type:'Container',
              name: 'Container Tempo',
              attributes: { class: 'container-cubo' },
              components: [
                {
                  type: 'columnsTimeLine2', 
                },
                
              ],
              styles: `
             .container-cubo{
                max-width: 1600px; 
             }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};