import block from "./block.js";
import component from "./components/index.js"
import containerTeam3 from "./components/containerTeam3.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerTeam3", containerTeam3(opts));
  editor.DomComponents.addType("team3", component(opts));
    //block
  editor.BlockManager.add("team3", block(opts));
};