export default (opts = {}) => {
    const block = {
      id: "video2",
      label: "2 Videos",
      fromPlugin: 'video',
      apper : 'out',
      media:'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 6"><g id="Grupo_179" data-name="Grupo 179" transform="translate(19733 17813)"><g id="Grupo_177" data-name="Grupo 177"><g id="play-btn" transform="translate(-19733 -17815)"><path id="Caminho_44" data-name="Caminho 44" d="M6.439,5.047a.3.3,0,0,0-.288-.019A.248.248,0,0,0,6,5.25v2.5a.248.248,0,0,0,.151.222.3.3,0,0,0,.288-.019L8.384,6.7a.236.236,0,0,0,0-.407Z" transform="translate(-3.25 -1.5)"/><path id="Caminho_45" data-name="Caminho 45" d="M0,3A1,1,0,0,1,1,2H7A1,1,0,0,1,8,3V7A1,1,0,0,1,7,8H1A1,1,0,0,1,0,7ZM7.5,3A.5.5,0,0,0,7,2.5H1A.5.5,0,0,0,.5,3V7a.5.5,0,0,0,.5.5H7A.5.5,0,0,0,7.5,7Z"/> </g></g><g id="Grupo_178" data-name="Grupo 178" transform="translate(8)"> <g id="play-btn-2" data-name="play-btn" transform="translate(-19733 -17815)"><path id="Caminho_44-2" data-name="Caminho 44" d="M6.439,5.047a.3.3,0,0,0-.288-.019A.248.248,0,0,0,6,5.25v2.5a.248.248,0,0,0,.151.222.3.3,0,0,0,.288-.019L8.384,6.7a.236.236,0,0,0,0-.407Z" transform="translate(-3.25 -1.5)"/><path id="Caminho_45-2" data-name="Caminho 45" d="M0,3A1,1,0,0,1,1,2H7A1,1,0,0,1,8,3V7A1,1,0,0,1,7,8H1A1,1,0,0,1,0,7ZM7.5,3A.5.5,0,0,0,7,2.5H1A.5.5,0,0,0,.5,3V7a.5.5,0,0,0,.5.5H7A.5.5,0,0,0,7.5,7Z"/></g></g> </g></svg>',
      content: { type: "video2" },
    };
  
    return block;
  };
  