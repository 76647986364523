export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname: 'div',
              name: "Colunas FAQ",
              attributes: { class: 'row-cubo' },
              components: [
                {
                  type: 'columnFaq4',
                },
                {
                  type: 'columnAccordion4',
                },
      
              ],
              styles: `
             .row-cubo{
                display: flex; 
                flex-direction:row; 
                width:100%; 
             }
             @media (max-width:992px){
              .row-cubo{
                flex-direction:column;
              }
             }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};