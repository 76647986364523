export default (opts = {}) => {
  const component = {
    model: {
      
      defaults: {
        type:'link', 
        name:'Botão',
        traits: [
          {
            type:'id',
            name:'id',
            Label:'id',
          },
        ],
        components:[
          {
            type:'link',
            style: {
              'min-width': '250px',
              'font-size':'16px',
              'color': '#fff',
              'padding':'10px', 
              'border': 'none', 
              'border-radius': '5px', 
              'background-image': 'linear-gradient(#649dff 0%, #649dff 100%)',
              'text-align': 'center', 
              'display':'flex',
              'align-items':'center',
              'justify-content': 'center',
              'gap' : '10px',
              'text-decoration': 'none',
            },
            components: [
              {
                type: "image",
                attributes: { src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAGKSURBVDiNhdK5a1VhEAXwcwNBTYx7Y9xIYyOChaBgwLSKrZ2VFoJgIWIjNoL+AYqNaBEsXJrg0gopFDQiD0EbcSkMiGgRgxIwLj+LzMPn4xmnmTtzZs49M98kXYaNOIcWZjCHV7iM3d313c2nqgHe4A5u4Sl+Vv4Ghno1X6qCJxjtgW/Btapp/UWCwwXcxpL/qDxRtTfbiUF8KMkD2IFJHF+EpK1kT3CwgiMF3qv4Pfr/QbC5dnKlL8neyt8tv6z8yiSrehE0TfMuybMkY31JhpN8bZrmU+HXyz9PMrPIOt4m2dCXZDZJP5oCxkvNriTjWFqSj2Kwg2BZkrngTM28vWPGAUxV/jVe1vdHjKCvdvQo2IZfuNC1qCFcxQ9/7AvW4UDFp9vFDzGPFd2DYhOO4Sy2YrWF057B2pScabyohp5PV9gwHtffD7WTo5WYwn18wwT2WTjfNdiJ85it9z/ZyXqxY8bPeIDvelsLY52qGkxl4Sgmkkw2TTOP9Un2JxlJsjzJdGGt7rF+A1Hb9ny+uIIHAAAAAElFTkSuQmCC" },
              },
              {
                type:'text',
                content: 'Lorem Ipsum',
            },

            ],
          },
        ],
        styles: `
        *{
          font-family: Roboto, Arial, sans-serif;
        }
       `,
      },
    },
    view: {

    },
  };

  return component;
};
