import block from "./block.js";
import component from "./components/index.js"
import containerProducts2 from "./components/containerProducts2.js";
import rowProducts2 from "./components/rowProducts2.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("products2", component(opts));
  editor.DomComponents.addType("containerProducts2", containerProducts2(opts));
  editor.DomComponents.addType("rowProducts2", rowProducts2(opts));
  
    //block
  editor.BlockManager.add("products2", block(opts));
};