export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type: 'div', 
              name: "Imagem",
              attributes: { class: 'div-image' },
              components: [
                {
                  type: 'image',
                  attributes: { class: 'img-loc', src:'https://placehold.co/751x786' }
                }
              ],
              styles: `
               
             .div-image{
              width:50%; 
             }
             .img-loc{
              position:absolute; 
              width:50%; 
              top:-22%; 
              right:0;
             }
      
             @media (max-width:992px){
              .div-image{
                 width:100%;
              }
              .img-loc{
                width:100%;
                position:static; 
              }
             }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};