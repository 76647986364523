export default (opts = {}) => {
  const block = {
    id: "title",
    label: "H1",
    fromPlugin: 'title',
    apper : 'out', 
    media: '<i class="bi bi-type-h1"></i>',
    content: { type: "title" },
  };

  return block;
};



