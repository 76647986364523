import block from "./block.js";
import component from "./components/index.js"
import containerVideoFull from "./components/containerVideoFull.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("videoFull", component(opts));
  editor.DomComponents.addType("containerVideoFull", containerVideoFull(opts));

    //block
  editor.BlockManager.add("videoFull", block(opts));
};