export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: 'Coluna Selos',
              attributes: { class: 'column column-50 p-20 justify-content-center' },
              components: [
                {
                  type:'text', 
                  content: 'Subtítulo', 
                  name:'Subtitulo',
                  attributes: {class:'subtitle text-uppercase'},
                }, 
                {
                  tagName: 'h2',
                  type:'text',
                  name:'Titulo',
                  components: 'Selos',
                  attributes: {class: 'principal-title margin-top-15'}, 
                  traits: [
                    {
                      type: "select",
                      options: [
                        { value: "h1", name: opts.labelN1 },
                        { value: "h2", name: opts.labelN2 },
                        { value: "h3", name: opts.labelN3 },
                        { value: "h4", name: opts.labelN4 },
                        { value: "h5", name: opts.labelN5 },
                        { value: "h6", name: opts.labelN6 },
                      ],
                      label: 'Tamanho',
                      name: "tagName",
                      changeProp: 1,
                    },
                    {
                      type:'id', 
                      name: 'id', 
                      label:'Id',
                    },
                  ],
                },
                {
                  type: 'text',
                  content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et bibendum risus. Cras faucibus viverra dolor, at vehicula erat tempus vel. Sed a velit dictum, semper nulla at, volutpat dolor. In ',
                  attributes: {class: 'body-text w-60 w-md-100'},
                },
                {
                  type: 'button',
                },
              ],
              styles: `
               .principal-title{
                font-size: 30px; 
                color: #363940; 
                margin:0;
               }
               .body-text{
                font-size: 16px; 
                color: #363940; 
                margin-top:30px;
               }
               .w-60{
                width:60%;
               }
               .subtitle{
                font-size: 13px; 
                color: #747474; 
               }
               .text-uppercase{
                text-transform: uppercase;
               }
               .margin-top-15{
                margin-top:15px; 
               }
               .justify-content-center{
                justify-content:center; 
               }
               .column{
                display:flex; 
                flex-direction:column;
                align-items:flex-start;
               }
               .column-50{
                width:50%; 
               }
               @media (max-width:992px){
                .column-50{
                  width:100%; 
                }
                .column{
                  align-items:center;
                }
                .w-md-100{
                  width:100% !important;
                }
              }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};