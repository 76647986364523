export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type: 'Secao',
              name: "Seção Cabeçalho",
              attributes: { class: 'section-header shadow'  },
              components: [
                {
                  type: 'containerHeader',
                },
      
              ],
              styles: `
             .section-header{
               position:relative; 
               with:100vw; 
               padding: 20px 5%; 
             }
             *{
              font-family: Roboto, Arial, sans-serif;
            }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};