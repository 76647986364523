export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        type: 'Container',
        name: "Container",
        attributes: { class: 'container-cubo' },
        components: `
       <div class="flex flex-col items-center">
   <h2 class="principal-title">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit
   </h2>
   <p class="body-text w-80 text-center">
      Sed tincidunt, elit non rhoncus egestas, dolor lectus pretium libero, in finibus urna justo vel augue. Mauris eros
      ligula, aliquet eget tincidunt sit amet,
   </p>
</div>
<div class="flex sm-flex-col gap-16 mt-50">
   <div class="item-statistics-3">
      <img class="image-statistics-3" src="https://dummyimage.com/60x60" />
      <h2 class="statistic-number">2.7K</h2>
      <p class="statistc-info">Downloads</p>
   </div>
   <div class="item-statistics-3">
      <img class="image-statistics-3" src="https://dummyimage.com/60x60" />
      <h2 class="statistic-number">2.7K</h2>
      <p class="statistc-info">Downloads</p>
   </div>
   <div class="item-statistics-3">
      <img class="image-statistics-3" src="https://dummyimage.com/60x60" />
      <h2 class="statistic-number">2.7K</h2>
      <p class="statistc-info">Downloads</p>
   </div>
   <div class="item-statistics-3">
      <img class="image-statistics-3" src="https://dummyimage.com/60x60" />
      <h2 class="statistic-number">2.7K</h2>
      <p class="statistc-info">Downloads</p>
   </div>
</div>
        `,
        styles: `
             .container-cubo{
                max-width: 1600px; 
             }
             .flex{
               display: flex; 
             }
             .flex-col{
               flex-direction: column; 
             }
             .items-center{
               align-items: center; 
             }
            .principal-title{
              font-size: 30px;
              color: #363940;
              margin:0;
            }
            .body-text{
            font-size: 16px;
            color: #363940;
            margin-top:30px;
           }
           .w-80{
             width: 80%; 
           }
           .text-center{
             text-align: center; 
           }
          .gap-16{
            gap: 16px; 
          }
          .item-statistics-3{
            width: 25%; 
            padding: 20px; 
            border: 1px solid #e5e7eb; 
            border-radius: 10px; 
            display: flex; 
            flex-direction: column; 
            align-items: center; 
          }
          .image-statistics-3{
            width: 60px; 
            height: 60px; 
            border-radius: 100%; 
            margin-bottom: 15px; 
          }
          .statistic-number{
            font-size: 20px; 
            color: #000; 
          }
          .statistc-info{
            font-size: 15px; 
            color: #4b5563; 
            margin-top: 10px; 
          }
          .mt-50{
            margin-top: 50px; 
          }

          @media (max-width: 992px){
           .w-80{
             width: 100%; 
           }
          .sm-flex-col{
            flex-direction: column; 
          }
          .item-statistics-3{
            width: 100%; 
          }
          }

            `,
      },
    },
    view: {

    },
  };
  return component;
};