import block from "./block.js";
import component from "./components/index.js"
import containerLegalSupport from "./components/containerLegalSupport.js";
import divLegalSupport from "./components/divLegalSupport.js";
import headerLegalSuport from "./components/headerLegalSuport.js";
import itemLegalSupport from "./components/itemLegalSupport.js";
import rowLegalSupport from "./components/rowLegalSupport.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("legalSupport1", component(opts));
  editor.DomComponents.addType("containerLegalSupport", containerLegalSupport(opts));
  editor.DomComponents.addType("headerLegalSuport", headerLegalSuport(opts));
  editor.DomComponents.addType("divLegalSupport", divLegalSupport(opts));
  editor.DomComponents.addType("itemLegalSupport", itemLegalSupport(opts));
  editor.DomComponents.addType("rowLegalSupport", rowLegalSupport(opts));

    //block
  editor.BlockManager.add("legalSupport1", block(opts));
};