export default (opts = {}) => {
  const block = {
    category: 'CTA',
    id: "cta4",
    label: "CTA (4)",
    fromPlugin: 'blocks',
    apper : 'out',
    media: '<svg class="preview-block" fill="none" viewBox="0 0 266 150" width="266"  height="150" ><path fill="#FFFFFF" d="M0 0h266v150H0z"></path><rect x="20" y="68" width="26" height="5" rx="2.5" fill="#6366F1"></rect><rect x="20" y="77" width="92" height="5" rx="2.5" fill="#4A5568"></rect><rect x="206" y="70" width="40" height="10" rx="2" fill="#CBD5E0"></rect><rect x="160" y="70" width="40" height="10" rx="2" fill="#CBD5E0"></rect></svg>',
    content: { type: 'cta4' },
  };

  return block;
};