export default (opts = {}) => {
  const block = {
    category: 'Conteúdos',
    id: "content4",
    label: "Conteúdos (4)",
    fromPlugin: 'blocks',
    apper : 'out',
    media: '<svg class="preview-block" viewBox="0 0 266 150" fill="none" width="266"  height="150" ><path fill="#FFFFFF" d="M0 0h266v150H0z"></path><rect x="20" y="59" width="70" height="5" rx="2.5" fill="#4A5568"></rect><rect x="20" y="70" width="92" height="4" rx="2" fill="#A0AEC0"></rect><rect x="20" y="78" width="79" height="4" rx="2" fill="#A0AEC0"></rect><rect x="20" y="88" width="24" height="4" rx="2" fill="#6366F1"></rect><rect x="144" y="65" width="40" height="4" rx="2" fill="#4A5568"></rect><rect x="144" y="74" width="22" height="2" rx="1" fill="#A0AEC0"></rect><rect x="144" y="79" width="28" height="2" rx="1" fill="#A0AEC0"></rect><rect x="144" y="84" width="19" height="2" rx="1" fill="#A0AEC0"></rect><rect x="180" y="74" width="18" height="2" rx="1" fill="#A0AEC0"></rect><rect x="180" y="79" width="24" height="2" rx="1" fill="#A0AEC0"></rect><rect x="180" y="84" width="24" height="2" rx="1" fill="#A0AEC0"></rect><rect x="212" y="74" width="18" height="2" rx="1" fill="#A0AEC0"></rect><rect x="212" y="79" width="24" height="2" rx="1" fill="#A0AEC0"></rect><rect x="212" y="84" width="24" height="2" rx="1" fill="#A0AEC0"></rect><path d="M128 44.5a.5.5 0 011 0v62a.5.5 0 01-1 0v-62z" fill="#CBD5E0"></path></svg>',
    content: { type: 'content4' },
  };

  return block;
};