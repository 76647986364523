export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              name: "Conteudo FAQ",
              attributes: { class: 'tab-content' },
              components: [
                {
                  type: 'text',
                  content: 'Lorem ipsum dolor sit amet consectetur',
                  attributes: { class: 'content-header' },
                },
                {
                  type: 'text',
                  content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris luctus tincidunt venenatis. Nulla viverra maximus eleifend. Quisque maximus gravida leo sed aliquet. Quisque luctus quam tortor, sit amet ultricies arcu molestie nec. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Duis nec aliquet urna. Proin eu lacus orci. Nam quis augue et mauris accumsan ultricies ullamcorper et felis. Sed vel laoreet nunc. Sed in metus porta, lobortis nunc vitae, sodales tortor. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vestibulum vel pellentesque erat, at feugiat massa. Morbi semper vel metus cursus rutrum.',
                  attributes: { class: 'content-text' },
                },
              ],
              styles: `
              .content-header{
                font-size:18px;
                font-weight:500;
                color: #363940; 
              }
            .content-text{
              font-size:16px;
              color: #363940; 
              margin-top:20px; 
            }
            .tab-content{
              display:none;
            }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};