import components from ".";

export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type: 'Container',
              name: "Container Conteudos",
              attributes: { class: 'container-cubo flex flex-col items-center gap-20' },
              components: [
                {
                  type: 'div',
                  attributes: { class: 'flex flex-col items-center text-center' },
                  components: [
                        {
                          type: 'title2', 
                          components: [
                            {
                              type:'text',
                              name:'H2',
                              components: 'Lorem Ipsum',
                              attributes: { class: 'principal-title' },
                            },
                          ],
                        },
                        {
                          type: 'text', 
                          attributes: { class: 'body-text w-50' },
                          components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus. '
                        }
                  ]
                },
                {
                  type: 'div', 
                  attributes: { class: 'flex sm-flex-col gap-20' },
                  components: [
                    {
                      type: 'div', 
                      attributes: { class: 'item-content3' },
                      components: [
                        {
                          type: 'div', 
                          attributes: { class: 'div-item-content3' },
                          components: [
                            {
                              type: 'image', 
                              attributes: { src: 'https://dummyimage.com/720x400', alt: 'conteudo', 
                                class: 'image-content3'
                              }, 
                            },
                            {
                              type:'title3', 
                              components: [
                                {
                                  type:'text',
                                  name:'H3',
                                  components: 'Lorem Ipsum',
                                  attributes: { class: 'title-item-content3' },
                                },
                              ],
                            },
                            {
                              type:'text', 
                              attributes: { class: 'text-item-content3' },
                              content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus. ', 
                            },
                          ]
                        }
                      ]
                    },
                    {
                      type: 'div', 
                      attributes: { class: 'item-content3' },
                      components: [
                        {
                          type: 'div', 
                          attributes: { class: 'div-item-content3' },
                          components: [
                            {
                              type: 'image', 
                              attributes: { src: 'https://dummyimage.com/720x400', alt: 'conteudo', 
                                class: 'image-content3'
                              }, 
                            },
                            {
                              type:'title3', 
                              components: [
                                {
                                  type:'text',
                                  name:'H3',
                                  components: 'Lorem Ipsum',
                                  attributes: { class: 'title-item-content3' },
                                },
                              ],
                            },
                            {
                              type:'text', 
                              attributes: { class: 'text-item-content3' },
                              content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus. ', 
                            },
                          ]
                        }
                      ]
                    },
                    {
                      type: 'div', 
                      attributes: { class: 'item-content3' },
                      components: [
                        {
                          type: 'div', 
                          attributes: { class: 'div-item-content3' },
                          components: [
                            {
                              type: 'image', 
                              attributes: { src: 'https://dummyimage.com/720x400', alt: 'conteudo', 
                                class: 'image-content3'
                              }, 
                            },
                            {
                              type:'title3', 
                              components: [
                                {
                                  type:'text',
                                  name:'H3',
                                  components: 'Lorem Ipsum',
                                  attributes: { class: 'title-item-content3' },
                                },
                              ],
                            },
                            {
                              type:'text', 
                              attributes: { class: 'text-item-content3' },
                              content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus. ', 
                            },
                          ]
                        }
                      ]
                    },
                  ]
                },
                {
                  type: 'div', 
                  attributes: { class: 'flex sm-flex-col gap-20' },
                  components: [
                    {
                      type: 'div', 
                      attributes: { class: 'item-content3' },
                      components: [
                        {
                          type: 'div', 
                          attributes: { class: 'div-item-content3' },
                          components: [
                            {
                              type: 'image', 
                              attributes: { src: 'https://dummyimage.com/720x400', alt: 'conteudo', 
                                class: 'image-content3'
                              }, 
                            },
                            {
                              type:'title3', 
                              components: [
                                {
                                  type:'text',
                                  name:'H3',
                                  components: 'Lorem Ipsum',
                                  attributes: { class: 'title-item-content3' },
                                },
                              ],
                            },
                            {
                              type:'text', 
                              attributes: { class: 'text-item-content3' },
                              content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus. ', 
                            },
                          ]
                        }
                      ]
                    },
                    {
                      type: 'div', 
                      attributes: { class: 'item-content3' },
                      components: [
                        {
                          type: 'div', 
                          attributes: { class: 'div-item-content3' },
                          components: [
                            {
                              type: 'image', 
                              attributes: { src: 'https://dummyimage.com/720x400', alt: 'conteudo', 
                                class: 'image-content3'
                              }, 
                            },
                            {
                              type:'title3', 
                              components: [
                                {
                                  type:'text',
                                  name:'H3',
                                  components: 'Lorem Ipsum',
                                  attributes: { class: 'title-item-content3' },
                                },
                              ],
                            },
                            {
                              type:'text', 
                              attributes: { class: 'text-item-content3' },
                              content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus. ', 
                            },
                          ]
                        }
                      ]
                    },
                    {
                      type: 'div', 
                      attributes: { class: 'item-content3' },
                      components: [
                        {
                          type: 'div', 
                          attributes: { class: 'div-item-content3' },
                          components: [
                            {
                              type: 'image', 
                              attributes: { src: 'https://dummyimage.com/720x400', alt: 'conteudo', 
                                class: 'image-content3'
                              }, 
                            },
                            {
                              type:'title3', 
                              components: [
                                {
                                  type:'text',
                                  name:'H3',
                                  components: 'Lorem Ipsum',
                                  attributes: { class: 'title-item-content3' },
                                },
                              ],
                            },
                            {
                              type:'text', 
                              attributes: { class: 'text-item-content3' },
                              content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus. ', 
                            },
                          ]
                        }
                      ]
                    },
                  ]
                },
                {
                  type: 'button3',
                  attributes: { class: 'mt-12' },
                }
              ],
              styles: `
             .container-cubo{
                max-width: 1600px; 
             }
              .flex{
               display: flex;
              }
              .flex-col{
                flex-direction: column; 
              }
              .items-center{
                align-items: center; 
              }
              .text-center{
                text-align: center; 
              }
              .principal-title{
            font-size: 30px;
            color: #363940;
            margin:0;
           }
            .body-text{
            font-size: 16px;
            color: #363940;
            margin-top:30px;
           }
           .w-50{
             width: 50%; 
           }
            .gap-20{
              gap: 20px; 
            }
            .item-content3{
             width: 33%; 
            }
            .div-item-content3{
             width: 100%; 
             height: 100%; 
             border: 1px solid #E5E7EB;
             border-radius: 0.5rem; 
             padding: 1.5rem;
             display: flex; 
             flex-direction: column; 
             align-items: flex-start; 
            }
           .image-content3{
               border-radius: 100%; 
               width: 2.5rem;
               height: 2.5rem; 
               margin-bottom: 1rem; 
            }
            .title-item-content3{
              color: #111827;
              font-size: 1.125rem;
              line-height: 1.75rem; 
              margin-bottom: 0.5rem
            }
            .text-item-content3{
              line-height: 1.625; 
              font-size: 1rem; 
              color: #4B5563;
            }
            .mt-12{
             margin-top: 48px;
            }

            @media (max-width: 992px){
             .w-50{
               width: 100%;
            }
            .sm-flex-col{
              flex-direction: column; 
            }
            .item-content3{
             width: 100%; 
            }
            }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};