export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type:'div', 
              classes: ['columns'],
              traits: [
                {
                  type:'id',
                  name:'id',
                  Label:'id',
                },
              ],
              components: [
                {
                    type: 'div',
                    classes: ['column', 'column-33'],
                    resizable:true,
                    traits: [
                      {
                        type:'id',
                        name:'id',
                        Label:'id',
                      },
                     
                    ],
                },
                {
                    type: 'div',
                    resizable:true,
                    classes: ['column', 'column-33'],
                    traits: [
                      {
                        type:'id',
                        name:'id',
                        Label:'id',
                      },
                      
                    ],
                },
                {
                    type: 'div',
                    resizable:true,
                    classes: ['column', 'column-33'],
                    traits: [
                      {
                        type:'id',
                        name:'id',
                        Label:'id',
                      },
                     
                    ],
                },
              ],
              styles: `
              .columns{
                display: flex;
                width: 100%;
              }
              .column-33{
                width: 33%;
                min-height: 100px;
                flex-grow: 1;
              }

              @media (max-width: 992px){
                .columns{
                  flex-direction: column;
                }
                .column-33{
                  min-width: 100%;
                  max-width: 100%;
                }
              }
             `,
            },
            
          },
          view: {
      
          },
};
  return component; 
};