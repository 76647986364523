const traitInputAttr = { placeholder: 'ex: Insira o texto' };

export default {
  pt: {
    assetManager: {
      addButton: "Adicionar imagem",
      inputPlh: "http://caminho/para/a/imagem.jpg",
      modalTitle: "Selecionar imagem",
      uploadTitle: "Solte os arquivos aqui ou clique para enviar",
    },
    plugins: {
      settings: "Configurações",
    },
    // Here just as a reference, GrapesJS core doesn't contain any block,
    // so this should be omitted from other local files
    blockManager: {
      labels: {
        // 'block-id': 'Block Label',
      },
      categories: {
        'Contact': 'Contato',
        'Footer':'Rodapé',
        'Content':'Conteúdo',
        'Commerce':'Comercial', 
        'Features':'Destaques',
        'Gallery': 'Galeria',
        'Header':'Cabeçalho',
        'Pricing':'Preços', 
        'Statistics':'Estatísticas',
        'Steps':'Passos', 
        'Team':'Time',
        'Testimonials':'Depoimentos',
        'Hero':'Topos',
      },
    },
    domComponents: {
      names: {
        "": "Box",
        wrapper: "Corpo",
        text: "Texto",
        comment: "Comentário",
        image: "Imagem",
        video: "Vídeo",
        label: "Label",
        link: "Link",
        map: "Mapa",
        tfoot: "Rodapé da tabela",
        tbody: "Corpo da tabela",
        thead: "Cabeçalho da tabela",
        table: "Tabela",
        row: "Linha da tabela",
        cell: "Célula da tabela",
        section: "Seção",
        body: "Corpo",
      },
    },
    deviceManager: {
      device: "Dispositivo",
      devices: {
        desktop: "Desktop",
        tablet: "Tablet",
        mobileLandscape: "Celular, modo panorâmico",
        mobilePortrait: "Celular, modo retrato",
      },
    },
    styleManager: {
      empty: "Selecione um elemento para usar o gerenciador de estilos",
      layer: "Camada",
      fileButton: "Imagens",
      sectors: { 
       
        general: "Geral",
        typography: "Tipografia",
        flex: "Posicionamento",
        dimension: "Dimensão",
        decorations: "Aparência",
        extra: "Efeito",
        flex: "Flex",
        dimension: "Dimensão",
      },
      properties: {
        display: "Tipo:",
        float: "Flutuar",
        position: "Tipo:",
        "font-family": "Fonte:",
        top: "Superior:",
        right: "Direita:",
        left: "Esquerda:",
        bottom: "Inferior:",
        "flex-direction": "Direção:",
        "justify-content": "Alinhamento vertical:",
        "align-content": "Alinhar conteúdo:",
        "flex-basis": "Tamanho base flexível:",
        "flex-shrink": "Redução flexível",
        "flex-wrap": "Quebrar:",
        "align-items": "Alinhamento horizontal:",
        order: "Ordem",
        "flex-grow": "Expansão flexível",
        "align-self": "Alinhamento vertical",
        width: "Largura:",
        height: "Altura:",
        "max-width": "Largura máxima:",
        "min-height": "Altura mínima:",
        margin: "Margem:",
        padding: "Espaçamento interno:",
        "font-size": "Tamanho:",
        "font-weight": "Peso:",
        "letter-spacing": "Espaçamento: ",
        color: "Cor:",
        'background-image-color':'Cor:',
        'background-image-gradient-dir':'Direção:',
        "line-height": "Altura da linha: ",
        "text-align": "Alinhamento do texto: ",
        "text-shadow": "Sombra de texto",
        "background-color": "Cor de fundo: ",
        "background-image": 'Imagem de fundo: ',
        "background-repeat" : 'Repetição: ',
        "border-radius": "Arredondamento: ",
        "border-top-left-radius-sub": "Superior esquerda:",
        "border-top-right-radius-sub": "Superior direita:",
        "border-bottom-left-radius-sub": "Inferior esquerda:",
        "border-bottom-right-radius-sub": "Inferior direita:",
        border: "Borda:",
        "border-width-sub": "Largura",
        "border-style-sub": "Estilo:",
        "border-color-sub": "Cor da borda:",
        "box-shadow": "Sombras:",
        background: "Fundo:",
        opacity: "Opacidade:",
        transition: "Transição:",
        transform: "Transformação:",
        "transition-property-sub": "Propriedade:",
        "transition-duration-sub": "Duração:",
        "transition-timing-function-sub": "Tempo:",
        "transform-value": "Valor:",
        "transform-type":'Tipo:',
        'background-image-gradient-type': 'Tipo:',
        'background-position':'Posição:',
        'background-attachment': 'Movimentação:',
        'background-size':'Tamanho:'
      },
      options: {
        display: {
          block: "Bloco",
          inline: "Em linha",
          "inline-block": "Bloco em linha",
          none: "Nenhum",
        },
        'background-image-gradient-type':{
            linear: 'Linear',
            radial: 'Radial', 
            'repeating-linear': 'Repetição linear',
            'repeating-radial': 'Repetição radial'
        },
        'background-image-gradient-dir':{
           right: 'Direita',
           left: 'Esquerda',
           top: 'pra cima',
           bottom: 'pra baixo'
        },
        'background-repeat':{
          repeat: 'Repetir',
          'repeat-x': 'Repetir em X',  
          'repeat-y': 'Repetir em Y',
          'no-repeat': 'Não repetir',
          'space': 'Espaçamento',
          'round': 'Circular'
        },
        'background-position': {
           'left top': 'Esquerda Superior',
           'left center': 'Esquerda Centro',
           'left bottom': 'Esquerda Inferior',
           'right top': 'Direita Superior',
           'right center': 'Direita Centro',
           'right bottom': 'Direita Inferior',
           'center top': 'Centro Superior',
           'center center': 'Centralizado',
           'center bottom': 'Centro Inferior'
        },
        'background-attachment':{
          'scroll':'Rolagem',
          'fixed': 'Fixo',
          'local':"Local"
        },
        'background-size':{
          cover: 'Preencher',
          contain: 'Conter',
          auto: 'Automático'
        },
        float: {
          none: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"style="width: 14px; height: 20px; fill: #fff;"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/></svg>',
          left: '<svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" style="width: 20px; height: 20px; fill: #fff;"><path d="m17 17.75c0-.414-.336-.75-.75-.75h-13.5c-.414 0-.75.336-.75.75s.336.75.75.75h13.5c.414 0 .75-.336.75-.75zm5-4c0-.414-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75s.336.75.75.75h18.5c.414 0 .75-.336.75-.75zm-9-4c0-.414-.336-.75-.75-.75h-9.5c-.414 0-.75.336-.75.75s.336.75.75.75h9.5c.414 0 .75-.336.75-.75zm7-4c0-.414-.336-.75-.75-.75h-16.5c-.414 0-.75.336-.75.75s.336.75.75.75h16.5c.414 0 .75-.336.75-.75z" fill-rule="nonzero"/></svg>',
          right:
            '<svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"style="width: 20px; height: 20px; fill: #fff;"><path d="m7 17.75c0-.414.336-.75.75-.75h13.5c.414 0 .75.336.75.75s-.336.75-.75.75h-13.5c-.414 0-.75-.336-.75-.75zm-5-4c0-.414.336-.75.75-.75h18.5c.414 0 .75.336.75.75s-.336.75-.75.75h-18.5c-.414 0-.75-.336-.75-.75zm9-4c0-.414.336-.75.75-.75h9.5c.414 0 .75.336.75.75s-.336.75-.75.75h-9.5c-.414 0-.75-.336-.75-.75zm-7-4c0-.414.336-.75.75-.75h16.5c.414 0 .75.336.75.75s-.336.75-.75.75h-16.5c-.414 0-.75-.336-.75-.75z" fill-rule="nonzero"/></svg>',
        },
        position: {
          static: "Estático",
          relative: "Relativo",
          absolute: "Flutuante",
          fixed: "Fixo",
        },
        "flex-direction": {
          row: "Linha",
          "row-reverse": "Linha reversa",
          column: "Coluna",
          "column-reverse": "Coluna reversa",
        },
        "justify-content": {
          "flex-start": '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path id="Caminho_57" data-name="Caminho57"d="M4,187V173a1,1,0,0,1,1-1H7a1,1,0,0,1,1,1v14a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1m11-5a1,1,0,0,0,1-1v-8a1,1,0,0,0-1-1H13a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1Zm4.5-14H.5a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h19a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5.5" transform="translate(0 -168)"/></svg>',
          "flex-end": '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path id="Caminho_56" data-name="Caminho 56" d="M231,112a1,1,0,0,1,1,1v14a1,1,0,0,1-1,1h-2a1,1,0,0,1-1-1V113a1,1,0,0,1,1-1Zm6,6a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1v-8a1,1,0,0,0-1-1Zm6.5,12h-19a.5.5,0,0,0-.5.5v1a.5.5,0,0,0,.5.5h19a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5" transform="translate(-224 -112)"/></svg>',
          center: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14"><path id="Caminho_61" data-name="Caminho 61" d="M244,177.5v1a.5.5,0,0,1-.5.5H240v2a1,1,0,0,1-1,1h-2a1,1,0,0,1-1-1v-2h-4v5a1,1,0,0,1-1,1h-2a1,1,0,0,1-1-1v-5h-3.5a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5H228v-5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1v5h4v-2a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1v2h3.5a.5.5,0,0,1,.5.5" transform="translate(-224 -171)"/></svg>',
          "space-between": '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g id="Grupo_23903" data-name="Grupo 23903" transform="translate(-1831 -671)"><g id="Grupo_23902" data-name="Grupo 23902" transform="translate(20)"><g id="Retângulo_1114" data-name="Retângulo 1114" transform="translate(1811 671)"><rect id="Retângulo_1129" data-name="Retângulo 1129" width="2" height="20" rx="0.5"/><rect id="Retângulo_1130" data-name="Retângulo 1130" width="1" height="19" transform="translate(0.5 0.5)"/></g><g id="Retângulo_1115" data-name="Retângulo 1115" transform="translate(1829 671)"><rect id="Retângulo_1131" data-name="Retângulo 1131" width="2" height="20" rx="0.5"/><rect id="Retângulo_1132" data-name="Retângulo 1132" width="1" height="19" transform="translate(0.5 0.5)"/></g></g><g id="Retângulo_1116" data-name="Retângulo 1116" transform="translate(1833 673)"><rect id="Retângulo_1133" data-name="Retângulo 1133" width="2" height="16" rx="0.5"/><rect id="Retângulo_1134" data-name="Retângulo 1134" width="1" height="15" transform="translate(0.5 0.5)"/></g><g id="Retângulo_1117" data-name="Retângulo 1117" transform="translate(1840 673)"><rect id="Retângulo_1135" data-name="Retângulo 1135" width="2" height="16" rx="0.5"/><rect id="Retângulo_1136" data-name="Retângulo 1136" width="1" height="15" transform="translate(0.5 0.5)"/></g><g id="Retângulo_1118" data-name="Retângulo 1118" transform="translate(1847 673)"><rect id="Retângulo_1137" data-name="Retângulo 1137" width="2" height="16" rx="0.5"/><rect id="Retângulo_1138" data-name="Retângulo 1138" width="1" height="15" transform="translate(0.5 0.5)"/></g></g></svg>',
          'space-around':'<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g id="Grupo_23903" data-name="Grupo 23903" transform="translate(-1831 -671)"><g id="Grupo_23902" data-name="Grupo 23902" transform="translate(20)"><g id="Retângulo_1114" data-name="Retângulo 1114" transform="translate(1811 671)"><rect id="Retângulo_1129" data-name="Retângulo 1129" width="2" height="20" rx="0.5"/><rect id="Retângulo_1130" data-name="Retângulo 1130" width="1" height="19" transform="translate(0.5 0.5)"/></g><g id="Retângulo_1115" data-name="Retângulo 1115" transform="translate(1829 671)"><rect id="Retângulo_1131" data-name="Retângulo 1131" width="2" height="20" rx="0.5"/><rect id="Retângulo_1132" data-name="Retângulo 1132" width="1" height="19" transform="translate(0.5 0.5)"/></g></g><g id="Retângulo_1116" data-name="Retângulo 1116" transform="translate(1835 673)"><rect id="Retângulo_1133" data-name="Retângulo 1133" width="2" height="16" rx="0.5"/><rect id="Retângulo_1134" data-name="Retângulo 1134" width="1" height="15" transform="translate(0.5 0.5)"/></g><g id="Retângulo_1117" data-name="Retângulo 1117" transform="translate(1840 673)"><rect id="Retângulo_1135" data-name="Retângulo 1135" width="2" height="16" rx="0.5"/><rect id="Retângulo_1136" data-name="Retângulo 1136" width="1" height="15" transform="translate(0.5 0.5)"/></g><g id="Retângulo_1118" data-name="Retângulo 1118" transform="translate(1845 673)"><rect id="Retângulo_1137" data-name="Retângulo 1137" width="2" height="16" rx="0.5"/><rect id="Retângulo_1138" data-name="Retângulo 1138" width="1" height="15" transform="translate(0.5 0.5)"/></g></g></svg>',
          "space-evenly": "uniforme",
          stretch: '<svg xmlns="http://www.w3.org/2000/svg" width="14.016" height="20" viewBox="0 0 14.016 20"><path id="Caminho_178" data-name="Caminho 178" d="M126.894,300.721a1.009,1.009,0,0,0,.993-1.06V282.754a1.094,1.094,0,0,0-.993-1.154h-2a1.106,1.106,0,0,0-1.007,1.154v16.684c0,.613.191,1.283.743,1.283Zm5.487-19.676h-1a.531.531,0,0,0-.5.556l.016,18.876a.53.53,0,0,0,.5.554h1a.529.529,0,0,0,.5-.555l-.016-18.876a.53.53,0,0,0-.5-.555m-11.982,20a.53.53,0,0,0,.5-.555l-.017-18.876a.529.529,0,0,0-.5-.555h-1a.529.529,0,0,0-.5.555l.017,18.876a.529.529,0,0,0,.5.555Z" transform="translate(-118.882 -281.045)"/></svg>',
        },
        "align-content": {
          "flex-start": "Início",
          "flex-end": "Final",
          center: "Centro",
          "space-between": "Entre espaços",
          "space-around": "Espaço ao redor",
          stretch: "Esticar",
        },
        "flex-wrap": {
          nowrap: "Não",
          wrap: "Sim",
          "wrap-reverse": "querbra-reversa",
        },
        "align-items": {
          "flex-start": '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path id="Caminho_58" data-name="Caminho 58" d="M76,181v2a1,1,0,0,1-1,1H61a1,1,0,0,1-1-1v-2a1,1,0,0,1,1-1H75a1,1,0,0,1,1,1m-15-9a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1v-2a1,1,0,0,0-1-1Zm-4.5,16h1a.5.5,0,0,0,.5-.5v-19a.5.5,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5v19a.5.5,0,0,0,.5.5" transform="translate(-56 -168)"/></svg>',
          "flex-end": '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path id="Caminho_59" data-name="Caminho 59"d="M113,180h14a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1H113a1,1,0,0,1-1-1v-2a1,1,0,0,1,1-1m14-8h-8a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1v-2a1,1,0,0,0-1-1m4.5-4h-1a.5.5,0,0,0-.5.5v19a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5v-19a.5.5,0,0,0-.5-.5" transform="translate(-112 -168)"/></svg>',
          center: '<svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20"><path id="Caminho_60" data-name="Caminho 60"d="M177,180v-4h-2a1,1,0,0,1-1-1v-2a1,1,0,0,1,1-1h2v-3.5a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5V172h2a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1h-2v4h5a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1h-5v3.5a.5.5,0,0,1-.5.5h-1a.5.5,0,0,1-.5-.5V184h-5a1,1,0,0,1-1-1v-2a1,1,0,0,1,1-1Z" transform="translate(-171 -168)"/></svg>',
          baseline: "linha base",
          stretch: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="14.016" viewBox="0 0 20 14.016"><path id="Caminho_153" data-name="Caminho 153"d="M127.239,300.477c.552,0,.649-.84.648-1.453V282.9c0-.614-.1-1.285-.648-1.284h-2.232c-.552,0-.837.67-.837,1.284v16.126c0,.613.285,1.466.837,1.466Zm5.142-19.431h-1a.531.531,0,0,0-.5.556l.016,18.876a.53.53,0,0,0,.5.554h1a.529.529,0,0,0,.5-.555l-.016-18.876a.53.53,0,0,0-.5-.555m-11.982,20a.53.53,0,0,0,.5-.555l-.017-18.876a.529.529,0,0,0-.5-.555h-1a.529.529,0,0,0-.5.555l.017,18.876a.529.529,0,0,0,.5.555Z" transform="translate(301.045 -118.882) rotate(90)"/></svg>',
          "space-between": '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g id="Grupo_23903" data-name="Grupo 23903" transform="translate(691 -1831) rotate(90)"><g id="Grupo_23902" data-name="Grupo 23902" transform="translate(20)"><g id="Retângulo_1114" data-name="Retângulo 1114" transform="translate(1811 671)"><rect id="Retângulo_1129" data-name="Retângulo 1129" width="2" height="20" rx="0.5"/><rect id="Retângulo_1130" data-name="Retângulo 1130" width="1" height="19" transform="translate(0.5 0.5)"/></g><g id="Retângulo_1115" data-name="Retângulo 1115" transform="translate(1829 671)"><rect id="Retângulo_1131" data-name="Retângulo 1131" width="2" height="20" rx="0.5"/><rect id="Retângulo_1132" data-name="Retângulo 1132" width="1" height="19" transform="translate(0.5 0.5)"/></g></g><g id="Retângulo_1116" data-name="Retângulo 1116" transform="translate(1833 673)"><rect id="Retângulo_1133" data-name="Retângulo 1133" width="2" height="16" rx="0.5"/><rect id="Retângulo_1134" data-name="Retângulo 1134" width="1" height="15" transform="translate(0.5 0.5)"/></g><g id="Retângulo_1117" data-name="Retângulo 1117" transform="translate(1840 673)"><rect id="Retângulo_1135" data-name="Retângulo 1135" width="2" height="16" rx="0.5"/><rect id="Retângulo_1136" data-name="Retângulo 1136" width="1" height="15" transform="translate(0.5 0.5)"/></g><g id="Retângulo_1118" data-name="Retângulo 1118" transform="translate(1847 673)"><rect id="Retângulo_1137" data-name="Retângulo 1137" width="2" height="16" rx="0.5"/><rect id="Retângulo_1138" data-name="Retângulo 1138" width="1" height="15" transform="translate(0.5 0.5)"/></g></g></svg>',
          "space-around": '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g id="Grupo_23903" data-name="Grupo 23903" transform="translate(691 -1831) rotate(90)"><g id="Grupo_23902" data-name="Grupo 23902" transform="translate(20)"><g id="Retângulo_1114" data-name="Retângulo 1114" transform="translate(1811 671)"><rect width="2" height="20" rx="0.5" stroke="none"/><rect x="0.5" y="0.5" width="1" height="19" fill="none"/></g><g id="Retângulo_1115" data-name="Retângulo 1115" transform="translate(1829 671)"><rect width="2" height="20" rx="0.5" stroke="none"/><rect x="0.5" y="0.5" width="1" height="19" fill="none"/></g></g><g id="Retângulo_1116" data-name="Retângulo 1116" transform="translate(1835 673)"><rect width="2" height="16" rx="0.5" stroke="none"/><rect x="0.5" y="0.5" width="1" height="15" fill="none"/></g><g id="Retângulo_1117" data-name="Retângulo 1117" transform="translate(1840 673)"><rect width="2" height="16" rx="0.5" stroke="none"/><rect x="0.5" y="0.5" width="1" height="15" fill="none"/></g><g id="Retângulo_1118" data-name="Retângulo 1118" transform="translate(1845 673)"><rect width="2" height="16" rx="0.5" stroke="none"/><rect x="0.5" y="0.5" width="1" height="15" fill="none"/></g></g></svg>',
        },
        "align-self": {
          "flex-start": "Início",
          "flex-end": "Final",
          center: "Centro",
          baseline: "linha base",
          stretch: "total",
        },
        "text-align": {
          left: '<svg clip-rule="evenodd"  stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" style="width: 20px; height: 20px; "><path d="m17 17.75c0-.414-.336-.75-.75-.75h-13.5c-.414 0-.75.336-.75.75s.336.75.75.75h13.5c.414 0 .75-.336.75-.75zm5-4c0-.414-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75s.336.75.75.75h18.5c.414 0 .75-.336.75-.75zm-9-4c0-.414-.336-.75-.75-.75h-9.5c-.414 0-.75.336-.75.75s.336.75.75.75h9.5c.414 0 .75-.336.75-.75zm7-4c0-.414-.336-.75-.75-.75h-16.5c-.414 0-.75.336-.75.75s.336.75.75.75h16.5c.414 0 .75-.336.75-.75z"/></svg>',
          center:
            '<svg clip-rule="evenodd"  stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"style="width: 20px; height: 20px; "><path d="m6 17.75c0-.414.336-.75.75-.75h10.5c.414 0 .75.336.75.75s-.336.75-.75.75h-10.5c-.414 0-.75-.336-.75-.75zm-4-4c0-.414.336-.75.75-.75h18.5c.414 0 .75.336.75.75s-.336.75-.75.75h-18.5c-.414 0-.75-.336-.75-.75zm0-4c0-.414.336-.75.75-.75h18.5c.414 0 .75.336.75.75s-.336.75-.75.75h-18.5c-.414 0-.75-.336-.75-.75zm4-4c0-.414.336-.75.75-.75h10.5c.414 0 .75.336.75.75s-.336.75-.75.75h-10.5c-.414 0-.75-.336-.75-.75z"/></svg>',
          right:
            '<svg clip-rule="evenodd"  stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"style="width: 20px; height: 20px; "><path d="m7 17.75c0-.414.336-.75.75-.75h13.5c.414 0 .75.336.75.75s-.336.75-.75.75h-13.5c-.414 0-.75-.336-.75-.75zm-5-4c0-.414.336-.75.75-.75h18.5c.414 0 .75.336.75.75s-.336.75-.75.75h-18.5c-.414 0-.75-.336-.75-.75zm9-4c0-.414.336-.75.75-.75h9.5c.414 0 .75.336.75.75s-.336.75-.75.75h-9.5c-.414 0-.75-.336-.75-.75zm-7-4c0-.414.336-.75.75-.75h16.5c.414 0 .75.336.75.75s-.336.75-.75.75h-16.5c-.414 0-.75-.336-.75-.75z"/></svg>',
          justify:
            '<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"  clip-rule="evenodd"style="width: 18px; height: 18px; "><path d="M24 21h-24v-2h24v2zm0-4.024h-24v-2h24v2zm0-3.976h-24v-2h24v2zm0-4h-24v-2h24v2zm0-6v2h-24v-2h24z"/></svg>',
        },
        "border-style-sub": {
          solid: "Sólido",
          dotted: "Pontilhada",
          dashed: "Tracejada",
          double: "Dupla",
          groove: "Entalhada",
          ridge: "Elevada",
          inset: "Interna",
          outset: "Externa",
        },
        "transition-property-sub": {
          all: "Todos",
          width: "Largura",
          height: "Altura",
          "background-color": "Cor de fundo",
          transform: "Tranformação",
          "box-shadow": "Sombra",
          opacity: "Opacidade",
        },
        "transition-timing-function-sub": {
          linear: "Constante",
          ease: "Suave",
          "ease-in": "Suave no início",
          "ease-out": "Suave no final",
          "ease-in-out": "Suave no início e no fim",
        },
      },
    },
    traitManager: {
      empty: "Selecione um elemento para usar o gerenciador de características",
      label: "Configurações do componente",
      traits: {
        
        // The core library generates the name by their `name` property
        labels: {
          // id: 'Id',
          // alt: 'Alt',
           address: 'Endereço', 
           'mapType': 'Tipo do mapa',
           title: 'Titulo',
           href: 'Link',
           target: 'Onde abrir:',
           strings: 'Linhas', 
           'type-speed': 'Velocidade', 
           'start-delay':  'Atraso para começar',
           'back-speed': 'Velocidade de volta',
           'smart-backspace':'Retrocesso inteligente',
           'back-delay': 'Atraso para voltar', 
           'fade-out': 'Desaparecer',
           'fade-out-class': 'Classe desaparecer', 
           'fade-out-delay': 'Atraso para desaparecer', 
           'show-cursor': 'Mostrar cursor',
           'cursor-char': 'Caractere do cursor',
           'auto-insert-css': 'Inserir css auto.',
           'bind-input-focus-events': 'Eventos de foco',
           'loop-count': 'Quantidade de loops',
           shuffle: 'Embaralhar',
           'content-type':'Tipo do conteúdo',
           infinite: 'Infinito', 
           rewind: 'Retroceder',
           'slide-speed':'Velocidade',
           'rewind-speed':'Velocidade retroceder', 
           'slides-to-scroll':'Slides para rolar',
           ease: 'Função de tempo',
           provider:'Provedor',
           src:'URL', 
           controls: 'Controles'
        },
        // In a simple trait, like text input, these are used on input attributes
        attributes: {
          id: traitInputAttr,
          alt: traitInputAttr,
          title: traitInputAttr,
          href: { placeholder: "ex: https://google.com" },
        },
        // In a trait like select, these are used to translate option names
        options: {
          target: {
            false: "Esta janela",
            _blank: "Nova janela",
          },
          mapType: {
            q: 'Padrão',
            w: 'Satelite'
          }
        },
      },
    },
    selectorManager: {
      label: "Classes",
      selected: "Selecionado",
      emptyState: "Estado",
      states: {
        hover: "Ao passar o mouse",
        active: "Ao Clicar",
        "nth-of-type(2n)": "Par/Ímpar",
      },
    },
    panels: {
      buttons: {
        titles: {
          preview: "Visualiar",
          fullscreen: "Tela cheia",
          "sw-visibility": "Ver componentes",
          "export-template": "Ver código",
          "open-sm": "Abrir gerenciador de estilos",
          "open-tm": "Configurações",
          "open-layers": "Abrir gerenciador de camadas",
          "open-blocks": "Abrir blocos",
        },
      },
    },
  },
};
