export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        tagname: 'div',
        name: "Container",
        attributes: { class: 'container-cubo flex sm-flex-col justify-between aling-center gap-50 p-section' },
        components: [
          {
            type: 'div',
            attributes: { class: 'w-20 flex-col items-start gap-20' },
            components: [
              {
                type: 'image',
                attributes: { class: 'logo-footer', src: 'https://dummyimage.com/420x260' }
              },
              {
                type: 'text',
                attributes: { class: 'body-text' },
                components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
              }
            ]
          },
          {
            type: 'div',
            attributes: { class: 'flex sm-flex-col gap-16 w-80' },
            components: [
              {
                type: 'div',
                attributes: { class: 'item-footer' },
                components: [
                  {
                    type: 'title2',
                    components: [
                      {
                        type: 'text',
                        name: 'H2',
                        components: 'Categorias',
                        attributes: { class: 'title-footer' },
                      },
                    ],
                  },
                  {
                    type: 'div',
                    attributes: { class: 'flex flex-col gap-16' },
                    components: [
                      {
                        type: 'link',
                        attributes: { class: 'link-footer mt-30' },
                        components: 'Categoria 1'
                      },
                      {
                        type: 'link',
                        attributes: { class: 'link-footer' },
                        components: 'Categoria 2'
                      },
                      {
                        type: 'link',
                        attributes: { class: 'link-footer' },
                        components: 'Categoria 3'
                      },
                      {
                        type: 'link',
                        attributes: { class: 'link-footer' },
                        components: 'Categoria 4'
                      },
                    ]
                  }
                ]
              },
              {
                type: 'div',
                attributes: { class: 'item-footer' },
                components: [
                  {
                    type: 'title2',
                    components: [
                      {
                        type: 'text',
                        name: 'H2',
                        components: 'Categorias',
                        attributes: { class: 'title-footer' },
                      },
                    ],
                  },
                  {
                    type: 'div',
                    attributes: { class: 'flex flex-col gap-16' },
                    components: [
                      {
                        type: 'link',
                        attributes: { class: 'link-footer mt-30' },
                        components: 'Categoria 1'
                      },
                      {
                        type: 'link',
                        attributes: { class: 'link-footer' },
                        components: 'Categoria 2'
                      },
                      {
                        type: 'link',
                        attributes: { class: 'link-footer' },
                        components: 'Categoria 3'
                      },
                      {
                        type: 'link',
                        attributes: { class: 'link-footer' },
                        components: 'Categoria 4'
                      },
                    ]
                  }
                ]
              },
              {
                type: 'div',
                attributes: { class: 'item-footer' },
                components: [
                  {
                    type: 'title2',
                    components: [
                      {
                        type: 'text',
                        name: 'H2',
                        components: 'Categorias',
                        attributes: { class: 'title-footer' },
                      },
                    ],
                  },
                  {
                    type: 'div',
                    attributes: { class: 'flex flex-col gap-16' },
                    components: [
                      {
                        type: 'link',
                        attributes: { class: 'link-footer mt-30' },
                        components: 'Categoria 1'
                      },
                      {
                        type: 'link',
                        attributes: { class: 'link-footer' },
                        components: 'Categoria 2'
                      },
                      {
                        type: 'link',
                        attributes: { class: 'link-footer' },
                        components: 'Categoria 3'
                      },
                      {
                        type: 'link',
                        attributes: { class: 'link-footer' },
                        components: 'Categoria 4'
                      },
                    ]
                  }
                ]
              },
              {
                type: 'div',
                attributes: { class: 'item-footer' },
                components: [
                  {
                    type: 'title2',
                    components: [
                      {
                        type: 'text',
                        name: 'H2',
                        components: 'Categorias',
                        attributes: { class: 'title-footer' },
                      },
                    ],
                  },
                  {
                    type: 'div',
                    attributes: { class: 'flex flex-col gap-16' },
                    components: [
                      {
                        type: 'link',
                        attributes: { class: 'link-footer mt-30' },
                        components: 'Categoria 1'
                      },
                      {
                        type: 'link',
                        attributes: { class: 'link-footer' },
                        components: 'Categoria 2'
                      },
                      {
                        type: 'link',
                        attributes: { class: 'link-footer' },
                        components: 'Categoria 3'
                      },
                      {
                        type: 'link',
                        attributes: { class: 'link-footer' },
                        components: 'Categoria 4'
                      },
                    ]
                  }
                ]
              },

            ]
          }
        ],
        styles: `
               .container-cubo{
                max-width: 1600px;
               }
              .flex{
                display: flex; 
              } 
              .flex-col{
                flex-direction: column; 
              }
              .gap-20{
                gap: 20px; 
              }
              .mt-30{
                margin-top: 30px; 
              }
              .justify-between{
                justify-content: space-between; 
              }
              .align-center{
                align-items: center; 
              }
              .gap-50{
                gap: 50px; 
              }
              .gap-16{
                gap: 16px; 
              }
              .w-20{
                width: 20%
              }
              .logo-footer{
                width: 60px; 
                height: 60px; 
                border-radius: 100%; 
              }
              .body-text{
            font-size: 16px;
            color: #363940;
            margin-top:30px;
           }
            .item-footer{
              width: 25%; 
            }
            .title-footer{
              font-size: 18px; 
              font-weigth: 500; 
              color: #363940;
            }
            .link-footer{
              font-size: 16px; 
              color: #363940;
              text-decoration: none; 
            }

            .p-section{
              padding: 50px 5%; 
            }
            .w-80{
              width: 80%; 
            }

              @media (max-width: 992px){
                .sm-flex-col{
                  flex-direction: column; 
                }
                .w-20{
                  width: 100%; 
                }
                  .w-80{
              width: 100%; 
            }
               .item-footer{
                 width: 100%; 
                }
              }
            `,
      },
    },
    view: {

    },
  };
  return component;
};