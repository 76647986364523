import block from "./block.js";
import component from "./components/index.js"
import containerContent1 from "./components/containerContent1.js";


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerContent1", containerContent1(opts));
  editor.DomComponents.addType("content1", component(opts));
    //block
  editor.BlockManager.add("content1", block(opts));
};