export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname: 'div',
              name: "Modelo",
              attributes: { class: 'div-model-1' },
              components: [
               {
                type:'image', 
                attributes: { class: 'img-model-1', src:'https://placehold.co/536x237' },
               },
               {
                type: 'div', 
                attributes: { class: 'info-model-1'},
                components: [
                  {
                    type: 'div', 
                    attributes: { class: 'header-model-1'},
                    components: [
                      {
                        type:'image', 
                        attributes: { class: 'icon-model-1'}, 
                      }, 
                      {
                        type: 'text',
                        content: 'Modelo de negócio',
                        attributes: { class: 'title-model-1'}
                      }
                    ],
                  },
                  {
                    type: 'text', 
                    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et',
                    attributes: { class: 'desc-model-1'}
                  },
                  {
                   type: 'div', 
                   attributes: { class: 'row-model-1'},
                   components: [
                      {
                       type: 'text', 
                       content: '• Faturamento médio anual: R$ 650 mil',
                       attributes: { class: 'w-33 w-sm-100 info'}
                      },
                      {
                       type: 'text', 
                       content: '• Investimento a partir de: R$ 130 mil',
                       attributes: { class: 'w-33 w-sm-100 info'}
                      },
                      {
                       type: 'text', 
                       content: '• Padrão de loja 2x3 m² a 2x4 m²',
                       attributes: { class: 'w-33 w-sm-100 info'}
                      },
                   ],
                  },
                ],
               },
               
              ],
              styles: `
               .div-model-1{
                width: 48%; 
              }
              .img-model-1{
                width: 100%; 
              }
              .info-model-1{
                width: 100%; 
                background: #F9F9F9;
                padding: 20px; 
              }
              .header-model-1{
                display: flex; 
                align-items: center;
              }
              .icon-model-1{
                width: 25px; 
                height: 25px; 
              }
              .title-model-1{
                font-size: 18px; 
                color: #363940; 
                font-weight: 600; 
                margin-left: 16px; 
              }
              .desc-model-1{
                font-size: 16px; 
                color: #8C8C8C; 
                margin-top: 10px; 
              }
              .row-model-1{
                display:flex; 
                gap: 10px; 
                margin-top: 15px;
              }
              .w-33{
                width:33%; 
              }
              .info{
                font-size:16px; 
                color:#8C8C8C; 
              }
              @media (max-width:992px){
                .w-sm-100{
                  width: 100% !important; 
                }
                .row-model-1{
                  flex-direction: column; 
                }
                .div-model-1{
                  width: 100%; 
                }
              }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};