export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        tagname: 'div',
        name: "Colunas Blog",
        attributes: { class: 'flex gap-16 sm-flex-col' },
        components: [
          {
            type: 'itemBlog2'
          },
          {
            type: 'itemBlog2'
          },
          {
            type: 'itemBlog2'
          },
        ],
        styles: `
        .flex{
          display: flex; 
        }
        .gap-16{
          gap: 16px
        }
        
        @media (max-width: 992px){
         .sm-flex-col{
           flex-direction: column; 
         }
        }
      `,
      },
    },
    view: {

    },
  };

  return component;
};
