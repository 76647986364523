export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname: 'div',
              name: "Coluna Numeros",
              attributes: { class: 'column column-50 p-20 justify-center' },
              components: [
                {
                    type: 'text',
                    content: 'Subtítulo',
                    name: 'Subtitulo',
                    attributes: { class: 'subtitle text-uppercase' },
                  },
                  {
                    tagName: 'h2',
                    type: 'text',
                    name: 'Titulo',
                    components: 'Big Numbers',
                    attributes: { class: 'principal-title' },
                    traits: [
                      {
                        type: "select",
                        options: [
                          { value: "h1", name: opts.labelN1 },
                          { value: "h2", name: opts.labelN2 },
                          { value: "h3", name: opts.labelN3 },
                          { value: "h4", name: opts.labelN4 },
                          { value: "h5", name: opts.labelN5 },
                          { value: "h6", name: opts.labelN6 },
                        ],
                        label: 'Tamanho',
                        name: "tagName",
                        changeProp: 1,
                      },
                      {
                        type: 'id',
                        name: 'id',
                        Label: 'id',
                      },
                    ],
                  },
                  {
                    type: 'text',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam viverra ex mauris, sed semper elit fermentum at. Nunc mattis tellus at maximus lacinia. Pellentesque felis lorem, scelerisque at tristique non, consequat eget ligula. Ut varius vitae dolor vitae luctus. Sed porta purus metus, vitae semper odio fermentum sit amet. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec faucibus nibh in justo ultrices ullamcorper.',
                    attributes: { class: 'body-text' },
                  },
                {
                  type: 'button',
                },
              ],
              styles: `
              .div-counters-5{
                width: 120%; 
                position: relative; 
                left: -20%; 
                z-index:99; 
                margin-top:30px;
              }
              .body-text{
                font-size: 16px; 
                color: #95A1BB; 
                margin-top: 30px;
              }
              .justify-center{
                display:flex; 
                flex-direction: column; 
                justify-content: center; 
              }
              .column{
                display:flex; 
                flex-direction:column;
                align-items: flex-start;
               }
               .principal-title{
                  font-size: 30px; 
                  color: #363940; 
                  margin:0;
                 }
               .column-50{
                width:50%; 
               }
               .subtitle{
                  font-size: 13px; 
                  color: #747474; 
                 }
                 .text-uppercase{
                  text-transform: uppercase;
                 }
                 .p-20{
                  padding:20px;
                }
              @media (max-width: 992px){
                .div-counters-5{
                  width:100%; 
                  left: 0; 
                }
                .column-50{
                    width:100%; 
                  }
              }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};