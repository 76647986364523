export default (opts = {}) => {
  const block = {
    id: "title5",
    label: "H5",
    fromPlugin: 'title',
    apper : 'out', 
    media: '<i class="bi bi-type-h5"></i>', 
    content: { type: "title5" },
  };

  return block;
};



