import block from "./block.js";
import component from "./components/index.js"
import containerHeader from "./components/containerHeader.js";


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerHeader", containerHeader(opts));
  editor.DomComponents.addType("header1", component(opts));
    //block
  editor.BlockManager.add("header1", block(opts));
};