import block from "./block.js";
import component from "./components/index.js"
import columnImgModel2 from "./components/columnImgModel2.js";
import columnModel2 from "./components/columnModel2.js";
import columnsModel2 from "./components/columnsModel2.js";
import containerModel2 from "./components/containerModel2.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("businessModel2", component(opts));
  editor.DomComponents.addType("columnImgModel2", columnImgModel2(opts));
  editor.DomComponents.addType("columnModel2", columnModel2(opts));
  editor.DomComponents.addType("columnsModel2", columnsModel2(opts));
  editor.DomComponents.addType("containerModel2", containerModel2(opts));

    //block
  editor.BlockManager.add("businessModel2", block(opts));
};