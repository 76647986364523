import block from "./block.js";
import component from "./components/index.js"
import containerContent6 from "./components/containerContent6.js";


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerContent6", containerContent6(opts));
  editor.DomComponents.addType("content6", component(opts));
    //block
  editor.BlockManager.add("content6", block(opts));
};