export default (opts = {}) => {
  const component = {
    model: {
      
      defaults: {
        type:'link', 
        name:'Botão',
        traits: [
          {
            type:'id',
            name:'id',
            Label:'id',
          },
        ],
        components:[
          {
            type:'link',
            style: {
              'min-width': '250px',
              'font-size':'16px',
              'color': '#fff',
              'padding':'10px', 
              'display':'block',
              'border': '1px solid #fff', 
              'border-radius': '5px', 
              'background-image': 'linear-gradient(#649dff 0%, #649dff 100%)',
              'box-shadow': '0 0 60px 0px #63d7ff' ,
              'text-align': 'center', 
              'text-decoration': 'none',
            },
            components: [
              {
                type:'text',
                content: 'Lorem Ipsum',
            },

            ],
          },
        ],
        styles: `
        *{
          font-family: Roboto, Arial, sans-serif;
        }
       `,
      },
    },
    view: {

    },
  };

  return component;
};
