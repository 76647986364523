export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type: 'Container', 
              name: "Container Suporte",
              attributes: { class: 'container-cubo align-center' },
              components: [
                {
                  tagName: 'h2',
                  type:'text',
                  name:'Titulo',
                  components: 'Central de Suporte',
                  attributes: {class: 'principal-title'}, 
                  traits: [
                    {
                      type: "select",
                      options: [
                        { value: "h1", name: opts.labelN1 },
                        { value: "h2", name: opts.labelN2 },
                        { value: "h3", name: opts.labelN3 },
                        { value: "h4", name: opts.labelN4 },
                        { value: "h5", name: opts.labelN5 },
                        { value: "h6", name: opts.labelN6 },
                      ],
                      label: 'Tamanho',
                      name: "tagName",
                      changeProp: 1,
                    },
                    {
                      type:'id', 
                      name:'id', 
                      label:'Id', 
                    }, 
                  ],
                },
                {
                  type:'text', 
                  content: 'Subtítulo', 
                  name:'Subtitulo',
                  attributes: {class:'subtitle text-uppercase margin-top-15'},
                }, 
                {
                  type:'support2_comp',
                },
                {
                  type: 'button',
                },
              ],
              styles: `
               .principal-title{
                font-size: 30px; 
                color: #363940; 
                margin:0;
               }
               .body-text{
                font-size: 16px; 
                color: #363940; 
                margin-top:30px;
               }
               .subtitle{
                font-size: 13px; 
                color: #747474;
                margin-top:15px; 
               }
               .text-uppercase{
                text-transform: uppercase;
               }
               .container-cubo{
                max-width: 1600px; 
             }
             .align-center{
              display:flex;
              flex-direction:column; 
              align-items: center;
             }
             .margin-top-15{
              margin-top:15px; 
             }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};