export default (opts = {}) => {
  const block = {
    category: 'Estatísticas',
    id: "statistics3",
    label: "Estatísticas (3)",
    fromPlugin: 'blocks',
    apper : 'out',
    media: '<svg class="preview-block" viewBox="0 0 266 150" fill="none" width="266"  height="150" ><path fill="#FFFFFF" d="M0 0h266v150H0z"></path><path d="M21 64.5h48a.5.5 0 01.5.5v33a.5.5 0 01-.5.5H21a.5.5 0 01-.5-.5V65a.5.5 0 01.5-.5z" stroke="#CBD5E0" fill="none"></path><path d="M50 75a5 5 0 11-10 0 5 5 0 0110 0z" fill="#6366F1"></path><path d="M39 92a1 1 0 011-1h10a1 1 0 110 2H40a1 1 0 01-1-1z" fill="#A0AEC0"></path><rect x="35" y="84" width="20" height="4" rx="2" fill="#4A5568"></rect><path d="M80 64.5h48a.5.5 0 01.5.5v33a.5.5 0 01-.5.5H80a.5.5 0 01-.5-.5V65a.5.5 0 01.5-.5z" stroke="#CBD5E0" fill="none"></path><path d="M109 75a5 5 0 11-10 0 5 5 0 0110 0z" fill="#6366F1"></path><path d="M98 92a1 1 0 011-1h10a1 1 0 010 2H99a1 1 0 01-1-1z" fill="#A0AEC0"></path><rect x="94" y="84" width="20" height="4" rx="2" fill="#4A5568"></rect><path d="M139 64.5h48a.5.5 0 01.5.5v33a.5.5 0 01-.5.5h-48a.5.5 0 01-.5-.5V65a.5.5 0 01.5-.5z" stroke="#CBD5E0" fill="none"></path><path d="M168 75a5 5 0 11-10 0 5 5 0 0110 0z" fill="#6366F1"></path><path d="M157 92a1 1 0 011-1h10a1 1 0 010 2h-10a1 1 0 01-1-1z" fill="#A0AEC0"></path><rect x="153" y="84" width="20" height="4" rx="2" fill="#4A5568"></rect><path d="M198 64.5h48a.5.5 0 01.5.5v33a.5.5 0 01-.5.5h-48a.5.5 0 01-.5-.5V65a.5.5 0 01.5-.5z" stroke="#CBD5E0" fill="none"></path><path d="M227 75a5 5 0 11-10 0 5 5 0 0110 0z" fill="#6366F1"></path><path d="M216 92a1 1 0 011-1h10a1 1 0 010 2h-10a1 1 0 01-1-1z" fill="#A0AEC0"></path><rect x="212" y="84" width="20" height="4" rx="2" fill="#4A5568"></rect><rect x="81" y="36" width="104.391" height="4" rx="2" fill="#A0AEC0"></rect><rect x="81" y="36" width="104.391" height="4" rx="2" fill="#A0AEC0"></rect><rect x="96" y="25" width="74" height="5" rx="2.5" fill="#4A5568"></rect><rect x="97" y="44" width="73" height="4" rx="2" fill="#A0AEC0"></rect><rect x="113" y="115" width="40" height="10" rx="2" fill="#6366F1"></rect></svg>',
    content: { type: 'statistics3' },
  };

  return block;
};