export default (opts = {}) => {
  const block = {
    id: "button8",
    label: "Slide Up",
    fromPlugin: 'button',
    apper : 'out', 
    media: '<img class="preview-block" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABaCAYAAABOkvOJAAAABHNCSVQICAgIfAhkiAAAA4JJREFUeF7tnT9oFEEUh3+rKCYEFFNYiAiijTam8V8hkYhioYWFWmgT0FpstBCJUSGVBMHGgNY2oliYE0Gx0yY2CjaChqAWEYUQRSHnm8sdIuaEzJuZezP5LQy7SW7evP2+fbt7m73bCs2pXq8fksWr0jZJW9P6PedRCXyV6O+kXayq6pEbqRIRy2V+RdoF93PU4Rm8HYG6/OGatCEnpF8WnpJVxwnMSQYHnJBxWTjY8XSYgCNQc0I+ycI68jBB4LMT4vZfnIwQoBAjIlppUAiFGCNgLB1WCIUYI2AsHVYIhRgjYCwdVgiFGCNgLB1WCIUYI2AsHVYIhRgjYCwdVgiFGCNgLB1WCIUYI2AsHVYIhRgjYCwdVgiFGCNgLB1WCIUYI2AsHVZISUJmfwJ3XwAT74HvsrzUpz1bgOM7ge6V/iRUFXLzCfDqg//gJfbcvRkY3Ou/Ziohp2/7D1xqzy6pjhsn/deOQvzZte05NugflEL82VFIBHZRQrJComD1D0oh/uyi9KSQKFj9g1KIP7soPSkkClb/oOaE9G0EDm8HNvT6r5TlntMzfy4ZLZSnOSEjx4DeHstI9blNTgPDDxaOY06IJiE9qnQR2l060qx/lHfqmoTS4dSPRCF6hkEjUEhQnPpg2QhZEgf1L3JQv5/JQb1x2tsnp71r9VuhxQjZnfZahJgyJ81JTZSzrJQrb3EsCjFmhUIoZJ4Ab3LI5CzL2AabPB3uspIj//+AFEIhPIa02wa6VsiNcqf8txDV+xDeSvov+I7eSuputr7zHHj7UW62/uW/VZTS08k4sauDN1uXAtLSeqh2WZZWpJRcKMSYSQqhEGMEjKXDCqGQ8ARmfszH7FkVPnbqiEVUyK3m42jO7EuNL/x42Qt5KU9wGnvWvJTTD+xwT9DKeMpayLdZ4NI9wF0xcJP7OPLwUWB1d75GshYyWgNeT/0Nf9t64GzGD3DKWkhLxcMJwD0m6IjcepT7RCHGDFIIhYQnwF1WeKaqiBSiwhe+M4WEZ6qKSCEqfOE7U0h4pqqIFKLCF74zhYRnqopIISp84TtTSHimqogUosIXvnNpQrJ/wH1BQhoPuB+XbTbj/yAABQmpOSEDIuSxtGXhdyaMuAgCc/Lagcp1ECkjMju/iM58aXgCl6uqGmoIaUrZKvNz0vZLk4/+c0pAQL6kHfL94LguMt648X4Ddeof6qInXdIAAAAASUVORK5CYII=">',
    content: { type: "button8" },
  };

  return block;
};

