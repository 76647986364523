import block from "./block.js";
import component from "./components/index.js"
import columnImageNumbers10 from "./components/columnImageNumbers10.js";
import columnNumbers10 from "./components/columnNumbers10.js";
import columnsNumbers10 from "./components/columnsNumbers10.js";
import containerNumbers10 from "./components/containerNumbers10.js";
import counters10 from "./components/counters10.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("bigNumber10", component(opts));
  editor.DomComponents.addType("columnImageNumbers10", columnImageNumbers10(opts));
  editor.DomComponents.addType("columnNumbers10", columnNumbers10(opts));
  editor.DomComponents.addType("columnsNumbers10", columnsNumbers10(opts));
  editor.DomComponents.addType("containerNumbers10", containerNumbers10(opts));
  editor.DomComponents.addType("counters10", counters10(opts));


    //block
  editor.BlockManager.add("bigNumber10", block(opts));
};