export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
                tagname: 'div',
                name: "Coluna Eventos",
                attributes: { class: 'column column-50 position-relative p-20' },
                components: [
                   {
                    type: 'div', 
                    attributes: { class: 'galery'},
                    components: [
                        {
                            type: 'div', 
                            attributes: { class: 'slides'}, 
                            components: [
                                {
                                    type: 'div', 
                                    attributes: { class: 'li-slide', id:'slide1'}, 
                                    components: [
                                        {
                                            type: 'image', 
                                            attributes: { class:'img-slide', src:'https://placehold.co/325x460'}
                                        }
                                    ]
                                },
                                {
                                    type: 'div', 
                                    attributes: { class: 'li-slide', id:'slide2'}, 
                                    components: [
                                        {
                                            type: 'image', 
                                            attributes: { class:'img-slide', src:'https://placehold.co/325x460'}
                                        }
                                    ]
                                },
                                {
                                    type: 'div', 
                                    attributes: { class: 'li-slide', id:'slide3'}, 
                                    components: [
                                        {
                                            type: 'image', 
                                            attributes: { class:'img-slide', src:'https://placehold.co/325x460'}
                                        }
                                    ]
                                },
                                {
                                    type: 'div', 
                                    attributes: { class: 'li-slide', id:'slide4'}, 
                                    components: [
                                        {
                                            type: 'image', 
                                            attributes: { class:'img-slide', src:'https://placehold.co/325x460'}
                                        }
                                    ]
                                },
                                {
                                    type: 'div', 
                                    attributes: { class: 'li-slide', id:'slide5'}, 
                                    components: [
                                        {
                                            type: 'image', 
                                            attributes: { class:'img-slide', src:'https://placehold.co/325x460'}
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                          type: 'div',
                          attributes: { class: 'tumbs'},
                          components: [
                            {
                                type: 'div', 
                                attributes: { class: 'li-tumb'},
                                components: [
                                    {
                                        type: 'link', 
                                        attributes: { class:'a-tumbs', href:'#slide1'}, 
                                        components: [
                                            {
                                                type: 'image', 
                                                attributes: { class: 'img-tumb', src:'https://placehold.co/100x100'}
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                type: 'div', 
                                attributes: { class: 'li-tumb'},
                                components: [
                                    {
                                        type: 'link', 
                                        attributes: { class:'a-tumbs', href:'#slide2'}, 
                                        components: [
                                            {
                                                type: 'image', 
                                                attributes: { class: 'img-tumb', src:'https://placehold.co/100x100'}
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                type: 'div', 
                                attributes: { class: 'li-tumb'},
                                components: [
                                    {
                                        type: 'link', 
                                        attributes: { class:'a-tumbs', href:'#slide3'}, 
                                        components: [
                                            {
                                                type: 'image', 
                                                attributes: { class: 'img-tumb', src:'https://placehold.co/100x100'}
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                type: 'div', 
                                attributes: { class: 'li-tumb'},
                                components: [
                                    {
                                        type: 'link', 
                                        attributes: { class:'a-tumbs', href:'#slide4'}, 
                                        components: [
                                            {
                                                type: 'image', 
                                                attributes: { class: 'img-tumb', src:'https://placehold.co/100x100'}
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                type: 'div', 
                                attributes: { class: 'li-tumb'},
                                components: [
                                    {
                                        type: 'link', 
                                        attributes: { class:'a-tumbs', href:'#slide5'}, 
                                        components: [
                                            {
                                                type: 'image', 
                                                attributes: { class: 'img-tumb', src:'https://placehold.co/100x100'}
                                            }
                                        ]
                                    }
                                ]
                            }
                          ]
                        }
                    ]
                   }

                ],
                styles: `
             .galery{
                width: 100%; 
                display: flex; 
                justify-content: center; 
             }
             .slides{
                position: relative; 
                width: 90%; 
                margin-right: 10px; 
             }
             .li-slide{
                position: absolute; 
                left: 0; 
                top: 0; 
                width: 100%; 
             }
             .li-slide:target{
              z-index:3;
             animation: slide 1s 1;
             }
             .li-slide:not(:target){
             animation:hidden 1s 1;
             }
             .img-slide{
                width:100%; 
                height: 500px; 
             }
             .tumbs{
                display: flex; 
                flex-direction: column; 
             }
             .li-tumb{
               width: 100%; 
               margin-bottom: 10px; 
             }
             .img-tumb{
                width: 100%; 
             }

             .column{
              display:flex; 
              flex-direction:column;
              align-items: flex-start;
             }
             .column-50{
              width:50%; 
             }

             @media (max-width:992px){
              .column-50{
                width:100%; 
              }
             }
            `,
            },
        },
        view: {

        },
    };
    return component;
};