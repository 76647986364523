export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              name: "Contador",
              attributes: { class:'counter-container'},
              components: [ 
                {
                  tagName: 'div',
                  attributes: { class: 'counters' },
                  components: [
                    {
                      type:'counters',
                      attributes:{class:'counter-value font-60'},
                    },
                    {
                      type: 'text',
                      content: '+',
                      attributes: { class: 'sufix font-60'},
                    },
                  ],
                },
                   {
                  type: 'text',
                  content: 'Lorem',
                  attributes: { class: 'context font-20 gray' }
                },
                {
                  type: 'text',
                  content: 'Lorem',
                  attributes: { class: 'counter-text gray' }
                },
              ],
              styles: `
              .context{
                margin-top:15px;
              }
              .sufix{
                color:#363940; 
                margin-left:8px; 
              }
              .counters{
                display:flex;
                justify-content:center; 
                aling-items:center;
              }
              .font-60{
                font-size:60px;
              }
              .font-20{
                font-size:20px; 
              }
              .gray{
                color:#363940;
              }
              .counter-container{
                display:flex;
                flex-direction:column; 
                align-items:flex-start; 
              }
              .counter-text{
                font-size:16px;
                margin-top:15px; 
              }
              @media (max-width:992px){
                .counter-container{ 
                  align-items:center; 
                }
              }
           `,
            },
          },
          view: {
      
          },
};
  return component; 
};