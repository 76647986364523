import block from "./block.js";
import component from "./components/index.js"
import containerModel3 from "./components/containerModel3.js";
import modelBusiness3 from "./components/modelBusiness3.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("businessModel3", component(opts));
  editor.DomComponents.addType("containerModel3", containerModel3(opts));
  editor.DomComponents.addType("modelBusiness3", modelBusiness3(opts));

    //block
  editor.BlockManager.add("businessModel3", block(opts));
};