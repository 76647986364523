export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname: 'div',
              name: "Coluna Eventos",
              attributes: { class: 'column column-50 p-20' },
              components: [
                {
                  type: 'text',
                  content: 'Subtítulo',
                  name: 'Subtitulo',
                  attributes: { class: 'subtitle text-uppercase' },
                },
                {
                  tagName: 'h2',
                  type: 'text',
                  name: 'Titulo',
                  components: 'Eventos realizados',
                  attributes: { class: 'principal-title' },
                  traits: [
                    {
                      type: "select",
                      options: [
                        { value: "h1", name: opts.labelN1 },
                        { value: "h2", name: opts.labelN2 },
                        { value: "h3", name: opts.labelN3 },
                        { value: "h4", name: opts.labelN4 },
                        { value: "h5", name: opts.labelN5 },
                        { value: "h6", name: opts.labelN6 },
                      ],
                      label: 'Tamanho',
                      name: "tagName",
                      changeProp: 1,
                    },
                    {
                      type: 'id',
                      name: 'id',
                      Label: 'id',
                    },
                  ],
                },
                {
                  type: 'text',
                  content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam viverra ex mauris, sed semper elit fermentum at. Nunc mattis tellus at maximus lacinia. Pellentesque felis lorem, scelerisque at tristique non, consequat eget ligula. Ut varius vitae dolor vitae luctus. Sed porta purus metus, vitae semper odio fermentum sit amet. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec faucibus nibh in justo ultrices ullamcorper.',
                  attributes: { class: 'body-text' },
                },
                {
                  type: 'div', 
                  attributes: { class: 'margin-top-30'},
                  components: [
                    {
                        type: 'div', 
                        attributes: { class: 'd-flex'}, 
                        components: [
                            {
                                type: 'image', 
                                attributes: { class: 'icon-cheklist'}
                            },
                            {
                                type: 'text',
                                content: 'Lorem ipsum dolor sit amet', 
                                attributes: { class: 'text-cheklist'}
                            }
                        ]
                    },
                    {
                        type: 'div', 
                        attributes: { class: 'd-flex margin-top-10'}, 
                        components: [
                            {
                                type: 'image', 
                                attributes: { class: 'icon-cheklist'}
                            },
                            {
                                type: 'text',
                                content: 'Lorem ipsum dolor sit amet', 
                                attributes: { class: 'text-cheklist'}
                            }
                        ]
                    },
                    {
                        type: 'div', 
                        attributes: { class: 'd-flex margin-top-10'}, 
                        components: [
                            {
                                type: 'image', 
                                attributes: { class: 'icon-cheklist'}
                            },
                            {
                                type: 'text',
                                content: 'Lorem ipsum dolor sit amet', 
                                attributes: { class: 'text-cheklist'}
                            }
                        ]
                    },
                  ]
                },
                {
                  type: 'button',
                },
              ],
              styles: `
               .margin-top-30{
                margin-top: 30px; 
               }
               .margin-top-10{
                margin-top: 10px; 
               }
               .d-flex{
                display: flex; 
               }
               .icon-cheklist{
                width: 15px; 
                height: 15px;
               }
               .text-cheklist{
                font-size: 16px; 
                color: #363940; 
                margin-left: 10px;
               }
               .principal-title{
                font-size: 30px; 
                color: #363940; 
                margin:0;
               }
               .body-text{
                font-size: 16px; 
                color: #363940; 
                margin-top:30px;
               }
               .subtitle{
                font-size: 13px; 
                color: #747474; 
               }
               .text-uppercase{
                text-transform: uppercase;
               }
              .p-20{
                padding:20px;
              }

            `,
            },
          },
          view: {
      
          },
};
  return component; 
};