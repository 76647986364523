import block from "./block.js";
import component from "./components/index.js"
import columnBigNumbers9 from "./components/columnBigNumbers9.js";
import columnNumbers9 from "./components/columnNumbers9.js";
import columnsNumbers9 from "./components/columnsNumbers9.js";
import containerNumbers9 from "./components/containerNumbers9.js";
import counters9 from "./components/counters9.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("bigNumber9", component(opts));
  editor.DomComponents.addType("columnBigNumbers9", columnBigNumbers9(opts));
  editor.DomComponents.addType("columnNumbers9", columnNumbers9(opts));
  editor.DomComponents.addType("columnsNumbers9", columnsNumbers9(opts));
  editor.DomComponents.addType("containerNumbers9", containerNumbers9(opts));
  editor.DomComponents.addType("counters9", counters9(opts));


    //block
  editor.BlockManager.add("bigNumber9", block(opts));
};