export default (opts = {}) => {
    const component = {
            model: {
              defaults: {
                type:'video', 
                components:[
                ],
              },
            },
            view: {
        
            },
};
  return component; 
};