export default (opts = {}) => {
    const block = {
      id: "image5",
      label: "Imagem redonda",
      fromPlugin: 'image',
      apper : 'out',
      media:'<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"><g id="Grupo_176" data-name="Grupo 176" transform="translate(19771 17802)"><g id="Grupo_175" data-name="Grupo 175"><g id="Elipse_4" data-name="Elipse 4" transform="translate(-19771 -17802)" fill="none" stroke="#6E707F" stroke-width="1"> <circle cx="7.5" cy="7.5" r="7.5" stroke="none"/><circle cx="7.5" cy="7.5" r="7" fill="none"/></g><path id="image-fill" d="M0,13.638ZM.563,11.86l5.882.323a8.812,8.812,0,0,1,1.221-.154l1.195-.319c.428,0,3.578-2.175,3.578-2.823l-1.407-.62L8.7,7.253a.3.3,0,0,0-.447.109L5.377,11.711,3.316,9.634a.3.3,0,0,0-.488.073ZM4.652,4.847c0-.971-.52-1.228-1.163-1.228s-1.163.257-1.163,1.228.52,1.225,1.163,1.225,1.163-.254,1.163-1.225" transform="translate(-19769.795 -17801.637)"/><path id="Caminho_42" data-name="Caminho 42" d="M5.113-.436c2.831,0,6.822-2.809,6.822-2.116S10.043,1.73,7.212,1.73-.187.661-.187-.031,2.282-.436,5.113-.436Z" transform="translate(-19769 -17790)"/><path id="Caminho_43" data-name="Caminho 43" d="M.685-.071c.688,0,3.753.537,3.753.889S1.373,1.2.685,1.2-3.326.44-3.326.088,0-.071.685-.071Z" transform="translate(-19764.67 -17789)"/></g></g> </svg>',
      content: { type: 'image5' },
    };
  
    return block;
  };