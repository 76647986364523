export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              name: 'Div',
              attributes: { class: 'scroll-down-3' },
              components: [
                {
                  type: 'link', 
                  components: [
                    {
                      type:'image',
                      attributes:{class:'arrow-down'},
                    },
                  ]
                }
              ],
              styles: `
              .scroll-down-3{
                width:60px; 
                height:60px; 
                border:2px solid #707070; 
                border-radius:100%; 
                background:#fff;
                display:flex;
                justify-content:center; 
                position:absolute; 
                bottom:-30px;
                z-index:9; 
                align-items: center;
              }
              .arrow-down{
                width:20px;
                height: 20px; 
              }
      
              @media (max-width:992px){
                .scroll-down-3{
                  display:none;
                }
              }
      
            `,
            },
          },
          view: {
      
          },
    }; 
    return component; 
};