export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        tagname:'div', 
        name: "Item Blog",
        attributes: { class: 'blog-item1' },
        components: [
          {
            type:'default', 
            attributes: { class: 'div-item1' },
            components: [
              {
                type: 'title2',
                components: [
                  {
                    type:'text',
                    name:'H2',
                    components: 'Categoria',
                    attributes: { class: 'catogory1' },
                  },
                ],
              }, 
              {
                type: 'title3',
                components: [
                  {
                    type:'text',
                    name:'H3',
                    components: 'Lorem Ipsum',
                    attributes: { class: 'title-blog1' },
                  },
                ],
                
              }, 
              {
                type: 'text', 
                attributes: { class: 'text-blog1' },
                components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus.',
              },
              {
                type: 'link', 
                attributes: { class: 'more' },
                components: 'Leia mais',
              },            
            ]
        },
        ],
        styles: `
          .blog-item1{
            width: 33%; 
          }
          .div-item1{
            height: 100%; 
            width: 100%;
            background-image: linear-gradient(#f3f4f6 0%, #f3f4f6 100%); 
            border-radius: 0.5rem;
            padding: 4rem 2rem; 
            overflow-x: hidden; 
            overflow-y: hidden;
            position: relative; 
            text-align: center; 
          }
            .catogory1{
              letter-spacing: 0.1em; 
              font-weight: 500; 
              font-size: 0.75rem; 
              line-height: 1rem;
              margin-bottom: 0.25rem; 
              color: #9CA3AF; 
            }
          .title-blog1{
            font-size: 1.5rem; 
            line-height: 2rem;
            color: #111827; 
            font-weight: 500; 
            margin-bottom: 0.75rem; 
          }
         .text-blog1{
           line-height: 1.625;
           margin-bottom: 0.75rem; 
           color: #000; 
         }
           .more{
            color: #6366f1;
            font-size: 16px; 
            text-decoration: none; 
          }
          @media (max-width: 992px){
            .blog-item1{
            width: 100%; 
          }
          }
      `,
       },
    },
    view: {

    },
  };

  return component;
};
