export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
                type: 'Container',
                name: 'Container Produtos',
                attributes: { class: 'container-cubo' },
                components: [
                   {
                    type: 'columnsProducts4'
                   }
                ],
                styles: `
              
               .container-cubo{
                max-width: 1600px; 
             }
            `,
            },
        },
        view: {

        },
    };
    return component;
};