

export default (opts = {}) => {
  const component = {
    model: {
      
      defaults: {
        type:'map', 
        name:'Mapa',
        styles: `
       `,
      },
    },
    view: {

    },
  };

  return component;
};
