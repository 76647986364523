import block from "./block.js";
import component from "./components/index.js"
import containerStep from "./components/containerStep.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerStep", containerStep(opts));
  editor.DomComponents.addType("step1", component(opts));
    //block
  editor.BlockManager.add("step1", block(opts));
};