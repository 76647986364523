export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: "Div",
              attributes: { class: 'support-2-container' },
              components: [      
                {
                  type:'rowSupport2',
                },
      
              ],
              styles: `
               .support-2-container{
                display: flex; 
                flex-direction:column;
                margin-top:50px;
               }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};