import block from "./block.js";
import component from "./components/index.js"
import containerPartners1 from "./components/containerPartners1.js";
import rowPartners1 from "./components/rowPartners1.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("partners1", component(opts));
  editor.DomComponents.addType("containerPartners1", containerPartners1(opts));
  editor.DomComponents.addType("rowPartners1", rowPartners1(opts));

    //block
  editor.BlockManager.add("partners1", block(opts));
};