export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        tagname: 'div',
        name: "Item Blog",
        attributes: { class: 'item-blog4' },
        components: [
          {
            type: 'default',
            attributes: { class: 'infos-blog4' },
            components: [
              {
                type: 'title2',
                components: [
                  {
                    type: 'text',
                    name: 'H2',
                    components: 'Categoria',
                    attributes: { class: 'category4' },
                  },
                ],
              },
              {
                type: 'text',
                components: '12 Jan 2024',
                attributes: { class: 'date4' }
              }
            ]
          },
          {
            type: 'default',
            attributes: { class: '' },
            components: [
              {
                type: 'title3',
                components: [
                  {
                    type: 'text',
                    name: 'H3',
                    components: 'Lorem Ipsum',
                    attributes: { class: 'title-blog4' },
                  },
                ],
                
              },
              {
                type: 'text',
                attributes: { class: 'text-blog4' },
                components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus.',
              },
              {
                type: 'link',
                attributes: { class: 'read4' },
                components: 'Leia mais',
              },

            ]
          },
          {
            type: 'default',
            name: 'divisor',  
            attributes: { class: 'divider4' },
          }
        ],
        styles: `
        .item-blog4{
          width: 100%;
          display: flex; 
          align-items: flex-start; 
          position: relative; 
          padding-bottom: 30px; 
        }
        .category4{
          color: #374151;
          font-weight: 600; 
          font-size: 14px; 
        }
        .infos-blog4{
         min-width:20%;  
        }
        .date4{
          font-size: 12px; 
          color: #6B7280;         
        }
        .title-blog4{
         font-size: 1.5rem; 
         line-height: 2rem; 
         font-weight: 500; 
         color: #111827; 
        }
        .text-blog4{
          color: #6B7280; 
          line-height: 1.625; 
          margin-top: 15px; 
          font-size: 16px;  
        }
        .read4{
            color: #6366F1; 
            font-size: 16px; 
            margin-top: 15px; 
            display: block;           
          }
        .divider4{
         width: 100%; 
         height: 2px; 
         background-image: linear-gradient(#f3f4f6 0%, #f3f4f6 100%); 
         position: absolute; 
         left: 0; 
         bottom: 0; 
        }

        @media (max-width:992px){
         .item-blog4{
           flex-direction: column; 
           gap: 20px; 
         }
        }
      `,

      },
    },
    view: {

    },
  };

  return component;
};
