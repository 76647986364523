export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname: 'div',
              name: "Coluna Numeros",
              attributes: { class: 'column column-50 position-relative p-20' },
              components: [
                {
                  type: 'image',
                  attributes: { class: 'image-numbers', src: 'https://placehold.co/536x456' }
                },
      
              ],
              styles: `
              .image-numbers{
                max-width:100%;
             }
             .column{
              display:flex; 
              flex-direction:column;
              align-items: flex-start;
             }
             .column-50{
              width:50%; 
             }
             @media (max-width:992px){
              .column-50{
                width:100%; 
              }
             }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};