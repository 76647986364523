export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname: 'div',
              name: "Coluna Numeros",
              attributes: { class: 'column column-60 position-relative p-20' },
              components: [
               {
                type: 'div', 
                attributes: { class: 'd-flex d-sm-column gap-16 wrap'},
                components: [
                  {
                    type: 'counters9'
                  },
                  {
                    type: 'counters9'
                  },
                  {
                    type: 'counters9'
                  },
                  {
                    type: 'counters9'
                  },
                  {
                    type: 'counters9'
                  },
                  {
                    type: 'counters9'
                  },
                  
                ]
               }
              ],
              styles: `
                .d-flex{
                  display: flex; 
                }
                .gap-16{
                  gap: 16px; 
                }
                .wrap{
                  flex-wrap: wrap; 
                }

                @media (max-width: 992px){
                  .d-sm-column{
                    flex-direction: column; 
                  }
             }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};