export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
                name: 'Div Tempo',
                attributes: { class: 'time-line-7' },
                components: [
                    {
                        type: 'itemTimeLineTop7',
                    },
                    {
                        type: 'itemTimeLineBottom7',
                    },
                    {
                        type: 'itemTimeLineTop7',
                    },
                    {
                        type: 'itemTimeLineBottom7',
                    },
                    {
                        type: 'itemTimeLineTop7',
                    },
                    {
                        type: 'itemTimeLineBottom7',
                    },
                    {
                        type: 'itemTimeLineTop7',
                    },
                    {
                        type: 'itemTimeLineBottom7',
                    },
                    
                    {
                        type: 'div',
                        attributes: { class: 'divider'}
                    }
                ],
                styles: `
               .time-line-7{
                display:flex; 
                margin-top:50px;
                justify-content:center; 
                position: relative;                 
               }
               .divider{
                width: 100%; 
                height: 5px; 
                position :absolute; 
                left: 0; 
                top: 53%; 
                background: #363940; 
               }

               @media (max-width:992px){
                .time-line-7{
                  flex-wrap: wrap; 
                }
                .divider{
                    display: none;
                }
               }
      
            `,
            },
        },
        view: {

        },
    };
    return component;
};