export default (opts = {}) => {
    const component = {
        model: {
            defaults: { 
              tagname:'div', 
              name: "Coluna Formulário",
              attributes: { class: 'column column-50 p-20 position-relative' },
              components: [
                {
                  type: 'image', 
                  attributes: {class: 'image-form',  src: 'https://placehold.co/812x567'}
                },
                
              ],
              styles: `
             .image-form{
               width:100%; 
             }
             .p-20{
              padding:20px;
             }
             .column{
              display:flex; 
              flex-direction:column;
              align-items:flex-start;
             }
             .column-50{
              width:50%; 
             }
             @media (max-width:992px){
              .column-50{
                width:100%; 
              }
             }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};