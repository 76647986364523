export default (opts = {}) => {
  const block = {
    category: 'Cabeçalho',
    id: "header3",
    label: "Cabeçalho (3)",
    fromPlugin: 'blocks',
    apper : 'out',
    media: '<svg class="preview-block" fill="none" viewBox="0 0 266 150" width="266"  height="150" ><path fill="#FFFFFF" d="M0 0h266v150H0z"></path><path stroke="#E2E8F0" d="M266 38.5H0" fill="none"></path><rect x="217" y="14" width="29" height="10" rx="2" fill="#CBD5E0"></rect><circle cx="133" cy="19" r="9" fill="#6366F1"></circle><rect x="62.264" y="17" width="16.604" height="4" rx="2" fill="#4A5568"></rect><rect x="41.132" y="17" width="16.604" height="4" rx="2" fill="#4A5568"></rect><rect x="83.396" y="17" width="16.604" height="4" rx="2" fill="#4A5568"></rect><rect x="20" y="17" width="16.604" height="4" rx="2" fill="#4A5568"></rect></svg>',
    content: { type: 'header3' },
  };

  return block;
};