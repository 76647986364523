export default (opts = {}) => {
  const block = {
    id: "button4",
    label: "Pulsante",
    fromPlugin: 'button',
    apper : 'out', 
    media: '<img class="preview-block" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABbCAYAAACFziAsAAAABHNCSVQICAgIfAhkiAAABM5JREFUeF7tnUloFFEQhv82GvctcSfuG4LgQhBBDx7EBREUXPAkCJ5FL4oIihteFD0LevHgAi4XFxA8iAdFcAsaV5S4RCUa474lVmUyzphorM70vJTmf/CYSafmVfX/dfV7PVM9E6Gh1dXVzZWnW6WPkN4rvZ2PeVWgWkZ/IH1DFEWn1FMkIArkcYv0dfp3Xt1z8D8pUCf/2CZ9kwKZIU/OUatWV6BWIpilQE7Lk9mtHg4DUAXOKJBKedKferhQ4LkC0fMXmxMFCMQJiHQYBEIgzhRwFg4zhECcKeAsHGYIgThTwFk4zBACcaaAs3CYIQTiTAFn4TBDCMSZAs7CYYYQiDMFnIXDDCEQZwo4C4cZkicglVLg061TqjfX9PPaKKvWh0DyBOTWU6BPd6CvdG03HgOj+gGdCzMOP30Fvn4HumdBI5AAQN59Au5IKcnkYRlnHz4DZU+AKVqWmNUIJACQBy+AwvZASVHKmQIqfwaUDm/qnEACALl4P5UdHaRG9K3AuNsoW5gheYKQPewXmRsKZLIuaJfZqvNFmcwlk4b+OYAWZciFO8CjKqDyDfBdCyDZfiqgAAb0BIb2AaaN/lWY6g9Sxd4ls63mI1AjGVPSO7MtFpBvQnjTMUAr617UkEJzCvSX1VUkcDYuANprOXtDq3oHvHwL6GPXjlIy2gMYmHWvQSwg6w8Db4Tol2+EYVGgUED06ipl7Ysy1vdkgu8hy9yibgIq63SWtjADOXoZuCSTU9V7Syi0SStQJECmjgQWlto0MQNZewh4RRg2VRtZFQuUHUtTG3W5q6f+7KbzSnpJbAJSK5PG+iOp8x5bfAWK5fS0fTHQTlZd1XJQN65u12sUnU+0mYCo4cp98QPhKzIK7F1hU4NAbDrlbEUgOUuY7AAEkqyeOY9GIDlLmOwABJKsnjmPRiA5S5jsAMGB6DuY8ycCg4uT3ZF/aTS9Tjt0EbjyqGnUwYHsWALoBVBbbxXyLvjmEw6AWI+AtgDsdxfRVn0SuzC0OiSQ5hUgkDwcIS4yhHNIimzFK5lDjjuYQ+pXWZNkldVQWZGHA8/9kK5WWe7VauUArXNsYnNIK++ve/cE4gwRgRCIMwWchcMMIRBnCjgLJ/EMWSdFcqw6aRllfdNVL5wtzbzs3Slf86s1RWzxFRg3CFgzx/Y6M5ByuSNo/3kWy9lkzVhpEdzKGcCYAbZXmoHocNcrgAMXgNdSxc32dwUUxvLpwPiSv9umLWIB0RddlU/DTl4DHr+WSjwpFuaXzP4qtt7AWSu3aGhp6LwJwIQhdhhqGRtIevj3co+c3u/A1lQBzYx0aWhcfVoMJK4j2tsUIBCbTsGsCCSY1DZHBGLTKZgVgQST2uaIQGw6BbMikGBS2xwRiE2nYFYEEkxqmyMCsekUzIpAgkltc0QgNp2CWRFIMKltjgjEplMwKwIJJrXNEYHYdApmRSDBpLY5IhCbTsGsCCSY1DZHBGLTKZgVgQST2uaIQGw6BbMikGBS2xwRiE2nYFYKpFy8jQ3mkY6aU+C2AtktFquokwsF9igQ/fLrMum/+a5+F0G2lSBuyo6W1v+2i0DRYvld0pe1lb13tp8HJZ7VURRVZv3YTj0YLZyfKX2Ys4D/13Aeyo6dFRDymwmp9gMEEUV4qWPlNAAAAABJRU5ErkJggg==">',
    content: { type: "button4" },
  };

  return block;
};

