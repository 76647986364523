export default (editor, opts = {}) => {
  // const domc = editor.DomComponents;
  const pipes = opts.pipes ?? [];
  const forms = opts.forms ?? [];
  const State = opts.State ?? undefined;

  const baseStyle = document.createElement('style');

  baseStyle.innerHTML = `
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Nunito:wght@200;300;400;500;700&display=swap');

    .d-none{
      display: none!important;
    }
    .swal2-container{
      font-family: Nunito,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    }

    .swal2-title{
      font-size: 1.5em!important;
    }

    .cubo-form{
      justify-content: space-between;
      row-gap: 10px;
      font-family: Nunito,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    }

    .cubo-form label{
      display: inline-block;
      display: flex;
      width: 100%;
      font-size: 14px;
    }

    .column-6 {
      max-width: 48%;
      flex: 0 0 48%;
    }

    .column-12 {
      max-width: 96%;
      flex: 0 0 96%;
    }

    .column {
      display: flex;
      flex-direction: column;
    }

    .rows {
        display: flex;
    }

    .wrap {
        flex-wrap: wrap;
    }

    body.swal2-shown.swal2-height-auto {
      height: 100vh!important;
    }

    .swal2-container{
      overflow-x: hidden!important;
    }

    .swal2-container input{
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: #fff;
      border: 1px solid #e2e4e6cc;
      border-radius: 3px;
      color: #5c6268;
      font-size: .875rem;
      padding: 0.5rem;
      width: 100%;
      transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
    }

    .swal2-container select{
      appearance: none;
      padding: 8px;
      width: 100%;
      cursor: pointer;
      background: #fff;
      color: #5c6268;
      border: 1px solid #ced4da;
      border-radius: 3px;
      transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
    }
    .swal2-container select::after{
      content: '';
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #333; /* Arrow color */
      pointer-events: none;
    }
  `;

  if (typeof Swal === "undefined") {

    const swalScript = document.createElement("script");
    swalScript.src =
      "https://cdn.jsdelivr.net/npm/sweetalert2@11.10.1/dist/sweetalert2.all.min.js";
    swalScript.type = "text/javascript";
    document.getElementsByTagName("head")[0].appendChild(swalScript);
  }

  document.getElementsByTagName('head')[0].appendChild(baseStyle);

  const script = function (props) {
    const definePristine = () => {
      if (typeof Pristine === "undefined") {
        const pristineScript = document.createElement("script");
        pristineScript.src =
          "https://cdn.jsdelivr.net/npm/pristinejs@1.1.0/dist/pristine.min.js";
        pristineScript.type = "text/javascript";
        document.getElementsByTagName("head")[0].appendChild(pristineScript);

        pristineScript.onload = () => {
          setTimeout(() => {
            Pristine.addMessages("pt-BR", {
              required: "Campo obrigatório",
              email: "Este campo requer um endereço de e-mail válido",
              number: "Este campo requer um número válido",
              integer: "Este campo requer um número inteiro válido",
              url: "Este campo requer uma URL válida",
              tel: "Este campo requer um número de telefone válido",
              maxlength: "Este campo deve ter no máximo ${1} caracteres",
              minlength: "Este campo deve ter pelo menos ${1} caracteres",
              min: "O campo deve ter pelo menos ${1} caracteres",
              max: "Este campo deve ter no máximo ${1} caracteres",
              pattern: "Este campo deve corresponder ao padrão",
              equals: "Os campos não correspondem",
              default: "Este campo não é válido",
            });

            Pristine.setLocale("pt-BR");
          }, 300)
        }

        return pristineScript
      }

      return undefined
    }

    const defineCookies = () => {
      if (typeof Cookies === "undefined") {
        const cookiesScript = document.createElement("script");
        cookiesScript.src =
          "https://cdn.jsdelivr.net/npm/js-cookie@3.0.5/dist/js.cookie.min.js";
        cookiesScript.type = "text/javascript";
        document.getElementsByTagName("head")[0].appendChild(cookiesScript);
        return cookiesScript
      }

      return undefined
    }

    const defineVMasker = () => {
      if (typeof VMasker === "undefined") {
        const maskScript = document.createElement("script");
        maskScript.src =
          "//unpkg.com/vanilla-masker@1.1.1/build/vanilla-masker.min.js";
        maskScript.type = "text/javascript";
        document.getElementsByTagName("head")[0].appendChild(maskScript);
        return maskScript
      }

      return undefined
    }

    const defineSentry = () => {
      if (typeof Sentry === "undefined") {
        const sentryScript = document.createElement("script");
        sentryScript.src = "https://js.sentry-cdn.com/709a655395f70235353b4983b1ee3690.min.js";
        sentryScript.crossorigin = "anonymous";
        sentryScript.onload = function () {
          Sentry.init({ dsn: 'https://709a655395f70235353b4983b1ee3690@o4505942537011200.ingest.us.sentry.io/4507613086482432' });

          Sentry.configureScope((scope) => {
            scope.setTag("pipeId", props.pipe ?? 'Sem valor');
            scope.setTag("key", props.keycubo ?? 'Sem valor');
          });

        };
        document.getElementsByTagName("head")[0].appendChild(sentryScript);
        return sentryScript
      }

      return undefined
    }

    const addLogMessage = (message, data = null) => {
      logMessages.push({ message, data })
    }

    const clearLogMessages = () => {
      logMessages = []
    }

    const logMessages = []

    addLogMessage('Inicio do formulário', props)

    addLogMessage('Definindo Sentry')
    defineSentry()

    addLogMessage('Definindo Cookies')
    defineCookies()

    addLogMessage('Definindo Pristine')
    definePristine()

    addLogMessage('Definindo VMasker')
    defineVMasker()


    const state = this.querySelector("[data-cf-type='state']");

    if (state) {
      addLogMessage('Tem o customfield state no formulário')

      state.addEventListener('change', event => {
        const selectedCity = this.querySelector("[data-cf-type='city']");

        if (!selectedCity) return Swal.fire({
          title: "<strong>Não foi encontrado o campo do tipo 'city' no formulário.</strong>",
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: `Ok`,
        })

        selectedCity.options[0].text = "Carregando cidades..."

        addLogMessage('Estado selecionado', event.target.value)

        fetch(`https://api.cubosuite.com.br/tools/cities/country/BR/state/${event.target.value}`, {
          headers: {
            "Content-type": "application/json",
          }
        })
          .then((response) => response.json())
          .then((response) => {
            selectedCity.innerHTML = '';
            const emptyOption = document.createElement('option');
            emptyOption.value = '';
            emptyOption.text = 'Selecione uma cidade';
            selectedCity.appendChild(emptyOption);

            response.forEach(c => {
              const option = document.createElement('option');
              option.value = c.name;
              option.text = c.name;
              selectedCity.appendChild(option);
            });
          })

      })
    }

    if (props.action === 'update') {
      addLogMessage('É atualização')

      setTimeout(() => {
        const url = window.location.href;

        const storage = localStorage.getItem("cuboDeal");
        const deal = storage ? JSON.parse(storage) : null;

        if ((!deal || !deal.id) && (!url.includes('127.0.0.1') && !url.includes('builder.cubosuite.com.br') && !url.includes('localhost'))) {
          alert('É necessario preencher a primeira etapa')

          if (props.redirect_not_filed) {
            window.location.href = props.redirect_not_filed
            return
          }


          return
        }

        let fields = this.querySelectorAll("input, select");

        this.querySelector('[name="title"]') ? this.querySelector('[name="title"]').value = deal && deal.people && deal.people.name ? deal.people.name : '' : null;
        this.querySelector('[name="phone"]') ? this.querySelector('[name="phone"]').value = deal && deal.people && deal.people.phone ? deal.people.phone : '' : null;


        fields.forEach((field) => {
          let type = field.getAttribute("type");
          let id = field.getAttribute("name");
          let customfieldName = field.getAttribute("data-cf");
          const customfield = deal && deal.customfields.length > 0 ? deal.customfields.find((customfield) => customfield.customfield.id === id) : null;

          if (customfieldName !== 'phone' && customfieldName !== 'title' && customfield) {
            if (type === "text" || type === "email") {
              field.value = customfield.value;
            }

            if (type === "select") {
              field.value = customfield.value;
            }
          }

        });

        this.querySelectorAll("[mask]").forEach(input => VMasker(input).maskPattern(input.getAttribute('mask')))

      }, 300);
    }

    setTimeout(() => {
      this.querySelectorAll("[mask]").forEach((input) => {
        defineVMasker()
        VMasker(input).maskPattern(input.getAttribute("mask"))
      });
    }, 300);

    this.addEventListener("click", (event) => {
      addLogMessage('Clique no formulário')

      if (typeof dataLayer !== "undefined") {
        addLogMessage('Datalayer definido', dataLayer)

        dataLayer.push({
          event: "formStart",
          eventModel: {
            form_id: this.getAttribute("id"),
            trigger_by: "CuboSuite"
          }
        })
      }
    }, { once: true });

    const phoneField = this.querySelector("[name='phone']")

    if (phoneField) phoneField.addEventListener('focus', (event) => {
      defineVMasker()
      VMasker(phoneField).maskPattern(phoneField.getAttribute("mask"))
    })

    this.addEventListener("submit", (event) => {
      addLogMessage('Enviando formulário')

      try {
        definePristine()

        event.preventDefault();

        const deal = JSON.parse(localStorage.getItem("cuboDeal"));
        const button = event.target.querySelector("button[type='submit']")
        const buttonLabel = button.querySelector("button[type='submit'] span")

        if (!deal) addLogMessage('Não há dados de negociação no localStorage')

        if (!button) addLogMessage('Não há botão de envio no formulário')

        if (!buttonLabel) addLogMessage('Não há label de envio no botão de envio')

        localStorage.removeItem("cuboDeal");

        button.setAttribute("disabled", true);

        buttonLabel ? buttonLabel.innerText = "Enviando..." : button.innerText = "Enviando...";

        if (deal && !deal.id && props.action === 'update') {
          alert('Id da negociação não informada.')
          throw new Error("Atualização: Id da negociação não informada")
        }

        if (!props.pipe && props.action === 'create') {
          alert('Funil não informado.')
          throw new Error("Criação: Funil não informado")
        }

        if (!props.keycubo) {
          alert("API KEY não informada.");
          throw new Error("Criação: API KEY não informada")
        }

        let form = this;

        let pristine = new Pristine(this, {
          classTo: "cubo-form-group",
          errorTextParent: "cubo-form-group",
        });

        let valid = pristine.validate();

        if (!valid) addLogMessage('Formulário não é válido', valid)

        if (!valid) {
          button.removeAttribute("disabled")
          buttonLabel ? buttonLabel.innerText = "Enviar novamente" : button.innerText = "Enviar novamente";
          return
        }

        if (valid) {
          addLogMessage('Formulário é válido')

          let email = 'Não informado'

          let fields = form.querySelectorAll("input, select");

          let payload = props.action === 'update' ? { customfields: [] } : {
            title: form.querySelector("[name='title']") ? form.querySelector("[name='title']").value : 'Não informado',
            peopleName: form.querySelector("[name='title']") ? form.querySelector("[name='title']").value : 'Não informado',
            peoplePhone: form.querySelector("[name='phone']") ? form.querySelector("[name='phone']").value : 'Não informado',
            customfields: [],
          };

          if (props.action === 'create') {
            payload.pipeId = props.pipe
            payload.stageId = props.stage ?? undefined
          }

          fields.forEach((field) => {
            let type = field.getAttribute("type") ? field.getAttribute("type") : 'text';
            let name = field.getAttribute("name");
            let customfieldName = field.getAttribute("data-cf");

            if (/^(email|e-mail)$/i.test(customfieldName)) {
              email = field.value
            }

            if (name !== "title" && name !== "phone") {

              if (type === "select") {
                if (field.options[field.selectedIndex].value) {
                  payload.customfields.push({
                    customfieldId: parseInt(field.getAttribute("name")),
                    value: field.options[field.selectedIndex].value,
                  });
                }
              } else {

                const name = customfieldName ? customfieldName.toLowerCase().split(" ").join("_") : name;
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);

                if (!field.value)
                  field.value = urlParams.has(name) ? urlParams.get(name) : null;

                if (field.value) {
                  payload.customfields.push({
                    customfieldId: parseInt(field.getAttribute("name")),
                    value: field.value,
                  });
                }
              }
            }
          });

          payload.customfields = payload.customfields.filter((cf) => cf.value);

          addLogMessage('Payload', payload)

          fetch(props.action === 'create' ? `https://api.cubosuite.com.br/deals/external` : `https://api.cubosuite.com.br/deals/external/${deal.id}`,
            {
              method: props.action === 'create' ? "POST" : 'PUT',
              headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${props.keycubo}`,
              },
              body: JSON.stringify(payload),
            }
          )
            .then((response) => response.json())
            .then((response) => {

              if (props.action === 'create') {
                addLogMessage('Setando localStorage')

                do {
                  try {
                    localStorage.setItem("cuboDeal", JSON.stringify(response));
                  } catch (exception) {
                    throw new Error("Criação: Erro ao setar localStorage")

                  }
                } while (localStorage.getItem("cuboDeal") == null);

                addLogMessage('localStorage setado')
              }

              button.removeAttribute("disabled");

              if (typeof dataLayer !== "undefined") {
                addLogMessage('Datalayer submit enviado')
                dataLayer.push({
                  event: "formSubmit",
                  user_name: payload.peopleName,
                  user_phone: payload.peoplePhone,
                  user_email: email,
                  eventModel: {
                    form_id: this.getAttribute("id"),
                    trigger_by: "CuboSuite",
                  }
                })
              }

              addLogMessage('VWO enviando')
              window.VWO = window.VWO || [];
              VWO.event = VWO.event || function () { VWO.push(["event"].concat([].slice.call(arguments))) };

              VWO.event("lead_prospecta", {
                "lead_prospecta": true
              });
              addLogMessage('VWO enviado')

              if (props.redirect) {
                addLogMessage('Tem redirecionamento', props.redirect)
                buttonLabel && buttonLabel.innerText ? buttonLabel.innerText = "Redirecionando..." : button && button.innerText ? button.innerText = "Redirecionando..." : null;
                addLogMessage('Redirecionando')
                window.location.href = props.redirect;
              }


              buttonLabel && buttonLabel.innerText ? buttonLabel.innerText = "Enviado" : button && button.innerText ? button.innerText = "Enviado" : null;
              addLogMessage('Enviado')
            });
        }

      } catch (exception) {
        Sentry.withScope((scope) => {

          logMessages.forEach((log, _index) => {
            scope.addBreadcrumb({
              category: 'log',
              message: log.message,
              level: 'info',
              data: log.data,
            })
          })

          Sentry.captureException(exception);
          clearLogMessages()

        });
      }
    });
  };

  const component = {
    isComponent(el) {
      if (el?.tagName === "form" && (el.getAttribute("componentName") === 'formNew' || el.getAttribute("componentName") === 'formNew')) {
        return { type: "formNew" };
      }
    },
    model: {
      defaults: {

        tagName: "form",
        attributes: { method: "POST", componentName: "formNew" },
        droppable: false,
        traits: [
          {
            label: "Formulário",
            type: "select",
            name: "form",
            changeProp: true,
            options: [{ name: "Selecione um formulário", id: '' }, ...forms],
            required: true,
          },
          {
            label: "Ação",
            type: "select",
            name: "action",
            changeProp: true,
            options: [{ name: 'Criação', id: 'create' }, { name: 'Atualização', id: 'update' }],
            required: true,
            value: 'create'
          },
          {
            label: "Api key",
            type: "text",
            name: "keycubo",
            changeProp: true,
            required: true,
          },
          {
            label: "Funíl",
            type: "select",
            name: "pipe",
            changeProp: true,
            options: pipes,
            required: true,
          },
          {
            label: "Estágio",
            type: "select",
            name: "stage",
            changeProp: true,
            options: [{ name: 'Selecione o funil', id: '' }],
            required: true,
          },
          {
            label: "Redirecionar para",
            type: "text",
            name: "redirect",
            changeProp: true,
          },
          {
            label: "Redirecionar se não prencheu primeira etapa",
            type: "text",
            name: "redirect_not_filed",
            changeProp: true,
          },

        ],
        classes: ['cubo-form', 'rows', 'wrap'],

        styles: `
          .column {
            display: flex;
            flex-direction: column;
          }

          .rows {
              display: flex;
          }

          .wrap {
              flex-wrap: wrap;
          }

          .column-1 {
              max-width: 8.33%;
              flex: 0 0 8.333333%;
          }

          .column-2 {
              max-width: 16.66%;
              flex: 0 0 16.66%;
          }

          .column-3 {
              max-width: 25%;
              flex: 0 0 25%;
          }

          .column-4 {
              max-width: 33.33%;
              flex: 0 0 33.33%;
          }

          .column-5 {
              max-width: 41.66%;
              flex: 0 0 41.66%;
          }

          .column-6 {
              max-width: 50%;
              flex: 0 0 50%;
          }

          .column-7 {
              max-width: 58.47%;
              flex: 0 0 58.47%;
          }

          .column-8 {
              max-width: 66.66%;
              flex: 0 0 66.66%;
          }

          .column-9 {
              max-width: 75.18%;
              flex: 0 0 75.18%;
          }

          .column-10 {
              max-width: 83.33%;
              flex: 0 0 83.33%;
          }

          .column-11 {
              max-width: 91.74%;
              flex: 0 0 91.74%;
          }

          .column-12 {
              max-width: 100%;
              flex: 0 0 100%;
          }

          @media screen and (min-width: 1200px) {
              .column-lg-1 {
                  max-width: 8.33%;
                  flex: 0 0 8.333333%;
              }

              .column-lg-2 {
                  max-width: 16.66%;
                  flex: 0 0 16.66%;
              }

              .column-lg-3 {
                  max-width: 25%;
                  flex: 0 0 25%;
              }

              .column-lg-4 {
                  max-width: 33.33%;
                  flex: 0 0 33.33%;
              }

              .column-lg-5 {
                  max-width: 41.66%;
                  flex: 0 0 41.66%;
              }

              .column-lg-6 {
                  max-width: 50%;
                  flex: 0 0 50%;
              }

              .column-lg-7 {
                  max-width: 58.47%;
                  flex: 0 0 58.47%;
              }

              .column-lg-8 {
                  max-width: 66.66%;
                  flex: 0 0 66.66%;
              }

              .column-lg-9 {
                  max-width: 75.18%;
                  flex: 0 0 75.18%;
              }

              .column-lg-10 {
                  max-width: 83.33%;
                  flex: 0 0 83.33%;
              }

              .column-lg-11 {
                  max-width: 91.74%;
                  flex: 0 0 91.74%;
              }

              .column-lg-12 {
                  max-width: 100%;
                  flex: 0 0 100%;
              }
          }

          @media screen and (min-width: 1200px) and (max-width:2000px) {
              .column-md-1 {
                  max-width: 8.33%;
                  flex: 0 0 8.333333%;
              }

              .column-md-2 {
                  max-width: 16.66%;
                  flex: 0 0 16.66%;
              }

              .column-md-3 {
                  max-width: 25%;
                  flex: 0 0 25%;
              }

              .column-md-4 {
                  max-width: 33.33%;
                  flex: 0 0 33.33%;
              }

              .column-md-5 {
                  max-width: 41.66%;
                  flex: 0 0 41.66%;
              }

              .column-md-6 {
                  max-width: 50%;
                  flex: 0 0 50%;
              }

              .column-md-7 {
                  max-width: 58.47%;
                  flex: 0 0 58.47%;
              }

              .column-md-8 {
                  max-width: 66.66%;
                  flex: 0 0 66.66%;
              }

              .column-md-9 {
                  max-width: 75.18%;
                  flex: 0 0 75.18%;
              }

              .column-md-10 {
                  max-width: 83.33%;
                  flex: 0 0 83.33%;
              }

              .column-md-11 {
                  max-width: 91.74%;
                  flex: 0 0 91.74%;
              }

              .column-md-12 {
                  max-width: 100%;
                  flex: 0 0 100%;
              }
          }

          @media screen and (max-width:576px) {
              .column-sm-1 {
                  max-width: 8.33%;
                  flex: 0 0 8.333333%;
              }

              .column-sm-2 {
                  max-width: 16.66%;
                  flex: 0 0 16.66%;
              }

              .column-sm-3 {
                  max-width: 25%;
                  flex: 0 0 25%;
              }

              .column-sm-4 {
                  max-width: 33.33%;
                  flex: 0 0 33.33%;
              }

              .column-sm-5 {
                  max-width: 41.66%;
                  flex: 0 0 41.66%;
              }

              .column-sm-6 {
                  max-width: 50%;
                  flex: 0 0 50%;
              }

              .column-sm-7 {
                  max-width: 58.47%;
                  flex: 0 0 58.47%;
              }

              .column-sm-8 {
                  max-width: 66.66%;
                  flex: 0 0 66.66%;
              }

              .column-sm-9 {
                  max-width: 75.18%;
                  flex: 0 0 75.18%;
              }

              .column-sm-10 {
                  max-width: 83.33%;
                  flex: 0 0 83.33%;
              }

              .column-sm-11 {
                  max-width: 91.74%;
                  flex: 0 0 91.74%;
              }

              .column-sm-12 {
                  max-width: 100%;
                  flex: 0 0 100%;
              }
          }

          .cubo-form{
            padding: 10px;
            font-family: Nunito,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
          }

          .cubo-form input{
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background: #fff;
            border: 1px solid #e2e4e6cc;
            border-radius: 3px;
            color: #5c6268;
            font-size: .875rem;
            padding: 0.5rem;
            width: 100%;
            transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
          }

          .cubo-form select{
            appearance: none;
            padding: 8px;
            width: 100%;
            cursor: pointer;
            background: #fff;
            color: #5c6268;
            border: 1px solid #ced4da;
            border-radius: 3px;
            transition: background-color .2s,color .2s,border-color .2s,box-shadow .2s;
          }

          .cubo-form select::after{
            content: '';
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #333; /* Arrow color */
            pointer-events: none;
          }

          .cubo-form button{
            color: #ffffff;
            background: #64748B;
            border: 1px solid #64748B;
            padding: 0.5rem 1rem;
            font-size: 1rem;
            transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
            border-radius: 3px;
          }

          .cubo-form button::hover{
            background: #334155;
            color: #ffffff;
            border-color: #334155;
          }

          .has-danger .pristine-error.text-help{
            color: red;
            padding: 5px;
            margin: 5px;
            background-color: #ffdede;
            border-radius: 5px;
            width: fit-content;
          }

          .form-group.has-danger input, .form-group.has-danger select{
            border-color: red!important;
          }

          .swal2-shown.swal2-height-auto{
            height: 100vh!important;
          }
        `,

        pipe: "",
        stage: "",
        keycubo: "",
        redirect: "",
        form: "",
        action: "create",
        form: "",
        redirect_not_filed: "",

        script,

        "script-props": ["pipe", "keycubo", "redirect", "form", "stage", "action"],

        components: (model) => {
          return {
            tagName: 'div',
            layerable: false,
            locked: true,
            attributes: {
              style: 'display: flex; padding: 10px; align-items: center; width: 100%; justify-content: center;'
            },
            components: [
              {
                locked: true,
                layerable: false,
                tagName: 'strong',
                content: 'Use o menu de configurações para configurar o formulário',
                attributes: {
                  style: 'margin-left: 10px;'
                }
              },
              {
                locked: true,
                layerable: false,
                tagName: 'span',
                content: '➡️',
                attributes: {
                  style: 'font-size: 30px; margin-left: 10px'
                }
              },
            ]
          }
        },
      },
      init() {
        editor.on('component:selected', (addedComponent) => {
          if (addedComponent === this) {


          }

        });

        editor.on('component:add', (addedComponent) => {
          if (addedComponent === this) {
            editor.stopCommand('core:component-outline');
            editor.select(this);
            const settings = document.querySelector('[title="Settings"]')
            if (settings) settings.click()
          }
        });

        editor.on('component:update:traits', (component) => {
          if (component.get('type') === 'formNew') {
            const traits = component.get('traits');

            const pipeTrait = traits.find(trait => trait.get('name') === 'pipe');
            const stageTrait = traits.find(trait => trait.get('name') === 'stage');

            if (pipeTrait && stageTrait) {
              const pipeValue = pipeTrait.get('value');
              const selectedPipe = pipes.find(pipe => pipe.id === +pipeValue) || { stages: [] };
              stageTrait.set('options', selectedPipe.stages.map(s => { return { name: s.name, id: s.id } }))
            }
          }
        });

        this.on('change:form', (event) => {
          const form = forms.find(form => form.id === +event.changed.form)
          const components = [];

          form.fields.forEach((field) => {

            const item = {
              tagName: 'div',
              type: 'cuboFormRow',
              classes: ['cubo-form-group', 'column-sm-12', `column-${field.columns ? field.columns : 12}`, `${field.hide == true ? 'd-none' : ''}`],
              attributes: {
                columns: field.columns,
                style: field.hide == true ? 'display:none;' : '',
              },
              components: [
                {
                  content: `${field.label}`,
                  tagName: 'label',
                  components: field.required || field.validations.required ? [
                    {
                      tagName: 'span',
                      content: `${field.label}`,
                    },
                    {
                      tagName: 'strong',
                      content: '*',
                      attributes: {
                        style: 'color:red;margin-left: 5px;'
                      }
                    }
                  ] : null
                }
              ]
            }

            if (field.id === 'phone' || field.id === 'title' || (field.isCustomfield && field.customfield.type === "text")) {
              console.log({ field })
              item.components.push({
                tagName: 'input',
                attributes: {
                  class: 'form-control',
                  type: field.validations.type.value ? field.validations.type.value : 'text',
                  placeholder: field.placeholder ? field.placeholder : 'Preencha o campo',
                  name: field.id,
                  'data-cf': field.isCustomfield ? field.customfield.name : '',
                  'data-cf-type': field.isCustomfield ? field.customfield.type : '',
                  mask: field.id === 'phone' ? '(99) 99999-9999' : field.customfield && field.customfield.mask ? field.customfield.mask : null,
                  'data-pristine-required': field.required || field.validations.required ? '' : null,
                  'data-pristine-max': field.validations.maxLenght && field.validations.maxLenght.use ? field.validations.maxLenght.value : field.validations.maxLength && field.validations.maxLength.use ? field.validations.maxLength.value : null,
                  'data-pristine-min': field.validations.minLenght && field.validations.minLenght.use ? field.validations.minLenght.value : field.validations.minLength && field.validations.minLength.use ? field.validations.minLength.value : null,
                }
              })
            } else {
              const select = {
                tagName: 'select',
                attributes: {
                  name: field.id,
                  'data-cf': field.customfield.name,
                  'data-pristine-required': field.required || field.validations.required ? '' : null,
                  'data-pristine-max': field.validations.maxLenght && field.validations.maxLenght.use ? field.validations.maxLenght.value : field.validations.maxLength && field.validations.maxLength.use ? field.validations.maxLength.value : null,
                  'data-pristine-min': field.validations.minLenght && field.validations.minLenght.use ? field.validations.minLenght.value : field.validations.minLength && field.validations.minLength.use ? field.validations.minLength.value : null,
                  'data-cf-type': field.customfield.type,
                },
                components: [
                  {
                    tagName: 'option',
                    attributes: {
                      selected: true,
                      disabled: true,
                      value: '',
                    },
                    content: field.placeholder ? field.placeholder : 'Selecione uma opção'
                  }
                ]
              }

              if (field.customfield.type === 'state' || field.customfield.type === 'city') {
                console.log(5)
                const options = field.customfield.type === 'state' ? State.getStatesOfCountry('BR') : []
                options.forEach((opt) => {
                  select.components.push({
                    tagName: 'option',
                    attributes: {
                      value: opt.isoCode,
                    },
                    content: opt.name
                  })
                });

              } else {
                field.customfield.options.forEach((opt) => {
                  select.components.push({
                    tagName: 'option',
                    attributes: {
                      value: opt.value,
                    },
                    content: opt.value
                  })
                });
              }

              item.components.push(select)
            }
            components.push(item)
            return

          });

          components.push({
            tagName: 'div',
            type: 'cuboFormRow',
            classes: ['cubo-form-group', 'column-sm-12', `column-2`],
            components: [
              {
                tagName: 'button',
                type: 'cubosuiteButton',
                attributes: {
                  type: 'submit',
                },
              }
            ],
          })

          this.components(components);

        });
      },
    },
    view: {},
  };

  return component;
};
