export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
                name: 'Div',
                attributes: { class: 'd-flex gap-16 wrap margin-top-50' },
                components: [
                    {
                        type: 'div',
                        attributes: { class: 'item-product-2' },
                        components: [
                            {
                                type: 'image',
                                attributes: { class: 'img-product-2', src:'https://placehold.co/390x390'}
                            },
                            {
                                type: 'div', 
                                attributes: { class: 'div-name-product-2'},
                                components: [
                                    {
                                        type: 'text',
                                        content: 'Nome do produto', 
                                        attributes: { class: 'name-product-2'}
                                    }
                                ]
                            }
                          
                        ]
                    },
                    {
                        type: 'div',
                        attributes: { class: 'item-product-2' },
                        components: [
                            {
                                type: 'image',
                                attributes: { class: 'img-product-2', src:'https://placehold.co/390x390'}
                            },
                            {
                                type: 'div', 
                                attributes: { class: 'div-name-product-2'},
                                components: [
                                    {
                                        type: 'text',
                                        content: 'Nome do produto', 
                                        attributes: { class: 'name-product-2'}
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        type: 'div',
                        attributes: { class: 'card-product-2' },
                        components: [
                            {
                                type: 'text',
                                content: 'Espaço/Card para conteúdo explicativo', 
                                attributes: { class: 'title-card-product-2'}
                            },
                            {
                                type: 'text',
                                content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et bibendum risus. Cras faucibus viverra dolor, at vehicula erat tempus vel. Sed a velit dictum, semper nulla at, volutpat dolor. In egestas tortor a tellus dignissim aliquet.', 
                                attributes: { class: 'desc-card-product-2'}
                            }
                        ]
                    },
                    {
                        type: 'div',
                        attributes: { class: 'card-product-2' },
                        components: [
                            {
                                type: 'text',
                                content: 'Espaço/Card para conteúdo explicativo', 
                                attributes: { class: 'title-card-product-2'}
                            },
                            {
                                type: 'text',
                                content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et bibendum risus. Cras faucibus viverra dolor, at vehicula erat tempus vel. Sed a velit dictum, semper nulla at, volutpat dolor. In egestas tortor a tellus dignissim aliquet.', 
                                attributes: { class: 'desc-card-product-2'}
                            }
                        ]
                    },
                    {
                        type: 'div',
                        attributes: { class: 'item-product-2' },
                        components: [
                            {
                                type: 'image',
                                attributes: { class: 'img-product-2', src:'https://placehold.co/390x390'}
                            },
                            {
                                type: 'div', 
                                attributes: { class: 'div-name-product-2'},
                                components: [
                                    {
                                        type: 'text',
                                        content: 'Nome do produto', 
                                        attributes: { class: 'name-product-2'}
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        type: 'div',
                        attributes: { class: 'item-product-2' },
                        components: [
                            {
                                type: 'image',
                                attributes: { class: 'img-product-2', src:'https://placehold.co/390x390'}
                            },
                            {
                                type: 'div', 
                                attributes: { class: 'div-name-product-2'},
                                components: [
                                    {
                                        type: 'text',
                                        content: 'Nome do produto', 
                                        attributes: { class: 'name-product-2'}
                                    }
                                ]
                            }
                        ]
                    },
                ],
                styles: `
                .gap-16{
                    gap: 16px; 
                }
                .margin-top-16{
                    margin-top: 16px;
                }
                .d-flex{
                    display: flex;
                }
                .margin-top-50{
                    margin-top: 50px;
                }
                .wrap{
                    flex-wrap: wrap; 
                }
                .item-product-2{
                    width: 23%; 
                }
                .img-product-2{
                    width: 100%; 
                }
                .div-name-product-2{
                    background: #fff; 
                    width: 100%; 
                    padding: 20px;
                }
                .name-product-2{
                    font-size: 16px; 
                    color: #95A1BB; 
                    text-align: center; 
                }

                .card-product-2{
                    background: #E9E9E9; 
                    padding: 80px; 
                    display: flex; 
                    flex-direction: column; 
                    align-items: center; 
                    width: 46%; 
                }
                .title-card-product-2{
                    font-size: 18px; 
                    color:#8C8C8C; 
                    text-align: center; 
                }
                .desc-card-product-2{
                    font-size: 16px; 
                    color:#8C8C8C; 
                    text-align: center; 
                    margin-top: 20px;
                }

                @media (max-width: 992px){
                    .item-product-2{
                        width: 47%; 
                    }
                    .card-product-2{
                        width: 100%; 
                        padding: 30px; 
                    }
                }
      
            `,
            },
        },
        view: {

        },
    };
    return component;
};