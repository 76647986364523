export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type: 'Container',
              name: "Container Conteudos",
              attributes: { class: 'container-cubo flex flex-col items-center' },
              components: [
                {
                  type:"image", 
                  attributes: { class: 'image-content6',
                    src: 'https://dummyimage.com/1200x500'
                   },
                }, 
                {
                  type: 'div', 
                  attributes: { class: 'flex sm-flex-col mt-10 gap-30' },
                  components: [
                    {
                      type: 'div', 
                      attributes: { class: 'w-33 flex flex-col items-center' },
                      components: [
                        {
                          type: 'image', 
                          attributes: { class: 'icon-content6',
                          src: 'https://dummyimage.com/1200x500'
                         },
                        },
                        {
                          type: 'div',
                          attributes: { class: 'flex flex-col items-center text-center' },
                          components: [
                            {
                              type: 'title2', 
                              components: [
                                {
                                  type:'text',
                                  name:'H2',
                                  components: 'Lorem Ipsum',
                                  attributes: { class: 'title-content6' },
                                },
                              ],
                            },
                            {
                              type: 'div', 
                              attributes: { class: 'divider-content6' },
                            },
                            {
                              type: 'text', 
                              attributes: { class: 'text-content6' },
                              components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus. '
                            }
                          ]
                        }
                      ]
                    },
                    {
                      type: 'default',
                      attributes: { class: 'divider-vert-content6' },
                    },
                    {
                      type: 'div', 
                      attributes: { class: 'w-66 flex flex-col items-start justify-center' },
                      components: [
                        {
                          type: 'text', 
                          attributes: { class: 'text-content6' },
                          components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus. Pellentesque eu finibus dolor. Aenean sit amet auctor est. Nunc et libero eu enim sollicitudin ornare. Sed arcu lectus, congue eget scelerisque eget'
                        },
                        {
                          type: 'link', 
                          attributes: { class: 'link-content6' },
                          components: 'Saiba mais'
                        }
                      ]
                    }
                  ]
                }, 
              ],
              styles: `
             .container-cubo{
                max-width: 1600px; 
             }
            .flex{
             display: flex; 
            }
            .flex-col{
              flex-direction: column; 
            }
            .items-center{
              align-items: center; 
            }
               .items-start{
              align-items: flex-start; 
            }
            .image-content6{
              width: 70%; 
              border-radius: 20px; 
              height: 400px; 
              object-fit: cover; 
            }
            .justify-center{
              justify-content: center;
            }
            .text-center{
              text-align: center; 
            }
            .mt-10{
              margin-top: 40px; 
            }
            .gap-30{
              gap: 30px;
            }
            .w-33{
              width: 33%; 
            }
            .icon-content6{
              width: 80px; 
              height: 80px; 
              border-radius: 100%;
              object-fit: cover; 
            }
            .title-content6{
               font-size: 1.875rem;
               line-height: 2.25rem; 
               color: #111827;
               margin-top: 20px;
            }
            .divider-content6{
              width: 3rem; 
              height: 0.25rem; 
              border-radius: 0.25rem; 
              background-image: linear-gradient(#3b82f6 0%, #3b82f6 100%); 
              margin: 15px 0; 
            }
            .text-content6{
              font-size: 1rem; 
              color: #4B5563;
            }
            .link-content6{
              font-size: 16px;
              color: #6366F1;
            }
            .w-66{
              width: 66%; 
            }
            .divider-vert-content6{
              width: 2px; 
              height: 400px; 
              background-image: linear-gradient(#e5e7eb 0%, #e5e7eb 100%);
            }

            @media (max-width: 992px){
            .image-content6{
               width: 100%;
            }
              .sm-flex-col{
                flex-direction: column; 
              }
                .w-33{
              width: 100%; 
            }
              .w-66{
              width: 100%; 
            }
             .divider-vert-content6{
              width: 100%; 
              height: 2px; 
            }
            }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};