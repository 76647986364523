// document.addEventListener('DOMContentLoaded', async (event) => {
//   await waitForElement('.gjs-frame')
//   let iframe = document.querySelector('.gjs-frame')
//   console.log({ iframe })
//   let iframeWindow = iframe.contentWindow;
//   console.log({ iframeWindow })
//   let iframeDocument = iframeWindow.document;
//   console.log({ iframeDocument })
//   iframeDocument.querySelector('body').classList.remove('gjs-dashed');

// })




const toolbar = (editor) => {
  const commands = editor.Commands;

  commands.add("command-up", (editor) => {
    const selectedComponent = editor.getSelected();
    const parent = selectedComponent.parent();
    const index = selectedComponent.collection.findIndex(selectedComponent);
    selectedComponent.move(parent, { at: index - 1 });
  });

  commands.add('command-down', (editor) => {
    const selectedComponent = editor.getSelected();
    const parent = selectedComponent.parent();
    const index = selectedComponent.collection.findIndex(selectedComponent);
    selectedComponent.move(parent, { at: index + 2 });
  });

  commands.add('show-option', (editor) => {

    let more = document.querySelector('.more-options')
    if (more.classList.contains('open')) {
      more.style.borderRadius = '8px'
      more.classList.remove('open')
    }
    else {
      more.style.borderRadius = '8px 8px 0 0'
      more.classList.add('open')
    }

    let parent = document.querySelector('.item-parent')

    if (parent.classList.contains('open')) {
      parent.style.opacity = '0'
      parent.style.width = '0'
      parent.classList.remove('open')
    }
    else {
      parent.style.opacity = '1'
      parent.style.width = '114px'
      parent.classList.add('open')
    }

    let code2 = document.querySelector('.gjs-toolbar-item:has(.fa-file-code-o)')

    if (code2) {
      if (code2.classList.contains('open')) {
        code2.style.opacity = '0'
        code2.style.width = '0'
        code2.classList.remove('open')
      }
      else {
        code2.style.opacity = '1'
        code2.style.width = '114px'
        code2.classList.add('open')
      }
    }
  });

  editor.on("component:selected", (component) => {
    // const selectedIndex = component.collection.findIndex(
    //   component
    // );
    const defaultToolbar = component.get("toolbar");
    const checkAlreadyExist1 = defaultToolbar.find(toolbar => toolbar.command === 'command-up');
    const checkAlreadyExist2 = defaultToolbar.find(toolbar => toolbar.command === 'command-down');

    if (!checkAlreadyExist1 || !checkAlreadyExist2) {
      defaultToolbar.push(
        {
          id: 'movedown',
          label: 'Mover para baixo',
          attributes: { class: 'bi bi-chevron-down custom-itens', title: 'Mover para baixo' },
          command: 'command-down'
        },
        {
          id: 'moveup',
          label: 'Mover para cima',
          attributes: { class: 'bi bi-chevron-up custom-itens', title: 'Mover para cima' },
          command: 'command-up'
        },
        {
          id: 'clone',
          label: 'Duplicar',
          attributes: { class: 'bi bi-copy custom-itens', title: 'Duplicar' },
          command: 'tlb-clone'
        },
        {
          id: 'move',
          label: 'Movimentar',
          attributes: { class: 'bi bi-arrows-move custom-itens', title: 'Movimentar' },
          command: 'tlb-move'
        },
        {
          id: 'more-options',
          label: 'Mais opções',
          command: 'show-option',
          attributes: { class: 'bi bi-three-dots-vertical more-options custom-itens', title: 'Mais opções' },
        },
        {
          id: 'select-parent',
          label: 'Selecionar pai',
          command: 'core:component-exit',
          attributes: { class: 'bi bi-arrow-90deg-up custom-itens item-parent', title: 'Selecionar pai' }
        },
        {
          id: 'delete',
          label: 'Exluir',
          attributes: { class: 'bi bi-trash3 custom-itens', title: 'Excluir' },
          command: 'tlb-delete'
        },
      );
      component.set("toolbar", defaultToolbar);
    }
  });
};

export default toolbar;

function waitForElement(selector, timeout = 3000, pollingInterval = 100) {
  return new Promise((resolve, reject) => {
    let attempts = timeout / pollingInterval;

    const checkElement = () => {
      const element = document.querySelector(selector);
      if (element) {
        resolve(element);
      } else if (--attempts > 0) {
        setTimeout(checkElement, pollingInterval);
      } else {
        reject(new Error(`Element ${selector} not found within ${timeout}ms`));
      }
    };

    checkElement();
  });
}