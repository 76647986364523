export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        type: 'Container',
        name: "Container Galeria",
        attributes: { class: 'container-cubo' },
        components: [
          {
            type: 'div',
            attributes: { class: 'flex flex-col align-center' },
            components: [
              {
                type: 'title2',
                components: [
                  {
                    type: 'text',
                    name: 'H2',
                    components: 'Lorem Ipsum',
                    attributes: { class: 'principal-title' },
                  },
                ],
              },
              {
                type: 'text',
                attributes: { class: 'body-text w-80 text-center' },
                components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae'
              },

            ]
          },
          {
            type: 'div',
            attributes: { class: 'flex sm-flex-col gap-16 mt-50' },
            components: [
              {
                type: 'div',
                attributes: { class: 'item-gallery-3' },
                components: [
                      {
                        type: 'image',
                        attributes: {
                          class: 'image-gallery-3',
                          src: 'https://dummyimage.com/600x360'
                        },
                      },
                      {
                        type: 'div',
                        attributes: { class: 'infos-item-gallery' },
                        components: [
                          {
                            type: 'title3',
                            components: [
                              {
                                type: 'text',
                                name: 'H3',
                                components: 'Lorem Ipsum',
                                attributes: { class: 'subtitle-gallery' },
                              },
                            ],
                          },
                          {
                            type: 'title4',
                            components: [
                              {
                                type: 'text',
                                name: 'H4',
                                components: 'Lorem Ipsum',
                                attributes: { class: 'title-item-gallery' },
                              },
                            ],
                          },
                          {
                            type: 'text',
                            attributes: { class: 'text-item-gallery' },
                            components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae'
                          },
                        ]
                  }
                ]
              },
              {
                type: 'div',
                attributes: { class: 'item-gallery-3' },
                components: [
                      {
                        type: 'image',
                        attributes: {
                          class: 'image-gallery-3',
                          src: 'https://dummyimage.com/600x360'
                        },
                      },
                      {
                        type: 'div',
                        attributes: { class: 'infos-item-gallery' },
                        components: [
                          {
                            type: 'title3',
                            components: [
                              {
                                type: 'text',
                                name: 'H3',
                                components: 'Lorem Ipsum',
                                attributes: { class: 'subtitle-gallery' },
                              },
                            ],
                          },
                          {
                            type: 'title4',
                            components: [
                              {
                                type: 'text',
                                name: 'H4',
                                components: 'Lorem Ipsum',
                                attributes: { class: 'title-item-gallery' },
                              },
                            ],
                          },
                          {
                            type: 'text',
                            attributes: { class: 'text-item-gallery' },
                            components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae'
                          },
                        ]
                  }
                ]
              },
              {
                type: 'div',
                attributes: { class: 'item-gallery-3' },
                components: [
                      {
                        type: 'image',
                        attributes: {
                          class: 'image-gallery-3',
                          src: 'https://dummyimage.com/600x360'
                        },
                      },
                      {
                        type: 'div',
                        attributes: { class: 'infos-item-gallery' },
                        components: [
                          {
                            type: 'title3',
                            components: [
                              {
                                type: 'text',
                                name: 'H3',
                                components: 'Lorem Ipsum',
                                attributes: { class: 'subtitle-gallery' },
                              },
                            ],
                          },
                          {
                            type: 'title4',
                            components: [
                              {
                                type: 'text',
                                name: 'H4',
                                components: 'Lorem Ipsum',
                                attributes: { class: 'title-item-gallery' },
                              },
                            ],
                          },
                          {
                            type: 'text',
                            attributes: { class: 'text-item-gallery' },
                            components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae'
                          },
                        ]
                  }
                ]
              },
            ]
          },
          {
            type: 'div',
            attributes: { class: 'flex sm-flex-col gap-16 mt-16' },
            components: [
              {
                type: 'div',
                attributes: { class: 'item-gallery-3' },
                components: [
                      {
                        type: 'image',
                        attributes: {
                          class: 'image-gallery-3',
                          src: 'https://dummyimage.com/600x360'
                        },
                      },
                      {
                        type: 'div',
                        attributes: { class: 'infos-item-gallery' },
                        components: [
                          {
                            type: 'title3',
                            components: [
                              {
                                type: 'text',
                                name: 'H3',
                                components: 'Lorem Ipsum',
                                attributes: { class: 'subtitle-gallery' },
                              },
                            ],
                          },
                          {
                            type: 'title4',
                            components: [
                              {
                                type: 'text',
                                name: 'H4',
                                components: 'Lorem Ipsum',
                                attributes: { class: 'title-item-gallery' },
                              },
                            ],
                          },
                          {
                            type: 'text',
                            attributes: { class: 'text-item-gallery' },
                            components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae'
                          },
                        ]
                  }
                ]
              },
              {
                type: 'div',
                attributes: { class: 'item-gallery-3' },
                components: [
                      {
                        type: 'image',
                        attributes: {
                          class: 'image-gallery-3',
                          src: 'https://dummyimage.com/600x360'
                        },
                      },
                      {
                        type: 'div',
                        attributes: { class: 'infos-item-gallery' },
                        components: [
                          {
                            type: 'title3',
                            components: [
                              {
                                type: 'text',
                                name: 'H3',
                                components: 'Lorem Ipsum',
                                attributes: { class: 'subtitle-gallery' },
                              },
                            ],
                          },
                          {
                            type: 'title4',
                            components: [
                              {
                                type: 'text',
                                name: 'H4',
                                components: 'Lorem Ipsum',
                                attributes: { class: 'title-item-gallery' },
                              },
                            ],
                          },
                          {
                            type: 'text',
                            attributes: { class: 'text-item-gallery' },
                            components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae'
                          },
                        ]
                  }
                ]
              },
              {
                type: 'div',
                attributes: { class: 'item-gallery-3' },
                components: [
                      {
                        type: 'image',
                        attributes: {
                          class: 'image-gallery-3',
                          src: 'https://dummyimage.com/600x360'
                        },
                      },
                      {
                        type: 'div',
                        attributes: { class: 'infos-item-gallery' },
                        components: [
                          {
                            type: 'title3',
                            components: [
                              {
                                type: 'text',
                                name: 'H3',
                                components: 'Lorem Ipsum',
                                attributes: { class: 'subtitle-gallery' },
                              },
                            ],
                          },
                          {
                            type: 'title4',
                            components: [
                              {
                                type: 'text',
                                name: 'H4',
                                components: 'Lorem Ipsum',
                                attributes: { class: 'title-item-gallery' },
                              },
                            ],
                          },
                          {
                            type: 'text',
                            attributes: { class: 'text-item-gallery' },
                            components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae'
                          },
                        ]
                  }
                ]
              },
            ]
          },
        ],
        styles: `
             .container-cubo{
                max-width: 1600px; 
             }
             .flex{
               display: flex; 
             }
            .flex-col{
              flex-direction: column; 
            }
            .align-center{
              align-items: center; 
            }
            .principal-title{
            font-size: 30px;
            color: #363940;
            margin:0;
           }
          .body-text{
            font-size: 16px;
            color: #363940;
            margin-top:30px;
           }
          .w-80{
            width: 80%; 
          }
          .text-center{
            text-align: center; 
          }
          .gap-16{
            gap: 16px; 
          }
          .mt-50{
            margin-top: 50px; 
          }
            .mt-16{
            margin-top: 16px; 
          }
          .item-gallery-3{
            width: 33%; 
            position: relative; 
          }
          .image-gallery-3{
            position: absolute; 
            width: 100%; 
            height: 100%; 
            object-fit: cover; 
            z-index: 9; 
          }
          .item-gallery-3:hover .image-gallery-3{
            display: none; 
          }
          .infos-item-gallery{
            padding: 30px; 
            width: 100%; 
            display: flex;
            flex-direction: column; 
            align-items: flex-start; 
            border: 2px solid #707070; 
          }
          .subtitle-gallery{
           font-size: 13px; 
           color: #6366f1; 
           margin-bottom: 10px; 
          }
          .title-item-gallery{
            font-size: 18px; 
            color: #363940;
            margin-bottom: 15px; 
          }
          .text-item-gallery{
           font-size: 16px;
            color: #363940;
           }

          @media (max-width: 992px){
            .w-80{
              width: 100%; 
            }
            .sm-flex-col{
              flex-direction: column; 
            }
            .item-gallery-3{
              width: 100%; 
            }
          }

            `,
      },
    },
    view: {

    },
  };
  return component;
};