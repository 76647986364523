export default (opts = {}) => {
  const block = {
    category: 'Conteúdos',
    id: "content6",
    label: "Conteúdos (6)",
    fromPlugin: 'blocks',
    apper : 'out',
    media: '<svg class="preview-block" viewBox="0 0 266 150" fill="none" width="266"  height="150" ><path fill="#FFFFFF" d="M0 0h266v150H0z"></path><path d="M93 67.5a.5.5 0 011 0v62a.5.5 0 01-1 0v-62z" fill="#CBD5E0"></path><path d="M106 83a2 2 0 012-2h103.337a2 2 0 110 4H108a2 2 0 01-2-2zM106 107a2 2 0 012-2h95a2 2 0 110 4h-95a2 2 0 01-2-2zM106 91a2 2 0 012-2h121a2 2 0 110 4H108a2 2 0 01-2-2zM106 99a2 2 0 012-2h82.957a2 2 0 010 4H108a2 2 0 01-2-2z" fill="#A0AEC0"></path><path d="M106 115a2 2 0 012-2h20a2 2 0 110 4h-20a2 2 0 01-2-2z" fill="#6366F1"></path><path d="M45 104a2 2 0 012-2h20a2 2 0 110 4H47a2 2 0 01-2-2z" fill="#4A5568"></path><rect x="37" y="110" width="40" height="2" rx="1" fill="#A0AEC0"></rect><rect x="35" y="120" width="44" height="2" rx="1" fill="#A0AEC0"></rect><path d="M33 116a1 1 0 011-1h45a1 1 0 010 2H34a1 1 0 01-1-1z" fill="#A0AEC0"></path><path d="M122.889 47h20.222A2.889 2.889 0 00146 44.111V23.89a2.889 2.889 0 00-2.889-2.89h-20.222A2.889 2.889 0 00120 23.889V44.11a2.889 2.889 0 002.889 2.89zm0 0l15.889-15.889L146 38.333m-15.889-9.389a2.167 2.167 0 11-4.333 0 2.167 2.167 0 014.333 0z" stroke="#A0AEC0" stroke-width="2px" stroke-linecap="round" stroke-linejoin="round" fill="none"></path><circle cx="56.5" cy="85.5" r="10.5" fill="#E2E8F0"></circle></svg>',
    content: { type: 'content6' },
  };

  return block;
};