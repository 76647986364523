import block from "./block.js";
import component from "./components/index.js"
import cardServices3 from "./components/cardServices3.js";
import columnService3 from "./components/columnService3.js";
import containerCardService3 from "./components/containerCardService3.js";
import rowCardService3 from "./components/rowCardService3.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("service3", component(opts));
  editor.DomComponents.addType("cardServices3", cardServices3(opts));
  editor.DomComponents.addType("columnService3", columnService3(opts));
  editor.DomComponents.addType("containerCardService3", containerCardService3(opts));
  editor.DomComponents.addType("rowCardService3", rowCardService3(opts));

    //block
  editor.BlockManager.add("service3", block(opts));
};