import block from "./block.js";
import component from "./components/index.js"
import cardServicesLeft4 from "./components/cardServicesLeft4.js";
import cardServicesRigth4 from "./components/cardServicesRigth4.js";
import colCardServicesC4 from "./components/colCardServicesC4.js";
import colCardServicesL4 from "./components/colCardServicesL4.js";
import colCardServicesR4 from "./components/colCardServicesR4.js";
import containerService4 from "./components/containerService4.js";
import rowCardServices4 from "./components/rowCardServices4.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("service4", component(opts));
  editor.DomComponents.addType("cardServicesLeft4", cardServicesLeft4(opts));
  editor.DomComponents.addType("cardServicesRigth4", cardServicesRigth4(opts));
  editor.DomComponents.addType("colCardServicesC4", colCardServicesC4(opts));
  editor.DomComponents.addType("colCardServicesL4", colCardServicesL4(opts));
  editor.DomComponents.addType("colcardServicesR4", colCardServicesR4(opts));
  editor.DomComponents.addType("containerService4", containerService4(opts));
  editor.DomComponents.addType("rowCardServices4", rowCardServices4(opts));

    //block
  editor.BlockManager.add("service4", block(opts));
};