export default (opts = {}) => {
  const component = {
    model: {
        defaults: {
          type:'section',
          name: 'Seção Topo',
          attributes: { class: 'full-section main-section bg-neutro position-realtive' },
          components: [
            {
              type: 'columns2'
            }
          ],
          styles: `
         .full-section{
           position:relative; 
           with:100vw; 
           padding: 0; 
         }
         .main-section{
          min-height: 100vh; 
          display:flex; 
          flex-direction: column; 
          justify-content: center;
          align-content:center;
         }
         .bg-neutro{
          background:#F6F7FA;
         }
         .position-relative{
          position: relative; 
         }
         *{
          font-family: Roboto, Arial, sans-serif;
        }
         
        `,
        },
      },
      view: {
  
      },
  };

  return component;
};

