export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        tagname: "section",
        style: {
          'position':'relative', 
          'width':'100vw', 
          'min-height': '100px', 
        },
        traits: [
          {
            type:'id',
            name:'id',
            Label:'id',
          },
        ],
        styles: `
      `,
      },
    },
    view: {},
  };

  return component;
};
