import block from "./block.js";
import component from "./components/index.js"
import columnItems5 from "./components/columnItems5.js";
import columnsTestimony5 from "./components/columnsTestimony5.js";
import columnTestimony5 from "./components/columnTestimony5.js";
import containerTestimony5 from "./components/containerTestimony5.js";
import itemTestimony5 from "./components/itemTestimony5.js";
import rowTestimony5 from "./components/rowTestimony5.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("testimony5", component(opts));
  editor.DomComponents.addType("columnItems5", columnItems5(opts));
  editor.DomComponents.addType("columnsTestimony5", columnsTestimony5(opts));
  editor.DomComponents.addType("columnTestimony5", columnTestimony5(opts));
  editor.DomComponents.addType("containerTestimony5", containerTestimony5(opts));
  editor.DomComponents.addType("itemTestimony5", itemTestimony5(opts));
  editor.DomComponents.addType("rowTestimony5", rowTestimony5(opts));

  //block
  editor.BlockManager.add("testimony5", block(opts));
};