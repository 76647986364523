export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        type: 'Container',
        name: "Container",
        attributes: { class: 'container-cubo' },
        components: `
        <div class="flex flex-col items-center">
   <h2 class="principal-title">Nosso time</h2>
   <p class="body-text">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt, elit non rhoncus egestas, dolor lectus
      pretium libero</p>
</div>
<div class="flex sm-flex-col mt-50 gap-20">
   <div class="item-team-1">
         <img alt="team" class="image-team-1"
            src="https://dummyimage.com/80x80">
         <div class="">
            <h3 class="name-team-1">
               Holden Caulfield</h3>
            <p class="info-team-1">UI Designer</p>
         </div>
   </div>
   <div class="item-team-1">
      <img alt="team" class="image-team-1"
         src="https://dummyimage.com/80x80">
      <div class="">
         <h3 class="name-team-1">
            Henry Letham</h3>
         <p class="info-team-1">CTO</p>
      </div>
</div>
<div class="item-team-1">
   <img alt="team" class="image-team-1"
      src="https://dummyimage.com/80x80">
   <div class="">
      <h3 class="name-team-1">
         Blinde</h3>
      <p class="info-team-1">Fundador</p>
   </div>
</div>
   
</div>
<div class="flex sm-flex-col mt-16 gap-20">
   <div class="item-team-1">
         <img alt="team" class="image-team-1"
            src="https://dummyimage.com/80x80">
         <div class="">
            <h3 class="name-team-1">
               Holden Caulfield</h3>
            <p class="info-team-1">UI Designer</p>
         </div>
   </div>
   <div class="item-team-1">
      <img alt="team" class="image-team-1"
         src="https://dummyimage.com/80x80">
      <div class="">
         <h3 class="name-team-1">
            Henry Letham</h3>
         <p class="info-team-1">CTO</p>
      </div>
</div>
<div class="item-team-1">
   <img alt="team" class="image-team-1"
      src="https://dummyimage.com/80x80">
   <div class="">
      <h3 class="name-team-1">
         Blinde</h3>
      <p class="info-team-1">Fundador</p>
   </div>
</div>
   
</div>
<div class="flex sm-flex-col mt-16 gap-20">
   <div class="item-team-1">
         <img alt="team" class="image-team-1"
            src="https://dummyimage.com/80x80">
         <div class="">
            <h3 class="name-team-1">
               Holden Caulfield</h3>
            <p class="info-team-1">UI Designer</p>
         </div>
   </div>
   <div class="item-team-1">
      <img alt="team" class="image-team-1"
         src="https://dummyimage.com/80x80">
      <div class="">
         <h3 class="name-team-1">
            Henry Letham</h3>
         <p class="info-team-1">CTO</p>
      </div>
</div>
<div class="item-team-1">
   <img alt="team" class="image-team-1"
      src="https://dummyimage.com/80x80">
   <div class="">
      <h3 class="name-team-1">
         Blinde</h3>
      <p class="info-team-1">Fundador</p>
   </div>
</div>
   
</div>
        `,
        styles: `
             .container-cubo{
                max-width: 1600px; 
             }
            .flex{
              display: flex; 
            }
            .flex-col{
              flex-direction: column; 
            }
            .items-center{
              align-items: center; 
             }
            .principal-title{
            font-size: 30px;
            color: #363940;
            margin:0;
           }
            .body-text{
            font-size: 16px;
            color: #363940;
            margin-top:30px;
           }
           .mt-50{
             margin-top: 50px; 
           }
          .gap-20{
            gap: 20px; 
          }
          .item-team-1{
             width: 33%; 
             padding: 20px; 
             display: flex; 
             gap: 15px; 
             border-radius: 10px; 
             border: 1px solid #e5e7eb; 
          }
          .image-team-1{
            width: 80px; 
            height: 80px; 
            border-radius: 100%; 
          }
          .name-team-1{
            font-weight: 500;
            font-size: 18px; 
            color: #111827; 
            margint-bottom: 8px; 
          }
          .info-team-1{
            font-size: 15px; 
            color: #6b7280; 
          }
          .mt-16{
            margin-top: 16px; 
          }
           @media (max-width: 992px){
             .sm-flex-col{
               flex-direction: column; 
             }
              .item-team-1{
                width: 100%; 
              }
           }


            `,
      },
    },
    view: {

    },
  };
  return component;
};