export default (opts = {}) => {
    const block = {
      id: "textBlock",
      label: "Alinhado a esquerda",
      fromPlugin: 'text',
      apper : 'out',
      media:'<i class="bi bi-text-left"></i>',
      content: { type: "textBlock" },
    };
  
    return block;
  };
  

  
