export default (opts = {}) => {
    const block = {
      id: "floatWhats",
      label: "WhatsApp flutuante",
      fromPlugin: 'extra',
      apper : 'out',
      media:'<i class="bi bi-whatsapp"></i>',
      content: { type: "floatWhats" },
    };
  
    return block;
  };
  

  
