export default (opts = {}) => {
    const block = {
      id: "column2",
      label: "2 Colunas",
      fromPlugin: 'layout',
      apper : 'out', 
      media: '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 30 28"><path id="layout-split" d="M0,5A3.881,3.881,0,0,1,3.75,1h22.5A3.881,3.881,0,0,1,30,5V25a3.881,3.881,0,0,1-3.75,4H3.75A3.881,3.881,0,0,1,0,25ZM15.938,3V27H26.25a1.941,1.941,0,0,0,1.875-2V5A1.941,1.941,0,0,0,26.25,3ZM14.063,3H3.75A1.941,1.941,0,0,0,1.875,5V25A1.941,1.941,0,0,0,3.75,27H14.063Z" transform="translate(0 -1)" fill="#92949f"/></svg>',
      content: { type: "column2" },
    };
  
    return block;
  };
  

