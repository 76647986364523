export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              components: [
                {
                  type:'image',
                  traits: [
                    {
                      type: 'text', 
                      name:'src',
                      label: 'link',
                      id:'src',
                    },
                    {
                      type: 'text', 
                      name:'alt',
                      id:'alt',
                    },
                    {
                      type:'id',
                      name:'id',
                      Label:'id',
                    },
                    {
                      type:'checkbox',
                      name:'loading',
                      id:'loading',
                      label: 'Lazy Load',
                      valueTrue: 'lazy',
                      valueFalse: ''
                    },
                  ],
                  style: {
                    'width': '300px',
                    'height': '300px', 
                    'border-radius':'100% 100% 100% 100%',
                    'object-fit': 'cover'
                  },
                }
              ],
            },
          },
          view: {},
};
  return component; 
};