export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        type: 'Container',
        name: "Container Preços",
        attributes: { class: 'container-cubo' },
        components: `
   <div class="flex flex-col items-center">
   <h2 class="principal-title">Nosso time</h2>
   <p class="body-text">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt, elit non rhoncus egestas, dolor lectus
      pretium libero</p>
</div>
<div class="flex sm-flex-col mt-50 gap-20">
   <div class="item-team-3">
      <img alt="team" class="image-team-3" src="https://dummyimage.com/200x200">
      <h2 class="name-team-3">Alper Kamu</h2>
      <h3 class="info-team-3">UI Developer</h3>
      <p class="text-team-3">
         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt, elit non rhoncus egestas</p>
   </div>
   <div class="item-team-3">
      <img alt="team" class="image-team-3" src="https://dummyimage.com/200x200">
      <h2 class="name-team-3">Alper Kamu</h2>
      <h3 class="info-team-3">UI Developer</h3>
      <p class="text-team-3">
         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt, elit non rhoncus egestas</p>
   </div>
   <div class="item-team-3">
      <img alt="team" class="image-team-3" src="https://dummyimage.com/200x200">
      <h2 class="name-team-3">Alper Kamu</h2>
      <h3 class="info-team-3">UI Developer</h3>
      <p class="text-team-3">
         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt, elit non rhoncus egestas</p>
   </div>
   <div class="item-team-3">
      <img alt="team" class="image-team-3" src="https://dummyimage.com/200x200">
      <h2 class="name-team-3">Alper Kamu</h2>
      <h3 class="info-team-3">UI Developer</h3>
      <p class="text-team-3">
         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt, elit non rhoncus egestas</p>
   </div>
</div>
<div class="flex sm-flex-col mt-20 gap-20">
   <div class="item-team-3">
      <img alt="team" class="image-team-3" src="https://dummyimage.com/200x200">
      <h2 class="name-team-3">Alper Kamu</h2>
      <h3 class="info-team-3">UI Developer</h3>
      <p class="text-team-3">
         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt, elit non rhoncus egestas</p>
   </div>
   <div class="item-team-3">
      <img alt="team" class="image-team-3" src="https://dummyimage.com/200x200">
      <h2 class="name-team-3">Alper Kamu</h2>
      <h3 class="info-team-3">UI Developer</h3>
      <p class="text-team-3">
         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt, elit non rhoncus egestas</p>
   </div>
   <div class="item-team-3">
      <img alt="team" class="image-team-3" src="https://dummyimage.com/200x200">
      <h2 class="name-team-3">Alper Kamu</h2>
      <h3 class="info-team-3">UI Developer</h3>
      <p class="text-team-3">
         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt, elit non rhoncus egestas</p>
   </div>
   <div class="item-team-3">
      <img alt="team" class="image-team-3" src="https://dummyimage.com/200x200">
      <h2 class="name-team-3">Alper Kamu</h2>
      <h3 class="info-team-3">UI Developer</h3>
      <p class="text-team-3">
         Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tincidunt, elit non rhoncus egestas</p>
   </div>
</div>
        `,
        styles: `
             .container-cubo{
                max-width: 1600px; 
             }
                 .flex{
              display: flex; 
            }
            .flex-col{
              flex-direction: column; 
            }
            .items-center{
              align-items: center; 
             }
            .principal-title{
            font-size: 30px;
            color: #363940;
            margin:0;
           }
            .body-text{
            font-size: 16px;
            color: #363940;
            margin-top:30px;
           }
            .mt-50{
             margin-top: 50px; 
           }
            .mt-20{
              margin-top: 20px; 
            }
          .gap-20{
            gap: 20px; 
          }
          .item-team-3{
            width: 25%; 
            display: flex; 
            flex-direction: column; 
            align-items: center; 
          }
          .image-team-3{
            width: 200px; 
            height: 200px; 
            border-radius: 10px; 
            object-fit: cover; 
          }

          .name-team-3{
            font-weight: 500;
            font-size: 18px; 
            color: #111827; 
            margin-top: 20px;
          }
          .info-team-3{
            font-size: 15px; 
            color: #6b7280; 
          }
          .text-team-3{
            margin-top: 15px; 
            color: #4b5563;
            text-align: center;
          }

           @media (max-width: 992px){
             .sm-flex-col{
               flex-direction: column; 
             }
            .item-team-3{
              width: 100%; 
            }
      }
            `,
      },
    },
    view: {

    },
  };
  return component;
};