import block from "./block.js";
import component from "./components/index.js"
import columnAbout from "./components/columnAbout.js"
import columnImageAbout from "./components/columnImageAbout.js"
import columnsAbout from "./components/columnsAbout.js"
import containerAbout from "./components/containerAbout.js"


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("columnAbout", columnAbout(opts));
  editor.DomComponents.addType("columnImageAbout", columnImageAbout(opts));
  editor.DomComponents.addType("columnsAbout", columnsAbout(opts));
  editor.DomComponents.addType("containerAbout", containerAbout(opts));
  editor.DomComponents.addType("about1", component(opts));
    //block
  editor.BlockManager.add("about1", block(opts));
};