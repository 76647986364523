export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type:'div', 
              name: 'Seção Tempo',
              attributes: { class: 'section' },
              components: [
                {
                  type: 'containerTimeLine7', 
                },
                
              ],
              styles: `
             .section{
               position:relative; 
               with:100vw; 
               padding: 75px 5%; 
             }
             *{
              font-family: Roboto, Arial, sans-serif;
            }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};