export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type:'Container', 
              name: "Container Sobre",
              attributes: { class: 'container-cubo' },
              components: [
                {
                  type: 'columnsAbout2'
                }        
              ],
              styles: `
             .container-cubo{
                max-width: 1600px; 
             }
            `,
             },
          },
          view: {
      
          },
    };
  
    return component;
  };
  