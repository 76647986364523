import block from "./block.js";
import component from "./components/index.js"
import containerTimeLine7 from "./components/containerTimeLine7.js";
import itemTimeLineTop7 from "./components/itemTimeLineTop7.js";
import itemTimeLineBottom7 from "./components/itemTimeLineBottom7.js";
import rowTimeLine7 from "./components/rowTimeLine7.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("timeLine7", component(opts));
  editor.DomComponents.addType("containerTimeLine7", containerTimeLine7(opts));
  editor.DomComponents.addType("itemTimeLineTop7", itemTimeLineTop7(opts));
  editor.DomComponents.addType("itemTimeLineBottom7", itemTimeLineBottom7(opts));
  editor.DomComponents.addType("rowTimeLine7", rowTimeLine7(opts));

  //block
  editor.BlockManager.add("timeLine7", block(opts));
};