export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type:'div', 
              name: 'Card Serviços',
              components: [
               {
                 type:'div', 
                 attributes: { class: 'card-right'}, 
                 components: [
                   {
                     type: 'text',
                     content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et bibendum risus. Cras faucibus viverra dolor, at vehicula erat tempus',
                     attributes: { class: 'text-card text-right'}
                   },
                   {
                     type: 'div', 
                     attributes: { class: 'name-card'}, 
                     components: [
                      {
                        type: 'text', 
                        content: 'Lorem ipsum dolor sit',
                      }, 
                     ],
                   },
                 ],
               },
              ],
              styles: `
               .card-right{
                padding: 15px; 
                background: #F9F9F9; 
                position: relative; 
                display: flex; 
                flex-direction: column; 
                align-items: flex-end; 
               }
               .text-card{
                font-size:16px; 
                color: #95A1BB; 
               }
               .text-right{
                text-align: right; 
               }
               .name-card{
                background: #E9E9E9; 
                padding: 10px; 
                position: absolute; 
                top: -31%; 
               }  
           `,
             },
          },
          view: {
      
          },
};
  return component; 
};