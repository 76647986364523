export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div',
              name: 'Coluna Tempo', 
              attributes: { class: 'column column-50' },
              components: [
                {
                  type: 'image', 
                  attributes: {class: 'featured-image', src:'https://placehold.co/767x827'}
                } ,
                
              ],
              styles: `
             .featured-image{
              width:100%; 
              height:100%; 
              object-fit: cover;
             }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};