export default (opts = {}) => {
  const component = {
      model: {
          defaults: {
            type:'div', 
            classes: ['columns'],
            traits: [
              {
                type:'id',
                name:'id',
                Label:'id',
              },
            ],
            components: [
              {
                  type: 'div',
                  resizable:true,
                  classes: ['column', 'column-50'],
                  traits: [
                    {
                      type:'id',
                      name:'id',
                      Label:'id',
                    },

                  ],
              },
              {
                  type: 'div',
                  resizable:true,
                  classes: ['column', 'column-50'],
                  traits: [
                    {
                      type:'id',
                      name:'id',
                      Label:'id',
                    },
                  ],
              },
            ],
            styles: `
            .columns{
              display: flex;
              width: 100%;
            }
            .column-50{
              width: 50%;
              min-height: 100px;
              flex-grow: 1;
            }

            @media (max-width: 992px){
              .columns{
                flex-direction: column;
              }
              .column-50{
                min-width: 100%;
                max-width: 100%;
              }
            }
            `,
          },
          
        },
        view: {
    
        },
};
return component; 
};