export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: "Portifolio",
              attributes: { class: 'grid-portifolio' },
              components: [      
                {
                  type:'image',
                  attributes: { class: 'w-65 w-sm-100', src:'https://placehold.co/1088x533' }
                },
                {
                  type:'image',
                  attributes: { class: 'w-32 w-sm-100' , src:'https://placehold.co/536x533' }
                },
                {
                  type:'image',
                  attributes: { class: 'w-32 w-sm-100', src:'https://placehold.co/536x533'}
                },
                {
                  type:'image',
                  attributes: { class: 'w-32 w-sm-100', src:'https://placehold.co/536x533' }
                },
                {
                  type:'image',
                  attributes: { class: 'w-32 w-sm-100', src:'https://placehold.co/536x533' }
                },
      
              ],
              styles: `
              .grid-portifolio {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                gap: 16px;
                margin-top:50px; 
              }
              .w-65{
                width:65.6%; 
              }
              .w-32{
                width:32%; 
              }
      
              @media (max-width:992px){
                .w-sm-100{
                  width:100%;
                }
              }
      
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};