import block from "./block.js";
import component from "./components/index.js"
import containerProducts3 from "./components/containerProducts3.js";
import itemProducts3 from "./components/itemProducts3.js";
import rowProducts3 from "./components/rowProducts3.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("products3", component(opts));
  editor.DomComponents.addType("containerProducts3", containerProducts3(opts));
  editor.DomComponents.addType("itemProducts3", itemProducts3(opts));
  editor.DomComponents.addType("rowProducts3", rowProducts3(opts));

    //block
  editor.BlockManager.add("products3", block(opts));
};