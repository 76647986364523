export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
                type: 'link', 
                droppable: true,
                traits: [
                  {
                    type:'id',
                    name:'id',
                    Label:'id',
                  },
                  {
                    type: 'text',
                    name:'title',
                    label: 'Title', 
                  },
                  {
                    type: 'text',
                    name: 'href',
                    label: 'Href',
                  },
                  {
                    type:'select',
                    name:'target',
                    id:'target',
                    label: 'Target',
                    options: [
                    { value: '', name: 'Esta Janela'},
                    { value: '_blank', name: 'Nova Janela'},
                  ]
                  },
                ],
              content: 'Lorem Ipsum',
              styles: `
              *{
                font-family: Roboto, Arial, sans-serif;
              }
             `,
            },
          },
          view: {
      
          },
};
  return component; 
};