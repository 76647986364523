export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type:'image',
              traits: [
                {
                  type: 'text', 
                  name:'src',
                  label: 'link',
                  id:'src',
                },
                {
                  type: 'text', 
                  name:'alt',
                  id:'alt',
                },
                {
                  type:'id',
                  name:'id',
                  Label:'id',
                },
                {
                  type:'checkbox',
                  name:'loading',
                  id:'loading',
                  label: 'Lazy Load',
                  valueTrue: 'lazy',
                  valueFalse: ''
                },
              ],
              style: {
              },
            },
          },
          view: {},
};
  return component; 
};