export default (opts = {}) => {
  const block = {
    category: 'Conteúdos',
    id: "content7",
    label: "Conteúdos (7)",
    fromPlugin: 'blocks',
    apper : 'out',
    media: '<svg class="preview-block" viewBox="0 0 266 150" fill="none" width="266"  height="150" ><path fill="#FFFFFF" d="M0 0h266v150H0z"></path><path d="M41.692 86a2 2 0 012-2H114.4a2 2 0 010 4H43.692a2 2 0 01-2-2z" fill="#A0AEC0"></path><rect x="59" y="104" width="40" height="10" rx="2" fill="#6366F1"></rect><path d="M35 94a2 2 0 012-2h83a2 2 0 110 4H37a2 2 0 01-2-2z" fill="#A0AEC0"></path><path d="M68.889 63H89.11A2.889 2.889 0 0092 60.111V39.89A2.889 2.889 0 0089.111 37H68.89A2.889 2.889 0 0066 39.889V60.11A2.889 2.889 0 0068.889 63zm0 0l15.889-15.889L92 54.333m-15.889-9.389a2.167 2.167 0 11-4.333 0 2.167 2.167 0 014.333 0z" stroke="#A0AEC0" stroke-width="2px" stroke-linecap="round" stroke-linejoin="round" fill="none"></path><rect x="56" y="73" width="46" height="5" rx="2.5" fill="#4A5568"></rect><path d="M150.692 86a2 2 0 012-2h70.707a2 2 0 010 4h-70.707a2 2 0 01-2-2z" fill="#A0AEC0"></path><rect x="168" y="104" width="40" height="10" rx="2" fill="#6366F1"></rect><path d="M144 94a2 2 0 012-2h83a2 2 0 110 4h-83a2 2 0 01-2-2z" fill="#A0AEC0"></path><path d="M177.889 63h20.222A2.889 2.889 0 00201 60.111V39.89a2.889 2.889 0 00-2.889-2.89h-20.222A2.889 2.889 0 00175 39.889V60.11a2.889 2.889 0 002.889 2.89zm0 0l15.889-15.889L201 54.333m-15.889-9.389a2.167 2.167 0 11-4.333 0 2.167 2.167 0 014.333 0z" stroke="#A0AEC0" stroke-width="2px" stroke-linecap="round" stroke-linejoin="round" fill="none"></path><rect x="165" y="73" width="46" height="5" rx="2.5" fill="#4A5568"></rect></svg>',
    content: { type: 'content7' },
  };

  return block;
};