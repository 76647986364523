export default (opts = {}) => {
  const block = {
    id: "button",
    label: "Contornado",
    fromPlugin: 'button',
    apper : 'out', 
    media: '<img class="preview-block" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABbCAYAAACFziAsAAAABHNCSVQICAgIfAhkiAAAAvNJREFUeF7tms+LTWEYx9+T3QxiRVhg4x/wBxjJj+woZTWlRjaSnYWFwlZkI0rsJFkpMyX8ERKbyQKNFRlj6XpeHDWLac4zdb/nO53PqbdzZu5zz/M9n8957j2L25R/22g0OhaH12LtjbWl/T/7sRL4Fmefj3W5aZrntVMTIjbE/mqsS/Xvsbbn5CsRGMUL12NdqUIOxMErWPVO4FckOFyFzMbBkd7jEKASmKtCFuJgGzwsCHypQurnF5sJAYSYiGhjIAQhZgTM4jAhCDEjYBaHCUGIGQGzOEwIQswImMVhQhBiRsAsDhOCEDMCZnGYEISYETCLw4QgxIyAWRwmBCFmBMziMCEIMSNgFocJQYgZAbM4nSfkzkuz5OsszrmD3QJ3FjJzv5SzU91OStVyAnfjl9P3znSjkhLS9aTdWg+nqt7MXdkhRHBfIEQAOdMCIRlaglqECCBnWiAkQ0tQixAB5EwLhGRoCWoRIoCcaYGQDC1BLUIEkDMtehGy+GOpPJ17Xb7HfqjbpsmJcuLoVNm8cXIZgl6EPHjyrCwu/Ryqi//XXWVMnzzev5DbDx8PXkYL4Pz0KYQ43Q0IcbIRWSyE8B3y966w+Q7hKasUq6css08Nqzi9PPZaETALgxCEmBEwi8OEIMSMgFkcJgQhZgTM4jAhCDEjYBaHCRmKkB1bza50ncT5/HUMP7b+FCdlWzuBnR1v5s6/fl97FN6ZIYCQDC1BLUIEkDMtEJKhJahFiABypgVCMrQEtQgRQM60QEiGlqAWIQLImRYIydAS1CJEADnTAiEZWoJahAggZ1ogJENLUIsQAeRMC4RkaAlqESKAnGmBkAwtQS1CBJAzLRCSoSWoRYgAcqYFQjK0BLUIEUDOtEBIhpagtgp5F332CXrRYnUC76uQm1F3YfVaKgQEblUhE9HoTaw9goa0WJnA23hpf1NfDynbY3cj1mmI9ULgUXS92DTNwh8h7RZidsXxoVi7e4k1vKYf4pJfhIiP7aX/BtprIHiYAevUAAAAAElFTkSuQmCC">',
    content: { type: "button" },
  };

  return block;
};

