export default (opts = {}) => {
  const component =  {
    model: {
      defaults: {
        type:'Container',
        name: "Container Blog",
        attributes: { class: 'container-cubo'},
        components: [
          {
            type:'columnsBlog2'
          }
        ],
        styles: `
       .container-cubo{
          max-width: 1600px; 
       }
      `,
       },
    },
    view: {

    },
  };

  return component;
};
