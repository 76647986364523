export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: "Rodapé",
              attributes: { class: 'section-footert' },
              components: [
                {
                  type: 'containerFooter2', 
                },
                {
                  type: 'footer'
                }
              ],
              styles: `
             .section-footer{
               position:relative; 
               padding: 20px 5% 0px 5%;
               display: flex;
               flex-direction: column; 
               align-items: center
             }
             *{
              font-family: Roboto, Arial, sans-serif;
            }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};