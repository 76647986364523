export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type:'div', 
              name: 'Card Serviços',
              attributes: { class:'card-service'},
              components: [
                {
                  type: 'text',
                  content:  'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', 
                  attributes: { class:'title-service'}, 
                },
                {
                  type: 'text',
                  content:  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et bibendum risus. Cras faucibus viverra dolor, at vehicula erat tempus vel. Sed a velit dictum, semper nulla at, volutpat dolor. In egestas tortor a tellus dignissim aliquet.', 
                  attributes: { class:'text-service'}, 
                },
              ],
              styles: `
               .card-service{
                padding:30px; 
                background: #E9E9E9; 
                width: 33%; 
               }
               .title-service{
                font-size: 20px; 
                color: #363940; 
                font-weight: 500; 
               }
               .text-service{
                font-size: 16px; 
                color: #95A1BB; 
                margin-top: 20px; 
               }
      
               @media (max-width:992px){
                .card-service{
                  width:100%;
                }
               }
           `,
             },
          },
          view: {
      
          },
};
  return component; 
};