import block from "./block.js";
import component from "./components/index.js"
import containerEcommerce1 from "./components/containerEcommerce1.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerEcommerce1", containerEcommerce1(opts));
  editor.DomComponents.addType("eccomerce1", component(opts));
    //block
  editor.BlockManager.add("eccomerce1", block(opts));
};