export default (opts = {}) => {
  const block = {
    category: 'Diferenciais',
    id: "differences6",
    label: "Diferenciais (6)",
    fromPlugin: 'blocks',
    apper : 'out',
    media: '<svg class="preview-block" viewBox="0 0 266 150" fill="none" width="266"  height="150" ><path fill="#FFFFFF" d="M0 0h266v150H0z"></path><rect x="96" y="39" width="74" height="5" rx="2.5" fill="#4A5568"></rect><circle cx="26" cy="90" r="6" fill="#C3DAFE"></circle><rect x="82" y="48" width="102" height="5" rx="2.5" fill="#4A5568"></rect><path d="M38 94.5a1.5 1.5 0 011.5-1.5h48a1.5 1.5 0 010 3h-48a1.5 1.5 0 01-1.5-1.5zM38 101.5a1.5 1.5 0 011.5-1.5h38a1.5 1.5 0 010 3h-38a1.5 1.5 0 01-1.5-1.5z" fill="#A0AEC0"></path><path d="M38 108.5a1.5 1.5 0 011.5-1.5h13a1.5 1.5 0 010 3h-13a1.5 1.5 0 01-1.5-1.5z" fill="#6366F1"></path><rect x="38" y="84" width="43" height="4" rx="2" fill="#4A5568"></rect><circle cx="105" cy="90" r="6" fill="#C3DAFE"></circle><path d="M117 94.5a1.5 1.5 0 011.5-1.5h48a1.5 1.5 0 010 3h-48a1.5 1.5 0 01-1.5-1.5zM117 101.5a1.5 1.5 0 011.5-1.5h38a1.5 1.5 0 010 3h-38a1.5 1.5 0 01-1.5-1.5z" fill="#A0AEC0"></path><path d="M117 108.5a1.5 1.5 0 011.5-1.5h13a1.5 1.5 0 010 3h-13a1.5 1.5 0 01-1.5-1.5z" fill="#6366F1"></path><rect x="117" y="84" width="43" height="4" rx="2" fill="#4A5568"></rect><circle cx="184" cy="90" r="6" fill="#C3DAFE"></circle><path d="M196 94.5a1.5 1.5 0 011.5-1.5h48a1.5 1.5 0 010 3h-48a1.5 1.5 0 01-1.5-1.5zM196 101.5a1.5 1.5 0 011.5-1.5h38a1.5 1.5 0 010 3h-38a1.5 1.5 0 01-1.5-1.5z" fill="#A0AEC0"></path><path d="M196 108.5a1.5 1.5 0 011.5-1.5h13a1.5 1.5 0 010 3h-13a1.5 1.5 0 01-1.5-1.5z" fill="#6366F1"></path><rect x="196" y="84" width="43" height="4" rx="2" fill="#4A5568"></rect></svg>',
    content: { type: 'differences6' },
  };

  return block;
};