export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: 'Div',
              attributes: { class: 'w-100 margin-top-16 d-flex gap-16 d-sm-column' },
              components: [
               {
                type:'cardServices2',
               },
               {
                type:'cardServices2',
               },
               {
                type:'cardServices2',
               },
      
              ],
              styles: `
               .d-flex{
                display:flex; 
                flex-direction:row; 
               }
               .gap-16{
                gap:16px;
               }
               .w-100{
                width:100%; 
               }
               .margin-top-16{
                margin-top:16px;
               }
               @media (max-width:992px){
                .d-sm-column{
                  flex-direction: column !important; 
                }
              }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};