import block from "./block.js";
import component from "./components/index.js"
import contactMap2 from "./components/contactMap2.js";
import containerContact2 from "./components/containerContact2.js";


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("contactMap2", contactMap2(opts));
  editor.DomComponents.addType("containerContact2", containerContact2(opts));
  editor.DomComponents.addType("contact2", component(opts));
    //block
  editor.BlockManager.add("contact2", block(opts));
};