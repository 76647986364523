export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        type: 'Container',
        name: "Container Cabeçalho",
        attributes: { class: 'container-cubo flex sm-flex-col items-center justify-between gap-15' },
        components: [
          {
            type: 'div',
            attributes: { class: 'flex flex-wrap items-center gap-15' },
            components: [
              {
                type: 'link',
                attributes: { class: 'link-header' },
                components: 'Lorem'
              },
              {
                type: 'link',
                attributes: { class: 'link-header' },
                components: 'Lorem'
              },
              {
                type: 'link',
                attributes: { class: 'link-header' },
                components: 'Lorem'
              },
              {
                type: 'link',
                attributes: { class: 'link-header' },
                components: 'Lorem'
              },
            ]
          },
          {
            type: 'image',
            attributes: { class: 'logo-header', src: 'https://dummyimage.com/40x40' },
          },
          {
            type: 'button3'
          }
        ],
        styles: `
             .container-cubo{
                max-width: 1600px; 
             }
             .flex{
               display: flex; 
             }
             .items-center{
               align-items: center; 
             }
            .justify-between{
               justify-content: space-between;
            }
            .gap-15{
              gap: 15px; 
            }
            .flex-wrap{
               flex-wrap: wrap; 
            }
            .link-header{
               font-size: 14px; 
               color: #363940;
               text-decoration: none; 
            }
            .logo-header{
              width: 40px; 
              height: 40px; 
              border-radius: 100%; 
            }

             @media (max-width: 992px){
               .sm-flex-col{
                  flex-direction: column; 
               }
             }
            `,
      },
    },
    view: {

    },
  };
  return component;
};