export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type: 'div',
              name: "Contador",
              attributes: { class: 'w-100 bg-cinza p-40 d-flex d-sm-column gap-50 margin-top-50' },
              components: [
                {
                  type: 'div',
                  attributes: { class: 'd-flex align-center d-sm-column'}, 
                  components: [
                    {
                      type: 'div', 
                      attributes: { class: 'd-flex align-center'}, 
                      components: [
                        {
                          type: 'text', 
                          content: '+', 
                          attributes: { class: 'prefix font-40'}
                      }, 
                      { 
                          type:'counters',
                          attributes:{class:'counter-value font-40'},
                      }, 
                      ]
                    },
                   
                    {
                        type: 'text', 
                        content: 'Lorem ipsum dolor sit amet consectetur', 
                        attributes: { class: 'context'}
                    }
                  ]
                },
                {
                  type: 'div',
                  attributes: { class: 'd-flex align-center d-sm-column'}, 
                  components: [
                    {
                      type: 'div', 
                      attributes: { class: 'd-flex align-center'}, 
                      components: [
                        {
                          type: 'text', 
                          content: '+', 
                          attributes: { class: 'prefix font-40'}
                      }, 
                      { 
                          type:'counters',
                          attributes:{class:'counter-value font-40'},
                      }, 
                      ]
                    },
                   
                    {
                        type: 'text', 
                        content: 'Lorem ipsum dolor sit amet consectetur', 
                        attributes: { class: 'context'}
                    }
                  ]
                },
                  {
                    type: 'div',
                    attributes: { class: 'd-flex align-center d-sm-column'}, 
                    components: [
                      {
                        type: 'div', 
                        attributes: { class: 'd-flex align-center'}, 
                        components: [
                          {
                            type: 'text', 
                            content: '+', 
                            attributes: { class: 'prefix font-40'}
                        }, 
                        { 
                            type:'counters',
                            attributes:{class:'counter-value font-40'},
                        }, 
                        ]
                      },
                     
                      {
                          type: 'text', 
                          content: 'Lorem ipsum dolor sit amet consectetur', 
                          attributes: { class: 'context'}
                      }
                    ]
                  },
                  {
                    type: 'div',
                    attributes: { class: 'd-flex align-center d-sm-column'}, 
                    components: [
                      {
                        type: 'div', 
                        attributes: { class: 'd-flex align-center'}, 
                        components: [
                          {
                            type: 'text', 
                            content: '+', 
                            attributes: { class: 'prefix font-40'}
                        }, 
                        { 
                            type:'counters',
                            attributes:{class:'counter-value font-40'},
                        }, 
                        ]
                      },
                     
                      {
                          type: 'text', 
                          content: 'Lorem ipsum dolor sit amet consectetur', 
                          attributes: { class: 'context'}
                      }
                    ]
                  },
      
              ],
              styles: `
             .w-100{
                width: 100%; 
             }
             .p-40{
                padding: 40px; 
             }
             .d-flex{
                display: flex; 
             }
             .gap-50{
                gap: 50px; 
             }
             .bg-cinza{
                background: #E6E6E6; 
             }
             .align-center{
                align-items: center; 
             }
             .font-40{
                font-size: 40px; 
             }
             .margin-top-50{
                margin-top:50px;
             }
             .prefix, .counter-value{
                color: #363940; 
             }
             .context{
                font-size: 16px; 
                color: #363940;
                margin-left: 10px;
             }
             @media (max-width: 992px){
                .d-sm-column{
                    flex-direction: column; 
                }
             }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};