import block from "./block.js";
import component from "./components/index.js"
import containerBlog4 from "./components/containerBlog4.js";
import itemBlog4 from "./components/itemBlog4.js";


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerBlog4", containerBlog4(opts));
  editor.DomComponents.addType("itemBlog4", itemBlog4(opts));
  editor.DomComponents.addType("blog4", component(opts));
    //block
  editor.BlockManager.add("blog4", block(opts));
};