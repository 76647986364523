import block from "./block.js";
import component from "./components/index.js"
import columnImageNumbers5 from "./components/columnImageNumbers5.js";
import columnNumbers5 from "./components/columnNumbers5.js";
import columnsNumbers5 from "./components/columnsNumbers5.js";
import containerNumbers5 from "./components/containerNUmbers5.js";
import counters5 from "./components/counters5.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("bigNumber5", component(opts));
  editor.DomComponents.addType("columnImageNumbers5", columnImageNumbers5(opts));
  editor.DomComponents.addType("columnNumbers5", columnNumbers5(opts));
  editor.DomComponents.addType("columnsNumbers5", columnsNumbers5(opts));
  editor.DomComponents.addType("containerNumbers5", containerNumbers5(opts));
  editor.DomComponents.addType("counters5", counters5(opts));

    //block
  editor.BlockManager.add("bigNumber5", block(opts));
};