export default (opts = {}) => {
  const block = {
    category: 'CTA',
    id: "cta3",
    label: "CTA (3)",
    fromPlugin: 'blocks',
    apper : 'out',
    media: '<svg class="preview-block" fill="none" viewBox="0 0 266 150" width="266"  height="150" ><path fill="#FFFFFF" d="M0 0h266v150H0z"></path><rect x="90" y="48" width="86" height="5" rx="2.5" fill="#4A5568"></rect><rect x="80" y="57" width="106" height="4" rx="2" fill="#A0AEC0"></rect><rect x="85" y="65" width="97" height="4" rx="2" fill="#A0AEC0"></rect><rect x="183" y="92" width="44" height="10" rx="2" fill="#6366F1"></rect><rect x="111" y="92" width="66" height="10" rx="2" fill="#CBD5E0"></rect><rect x="39" y="92" width="66" height="10" rx="2" fill="#CBD5E0"></rect></svg>',
    content: { type: 'cta3' },
  };

  return block;
};