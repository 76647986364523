import block from "./block.js";
import component from "./components/index.js"
import columnsBlog from "./components/columnsBlog.js";
import containerBlog from "./components/containerBlog.js";
import itemBlog from "./components/itemBlog.js";


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("columnsBlog", columnsBlog(opts));
  editor.DomComponents.addType("containerBlog", containerBlog(opts));
  editor.DomComponents.addType("itemBlog", itemBlog(opts));
  editor.DomComponents.addType("blog1", component(opts));
    //block
  editor.BlockManager.add("blog1", block(opts));
};