export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type: 'Container',
              name: "Container Conteudos",
              attributes: { class: 'container-cubo flex flex-col items-center' },
              components: [
                {
                  type: 'div',
                  attributes: { class: 'flex flex-col text-center items-center w-full' },
                  components: [
                    {
                      type: 'title2', 
                      components: [
                        {
                          type:'text',
                          name:'H2',
                          components: 'Lorem Ipsum',
                          attributes: { class: 'subtitle' },
                        },
                      ],
                    }, 
                    {
                      type: 'title3', 
                      components: [
                        {
                          type:'text',
                          name:'H3',
                          components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                          attributes: { class: 'principal-title' },
                        },
                      ],
                    }, 
                    {
                      type: 'text', 
                      attributes: { class: 'body-text w-66' },
                      components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus.'
                    }
                  ]
                },
                {
                  type: 'div', 
                  attributes: { class: 'flex sm-flex-col items-center gap-20 mt-14' },
                  components: [
                    {
                      type: 'default', 
                      name: 'divider', 
                      attributes: { class: 'divider-content1' },
                    },
                    {
                      type: 'div', 
                      attributes: { class: 'item-content1' },
                      components: [
                        {
                          type: 'title4', 
                          components: [
                            {
                              type:'text',
                              name:'H4',
                              components: 'Lorem Ipsum',
                              attributes: { class: 'title-item-content1' },
                            },
                          ],
                        }, 
                        {
                          type: 'text', 
                          attributes: { class: 'text-item-content1' },
                          components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus.'
                        },
                        {
                          type: 'link', 
                          attributes: { class: 'know-content1' },
                          components: 'Saiba mais'
                        }

                      ]
                    },
                    {
                      type: 'default', 
                      name: 'divider', 
                      attributes: { class: 'divider-content1' },
                    },
                    {
                      type: 'div', 
                      attributes: { class: 'item-content1' },
                      components: [
                        {
                          type: 'title4', 
                          components: [
                            {
                              type:'text',
                              name:'H4',
                              components: 'Lorem Ipsum',
                              attributes: { class: 'title-item-content1' },
                            },
                          ],
                        }, 
                        {
                          type: 'text', 
                          attributes: { class: 'text-item-content1' },
                          components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus.'
                        },
                        {
                          type: 'link', 
                          attributes: { class: 'know-content1' },
                          components: 'Saiba mais'
                        }

                      ]
                    },
                    {
                      type: 'default', 
                      name: 'divider', 
                      attributes: { class: 'divider-content1' },
                    },
                    {
                      type: 'div', 
                      attributes: { class: 'item-content1' },
                      components: [
                        {
                          type: 'title4', 
                          components: [
                            {
                              type:'text',
                              name:'H4',
                              components: 'Lorem Ipsum',
                              attributes: { class: 'title-item-content1' },
                            },
                          ],
                        }, 
                        {
                          type: 'text', 
                          attributes: { class: 'text-item-content1' },
                          components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus.'
                        },
                        {
                          type: 'link', 
                          attributes: { class: 'know-content1' },
                          components: 'Saiba mais'
                        }

                      ]
                    },
                    {
                      type: 'default', 
                      name: 'divider', 
                      attributes: { class: 'divider-content1' },
                    },
                    {
                      type: 'div', 
                      attributes: { class: 'item-content1' },
                      components: [
                        {
                          type: 'title4', 
                          components: [
                            {
                              type:'text',
                              name:'H4',
                              components: 'Lorem Ipsum',
                              attributes: { class: 'title-item-content1' },
                            },
                          ],
                        }, 
                        {
                          type: 'text', 
                          attributes: { class: 'text-item-content1' },
                          components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus.'
                        },
                        {
                          type: 'link', 
                          attributes: { class: 'know-content1' },
                          components: 'Saiba mais'
                        }

                      ]
                    },
                  ]
                },
                {
                  type: 'button3', 
                  attributes: { class: 'btn-cubo2'}
                }
      
              ],
              styles: `
             .container-cubo{
                max-width: 1600px; 
             }
             .flex{
               display: flex;
             }
            .flex-col{
               flex-direction: column; 
            }
            .items-center{
              align-items: center; 
            }
            .text-center{
               text-align: center; 
            }
            .w-full{
               width: 100%; 
             }
           .subtitle{
                font-size: 13px; 
                color: #747474;
                margin-top:15px; 
               }
             .principal-title{
            font-size: 30px;
            color: #363940;
            margin:0;
           }
            .body-text{
            font-size: 16px;
            color: #363940;
            margin-top:30px;
           }
            .w-66{
              width: 66%; 
            }
            .gap-20{
              gap: 20px; 
             }
            .item-content1{
              width: 24%; 
              display: flex; 
              flex-direction: column; 
              align-items: flex-start; 
            }
            .title-item-content1{
              font-size: 1.25rem; 
              line-height: 1.75rem; 
              color: #111827;
              font-weight: 500; 
              margin-bottom: 0.5rem; 
            }
            .text-item-content1{
              line-height: 1.625; 
              font-size: 1rem; 
              margin-bottom: 1rem; 
              color: #4B5563;
            }
            .know-content1{
              color: #3B82F6;
              font-size: 1rem; 
            }
            .btn-cubo2{
              margin-top: 50px; 
            }
            .mt-14{
              margin-top: 56px; 
            }
            .divider-content1{
              width: 2px; 
              height: 300px; 
              background-image: linear-gradient(#e5e7eb 0%, #e5e7eb 100%); 
            }

            @media (max-width: 992px){
            .w-66{
               width: 100%; 
            }
            .sm-flex-col{
              flex-direction: column; 
            }
              .item-content1{
                width: 100%; 
              }
                .divider-content1{
              width: 100%; 
              height: 2px; 
              background-image: linear-gradient(#e5e7eb 0%, #e5e7eb 100%); 
            }
            }
            
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};