import block from "./block.js";
import component from "./components/index.js"
import containerPricing from "./components/containerPricing.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerPricing", containerPricing(opts));
  editor.DomComponents.addType("pricing1", component(opts));
    //block
  editor.BlockManager.add("pricing1", block(opts));
};