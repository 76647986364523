export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: "Div",
              attributes: { class: 'checklist' },
              components: [
                {
                  type:'containerCheckList'
                },
                {
                  type:'containerCheckList'
                }          
              ],
              styles: `
             .checklist{
              width:100%;
              display:flex;
             }
             @media (max-width:992px){
              .checklist{
                display:block;
               }
             }
            `,
             },
          },
          view: {
      
          },
    };
  
    return component;
  };
  