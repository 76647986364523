export default (opts = {}) => {
  const script = function () {
    const intLib = function () {
      const swiper3 = new Swiper(".mySwiper3", {
        spaceBetween: 0,
        slidesPerView: 1,
        breakpoints: {
          640: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: 'bullets'
        },
        navigation: {
          nextEl: ".button-next-3",
          prevEl: ".button-prev-3"
        }
      });
    };
    if ("undefined" == typeof Swiper) {
      const cdn = document.createElement("script");
      const sty = document.createElement("link");
      cdn.onload = intLib;
      sty.onload = intLib; 
      sty.href = "https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.css"
      sty.rel ="stylesheet"
      cdn.src = "https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.js";
      document.head.appendChild(cdn);
      document.head.appendChild(sty);
    }
    else {
      intLib();
    }
  };
  const component = {
    model: {
      defaults: {
        script,
        name: 'Slider',
        classes: ['swiper', 'mySwiper3'],
        components: [
          {
            name: 'Conainer',
            classes: ['swiper-wrapper'],
            components: [
              {
                name: 'Slide',
                classes: ['swiper-slide'],
                style: {
                  'height': '200px',
                  'padding': '20px 20px 20px 20px',
                  'display': 'flex',
                  'flex-direction': 'column',
                  'align-items': 'center',
                  'justify-content': 'center',
                  'background-color': 'rgba(0, 0, 0, 0.26)'
                },
                components: [
                  {
                    type: 'text',
                    content: 'Slider 1'
                  }
                ]
              },
              {
                name: 'Slide',
                classes: ['swiper-slide'],
                style: {
                  'height': '200px',
                  'padding': '20px 20px 20px 20px',
                  'display': 'flex',
                  'flex-direction': 'column',
                  'align-items': 'center',
                  'justify-content': 'center',
                  'background-color': 'rgba(0, 0, 0, 0.26)'
                },
                components: [
                  {
                    type: 'text',
                    content: 'Slider 2'
                  }
                ]
              },
              {
                name: 'Slide',
                classes: ['swiper-slide'],
                style: {
                  'height': '200px',
                  'padding': '20px 20px 20px 20px',
                  'display': 'flex',
                  'flex-direction': 'column',
                  'align-items': 'center',
                  'justify-content': 'center',
                  'background-color': 'rgba(0, 0, 0, 0.26)'
                },
                components: [
                  {
                    type: 'text',
                    content: 'Slider 3'
                  }
                ]
              },
              {
                name: 'Slide',
                classes: ['swiper-slide'],
                style: {
                  'height': '200px',
                  'padding': '20px 20px 20px 20px',
                  'display': 'flex',
                  'flex-direction': 'column',
                  'align-items': 'center',
                  'justify-content': 'center',
                  'background-color': 'rgba(0, 0, 0, 0.26)'
                },
                components: [
                  {
                    type: 'text',
                    content: 'Slider 4'
                  }
                ]
              },
              {
                name: 'Slide',
                classes: ['swiper-slide'],
                style: {
                  'height': '200px',
                  'padding': '20px 20px 20px 20px',
                  'display': 'flex',
                  'flex-direction': 'column',
                  'align-items': 'center',
                  'justify-content': 'center',
                  'background-color': 'rgba(0, 0, 0, 0.26)'
                },
                components: [
                  {
                    type: 'text',
                    content: 'Slider 5'
                  }
                ]
              },
              {
                name: 'Slide',
                classes: ['swiper-slide'],
                style: {
                  'height': '200px',
                  'padding': '20px 20px 20px 20px',
                  'display': 'flex',
                  'flex-direction': 'column',
                  'align-items': 'center',
                  'justify-content': 'center',
                  'background-color': 'rgba(0, 0, 0, 0.26)'
                },
                components: [
                  {
                    type: 'text',
                    content: 'Slider 6'
                  }
                ]
              },
            ]
          },
          {
            name: 'Próximo',
            classes: ['button-next-3'],
            style: {
              'position':'absolute',
              'right':'0',
              'top':'50%',
              'z-index':'9',
            },
            components: [
              {
                type: 'image', 
                attributes: { src: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAA3NCSVQICAjb4U/gAAAACXBIWXMAAADmAAAA5gEiXLpRAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAFRQTFRF////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAsI6hEwAAABt0Uk5TAAECAwwOGx4xPEJpgoabnp+goaKjpMDB5fz+/OuR+AAAAGBJREFUOMvt0rcOgEAMA9DQezvaHf7//2RghRihjHj1kxIpEbmSDr5LRMkGYFVEBQBY4keQByaa46WYDYQzEJOBGA3EQEUvTJSfARvBlvx7vS8COXLNniDa9V4kc7697U9vSxXnRfolUwAAAABJRU5ErkJggg=='}, 
              }
            ]
          },
          {
            name: 'Anterior',
            classes: ['button-prev-3'],
            style: {
              'position':'absolute',
              'left':'0',
              'top':'50%',
              'z-index':'9',
            },
            components: [
              {
                type: 'image', 
                attributes: { src: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAA3NCSVQICAjb4U/gAAAACXBIWXMAAADmAAAA5gEiXLpRAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAFRQTFRF////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAsI6hEwAAABt0Uk5TAAECAwwOGx4xPEJpgoabnp+goaKjpMDB5fz+/OuR+AAAAGJJREFUOMvtzskOgCAMRdEKzhOKE/j+/z/duJTWmC59K5J7kkL0MNMHX1B6xgE4Mr4DrdBjxfez+/unTqPQa6HLQDwhf5LMpCBmBeEVxKIg1pcilpJokoDsBmAnZnYILr/fF/LsFedf9J6UAAAAAElFTkSuQmCC'}, 
              }
            ]
          },
        ],
      },
      styles: `
              *{
                font-family: Roboto, Arial, sans-serif;
              }
              .swiper {
                width: 100%;
                height: 100%;
              }
             `,
    },
    view: {

    }

  };
  return component;
};