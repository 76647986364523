import block from "./block.js";
import component from "./components/index.js"
import containerCta4 from "./components/containerCta4.js";


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerCta4", containerCta4(opts));
  editor.DomComponents.addType("cta4", component(opts));
    //block
  editor.BlockManager.add("cta4", block(opts));
};