export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        type: 'Container',
        name: "Container Contato",
        attributes: { class: 'container-cubo flex sm-flex-col gap-40' },
        components: [
          {
            type: 'contactMap2'
          },
          {
            type: 'default',
            attributes: { class: 'divform-contact2' },
            components: [
              {
                type: 'title2',
                components: [
                  {
                    type:'text',
                    name:'H2',
                    components: 'FeedBack',
                    attributes: { class: 'title-contact2' },
                  },
                ],
              },
              {
                type: 'text',
                attributes: { class: 'text-contact2' },
                components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              },
              {
                type: 'formNew'
              }

            ]
          }
        ],
        styles: `
       .container-cubo{
          max-width: 1600px; 
       }
       .flex{
         display: flex; 
        }
       .gap-40{
         gap: 40px;
       }
      .divform-contact2{
         width: 33%; 
         background-image: linear-gradient(#ffffff 0%, #ffffff 100%); 
         display: flex; 
         flex-direction: column; 
         align-items: flex-start;
      }
      .title-contact2{
         color: #111827; 
         font-weight: 500; 
         font-size: 1.125rem; 
         line-height: 1.75rem; 
         margin-bottom: 0.25rem; 
       }
      .text-contact2{
        line-height: 1.625; 
        margin-bottom: 0.75rem; 
        font-size: 16px; 
        color: #4B5563; 
      }
       @media (max-width: 992px){
        .sm-flex-col{
         flex-direction: column; 
        }
         .divform-contact2{
           width: 100%; 
         }
        
       }
      `,
      },
    },
    view: {

    },
  };

  return component;
};
