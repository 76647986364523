export default (opts = {}) => {
    const block = {
      id: "checkList",
      label: "Check-List",
      fromPlugin: 'extra',
      apper : 'out',
      media: '<i class="bi bi-ui-checks"></i>',
      content: { type: "checkList" },
    };
  
    return block;
  };
  

  
