import block from "./block.js";
import component from "./components/index.js"
import containerContent4 from "./components/containerContent4.js";


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerContent4", containerContent4(opts));
  editor.DomComponents.addType("content4", component(opts));
    //block
  editor.BlockManager.add("content4", block(opts));
};