import block from "./block.js";
import component from "./components/index.js"
import containerGallery2 from "./components/containerGallery2.js";


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerGallery2", containerGallery2(opts));
  editor.DomComponents.addType("gallery2", component(opts));
    //block
  editor.BlockManager.add("gallery2", block(opts));
};