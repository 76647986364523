export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: 'Colunas Depoimentos',
              attributes: { class: 'row-full' },
              components: [
                {
                  type: 'columnTestimony1'
                },
                {
                  type: 'columnImageTestimony'
                } 
              ],
              styles: `
             .row-full{
                display: flex; 
                flex-direction:row; 
                width:100vw; 
             }
             @media (max-width:992px){
              .row-full{
                flex-direction:column;
              }
             }
            `,
             },
          },
          view: {
      
          },
    }; 
    return component; 
};