export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        type: 'Container',
        name: "Container Cabeçalho",
        attributes: { class: 'container-cubo flex sm-flex-col items-center gap-15 justify-between' },
        components: [
          {
            type: 'div', 
            attributes: { class: 'flex items-center gap-15 sm-flex-col' },
            components: [
              {
                type: 'image', 
                attributes: { class: 'logo-header', src: 'https://dummyimage.com/40x40' },
              },
              {
                type: 'div',
                attributes: { class: 'separator-header' },
              },
              {
                type: 'div',
                attributes: { class: 'flex flex-wrap items-center gap-15' },
                components: [
                  {
                    type: 'link', 
                    attributes: { class: 'link-header' },
                    components: 'Lorem'
                  },  
                  {
                    type: 'link', 
                    attributes: { class: 'link-header' },
                    components: 'Lorem'
                  },  
                  {
                    type: 'link', 
                    attributes: { class: 'link-header' },
                    components: 'Lorem'
                  },  
                  {
                    type: 'link', 
                    attributes: { class: 'link-header' },
                    components: 'Lorem'
                  },  
                ]
              },
              {
                type: 'button3', 
              }
            ]
          },
          
        ],
        styles: `
             .container-cubo{
                max-width: 1600px; 
             }
            .flex{
              display: flex; 
            }
            .items-center{
              align-items: center; 
            }
            .justify-between{
              justify-content: space-between; 
            }
            .gap-15{
              gap: 15px; 
            }
            .logo-header{
              width: 40px; 
              height: 40px; 
              border-radius: 100%; 
            }
            .separator-header{
              width: 1px; 
              height: 45px; 
              background-image: linear-gradient(#e4e4e4 0%, #e4e4e4 100%);
            }
            .link-header{
               font-size: 14px; 
               color: #363940;
               text-decoration: none; 
            }
            .flex-wrap{
              flex-wrap: wrap; 
            }

           @media (max-width: 992px){
             .sm-flex-col{
               flex-direction: column; 
             }
            .separator-header{
              width: 45px; 
              height: 1px; 
            }
           }
            `,
      },
    },
    view: {

    },
  };
  return component;
};