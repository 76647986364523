import block from "./block.js";
import component from "./components/index.js"
import cardDifferences4 from "./components/cardDifferences4.js";
import columnDifferences4 from "./components/columnDifferences4.js";
import columnImgDifferences4 from "./components/columnImgDifferences4.js";
import columnsDifferences4 from "./components/columnsDifferences4.js";
import containerDifferences4 from "./components/containerDifferences4.js";
import rowCardDifferences4 from "./components/rowCardDifferences4.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("differences4", component(opts));
  editor.DomComponents.addType("cardDifferences4", cardDifferences4(opts));
  editor.DomComponents.addType("columnDifferences4", columnDifferences4(opts));
  editor.DomComponents.addType("columnImgDifferences4", columnImgDifferences4(opts));
  editor.DomComponents.addType("columnsDifferences4", columnsDifferences4(opts));
  editor.DomComponents.addType("containerDifferences4", containerDifferences4(opts));
  editor.DomComponents.addType("rowCardDifferences4", rowCardDifferences4(opts));

    //block
  editor.BlockManager.add("differences4", block(opts));
};