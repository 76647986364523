export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: 'Item Prints',
              attributes: { class: 'item-print' },
              components: [
                {
                  tagname:'img',
                  type:'image',
                  attributes: {class: 'img-print w-100', src:'https://placehold.co/460x300'}, 
                },
                {
                  type:'text',
                  content:'Print Google Meu negócio',
                  attributes: {class: 'title-print'}, 
                },
                {
                  type:'text',
                  content:'Jan 31',
                  attributes: {class: 'date-print'}, 
                },
                
              ],
              styles: `
               .img-print.w-100{
                max-width:100%;
                width:100%;
               }
               .title-print{
                font-size:16px;
                color:#363940;
                margin-top: 20px;
               }
              .date-print{
                font-size:12px; 
                color: #95A1BB;
                font-weight: 500;
                text-transform: uppercase;
                margin-top:13px;
              }
            `,
             },
          },
          view: {
      
          },
    }; 
    return component; 
};