export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: 'Coluna Serviços',
              attributes: { class: 'w-25 w-sm-100 d-col-r gap-80' },
              components: [
               {
                type:'cardServicesRigth4',
               },
               {
                type:'cardServicesRigth4',
               },
               {
                type:'cardServicesRigth4',
               },
              ],
              styles: `
              .w-25{
                width:25%; 
              }
              .d-col-r{
                display: flex; 
                flex-direction: column; 
                align-items: flex-end; 
              }
              .gap-80{
                gap: 80px; 
              }
      
              @media (max-width:992px){
                .w-sm-100{
                  width: 100% !important; 
                }
              }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};