export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: 'Coluna Produtos',
              attributes: { class: 'column column-60 p-20' },
              components: [
               {
                type: 'rowProducts4'
               }
              ],
              styles: `
               
            `,
             },
          },
          view: {
      
          },
    };
  
    return component;
  };
  