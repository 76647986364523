export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              name: "Coluna FAQ",
              tagname: 'div',
              attributes: { class: 'column column-60 position-relative p-20' },
              components: [
                {
                    type: 'divAccordion4'
                }
              ],
              styles: `
              
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};