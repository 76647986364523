export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        type: 'Container',
        name: "Container Preços",
        attributes: { class: 'container-cubo' },
        components: [
          {
            type: 'div',
            attributes: { class: 'flex flex-col items-center' },
            components: [
              {
                type: 'title2',
                components: [
                  {
                    type: 'text',
                    name: 'H2',
                    components: 'Preços',
                    attributes: { class: 'principal-title' },
                  },
                ],
              },
              {
                type: 'text',
                attributes: { class: 'body-text w-80 text-center' },
                components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae'
              }
            ]
          },
          {
            type: 'div',
            attributes: { class: 'flex gap-20 sm-flex-col mt-50' },
            components: [
              {
                type: 'div',
                attributes: { class: 'item-price-1' },
                components: [
                  {
                    type: 'text',
                    attributes: { class: 'category-price-1' },
                    components: 'Inicial'
                  },
                  {
                    type: 'text',
                    attributes: { class: 'title-price-1' },
                    components: 'Grátis'
                  },
                  {
                    type: 'checkList'
                  },
                  {
                    type: 'button3',
                    attributes: { class: 'mt-20' },
                  },
                  {
                    type: 'text',
                    attributes: { class: 'text-info-price' },
                    components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                  }
                ]
              },
              {
                type: 'div',
                attributes: { class: 'item-price-1' },
                components: [
                  {
                    type: 'text',
                    attributes: { class: 'category-price-1' },
                    components: 'Inicial'
                  },
                  {
                    type: 'text',
                    attributes: { class: 'title-price-1' },
                    components: 'Grátis'
                  },
                  {
                    type: 'checkList'
                  },
                  {
                    type: 'button3',
                    attributes: { class: 'mt-20' },
                  },
                  {
                    type: 'text',
                    attributes: { class: 'text-info-price' },
                    components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                  }
                ]
              },
              {
                type: 'div',
                attributes: { class: 'item-price-1' },
                components: [
                  {
                    type: 'text',
                    attributes: { class: 'category-price-1' },
                    components: 'Inicial'
                  },
                  {
                    type: 'text',
                    attributes: { class: 'title-price-1' },
                    components: 'Grátis'
                  },
                  {
                    type: 'checkList'
                  },
                  {
                    type: 'button3',
                    attributes: { class: 'mt-20' },
                  },
                  {
                    type: 'text',
                    attributes: { class: 'text-info-price' },
                    components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                  }
                ]
              },
              {
                type: 'div',
                attributes: { class: 'item-price-1' },
                components: [
                  {
                    type: 'text',
                    attributes: { class: 'category-price-1' },
                    components: 'Inicial'
                  },
                  {
                    type: 'text',
                    attributes: { class: 'title-price-1' },
                    components: 'Grátis'
                  },
                  {
                    type: 'checkList'
                  },
                  {
                    type: 'button3',
                    attributes: { class: 'mt-20' },
                  },
                  {
                    type: 'text',
                    attributes: { class: 'text-info-price' },
                    components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                  }
                ]
              },
            ]
          },

        ],
        styles: `
             .container-cubo{
                max-width: 1600px; 
             }
             .flex{
               display: flex; 
             }
             .flex-col{
               flex-direction: column; 
             }
             .items-center{
               aling-items: center; 
             }
               .principal-title{
            font-size: 30px;
            color: #363940;
            margin:0;
           }
            .body-text{
            font-size: 16px;
            color: #363940;
            margin-top:30px;
           }
          .w-80{
            width: 80%; 
          }
          .text-center{
             text-align: center; 
          }
          .gap-20{
            gap: 20px; 
          }
          .mt-50{
            margin-top: 50px; 
          }
          .mt-20{
            margin-top: 20px; 
          }
          .item-price-1{
            width: 25%; 
            border: 1px solid #707070; 
            border-radius: 5px; 
            padding: 20px; 
            display: flex; 
            flex-direction: column; 
            align-items: flex-start; 
          }
          .category-price-1{
            font-size: 13px; 
            color: #363940; 
            margin-bottom: 10px; 
          }
          .title-price-1{
            font-size: 25px; 
            color: #000; 
            margin-bottom: 20px; 
          }
          .text-info-price{
             font-size: 12px; 
             color: #6b7280; 
             margin-top: 20px;
          }
          @media (max-width: 992px){
            .w-80{
              width: 100%; 
            }
            .sm-flex-col{
              flex-direction: column; 
            }
            .item-price-1{
               width: 100%; 
            }
          }
            `,
      },
    },
    view: {

    },
  };
  return component;
};