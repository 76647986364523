export default (opts = {}) => {
    const component = {
      model: {
        defaults: {
          type: 'Container', 
          name: "Container Unidades",
          attributes: { class: 'container-cubo d-flex d-sm-column position-relative bg-cinza p-50 p-sm-20' },
          components: [
            {
              type: 'units1',
            },
            {
              type: 'imageLocation1',
            },
          ],
          styles: `
           .container-cubo{
            max-width: 1600px; 
         }
         .d-flex{
          display:flex;
         }
         .position-relative{
          position:relative;
         }
         .bg-cinza{
          background:#F8F8F8; 
         }
         .p-50{
          padding:50px;
         }
  
         @media (max-width:992px){
          .p-sm-20{
            padding:20px !important; 
          }
          .d-sm-column{
            flex-direction:column; 
          }
         }
  
        `,
         },
      },
      view: {
  
      },
};
  return component; 
};