import block from "./block.js";
import component from "./components/index.js"
import containerHeader3 from "./components/containerHeader3.js";


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerHeader3", containerHeader3(opts));
  editor.DomComponents.addType("header3", component(opts));
    //block
  editor.BlockManager.add("header3", block(opts));
};