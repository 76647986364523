export default (opts = {}) => {
  const block = {
    category: 'Contato',
    id: "contact3",
    label: "Contato (3)",
    apper: 'out',
    media: '<svg class="preview-block" fill="none" viewBox="0 0 266 150" width="266"  height="150" ><path fill="#FFFFFF" d="M0 0h266v150H0z"></path><rect x="113" y="117" width="40" height="10" rx="2" fill="#6366F1"></rect><rect x="63" y="81" width="140" height="30" rx="2" fill="#CBD5E0"></rect><rect x="63" y="65" width="66" height="10" rx="2" fill="#CBD5E0"></rect><rect x="135" y="65" width="68" height="10" rx="2" fill="#CBD5E0"></rect><rect x="90" y="24" width="86" height="5" rx="2.5" fill="#4A5568"></rect><rect x="80" y="33" width="106" height="4" rx="2" fill="#A0AEC0"></rect><rect x="85" y="41" width="97" height="4" rx="2" fill="#A0AEC0"></rect></svg>',
    fromPlugin: 'blocks',
    content: { type: 'contact3' },
  };

  return block;
};