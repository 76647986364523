import block from "./block.js";
import component from "./components/index.js"
import columnImageMarketing1 from "./components/columnImageMarketing1.js";
import columnMarketing1 from "./components/columnMarketing1.js";
import columnsMarketing1 from "./components/columnsMarketing1.js";
import containerMarketing1 from "./components/containerMarketing1.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("marketing1", component(opts));
  editor.DomComponents.addType("columnImageMarketing1", columnImageMarketing1(opts));
  editor.DomComponents.addType("columnMarketing1", columnMarketing1(opts));
  editor.DomComponents.addType("columnsMarketing1", columnsMarketing1(opts));
  editor.DomComponents.addType("containerMarketing1", containerMarketing1(opts));

    //block
  editor.BlockManager.add("marketing1", block(opts));
};