export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: 'Item Selos',
              attributes: { class: 'stamps-container-2' },
              components: [
              {
                type: 'rowStamps2', 
              },
              {
                type: 'rowStamps2', 
              },
              ],
              styles: `
               .stamps-container-2{
                width:100%; 
               }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};