export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              name: "Div",
              attributes: { class: 'tabs' },
              components: [
                {
                  type: 'tabLink',
                },
                {
                  type: 'tabLink'
                },
                {
                  type: 'tabLink'
                },
                {
                  type: 'tabLink'
                },
              ],
              styles: `
              .tabs{
                width:30%; 
                padding:20px 20px 20px 30px;
                border: 1px solid #D9DEEA;
                border-radius:4px; 
              }
              @media (max-width:992px){
                .tabs{
                  width:100%; 
                }
              }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};