import block from "./block.js";
import component from "./components/index.js"
import containerStatistics2 from "./components/containerStatistics2.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerStatistics2", containerStatistics2(opts));
  editor.DomComponents.addType("statistics2", component(opts));
    //block
  editor.BlockManager.add("statistics2", block(opts));
};