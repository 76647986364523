export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        type: 'Container',
        name: "Container Preços",
        attributes: { class: 'container-cubo' },
        components: `
        <div class="flex sm-flex-col gap-30">
        <div class="item-statistic-1">
        <h2 class="principal-title">2.7K</h2>
        <p class="desc-statistic-1">Usuarios</p></div>
        <div class="item-statistic-1">
        <h2 class="principal-title">1.8K</h2>
        <p class="desc-statistic-1">Inscritos</p></div><div class="item-statistic-1">
        <h2 class="principal-title">35</h2>
        <p class="desc-statistic-1">Downloads</p></div><div class="item-statistic-1">
        <h2 class="principal-title">4</h2>
        <p class="desc-statistic-1">Produtos</p></div></div>
        `,
        styles: `
             .container-cubo{
                max-width: 1600px; 
             }
             .flex{
               display: flex; 
             }
            .gap-30{
              gap: 30px; 
            }
            .item-statistic-1{
              width: 25%; 
              display: flex; 
              flex-direction: column; 
              align-items: center; 
              justify-content: center; 
            }
            .principal-title{
            font-size: 30px;
            color: #363940;
            margin:0;
           }
          .desc-statistic-1{
               font-size: 15px; 
               color: #707070; 
               margin-top: 15px; 
            }
              
             @media (max-width: 992px){
               .sm-flex-col{
                 flex-direction: column; 
               }
               .item-statistic-1{
                    width: 100%; 
                  }
             }
            `,
      },
    },
    view: {

    },
  };
  return component;
};