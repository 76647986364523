export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type: 'div', 
              name: "Portifolio",
              attributes: { class: 'w-40 w-sm-100' },
              components: [
                {
                  type:'text', 
                  content: 'Subtítulo', 
                  name:'Subtitulo',
                  attributes: {class:'subtitle text-uppercase'},
                }, 
                {
                  tagName: 'h2',
                  type:'text',
                  name:'Titulo',
                  components: 'Portfolio do cliente',
                  attributes: {class: 'principal-title'}, 
                  traits: [
                    {
                      type: "select",
                      options: [
                        { value: "h1", name: opts.labelN1 },
                        { value: "h2", name: opts.labelN2 },
                        { value: "h3", name: opts.labelN3 },
                        { value: "h4", name: opts.labelN4 },
                        { value: "h5", name: opts.labelN5 },
                        { value: "h6", name: opts.labelN6 },
                      ],
                      label: 'Tamanho',
                      name: "tagName",
                      changeProp: 1,
                    },
                    {
                      type:'id',
                      name:'id',
                      Label:'id',
                    }
                  ],
                },
                {
                  type: 'text',
                  content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et bibendum risus. Cras faucibus viverra dolor, at vehicula erat tempus vel. Sed a velit ',
                  attributes: {class: 'body-text text-center'},
                },
                {
                  type:'div', 
                  attributes: { class: 'd-flex w-100 margin-top-30' },
                  components: [
                    {
                      type: 'image',
                      attributes: { class: 'w-60', src:'https://placehold.co/398x437' },
                    },
                    {
                      type: 'div',
                      attributes: { class: 'w-40 margin-left-16' },
                      components: [
                        {
                          type: 'image', 
                          attributes: { class: 'w-100', src:'https://placehold.co/260x259' },
                        },
                        {
                          type: 'image', 
                          attributes: { class: 'w-50 margin-top-16', src:'https://placehold.co/130x129' },
                        },
                      ],
                    },
                  ],
                }
              ],
              styles: `
               .w-40{
                width:40%; 
               }
               .margin-top-30{
                margin-top:30px; 
               }
               .principal-title{
                font-size: 30px; 
                color: #363940; 
                margin:0;
               }
               .body-text{
                font-size: 16px; 
                color: #363940; 
                margin-top:30px;
               }
               .subtitle{
                font-size: 13px; 
                color: #747474;
                margin-top:15px; 
               }
               .text-uppercase{
                text-transform: uppercase;
               }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};