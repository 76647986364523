import block from "./block.js";
import component from "./components/index.js"
import columnItemStamps2 from "./components/columnItemStamps2.js";
import columnsStamps2 from "./components/columnsStamps2.js";
import columnStamps2 from "./components/columnStamps2.js";
import containerStamps2 from "./components/containerStamps2.js";
import itemStamps2 from "./components/itemStamps2.js";
import rowStamps2 from "./components/rowStamps2.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("stamps2", component(opts));
  editor.DomComponents.addType("columnItemStamps2", columnItemStamps2(opts));
  editor.DomComponents.addType("columnsStamps2", columnsStamps2(opts));
  editor.DomComponents.addType("columnStamps2", columnStamps2(opts));
  editor.DomComponents.addType("containerStamps2", containerStamps2(opts));
  editor.DomComponents.addType("itemStamps2", itemStamps2(opts));
  editor.DomComponents.addType("rowStamps2", rowStamps2(opts));


    //block
  editor.BlockManager.add("stamps2", block(opts));
};