export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        type: 'Container',
        name: "Container E-commerce",
        attributes: { class: 'container-cubo flex flex-col gap-20' },
        components: [
          {
            type: 'div',
            attributes: { class: 'flex sm-flex-col gap-20' },
            components: [
              {
                type: 'div',
                attributes: { class: 'item-eccomerce' },
                components: [
                  {
                    type: 'image',
                    attributes: { class: 'image-eccommerce', src: 'https://dummyimage.com/420x260' },
                  },
                  {
                    type: 'div',
                    attributes: { class: '' },
                    components: [
                      {
                        type: 'title2',
                        components: [
                          {
                            type: 'text',
                            name: 'H2',
                            components: 'Categoria',
                            attributes: { class: 'title-eccommerce' },
                          },
                        ],
                      },
                      {
                        type: 'title3',
                        components: [
                          {
                            type: 'text',
                            name: 'H3',
                            components: 'Lorem ipsum',
                            attributes: { class: 'title-eccommerce' },
                          },
                        ],
                      },
                      {
                        type: 'text',
                        attributes: { class: 'price-eccommerce' },
                        components: 'R$16,00'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'div',
                attributes: { class: 'item-eccomerce' },
                components: [
                  {
                    type: 'image',
                    attributes: { class: 'image-eccommerce', src: 'https://dummyimage.com/420x260' },
                  },
                  {
                    type: 'div',
                    attributes: { class: '' },
                    components: [
                      {
                        type: 'title2',
                        components: [
                          {
                            type: 'text',
                            name: 'H2',
                            components: 'Categoria',
                            attributes: { class: 'title-eccommerce' },
                          },
                        ],
                      },
                      {
                        type: 'title3',
                        components: [
                          {
                            type: 'text',
                            name: 'H3',
                            components: 'Lorem ipsum',
                            attributes: { class: 'title-eccommerce' },
                          },
                        ],
                      },
                      {
                        type: 'text',
                        attributes: { class: 'price-eccommerce' },
                        components: 'R$16,00'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'div',
                attributes: { class: 'item-eccomerce' },
                components: [
                  {
                    type: 'image',
                    attributes: { class: 'image-eccommerce', src: 'https://dummyimage.com/420x260' },
                  },
                  {
                    type: 'div',
                    attributes: { class: '' },
                    components: [
                      {
                        type: 'title2',
                        components: [
                          {
                            type: 'text',
                            name: 'H2',
                            components: 'Categoria',
                            attributes: { class: 'title-eccommerce' },
                          },
                        ],
                      },
                      {
                        type: 'title3',
                        components: [
                          {
                            type: 'text',
                            name: 'H3',
                            components: 'Lorem ipsum',
                            attributes: { class: 'title-eccommerce' },
                          },
                        ],
                      },
                      {
                        type: 'text',
                        attributes: { class: 'price-eccommerce' },
                        components: 'R$16,00'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'div',
                attributes: { class: 'item-eccomerce' },
                components: [
                  {
                    type: 'image',
                    attributes: { class: 'image-eccommerce', src: 'https://dummyimage.com/420x260' },
                  },
                  {
                    type: 'div',
                    attributes: { class: '' },
                    components: [
                      {
                        type: 'title2',
                        components: [
                          {
                            type: 'text',
                            name: 'H2',
                            components: 'Categoria',
                            attributes: { class: 'title-eccommerce' },
                          },
                        ],
                      },
                      {
                        type: 'title3',
                        components: [
                          {
                            type: 'text',
                            name: 'H3',
                            components: 'Lorem ipsum',
                            attributes: { class: 'title-eccommerce' },
                          },
                        ],
                      },
                      {
                        type: 'text',
                        attributes: { class: 'price-eccommerce' },
                        components: 'R$16,00'
                      }
                    ]
                  }
                ]
              },
            ],
          },
          {
            type: 'div',
            attributes: { class: 'flex sm-flex-col gap-20' },
            components: [
              {
                type: 'div',
                attributes: { class: 'item-eccomerce' },
                components: [
                  {
                    type: 'image',
                    attributes: { class: 'image-eccommerce', src: 'https://dummyimage.com/420x260' },
                  },
                  {
                    type: 'div',
                    attributes: { class: '' },
                    components: [
                      {
                        type: 'title2',
                        components: [
                          {
                            type: 'text',
                            name: 'H2',
                            components: 'Categoria',
                            attributes: { class: 'title-eccommerce' },
                          },
                        ],
                      },
                      {
                        type: 'title3',
                        components: [
                          {
                            type: 'text',
                            name: 'H3',
                            components: 'Lorem ipsum',
                            attributes: { class: 'title-eccommerce' },
                          },
                        ],
                      },
                      {
                        type: 'text',
                        attributes: { class: 'price-eccommerce' },
                        components: 'R$16,00'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'div',
                attributes: { class: 'item-eccomerce' },
                components: [
                  {
                    type: 'image',
                    attributes: { class: 'image-eccommerce', src: 'https://dummyimage.com/420x260' },
                  },
                  {
                    type: 'div',
                    attributes: { class: '' },
                    components: [
                      {
                        type: 'title2',
                        components: [
                          {
                            type: 'text',
                            name: 'H2',
                            components: 'Categoria',
                            attributes: { class: 'title-eccommerce' },
                          },
                        ],
                      },
                      {
                        type: 'title3',
                        components: [
                          {
                            type: 'text',
                            name: 'H3',
                            components: 'Lorem ipsum',
                            attributes: { class: 'title-eccommerce' },
                          },
                        ],
                      },
                      {
                        type: 'text',
                        attributes: { class: 'price-eccommerce' },
                        components: 'R$16,00'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'div',
                attributes: { class: 'item-eccomerce' },
                components: [
                  {
                    type: 'image',
                    attributes: { class: 'image-eccommerce', src: 'https://dummyimage.com/420x260' },
                  },
                  {
                    type: 'div',
                    attributes: { class: '' },
                    components: [
                      {
                        type: 'title2',
                        components: [
                          {
                            type: 'text',
                            name: 'H2',
                            components: 'Categoria',
                            attributes: { class: 'title-eccommerce' },
                          },
                        ],
                      },
                      {
                        type: 'title3',
                        components: [
                          {
                            type: 'text',
                            name: 'H3',
                            components: 'Lorem ipsum',
                            attributes: { class: 'title-eccommerce' },
                          },
                        ],
                      },
                      {
                        type: 'text',
                        attributes: { class: 'price-eccommerce' },
                        components: 'R$16,00'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'div',
                attributes: { class: 'item-eccomerce' },
                components: [
                  {
                    type: 'image',
                    attributes: { class: 'image-eccommerce', src: 'https://dummyimage.com/420x260' },
                  },
                  {
                    type: 'div',
                    attributes: { class: '' },
                    components: [
                      {
                        type: 'title2',
                        components: [
                          {
                            type: 'text',
                            name: 'H2',
                            components: 'Categoria',
                            attributes: { class: 'title-eccommerce' },
                          },
                        ],
                      },
                      {
                        type: 'title3',
                        components: [
                          {
                            type: 'text',
                            name: 'H3',
                            components: 'Lorem ipsum',
                            attributes: { class: 'title-eccommerce' },
                          },
                        ],
                      },
                      {
                        type: 'text',
                        attributes: { class: 'price-eccommerce' },
                        components: 'R$16,00'
                      }
                    ]
                  }
                ]
              },
            ],
          }
        ],
        styles: `
             .container-cubo{
                max-width: 1600px; 
             }
             .flex{
               display: flex; 
             }
             .flex-col{
               flex-direction: column; 
             }
             .gap-20{
               gap: 20px; 
             }
            .item-eccomerce{
              width: 25%; 
              display: flex; 
              flex-direction: column; 
              align-items: flex-start;
            }
            .image-eccommerce{
              width: 100%; 
              height: 200px; 
              object-fit: cover; 
              border-radius: 15px; 
              margin-bottom: 15px;
            }
            .title-eccommerce{
              font-size: 0.75rem; 
              line-height: 1rem; 
              letter-spacing: 0.1em; 
              margin-bottom: 0.25rem; 
              color: #6B7280;
            }
            .price-eccommerce{
               color: #4B5563;
               font-size: 1rem; 
            }

             @media (max-width: 992px){
               .sm-flex-col{
                 flex-direction: column; 
               }
              .item-eccomerce{
              width: 100%; 
            }
             }
            `,
      },
    },
    view: {

    },
  };
  return component;
};