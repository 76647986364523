import block from "./block.js";
import component from "./components/index.js"
import accordion4 from "./components/accordion4.js";
import columnAccordion4 from "./components/columnAccordion4.js";
import columnFaq4 from "./components/columnFaq4.js";
import columnsFaq4 from "./components/columnsFaq4.js";
import containerFaq4 from "./components/containerFaq4.js";
import divAccordion4 from "./components/divAccordion4.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("faq4", component(opts));
  editor.DomComponents.addType("accordion4", accordion4(opts));
  editor.DomComponents.addType("columnAccordion4", columnAccordion4(opts));
  editor.DomComponents.addType("columnFaq4", columnFaq4(opts));
  editor.DomComponents.addType("columnsFaq4", columnsFaq4(opts));
  editor.DomComponents.addType("containerFaq4", containerFaq4(opts));
  editor.DomComponents.addType("divAccordion4", divAccordion4(opts));

    //block
  editor.BlockManager.add("faq4", block(opts));
};