export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              name: "Div",
              attributes: { class: 'contents' },
              components: [
                {
                  type: 'tabContent',
                },
                {
                  type: 'tabContent'
                },
                {
                  type: 'tabContent'
                },
                {
                  type: 'tabContent'
                },
              ],
              styles: `
              .contents{
                width:70%; 
                padding:20px;
                border: 1px solid #D9DEEA;
                border-radius:4px; 
              }
              @media (max-width:992px){
                .contents{
                  width:100%; 
                }
              }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};