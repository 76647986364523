export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              name: 'Item Depoimentos',
              attributes: { class: 'item-testimony-5' },
              components: [
                {
                    type: 'text',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et bibendum risus. Cras faucibus viverra dolor, at vehicula erat tempus vel. Sed a velit dictum, semper nulla at, volutpat dolor. In egestas ',
                    attributes: { class: 'text-testimony-4'}
                }, 
                {
                    type: 'div', 
                    attributes: { class: 'd-flex align-center-row margin-top-20'}, 
                    components: [
                        {
                            type: 'image', 
                            attributes: { class: 'picture-teestimony-2', src: 'https://placehold.co/60x60'}
                        }, 
                        {
                            type: 'div', 
                            attributes: { class: 'margin-left-16'}, 
                            components: [
                                {
                                    type: 'text', 
                                    content: 'Silvia Natalia',
                                    attributes: { class: 'name-testimony-2'}, 
                                },
                                {
                                    type: 'text', 
                                    content: 'Prospecta Digital',
                                    attributes: { class: 'desc-testimony-2'}, 
                                }
                            ]
                        }
                    ]
                }
              ],
              styles: `
              .item-testimony-5{
                width: 50%; 
                padding: 20px; 
                background:#F5F5F5; 
              }
              .text-testimony-4{
                font-size: 15px; 
                color: #6D7783; 
              }
              .d-flex{
                display: flex; 
              }
              .align-center-row{
                align-items: center; 
              }
              .margin-top-20{
                margin-top: 20px;
              }
              .margin-left-16{
                margin-left: 16px; 
              }
              .name-testimony-2{
                font-size: 15px; 
                color: #000000; 
              }
              .desc-testimony-2{
                font-size: 15px; 
                color: #6D7783; 
              }
              .picture-teestimony-2{
                border-radius: 100%;
              }

              @media (max-width: 992px){
                .item-testimony-5{
                    width: 100%; 
                }
              }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};