import block from "./block.js";
import component from "./components/index.js"
import columnCall3 from "./components/columnCall3.js";
import divForm3 from "./components/divForm3.js";
import imageForm3 from "./components/imageForm3.js";
import scrollDown3 from "./components/scrollDown3.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("topPage3", component(opts));
  editor.DomComponents.addType("columnCall3", columnCall3(opts));
  editor.DomComponents.addType("divForm3", divForm3(opts));
  editor.DomComponents.addType("imageForm3", imageForm3(opts));
  editor.DomComponents.addType("scrollDown3", scrollDown3(opts));

    //block
  editor.BlockManager.add("topPage3", block(opts));
};