export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        tagname: 'div',
        name: "Colunas Blog",
        attributes: { class: 'flex justify-between gap-16 sm-flex-col' },
        components: [
          {
            type: 'itemBlog'
          },
          {
            type: 'itemBlog'
          },
          {
            type: 'itemBlog'
          },
        ],
        styles: `
        .flex{
          display: flex; 
        }
        .gap-16{
          gap: 16px; 
        }
        .justify-between{
          justify-content: space-between; 
        }
        @media (max-width: 992px){
          .sm-flex-col{
            flex-direction: column; 
          }
        }

      `,
      },
    },
    view: {

    },
  };

  return component;
};
