export default (opts = {}) => {
  const block = {
    id: "title3",
    label: "H3",
    fromPlugin: 'title',
    apper : 'out', 
    media: '<i class="bi bi-type-h3"></i>', 
    content: { type: "title3" },
  };

  return block;
};



