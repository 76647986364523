export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type:'div', 
              name:'Check-List', 
              style: {
                'display':'flex',
                'flex-direction':'column', 
                'gap':'8px', 
              },
              components: [
                {
                    type:'div',
                    name:'Item', 
                    style: {
                     'display':'flex',
                     'align-items':'center',
                     'gap':'10px'
                    },
                    components: [
                        {
                          type:'image', 
                          attributes: {src : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAaVJREFUSInd1DtrVGEQBuBno5hFQRMwgkhS2fk3tBFT6NqJlcRKLEWxkVQW/gX1L3hBwVshCC6ClYIWlhIweIkhwiIma3HmcD7P+ZbdEyt94eNwZt55Z+a7DP8r5rCMPj7hK7YwTNYGPuIpLrYRP431mti49bmNeFnpXZzAAmbRqXG74TuKI4m9h+M58QNJ5ZcnrSjBITyM+G85wnJSeVucDNEhvih2ooF+EBZbil/AZsQ+wMFRxNUgzbcQvxYxW7ikeU5/YCPIeyYUXwr+L5xL7H08zwUMImA6sd3BG82ujuFn8M8m9ilVRw2U+ziV2J6E7UOSZBYrYb9R0+io3sZECWbwqpbkVvw/w86aRtnBZi5BbovqSVYU7Q9wOKMxHbxBLsGPcO7O+PaprvEQ13MCigtSzqkGxl3TGbzAe+wdwZkPjdWcc7sPLcViaLwsDemBPorv0l8kOB/fxznnHL5HBVe2IX41YtewfxSppxrX94we152wLQTnvuqBnRpXSU/VSZu1lhPfkUnwDjcVd7mLXYqx0E26KAXX8Ra3cQavx1X/7+E30o2R6IkSwAEAAAAASUVORK5CYII="},
                          style: {
                            'width':'20px', 
                            'height':'20px',
                          },
                        },                
                        {
                          type:'text', 
                          content: 'Lorem Ipsum'
                        }
                    ]
                },
                {
                  type:'div',
                  name:'Item', 
                  style: {
                   'display':'flex',
                   'align-items':'center',
                   'gap':'10px'
                  },
                  components: [
                      {
                        type:'image', 
                        attributes: {src : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAaVJREFUSInd1DtrVGEQBuBno5hFQRMwgkhS2fk3tBFT6NqJlcRKLEWxkVQW/gX1L3hBwVshCC6ClYIWlhIweIkhwiIma3HmcD7P+ZbdEyt94eNwZt55Z+a7DP8r5rCMPj7hK7YwTNYGPuIpLrYRP431mti49bmNeFnpXZzAAmbRqXG74TuKI4m9h+M58QNJ5ZcnrSjBITyM+G85wnJSeVucDNEhvih2ooF+EBZbil/AZsQ+wMFRxNUgzbcQvxYxW7ikeU5/YCPIeyYUXwr+L5xL7H08zwUMImA6sd3BG82ujuFn8M8m9ilVRw2U+ziV2J6E7UOSZBYrYb9R0+io3sZECWbwqpbkVvw/w86aRtnBZi5BbovqSVYU7Q9wOKMxHbxBLsGPcO7O+PaprvEQ13MCigtSzqkGxl3TGbzAe+wdwZkPjdWcc7sPLcViaLwsDemBPorv0l8kOB/fxznnHL5HBVe2IX41YtewfxSppxrX94we152wLQTnvuqBnRpXSU/VSZu1lhPfkUnwDjcVd7mLXYqx0E26KAXX8Ra3cQavx1X/7+E30o2R6IkSwAEAAAAASUVORK5CYII="},
                        style: {
                          'width':'20px', 
                          'height':'20px',
                        },
                      },                
                      {
                        type:'text', 
                        content: 'Lorem Ipsum'
                      }
                  ]
              },
              {
                type:'div',
                name:'Item', 
                style: {
                 'display':'flex',
                 'align-items':'center',
                 'gap':'10px'
                },
                components: [
                    {
                      type:'image', 
                      attributes: {src : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAaVJREFUSInd1DtrVGEQBuBno5hFQRMwgkhS2fk3tBFT6NqJlcRKLEWxkVQW/gX1L3hBwVshCC6ClYIWlhIweIkhwiIma3HmcD7P+ZbdEyt94eNwZt55Z+a7DP8r5rCMPj7hK7YwTNYGPuIpLrYRP431mti49bmNeFnpXZzAAmbRqXG74TuKI4m9h+M58QNJ5ZcnrSjBITyM+G85wnJSeVucDNEhvih2ooF+EBZbil/AZsQ+wMFRxNUgzbcQvxYxW7ikeU5/YCPIeyYUXwr+L5xL7H08zwUMImA6sd3BG82ujuFn8M8m9ilVRw2U+ziV2J6E7UOSZBYrYb9R0+io3sZECWbwqpbkVvw/w86aRtnBZi5BbovqSVYU7Q9wOKMxHbxBLsGPcO7O+PaprvEQ13MCigtSzqkGxl3TGbzAe+wdwZkPjdWcc7sPLcViaLwsDemBPorv0l8kOB/fxznnHL5HBVe2IX41YtewfxSppxrX94we152wLQTnvuqBnRpXSU/VSZu1lhPfkUnwDjcVd7mLXYqx0E26KAXX8Ra3cQavx1X/7+E30o2R6IkSwAEAAAAASUVORK5CYII="},
                      style: {
                        'width':'20px', 
                        'height':'20px',
                      },
                    },                
                    {
                      type:'text', 
                      content: 'Lorem Ipsum'
                    }
                ]
            },
              ],
              styles: `
             
             `,
            },
            
          },
          view: {
      
          },
};
  return component; 
};