export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              name: 'Item Tempo',
              attributes: { class: 'item-time-line' },
              components: [
                 {
                   type:'image', 
                   tagName:'img',  
                   attributes:{class:'icon-time-line', src: 'https://placehold.co/45x45'}, 
                 },
                 {
                  type:'text', 
                  content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris luctus tincidunt venenatis. Nulla viverra maximus eleifend. Quisque maximus gravida leo sed aliquet. Quisque luctus quam tortor, sit amet ultricies arcu molestie nec.',
                  attributes:{class:'text-time-line'},
                },
                {
                  type:'text', 
                  content:'31.05.22',
                  attributes:{class:'date-time-line'}, 
                },
             
              ],
              styles: `
               .item-time-line{
                background: #F6F7FA; 
                padding: 15px;
                width: 33%; 
               }
               .icon-time-line{
                width:45px; 
               }
               .text-time-line{
                font-size: 16px; 
                color: #363940; 
                margin-top: 15px; 
               }
               .date-time-line{
                font-size:12px; 
                color:#95A1BB; 
                font-weight:500; 
                margin-top:15px; 
               }
               @media (max-width:992px){
                .item-time-line{
                  width:100%;
                }
               }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};