export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: 'Colunas Depoimentos',
              attributes: { class: 'row-cubo' },
              components: [
                {
                  type: 'columnTestimony5'
                },
                {
                  type: 'columnItems5'
                }  
              ],
              styles: `
             .row-cubo{
                display: flex; 
                flex-direction:row; 
                width:100%; 
             }
             @media (max-width:992px){
              .row-cubo{
                flex-direction:column;
              }
             }
            `,
             },
          },
          view: {
      
          },
    };
  
    return component;
  };
  