import block from "./block.js";
import component from "./components/index.js"
import containerTeam from "./components/containerTeam.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerTeam", containerTeam(opts));
  editor.DomComponents.addType("team1", component(opts));
    //block
  editor.BlockManager.add("team1", block(opts));
};