export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type:'Container', 
              name: "Container Numeros",
              attributes: { class: 'container-cubo align-center' },
              components: [
                {
                  tagName: 'h2',
                  type:'text',
                  name:'Titulo',
                  components: 'Big Numbers',
                  attributes: {class: 'principal-title'}, 
                  traits: [
                    {
                      type: "select",
                      options: [
                        { value: "h1", name: opts.labelN1 },
                        { value: "h2", name: opts.labelN2 },
                        { value: "h3", name: opts.labelN3 },
                        { value: "h4", name: opts.labelN4 },
                        { value: "h5", name: opts.labelN5 },
                        { value: "h6", name: opts.labelN6 },
                      ],
                      label: 'Tamanho',
                      name: "tagName",
                      changeProp: 1,
                    },
                    {
                      type:'id',
                      name:'id',
                      Label:'id',
                    }
                  ],
                }, 
                {
                  type:'rowNumbers3',
                },
                {
                  type: 'button',
                },
              ],
              styles: `
               .principal-title{
                font-size: 30px; 
                color: #363940; 
                margin:0;
               }
               .container-cubo{
                max-width: 1600px; 
             }
             .align-center{
              display:flex;
              flex-direction:column; 
              align-items: center;
             }
              .text-center{
                text-align:center;
              }  
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};