import block from "./block.js";
import component from "./components/index.js"
import columnItemSupport1 from "./components/columnItemSupport1.js";
import columnSupport1 from "./components/columnSupport1.js";
import columnsSupport1 from "./components/columnsSupport1.js";
import containerSupport1 from "./components/containerSupport1.js";
import supportItem1 from "./components/supportItem1.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("support1", component(opts));
  editor.DomComponents.addType("columnItemSupport1", columnItemSupport1(opts));
  editor.DomComponents.addType("columnSupport1", columnSupport1(opts));
  editor.DomComponents.addType("columnsSupport1", columnsSupport1(opts));
  editor.DomComponents.addType("containerSupport1", containerSupport1(opts));
  editor.DomComponents.addType("supportItem1", supportItem1(opts));

    //block
  editor.BlockManager.add("support1", block(opts));
};