export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        type: 'Container',
        name: "Container Cabeçalho",
        attributes: { class: 'container-cubo flex sm-flex-col items-center justify-between gap-20' },
        components: [
          {
            type: 'image',
            attributes: { class: 'logo-header', src: 'https://dummyimage.com/40x40' },
          },
          {
            type: 'div',
            attributes: { class: 'flex sm-flex-col items-center gap-20' },
            components: [
              {
                type: 'div',
                attributes: { class: 'flex flex-wrap items-center justify-center gap-20' },
                components: [
                  {
                    type: 'link',
                    attributes: { class: 'link-header' },
                    components: 'Lorem'
                  },
                  {
                    type: 'link',
                    attributes: { class: 'link-header' },
                    components: 'Lorem'
                  },
                  {
                    type: 'link',
                    attributes: { class: 'link-header' },
                    components: 'Lorem'
                  },
                  {
                    type: 'link',
                    attributes: { class: 'link-header' },
                    components: 'Lorem'
                  },
                ]
              },
              {
                type: 'button3',
              }
            ]
          },
        ],
        styles: `
             .container-cubo{
                max-width: 1600px; 
             }
             .flex{
               display: flex; 
             }
            .items-center{
              align-items: center; 
            }
            .justify-between{
              justify-content: space-between; 
            }
              .justify-center{
              justify-content: center; 
            }
            .flex-wrap{
              flex-wrap: wrap; 
            }
            .gap-20{
              gap: 20px; 
            }
            .logo-header{
              width: 40px; 
              height: 40px; 
              border-radius: 100%; 
            }
            .link-header{
              font-size: 14px; 
              color: #363940;
              text-decoration: none; 
            }
            .shadow{
              box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
            }
            @media (max-width: 992px){
              .sm-flex-col{
                flex-direction: column; 
              }
            }
            `,
      },
    },
    view: {

    },
  };
  return component;
};