export default (opts = {}) => {
  const block = {
    category: 'Blog',
    id: "blog3",
    label: "Blog (3)",
    apper: 'out',
    media: '<svg class="preview-block" viewBox="0 0 266 150" fill="none" width="266"  height="150" ><path fill="#FFFFFF" d="M0 0h266v150H0z"></path><rect x="20" y="48" width="70" height="5" rx="2.5" fill="#4A5568"></rect><rect x="20" y="36" width="34" height="8" rx="2" fill="#EBF4FF"></rect><rect x="20" y="58" width="92" height="4" rx="2" fill="#A0AEC0"></rect><rect x="20" y="66" width="82" height="4" rx="2" fill="#A0AEC0"></rect><rect x="20" y="74" width="68" height="4" rx="2" fill="#A0AEC0"></rect><rect x="20" y="82" width="18" height="4" rx="2" fill="#6366F1"></rect><rect x="23" y="39" width="28" height="2" rx="1" fill="#6366F1"></rect><path d="M121.5 93a.5.5 0 010 1h-101a.5.5 0 010-1h101z" fill="#E2E8F0"></path><circle cx="27.5" cy="107.5" r="7.5" fill="#E2E8F0"></circle><path d="M39 110a1 1 0 011-1h19a1 1 0 010 2H40a1 1 0 01-1-1z" fill="#A0AEC0"></path><rect x="39" y="103" width="35" height="3" rx="1.5" fill="#4A5568"></rect><rect x="144" y="48" width="70" height="5" rx="2.5" fill="#4A5568"></rect><rect x="144" y="36" width="34" height="8" rx="2" fill="#EBF4FF"></rect><rect x="144" y="58" width="92" height="4" rx="2" fill="#A0AEC0"></rect><rect x="144" y="66" width="82" height="4" rx="2" fill="#A0AEC0"></rect><rect x="144" y="74" width="68" height="4" rx="2" fill="#A0AEC0"></rect><rect x="144" y="82" width="18" height="4" rx="2" fill="#6366F1"></rect><rect x="147" y="39" width="28" height="2" rx="1" fill="#6366F1"></rect><path d="M245.5 93a.5.5 0 010 1h-101a.5.5 0 010-1h101z" fill="#E2E8F0"></path><circle cx="151.5" cy="107.5" r="7.5" fill="#E2E8F0"></circle><path d="M163 110a1 1 0 011-1h19a1 1 0 010 2h-19a1 1 0 01-1-1z" fill="#A0AEC0"></path><rect x="163" y="103" width="35" height="3" rx="1.5" fill="#4A5568"></rect></svg>',
    fromPlugin: 'blocks',
    content: { type: 'blog3' },
  };

  return block;
};