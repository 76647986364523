import block from "./block.js";
import component from "./components/index.js"
import containerContact3 from "./components/containerContact3.js";


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("containerContact3", containerContact3(opts));
  editor.DomComponents.addType("contact3", component(opts));
    //block
  editor.BlockManager.add("contact3", block(opts));
};