export default (opts = {}) => {
  const block = {
    category: 'Footer',
    id: "footer2",
    label: "Rodapé (2)",
    fromPlugin: 'blocks',
    apper : 'out',
    media: '<svg class="preview-block" fill="none" viewBox="0 0 266 150" width="266"  height="150" ><path fill="#FFFFFF" d="M0 0h266v150H0z"></path><rect x="61" y="85" width="35" height="3" rx="1.5" fill="#4A5568"></rect><rect x="61" y="93" width="23" height="3" rx="1.5" fill="#A0AEC0"></rect><rect x="61" y="100" width="16" height="3" rx="1.5" fill="#A0AEC0"></rect><rect x="61" y="107" width="28" height="3" rx="1.5" fill="#A0AEC0"></rect><rect x="61" y="114" width="23" height="3" rx="1.5" fill="#A0AEC0"></rect><rect x="111" y="85" width="35" height="3" rx="1.5" fill="#4A5568"></rect><rect x="111" y="93" width="23" height="3" rx="1.5" fill="#A0AEC0"></rect><rect x="111" y="100" width="16" height="3" rx="1.5" fill="#A0AEC0"></rect><rect x="111" y="107" width="28" height="3" rx="1.5" fill="#A0AEC0"></rect><rect x="111" y="114" width="23" height="3" rx="1.5" fill="#A0AEC0"></rect><rect x="161" y="85" width="35" height="3" rx="1.5" fill="#4A5568"></rect><rect x="161" y="93" width="23" height="3" rx="1.5" fill="#A0AEC0"></rect><rect x="161" y="107" width="28" height="3" rx="1.5" fill="#A0AEC0"></rect><rect x="161" y="100" width="16" height="3" rx="1.5" fill="#A0AEC0"></rect><rect x="161" y="114" width="23" height="3" rx="1.5" fill="#A0AEC0"></rect><rect x="211" y="85" width="35" height="3" rx="1.5" fill="#4A5568"></rect><rect x="211" y="93" width="23" height="3" rx="1.5" fill="#A0AEC0"></rect><rect x="211" y="100" width="16" height="3" rx="1.5" fill="#A0AEC0"></rect><rect x="211" y="107" width="28" height="3" rx="1.5" fill="#A0AEC0"></rect><rect x="211" y="114" width="23" height="3" rx="1.5" fill="#A0AEC0"></rect><path fill="#E2E8F0" d="M0 131h266v19H0z"></path><rect x="20" y="139" width="41" height="3" rx="1.5" fill="#A0AEC0"></rect><rect x="222" y="139" width="25" height="3" rx="1.5" fill="#A0AEC0"></rect><circle cx="29" cy="94" r="9" fill="#6366F1"></circle></svg>',
    content: { type: 'footer2' },
  };

  return block;
};