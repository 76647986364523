export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: "Coluna Diferenciais",
              attributes: { class: 'column column-50 p-20' },
              components: [
               {
                type:'image',
                attributes: { class: 'featured-image', src:'https://placehold.co/674x779'}
               },
              ],
              styles: `
              .p-20{
                padding:20px;
               }
               .column{
                display:flex; 
                flex-direction:column;
                align-items:flex-start;
               }
               .column-50{
                width:50%; 
               }
               .featured-image{
                width: 100%; 
                height: 100%; 
                object-fit: cover; 
               }
               @media (max-width:992px){
                .column-50{
                  width:100%; 
                }
                .d-sm-column{
                  flex-direction: column !important; 
                }
              }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};