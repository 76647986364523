export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname: 'div',
              name: 'Div Produtos',
              attributes: { class: 'w-100 d-flex d-sm-column gap-50 margin-top-50' },
              components: [
                {
                  type: 'div',
                  attributes: { class: 'w-50 w-sm-100 position-relative'},
                  components: [
                   {
                    type: 'image', 
                    attributes: { class: 'w-100', src: 'https://placehold.co/812x464'},
                   }, 
                ]        
                },
                {
                  type: 'div',
                  attributes: { class: 'w-50 w-sm-100 position-relative d-column justify-center'},
                  components: [
                     {
                      type: 'text', 
                      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', 
                      attributes: { class: 'title-product-1'},
                     },
                     {
                        type: 'text', 
                        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et bibendum risus. Cras faucibus viverra dolor, at vehicula erat tempus vel. Sed a velit dictum, semper nulla at, volutpat dolor. In egestas tortor a tellus dignissim aliquet.',
                        attributes: { class: 'text-product-1'}
                     }
                  ],          
                },
      
              ],
              styles: `
                .w-100{
                  width: 100%; 
                }
                .d-flex{
                  display:flex; 
                }
                .gap-50{
                  gap:50px; 
                }
                .w-50{
                  width: 50%; 
                }
                .position-relative{
                  position:relative
                }
                .position-absolute{
                  position: absolute; 
                }
               
                .margin-top-50{
                  margin-top: 50px;
                }
                .title-product-1{
                    font-size: 25px; 
                    color: #95A1BB; 
                }
                .text-product-1{
                    font-size: 16px; 
                    color: #95A1BB;
                    margin-top: 30px;
                }
                .d-column{
                    display: flex; 
                    flex-direction: column;
                }
                .justify-center{
                    justify-content: center;
                }
                @media (max-width:992px){
                  .d-sm-column{
                    flex-direction:column; 
                  }
                  .w-sm-100{
                    width: 100% !important; 
                  }
                }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};