export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname: 'div',
              name: "Coluna Numeros",
              attributes: { class: 'column column-50 p-20 align-rigth' },
              components: [
               {
                type: 'div', 
                attributes: { class: 'div-numbers-6'},
                components:[
                  {
                    type: 'div', 
                    attributes: { class: 'numbers-6'}, 
                    components: [
                      {
                        type: 'div', 
                        attributes: { class: 'd-flex'}, 
                        components: [
                          {
                            type: 'text', 
                            content: '+', 
                            attributes:{ class: 'prefix font-30'}
                          }, 
                          { 
                            type:'counters',
                            attributes:{class:'counter-value font-30'},
                          }, 
                          {
                            type: 'text',
                            content: 'lorem ipsum dolor', 
                            attributes: { class: 'sufix font-30'}
                          }
                        ]
                      },
                      {
                        type: 'text',
                        content: 'Lorem ipsum dolor sit amet ', 
                        attributes: { class: 'context'}
                      }
                    ]
                  },
                  {
                    type: 'div', 
                    attributes: { class: 'numbers-6'}, 
                    components: [
                      {
                        type: 'div', 
                        attributes: { class: 'd-flex'}, 
                        components: [
                          {
                            type: 'text', 
                            content: '+', 
                            attributes:{ class: 'prefix font-30'}
                          }, 
                          { 
                            type:'counters',
                            attributes:{class:'counter-value font-30'},
                          }, 
                          {
                            type: 'text',
                            content: 'lorem ipsum dolor', 
                            attributes: { class: 'sufix font-30'}
                          }
                        ]
                      },
                      {
                        type: 'text',
                        content: 'Lorem ipsum dolor sit amet ', 
                        attributes: { class: 'context'}
                      }
                    ]
                  },
                  {
                    type: 'div', 
                    attributes: { class: 'numbers-6'}, 
                    components: [
                      {
                        type: 'div', 
                        attributes: { class: 'd-flex'}, 
                        components: [
                          {
                            type: 'text', 
                            content: '+', 
                            attributes:{ class: 'prefix font-30'}
                          }, 
                          { 
                            type:'counters',
                            attributes:{class:'counter-value font-30'},
                          }, 
                          {
                            type: 'text',
                            content: 'lorem ipsum dolor', 
                            attributes: { class: 'sufix font-30'}
                          }
                        ]
                      },
                      {
                        type: 'text',
                        content: 'Lorem ipsum dolor sit amet ', 
                        attributes: { class: 'context'}
                      }
                    ]
                  },
                ]
               },
              
              ],
              styles: `
              .align-rigth{
                align-items: flex-end;
              }
              .div-numbers-6{
                background: #fff; 
                padding: 20px; 
              }
              .numbers-6{
                width: 100%; 
                border-bottom: 1px dashed #CBCBCB; 
                padding-bottom: 20px; 
                margin-top: 20px; 
              }
              .d-flex{
                display: flex; 
              }
              .font-30{
                font-size: 30px; 
              }
              .prefix, .counter-value, .sufix{
                color: #363940
              }
              .sufix{
                margin-left: 8px; 
              }
              .context{
                font-size: 16px; 
                color: #363940; 
                font-weight: 300;
              }
            `,
            },
          },
          view: {
           
          },
};
  return component; 
};