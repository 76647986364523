import block from "./block.js";
import component from "./components/index.js"
import cardServices1 from "./components/cardServices1.js";
import columnCardService1 from "./components/columnCardService1.js";
import columnService1 from "./components/columnService1.js";
import columnsService1 from "./components/columnsService1.js";
import containerService1 from "./components/containerService1.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("service1", component(opts));
  editor.DomComponents.addType("cardServices1", cardServices1(opts));
  editor.DomComponents.addType("columnCardService1", columnCardService1(opts));
  editor.DomComponents.addType("columnService1", columnService1(opts));
  editor.DomComponents.addType("containerService1", containerService1(opts));
  editor.DomComponents.addType("columnsService1", columnsService1(opts));

    //block
  editor.BlockManager.add("service1", block(opts));
};