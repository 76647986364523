export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        type: 'Container',
        name: "Container",
        attributes: { class: 'container-cubo flex flex-col items-center' },
        components: [
          {
            type: 'title2', 
            components: [
              {
                type:'text',
                name:'H2',
                components: 'Lorem Ipsum',
                attributes: { class: 'principal-title' },
              },
            ],
          },
          {
            type: 'div',
            attributes: { class: 'flex gap-16 sm-flex-col mt-50' },
            components: [
              {
                type: 'div', 
                attributes: { class: 'item-differences-6' },
                components: [
                  {
                    type: 'image', 
                    attributes: { class: 'image-differeces-6', src: 'https://dummyimage.com/420x260' },
                  },
                  {
                    type: 'div', 
                    attributes: { class: '' },
                    components: [
                      {
                        type: 'title3', 
                        components: [
                          {
                            type:'text',
                            name:'H3',
                            components: 'Lorem ipsum',
                            attributes: { class: 'title-differeces-6' },
                          },
                        ],
                      },
                      {
                        type: 'text', 
                        attributes: { class: 'body-text' },
                        components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae'
                      },
                      {
                        type: 'link', 
                        attributes: { class: 'more' },
                        components: 'Saiba mais'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'div', 
                attributes: { class: 'item-differences-6' },
                components: [
                  {
                    type: 'image', 
                    attributes: { class: 'image-differeces-6', src: 'https://dummyimage.com/420x260' },
                  },
                  {
                    type: 'div', 
                    attributes: { class: '' },
                    components: [
                      {
                        type: 'title3', 
                        components: [
                          {
                            type:'text',
                            name:'H3',
                            components: 'Lorem ipsum',
                            attributes: { class: 'title-differeces-6' },
                          },
                        ],
                      },
                      {
                        type: 'text', 
                        attributes: { class: 'body-text' },
                        components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae'
                      },
                      {
                        type: 'link', 
                        attributes: { class: 'more' },
                        components: 'Saiba mais'
                      }
                    ]
                  }
                ]
              },
              {
                type: 'div', 
                attributes: { class: 'item-differences-6' },
                components: [
                  {
                    type: 'image', 
                    attributes: { class: 'image-differeces-6', src: 'https://dummyimage.com/420x260' },
                  },
                  {
                    type: 'div', 
                    attributes: { class: '' },
                    components: [
                      {
                        type: 'title3', 
                        components: [
                          {
                            type:'text',
                            name:'H3',
                            components: 'Lorem ipsum',
                            attributes: { class: 'title-differeces-6' },
                          },
                        ],
                      },
                      {
                        type: 'text', 
                        attributes: { class: 'body-text' },
                        components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae'
                      },
                      {
                        type: 'link', 
                        attributes: { class: 'more' },
                        components: 'Saiba mais'
                      }
                    ]
                  }
                ]
              },
            ],
          }
        ],
        styles: `
             .container-cubo{
                max-width: 1600px; 
             }
                 .principal-title{
            font-size: 30px;
            color: #363940;
            margin:0;
           }
            .gap-16{
              gap: 16px; 
            }
            .flex{
              display: flex; 
            }
            .flex-col{
              flex-direction: column; 
            }
            .items-center{
              align-items: center; 
            }
            .mt-50{
              margin-top: 50px;
            }
          .item-differences-6{
            width: 33%; 
            display: flex; 
            align-items: flex-start; 
            gap: 15px; 
          }
          .image-differeces-6{
            width: 60px;
            height: 60px; 
            border-radius: 100%; 
          }
          .title-differeces-6{
             font-size: 20px; 
             color: #111827; 
          }
             .body-text{
            font-size: 16px;
            color: #363940;
            margin-top:30px;
           }
          .more{
            color: #6366f1;
            font-size: 16px; 
            text-decoration: none; 
            margin-top: 30px;
            display: block;
          }
            @media (max-width: 992px){
               .sm-flex-col{
                 flex-direction: column; 
               }
                .item-differences-6{
                  width: 100%;
                }
            }
            `,
      },
    },
    view: {

    },
  };
  return component;
};