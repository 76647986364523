export default (opts = {}) => {
  const block = {
    id: "title6",
    label: "H6",
    fromPlugin: 'title',
    apper : 'out', 
    media: '<i class="bi bi-type-h6"></i>', 
    content: { type: "title6" },
  };

  return block;
};



