import components from ".";

export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type: 'Container',
              name: "Container Conteudos",
              attributes: { class: 'container-cubo' },
              components: [
                {
                  type: 'div',
                  attributes: { class: 'flex sm-flex-col w-full mb-12 justify-between gap-20' },
                  components: [
                        {
                          type: 'title2', 
                          components: [
                            {
                              type:'text',
                              name:'H2',
                              components: 'Lorem Ipsum',
                              attributes: { class: 'principal-title' },
                            },
                          ],
                        },
                        {
                          type: 'text', 
                          attributes: { class: 'body-text w-40' },
                          components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus. '
                        }
                  ]
                },
                {
                  type: 'div', 
                  attributes: { class: 'flex sm-flex-col gap-30' },
                  components: [
                    {
                      type: 'div', 
                      attributes: { class: 'item-content2' },
                      components: [
                        {
                          type: 'div', 
                          attributes: { class: 'div-item-content2' },
                          components: [
                            {
                              type: 'image', 
                              attributes: { src: 'https://dummyimage.com/720x400', alt: 'conteudo', 
                                class: 'image-item-content2'
                              }, 
                            },
                            {
                              type:'title3', 
                              components: [
                                {
                                  type:'text',
                                  name:'H3',
                                  components: 'Lorem Ipsum',
                                  attributes: { class: 'subtitle-item-content2' },
                                },
                              ],
                            },
                            {
                              type:'title4', 
                              components: [
                                {
                                  type:'text',
                                  name:'H4',
                                  components: 'Lorem Ipsum',
                                  attributes: { class: 'title-item-content2' },
                                },
                              ],
                            },
                            {
                              type:'text', 
                              attributes: { class: 'text-item-content2' },
                              content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus. ', 
                            },
                          ]
                        }
                      ]
                    },
                    {
                      type: 'div', 
                      attributes: { class: 'item-content2' },
                      components: [
                        {
                          type: 'div', 
                          attributes: { class: 'div-item-content2' },
                          components: [
                            {
                              type: 'image', 
                              attributes: { src: 'https://dummyimage.com/720x400', alt: 'conteudo', 
                                class: 'image-item-content2'
                              }, 
                            },
                            {
                              type:'title3', 
                              components: [
                                {
                                  type:'text',
                                  name:'H3',
                                  components: 'Lorem Ipsum',
                                  attributes: { class: 'subtitle-item-content2' },
                                },
                              ],
                            },
                            {
                              type:'title4', 
                              components: [
                                {
                                  type:'text',
                                  name:'H4',
                                  components: 'Lorem Ipsum',
                                  attributes: { class: 'title-item-content2' },
                                },
                              ],
                            },
                            {
                              type:'text', 
                              attributes: { class: 'text-item-content2' },
                              content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus. ', 
                            },
                          ]
                        }
                      ]
                    },
                    {
                      type: 'div', 
                      attributes: { class: 'item-content2' },
                      components: [
                        {
                          type: 'div', 
                          attributes: { class: 'div-item-content2' },
                          components: [
                            {
                              type: 'image', 
                              attributes: { src: 'https://dummyimage.com/720x400', alt: 'conteudo', 
                                class: 'image-item-content2'
                              }, 
                            },
                            {
                              type:'title3', 
                              components: [
                                {
                                  type:'text',
                                  name:'H3',
                                  components: 'Lorem Ipsum',
                                  attributes: { class: 'subtitle-item-content2' },
                                },
                              ],
                            },
                            {
                              type:'title4', 
                              components: [
                                {
                                  type:'text',
                                  name:'H4',
                                  components: 'Lorem Ipsum',
                                  attributes: { class: 'title-item-content2' },
                                },
                              ],
                            },
                            {
                              type:'text', 
                              attributes: { class: 'text-item-content2' },
                              content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus. ', 
                            },
                          ]
                        }
                      ]
                    },
                    {
                      type: 'div', 
                      attributes: { class: 'item-content2' },
                      components: [
                        {
                          type: 'div', 
                          attributes: { class: 'div-item-content2' },
                          components: [
                            {
                              type: 'image', 
                              attributes: { src: 'https://dummyimage.com/720x400', alt: 'conteudo', 
                                class: 'image-item-content2'
                              }, 
                            },
                            {
                              type:'title3', 
                              components: [
                                {
                                  type:'text',
                                  name:'H3',
                                  components: 'Lorem Ipsum',
                                  attributes: { class: 'subtitle-item-content2' },
                                },
                              ],
                            },
                            {
                              type:'title4', 
                              components: [
                                {
                                  type:'text',
                                  name:'H4',
                                  components: 'Lorem Ipsum',
                                  attributes: { class: 'title-item-content2' },
                                },
                              ],
                            },
                            {
                              type:'text', 
                              attributes: { class: 'text-item-content2' },
                              content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus. ', 
                            },
                          ]
                        }
                      ]
                    },
                  ]
                }
              ],
              styles: `
             .container-cubo{
                max-width: 1600px; 
             }
              .flex{
                display: flex; 
              }
              .justify-between{
                justify-content: space-between; 
              }
              .w-full{
                width: 100%; 
              }
              .mb-12{
                margin-bottom: 3rem;
              }
              .gap-20{
                gap: 20px; 
              }
              .gap-30{
                gap: 30px
              }
              .principal-title{
            font-size: 30px;
            color: #363940;
            margin:0;
           }
              .body-text{
            font-size: 16px;
            color: #363940;
            margin-top:30px;
           }
            .w-40{
              width: 40%; 
            }
              .item-content2{
                width: 25%;
              }
              .div-item-content2{
                padding: 1.5rem; 
                background-image: linear-gradient(#f3f4f6 0%, #f3f4f6 100%);
                border-radius: 0.5rem; 
                width: 100%; 
                display: flex;
                flex-direction: column; 
                align-items: flex-start; 
              }
              .image-item-content2{
                object-fit: cover; 
                border-radius: 0.25rem; 
                width: 100%; 
                height: 10rem; 
                margin-bottom: 1.5rem; 
              }
              .subtitle-item-content2{
                color: #6366F1;
                letter-spacing: 0.1em; 
                font-weight: 500; 
                font-size: 0.75rem; 
                line-height: 1rem; 
              }
              .title-item-content2{
                color: #111827;
                font-weight: 500;
                font-size: 1.125rem; 
                line-height: 1.75rem; 
                margin-bottom: 1rem; 
              }
              .text-item-content2{
                line-height: 1.625; 
                font-size: 1rem; 
                color: #4B5563;
              }

              @media (max-width: 992px){
                .sm-flex-col{
                  flex-direction: column; 
                }
                  .item-content2{
                width: 100%;
              }
                .w-40{
                  width: 100%;
                }
              }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};