export default (opts = {}) => {
  const component =  {
    model: {
      defaults: {
        type:'Container',
        name: "Container Blog",
        attributes: { class: 'container-cubo flex flex-col gap-40'},
        components: [
          {
            type: 'itemBlog4'
          },
          {
            type: 'itemBlog4'
          },
          {
            type: 'itemBlog4'
          },
        ],
        styles: `
       .container-cubo{
          max-width: 1600px; 
       }
       .flex{
         display: flex; 
       }
      .flex-col{
        flex-direction: column; 
      }
      .gap-40{
        gap: 40px; 
      }
      `,
       },
    },
    view: {

    },
  };

  return component;
};
