import block from "./block.js";
import component from "./components/index.js"
import prints1_comp from "./components/prints1.js";
import containerPrints1 from "./components/containerPrints1.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("prints1", component(opts));
  editor.DomComponents.addType("prints1_comp", prints1_comp(opts));
  editor.DomComponents.addType("containerPrints1", containerPrints1(opts));

    //block
  editor.BlockManager.add("prints1", block(opts));
};