export default (opts = {}) => {
    const block = {
      id: "image",
      label: "Imagem",
      fromPlugin: 'image',
      apper : 'out', 
      media:'<i class="bi bi-image-fill"></i>',
      content: { type: 'image' },
    };
  
    return block;
  };