export default (opts = {}) => {
  const component = {
      model: {
          defaults: {
            style: {
              'display':'flex', 
              'gap':'20px'
            },
            components:[
              {
                type:'video',
                style: {
                  'width': '50%',
                },
              },
              {
                type:'video',
                style: {
                  'width': '50%',
                },
              }
            ],
           
            
          },
          
        },
        view: {},
};
return component; 
};