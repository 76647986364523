export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              type: 'Container',
              name: "Container Galeria",
              attributes: { class: 'container-cubo' },
              components: [
                {
                  type: 'div',
                  attributes: { class: 'flex sm-flex-col align-center justify-between' },
                  components: [
                    {
                      type: 'title2',
                      components: [
                        {
                          type:'text',
                          name:'H2',
                          components: 'Lorem Ipsum',
                          attributes: { class: 'principal-title' },
                        },
                      ],
                    },
                    {
                      type: 'text', 
                      attributes: { class: 'w-66 body-text' },
                      components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae'
                    }
                  ]
                },
                {
                  type: 'div',
                  attributes: { class: 'flex sm-flex-col mt-50 gap-16' },
                  components: [
                    {
                      type: 'div',
                      attributes: { class: 'flex flex-wrap w-50 gap-16' },
                      components: [
                        {
                          type: 'div',
                          attributes: { class: 'w-48' },
                          components: [
                            {
                              type: 'image', 
                              attributes: { class: 'w-full object-cover', src: 'https://dummyimage.com/500x300' },
                            }
                          ]
                        },
                        {
                          type: 'div',
                          attributes: { class: 'w-48' },
                          components: [
                            {
                              type: 'image', 
                              attributes: { class: 'w-full object-cover', src: 'https://dummyimage.com/500x300' },
                            }
                          ]
                        },
                        {
                          type: 'div',
                          attributes: { class: 'w-full' },
                          components: [
                            {
                              type: 'image', 
                              attributes: { class: 'w-full object-cover', src: 'https://dummyimage.com/500x300' },
                            }
                          ]
                        },
                        
                      ]
                    },
                    {
                      type: 'div',
                      attributes: { class: 'flex flex-wrap w-50 gap-16' },
                      components: [
                        {
                          type: 'div',
                          attributes: { class: 'w-full' },
                          components: [
                            {
                              type: 'image', 
                              attributes: { class: 'w-full object-cover', src: 'https://dummyimage.com/500x300' },
                            }
                          ]
                        },
                        {
                          type: 'div',
                          attributes: { class: 'w-48' },
                          components: [
                            {
                              type: 'image', 
                              attributes: { class: 'w-full object-cover', src: 'https://dummyimage.com/500x300' },
                            }
                          ]
                        },
                        {
                          type: 'div',
                          attributes: { class: 'w-48' },
                          components: [
                            {
                              type: 'image', 
                              attributes: { class: 'w-full object-cover', src: 'https://dummyimage.com/500x300' },
                            }
                          ]
                        },          
                      ]
                    },
                  ]
                }
              
              ],
              styles: `
             .container-cubo{
                max-width: 1600px; 
             }
            .align-center{
              align-items: center;
            }
            .justify-between{
              justify-content: space-between; 
            }
             .flex{
               display: flex; 
             }
            .flex-col{
              flex-direction: column; 
            }
            .flex-wrap{
              flex-wrap: wrap; 
            }
            .w-50{
              width: 50%
            }
               .w-48{
              width: 48%
            }
              .principal-title{
            font-size: 30px;
            color: #363940;
            margin:0;
           }
            .w-66{
              width: 66%; 
            }
              .body-text{
            font-size: 16px;
            color: #363940;
            margin-top:30px;
           }
           .mt-50{
             margin-top: 50px; 
           }
            .gap-16{
              gap: 16px; 
            }
            .w-full{
              width: 100%; 
            }
            .object-cover{
              object-fit: cover; 
            }
             @media (max-width:992px){
               .sm-flex-col{
                  flex-direction: column; 
               }
            .w-66{
              width: 100%; 
            }
              .w-50{
              width: 100%; 
            }
               .w-48{
              width: 100%; 
            }
             }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};