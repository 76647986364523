export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname: 'div',
              name: "Modelo",
              attributes: { class: 'w-100 d-flex d-sm-column gap-80 margin-top-80' },
              components: [
                {
                  type: 'div',
                  attributes: { class: 'w-50 w-sm-100 position-relative'},
                  components: [
                   {
                    type: 'image', 
                    attributes: { class: 'w-100', src:'https://placehold.co/780x464'},
                   }, 
                   {
                    type: 'div',
                    attributes: { class: 'position-absolute right--30 card-model-3'},
                    components: [
                     {
                      type: 'text', 
                      content: 'Modelo de negócio', 
                      attributes: { class: 'title-model-3'},
                     },
                     {
                      type: 'text', 
                      content: 'Lorem ipsum dolor sit amet, consectetur', 
                      attributes: { class: 'desc-model-3'}, 
                     },
                     {
                      type: 'text', 
                      content: '• Faturamento médio anual: R$ 650 mil', 
                      attributes: { class: 'info-model-3 margin-top-20'}, 
                     },
                     {
                      type: 'text', 
                      content: '• Investimento a partir de: R$ 130 mil', 
                      attributes: { class: 'info-model-3 margin-top-10'}, 
                     },
                     {
                      type: 'text', 
                      content: '• Padrão de loja 2x3 m² a 2x4 m²', 
                      attributes: { class: 'info-model-3 margin-top-10'}, 
                     },
                    ],
                   },
                  ],          
                },
      
                {
                  type: 'div',
                  attributes: { class: 'w-50 w-sm-100 position-relative'},
                  components: [
                   {
                    type: 'image', 
                    attributes: { class: 'w-100', src:'https://placehold.co/780x464'},
                   }, 
                   {
                    type: 'div',
                    attributes: { class: 'position-absolute left--30 card-model-3'},
                    components: [
                     {
                      type: 'text', 
                      content: 'Modelo de negócio', 
                      attributes: { class: 'title-model-3'},
                     },
                     {
                      type: 'text', 
                      content: 'Lorem ipsum dolor sit amet, consectetur', 
                      attributes: { class: 'desc-model-3'}, 
                     },
                     {
                      type: 'text', 
                      content: '• Faturamento médio anual: R$ 650 mil', 
                      attributes: { class: 'info-model-3 margin-top-20'}, 
                     },
                     {
                      type: 'text', 
                      content: '• Investimento a partir de: R$ 130 mil', 
                      attributes: { class: 'info-model-3 margin-top-10'}, 
                     },
                     {
                      type: 'text', 
                      content: '• Padrão de loja 2x3 m² a 2x4 m²', 
                      attributes: { class: 'info-model-3 margin-top-10'}, 
                     },
                    ],
                   },
                  ],          
                },
      
              ],
              styles: `
                .w-100{
                  width: 100%; 
                }
                .d-flex{
                  display:flex; 
                }
                .gap-80{
                  gap:80px; 
                }
                .w-50{
                  width: 50%; 
                }
                .position-relative{
                  position:relative
                }
                .position-absolute{
                  position: absolute; 
                }
                .right--30{
                  right: -30px; 
                }
                .left--30{
                  left: -30px; 
                }
                .card-model-3{
                  background: #F9F9F9; 
                  padding: 20px; 
                  top: -30px; 
                }
                .title-model-3{
                  font-size: 18px; 
                  color: #363940; 
                  font-weight: 600; 
                }
                .desc-model-3{
                  font-size: 16px; 
                  color: #8C8C8C; 
                  margin-top: 15px; 
                }
                .info-model-3{
                  font-size: 16px; 
                  color: #8C8C8C; 
                  margin-top: 15px; 
                }
                .margin-top-20{
                  margin-top: 20px; 
                }
                .margin-top-10{
                  margin-top: 10px; 
                }
                .margin-top-80{
                  margin-top: 80px;
                }
                @media (max-width:992px){
                  .d-sm-column{
                    flex-direction:column; 
                  }
                  .w-sm-100{
                    width: 100% !important; 
                  }
                }
            `,
            },
          },
          view: {
      
          },
};
  return component; 
};