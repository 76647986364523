export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        type:'section', 
        tagname:'section',
        name: "Seção Contato",
        attributes: { class: 'section' },
        components: [
          {
            type: 'containerContact3'
          },        
        ],
        styles: `
       .section{
        padding:75px 5%; 
        position: relative; 
       }
        *{
          font-family: Roboto, Arial, sans-serif;
       }
      `,
       },
    },
    view: {

    },
  };

  return component;
};

