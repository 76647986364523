export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        tagname: 'div',
        name: "Item Blog",
        attributes: { class: 'item-blog3' },
        components: [
          {
            type: 'title2',
            components: [
              {
                type: 'text',
                name: 'H2',
                components: 'Categoria',
                attributes: { class: 'category3' },
              },
            ],
          },
          {
            type: 'title3',
            components: [
              {
                type: 'text',
                name: 'H3',
                components: 'Lorem Ipsum',
                attributes: { class: 'title-blog3' },
              },
            ],
          },
          {
            type: 'text',
            attributes: { class: 'text-blog3' },
            components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus.',
          },
          {
            type: 'link',
            attributes: { class: 'read3' },
            components: 'Leia mais',
          },
          {
            type: 'default',
            attributes: { class: 'author3' },
            components: [
              {
                type: 'image',
                attributes: {
                  src: 'https://dummyimage.com/104x104', alt: 'Blog',
                  class: 'author-image'
                }
              },
              {
                type: 'default',
                attributes: { class: 'author-infos' },
                components: [
                  {
                    type: 'text',
                    attributes: { class: 'author-name' },
                    components: 'Holden Caulfield',
                  },
                  {
                    type: 'text',
                    attributes: { class: 'author-ocup' },
                    components: 'UI DEVELOPER',
                  }
                ]
              }
            ]
          }
        ],
        styles: `
       .item-blog3{
    width: 50%; 
    display: flex; 
    flex-direction: column; 
    align-items: flex-start; 
  }
  .category3{
    padding: 3px 10px; 
    background-image: linear-gradient(#eef2ff 0%, #eef2ff 100%); 
    border-radius: 0.25rem; 
    font-weight: 500; 
    color: #6366F1; 
    font-size: 0.75rem; 
    letter-spacing: 0.1em; 
    line-height: 1rem;
  }
  .title-blog3{
   font-size: 1.5rem; 
   line-height: 2rem; 
   font-weight: 500; 
   color: #111827; 
   margin-top: 20px; 
  }
  .text-blog3{
    color: #6B7280; 
    line-height: 1.625; 
    margin-top: 15px; 
    font-size: 16px;  
  }
  .author3{
    display: flex; 
    align-items: center; 
    gap: 15px;
    margin-top: 20px; 
  }
  .read3{
      color: #6366F1; 
      font-size: 16px; 
      margin-top: 15px; 
      display: block;           
    }
  .author-image{
    width: 48px;
    height: 48px;
    border-radius: 100%; 
  }
  .author-name{
    color: #111827; 
    font-weight: 500; 
    font-size: 14px;  
  }
  .author-ocup{
    color: #9CA3AF; 
    letter-spacing: 0.1em; 
    font-size: 0.75rem; 
    line-height: 1rem; 
  }
  @media (max-width: 992px){
   .item-blog3{
     width: 100%; 
   }
  }
      `,


      },
    },
    view: {

    },
  };

  return component;
};
