export default (opts = {}) => {
  const block = {
    id: "Section",
    label: "Seção",
    fromPlugin: 'layout',
    apper : 'out', 
    media: '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 30 28"><g id="Grupo_23910" data-name="Grupo 23910" transform="translate(-228 -1249)"><g id="Grupo_23909" data-name="Grupo 23909"><g id="Retângulo_1116" data-name="Retângulo 1116" transform="translate(228 1249)" fill="none" stroke="#92949f" stroke-width="1" stroke-dasharray="0.8 1"><rect width="30" height="28" rx="3" stroke="none"/><rect x="0.5" y="0.5" width="29" height="27" rx="2.5" fill="none"/></g><g id="Retângulo_1117" data-name="Retângulo 1117" transform="translate(231 1252)" fill="none" stroke="#92949f" stroke-width="1" stroke-dasharray="0.8 1"><rect width="24" height="22" rx="3" stroke="none"/><rect x="0.5" y="0.5" width="23" height="21" rx="2.5" fill="none"/></g></g></g></svg>',
    content: { type: 'section' },
  };

  return block;
};

