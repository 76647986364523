export default (opts = {}) => {
    const block = {
      id: "textBlock2",
      label: "Centralizado",
      fromPlugin: 'text',
      apper : 'out',
      media:'<i class="bi bi-text-center"></i>',
      content: { type: "textBlock2" },
    };
  
    return block;
  };
  

  
