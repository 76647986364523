import components from "../../topPage1/components";

export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        type: 'Container',
        name: "Container Conteudos",
        attributes: { class: 'container-cubo flex sm-flex-col items-center gap-30' },
        components: [
          {
            type: 'div',
            attributes: { class: 'w-50 flex flex-col items-start' },
            components: [
              {
                type: 'title2',
                components: [
                  {
                    type: 'text',
                    name: 'H2',
                    components: 'Lorem Ipsum',
                    attributes: { class: 'principal-title' },
                  },
                ],
              },
              {
                type: 'text',
                attributes: { class: 'body-text' },
                components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec et enim interdum, commodo elit id, condimentum mauris. Ut tortor velit, consectetur tempor lobortis vitae, vestibulum sed risus. '
              },
              {
                type: 'link',
                attributes: { class: 'link-content4' },
                components: 'Saiba mais'
              }
            ]
          },
          {
            type: 'default',
            name: 'divider',
            attributes: { class: 'divider-content4' },
          },
          {
            type: 'div',
            attributes: { class: 'w-50 flex flex-col items-start' },
            components: [
              {
                type: 'title3',
                components: [
                  {
                    type: 'text',
                    name: 'H3',
                    components: 'Lorem Ipsum',
                    attributes: { class: 'subtitle-content4' },
                  },
                ],
              },
              {
                type: 'div',
                attributes: { class: 'flex sm-flex-col gap-15' },
                components: [
                  {
                    type: 'div',
                    attributes: { class: 'w-33' },
                    components: [
                      {
                        type: 'link',
                        attributes: { class: 'link-item-content4' },
                        components: 'Lorem ipsum'
                      }
                    ]
                  },
                  {
                    type: 'div',
                    attributes: { class: 'w-33' },
                    components: [
                      {
                        type: 'link',
                        attributes: { class: 'link-item-content4' },
                        components: 'Lorem ipsum'
                      }
                    ]
                  },
                  {
                    type: 'div',
                    attributes: { class: 'w-33' },
                    components: [
                      {
                        type: 'link',
                        attributes: { class: 'link-item-content4' },
                        components: 'Lorem ipsum'
                      }
                    ]
                  },
                ]
              },
            ]
          },
        ],
        styles: `
             .container-cubo{
                max-width: 1600px; 
             }
            .flex{
              display: flex; 
            }
            .flex-col{
             flex-direction: column; 
            }
            .items-center{
              align-items: center; 
            }
            .items-start{
             align-items: flex-start; 
            }
            .gap-30{
              gap: 30px; 
            }
            .gap-15{
             gap: 15px; 
            }
            .mt-4{
              margin-top: 1rem;
            }
            .w-50{
              width: 50%; 
            }
            .principal-title{
            font-size: 30px;
            color: #363940;
            margin:0;
           }
             .principal-title{
            font-size: 30px;
            color: #363940;
            margin:0;
           }
            .link-content4{
             margin-top: 1rem; 
             color: #6366F1;
            }
            .subtitle-content4{
              color: #1F2937;
              letter-spacing: 0.05em;
              font-weight: 600;
              font-size: 0.875rem;
              line-height:1.25rem; 
              margin-bottom: 0.75rem; 
            }
            .w-33{
              width: 33%; 
            }
            .link-item-content4{
              color: #4B5563;
              font-size: 1rem;
            }
           .divider-content4{
             width: 2px;
             height: 230px;
             background-image: linear-gradient(#e5e7eb 0%, #e5e7eb 100%); 
           }
            @media (max-width: 992px){
              .sm-flex-col{
                flex-direction: column; 
              }
               .w-50{
              width: 100%; 
            }
               .w-33{
              width: 100%; 
            }
            .divider-content4{
             width: 100%;
             height: 2px;
           }
            }
            `,
      },
    },
    view: {

    },
  };
  return component;
};