import block from "./block.js";
import component from "./components/index.js"
import containerBlog2 from "./components/containerBlog2.js";
import columnsBlog2 from "./components/columnsBlog2.js";
import itemBlog2 from "./components/itemBlog2.js";


export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("columnsBlog2", columnsBlog2(opts));
  editor.DomComponents.addType("containerBlog2", containerBlog2(opts));
  editor.DomComponents.addType("itemBlog2", itemBlog2(opts));
  editor.DomComponents.addType("blog2", component(opts));
    //block
  editor.BlockManager.add("blog2", block(opts));
};