export default (opts = {}) => {
  const block = {
    category: 'Contato',
    id: "contact2",
    label: "Contato (2)",
    apper: 'out',
    media: '<svg class="preview-block" fill="none" viewBox="0 0 266 150" width="266"  height="150" ><path fill="#FFFFFF" d="M0 0h266v150H0z"></path><rect x="20" y="30" width="127" height="90" rx="2" fill="#E2E8F0"></rect><rect x="30" y="71" width="107" height="39" rx="2" fill="#FFFFFF"></rect><rect x="35" y="76" width="24" height="3" rx="1.5" fill="#4A5568"></rect><rect x="35" y="83" width="37" height="3" rx="1.5" fill="#A0AEC0"></rect><rect x="35" y="89" width="40" height="3" rx="1.5" fill="#A0AEC0"></rect><rect x="86" y="76" width="24" height="3" rx="1.5" fill="#4A5568"></rect><rect x="86" y="83" width="32" height="3" rx="1.5" fill="#6366F1"></rect><rect x="86" y="95" width="20" height="3" rx="1.5" fill="#4A5568"></rect><rect x="86" y="102" width="32" height="3" rx="1.5" fill="#A0AEC0"></rect><rect x="162" y="101" width="84" height="10" rx="2" fill="#6366F1"></rect><rect x="162" y="66" width="84" height="30" rx="2" fill="#CBD5E0"></rect><rect x="162" y="51" width="84" height="10" rx="2" fill="#CBD5E0"></rect><rect x="162" y="40" width="44.8" height="4" rx="2" fill="#4A5568"></rect><path d="M89 49.136C89 53.91 83 58 83 58s-6-4.09-6-8.864a6.21 6.21 0 011.757-4.339A5.933 5.933 0 0183 43c1.591 0 3.117.647 4.243 1.797A6.208 6.208 0 0189 49.137z" stroke="#A0AEC0" stroke-width="1.6px" stroke-linecap="round" stroke-linejoin="round" fill="none"></path><path d="M83 51.182c1.105 0 2-.916 2-2.046s-.895-2.045-2-2.045-2 .916-2 2.045c0 1.13.895 2.046 2 2.046z" stroke="#A0AEC0" stroke-width="1.6px" stroke-linecap="round" stroke-linejoin="round" fill="none"></path></svg>',
    fromPlugin: 'blocks',
    content: { type: 'contact2' },
  };

  return block;
};