export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
                name: 'Div',
                attributes: { class: 'time-line-6' },
                components: [
                    {
                        type: 'itemTimeLine6',
                    },
                    {
                        type: 'itemTimeLine6',
                    },
                    {
                        type: 'itemTimeLine6',
                    },
                    {
                        type: 'itemTimeLine6',
                    },
                    {
                        type: 'itemTimeLine6',
                    },
                    {
                        type: 'itemTimeLine6',
                    },
                    {
                        type: 'div',
                        attributes: { class: 'divider'}
                    }
                ],
                styles: `
               .time-line-6{
                display:flex; 
                gap: 50px; 
                margin-top:50px;
                justify-content:center; 
                position: relative;                 
               }
               .divider{
                width: 100%; 
                height: 10px; 
                position :absolute; 
                left: 0; 
                top: 59px; 
                top: 59px;
                box-shadow: 0 -21px 6px 0 rgba(0, 0, 0, 0.06);
               }

               @media (max-width:992px){
                .time-line-6{
                  flex-wrap: wrap; 
                  gap: 16px;
                }
                .divider{
                    display: none;
                }
               }
      
            `,
            },
        },
        view: {

        },
    };
    return component;
};