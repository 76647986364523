export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        tagname: "div",
        style: {
          "max-width": "1600px"
        },
        traits: [
          {
            type: "id",
            name: "id",
            Label: "id",
          },
        ],
        styles: `
        `,
      },
    },
    view: {
      onRender({ el }) {
        el.setAttribute('style', 'min-height: 20px;')
      },
    },
  };

  return component;
};
