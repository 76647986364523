export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
            components:[
              {
                tagname: 'p',
                type:'text',
                content:'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eget massa nibh. Donec sed varius nibh, non ultrices velit. Suspendisse neque sem, luctus ac lacinia sed, sollicitudin eget eros',
              },
            ],
            styles: `
        *{
          font-family: Roboto, Arial, sans-serif;
        }
       `,
            },
          },
          view: {
      
          },
    };
  
    return component;
  };
  