import block from "./block.js";
import component from "./components/index.js"
import cardDifferences5 from "./components/cardDifferences5.js";
import columnDifferences5 from "./components/columnDifferences5.js";
import columnsDifferences5 from "./components/columnsDifferences5.js";
import containerDifferences5 from "./components/containerDifferences5.js";
import rowCardDifferences5 from "./components/rowCardDifferences5.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("differences5", component(opts));
  editor.DomComponents.addType("cardDifferences5", cardDifferences5(opts));
  editor.DomComponents.addType("columnDifferences5", columnDifferences5(opts));
  editor.DomComponents.addType("columnsDifferences5", columnsDifferences5(opts));
  editor.DomComponents.addType("containerDifferences5", containerDifferences5(opts));
  editor.DomComponents.addType("rowCardDifferences5", rowCardDifferences5(opts));

    //block
  editor.BlockManager.add("differences5", block(opts));
};