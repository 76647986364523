export default (opts = {}) => {
  const component = {
    model: {
      defaults: {
        type:'section', 
        tagname:'section',
        name: "Seção Blog",
        attributes: { class: 'section overflow-hidden' },
        components: [
          {
            type: 'containerBlog4'
          }          
        ],
        styles: `
       .section{
        padding:75px 5%; 
       }
        *{
          font-family: Roboto, Arial, sans-serif;
       }
       .overflow-hidden{
        overflow-y: hidden;
        overflow-x: hidden;
        }
      `,
       },
    },
    view: {

    },
  };

  return component;
};

