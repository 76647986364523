export default (opts = {}) => {
    const component = {
      model: {
        defaults: {
          tagname: 'div',
          name: "Div",
          attributes: { class: 'map-contact-1' },
          components: [
           {
            type: 'map', 
            style: {
              'width':'100%', 
              'height':'100%',
            }
           }
          ],
          styles: `
          .map-contact-1{
            position: absolute;
            left: 0; 
            top: 0; 
            width: 100%; 
            height: 100%; 
            opacity: 40%; 
            filter: grayscale(); 
           }
        `,
        },
      },
      view: {
  
      },
    };
  
    return component;
  };
  