export default (opts = {}) => {
    const component = {
        model: { 
            defaults: {
              type: 'div', 
              name: "Portifolio",
              attributes: { class: 'w-60 d-flex w-sm-100' },
              components: [
              {
                type: 'div', 
              attributes: { class: 'w-30  align-end' },
              components: [
              {
                type: 'image', 
                attributes: { class: 'w-50', src:'https://placehold.co/130x129' },
              },
              {
                type: 'image', 
                attributes: { class: 'w-100 margin-top-16', src:'https://placehold.co/260x259' },
              }
              ],
              },
              {
                type: 'div', 
              attributes: { class: 'w-70 margin-left-16' },
              components: [
              {
                type: 'image', 
                attributes: { class: 'w-100', src:'https://placehold.co/674x598' },
              },
              {
                type: 'div', 
                attributes: { class: 'w-60 margin-top-16 d-flex' },
      
                components: [
                  {
                    type:'image', 
                    attributes: { class: 'w-25', src:'https://placehold.co/130x129' },
      
                  },
                  {
                    type:'image', 
                    attributes: { class: 'w-75 margin-left-16', src:'https://placehold.co/260x259' },
      
                  },
                ],
              },
      
              ],
              },
              ],
              styles: `
              .align-end{
                display: flex;
                flex-direction: column;
                align-items: flex-end;
              }
               .w-30{
                width:30%; 
               }
               .w-50{
                width:50%; 
               }
               .w-20{
                width:20%; 
               }
               .margin-top-16{
                margin-top:16px; 
               }
               .w-100{
                width:100%; 
               }
               .w-60{
                width:60%; 
               }
               .w-70{
                width:70%; 
               }
               .w-25{
                width:25%; 
               }
              .w-75{
                width:75%; 
              }
               .margin-left-16{
                margin-left:16px; 
               }
               .d-flex{
                display:flex;
               }
               @media (max-width:992px){
                .w-sm-100{
                  width:100% !important;
                }
               }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};