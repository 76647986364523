export default (opts = {}) => {
  const block = {
    category: 'Blog',
    id: "blog4",
    label: "Blog (4)",
    apper: 'out',
    media: '<svg class="preview-block" viewBox="0 0 266 150" fill="none" width="266"  height="150" ><path fill="#FFFFFF" d="M0 0h266v150H0z"></path><rect x="84" y="20" width="70" height="5" rx="2.5" fill="#4A5568"></rect><rect x="84" y="29" width="145" height="3" rx="1.5" fill="#A0AEC0"></rect><rect x="20" y="26" width="12" height="2" rx="1" fill="#A0AEC0"></rect><rect x="84" y="35" width="129" height="3" rx="1.5" fill="#A0AEC0"></rect><rect x="84" y="41" width="18" height="3" rx="1.5" fill="#6366F1"></rect><path d="M245.5 53a.5.5 0 010 1h-225a.5.5 0 010-1h225zM245.5 96a.5.5 0 010 1h-225a.5.5 0 010-1h225z" fill="#E2E8F0"></path><rect x="20" y="20" width="23" height="3" rx="1.5" fill="#4A5568"></rect><rect x="84" y="63" width="70" height="5" rx="2.5" fill="#4A5568"></rect><rect x="84" y="72" width="145" height="3" rx="1.5" fill="#A0AEC0"></rect><rect x="20" y="69" width="12" height="2" rx="1" fill="#A0AEC0"></rect><rect x="84" y="78" width="129" height="3" rx="1.5" fill="#A0AEC0"></rect><rect x="84" y="84" width="18" height="3" rx="1.5" fill="#6366F1"></rect><rect x="20" y="63" width="23" height="3" rx="1.5" fill="#4A5568"></rect><rect x="84" y="106" width="70" height="5" rx="2.5" fill="#4A5568"></rect><rect x="84" y="115" width="145" height="3" rx="1.5" fill="#A0AEC0"></rect><rect x="20" y="112" width="12" height="2" rx="1" fill="#A0AEC0"></rect><rect x="84" y="121" width="129" height="3" rx="1.5" fill="#A0AEC0"></rect><rect x="84" y="127" width="18" height="3" rx="1.5" fill="#6366F1"></rect><rect x="20" y="106" width="23" height="3" rx="1.5" fill="#4A5568"></rect></svg>',
    fromPlugin: 'blocks',
    content: { type: 'blog4' },
  };

  return block;
};