export default (opts = {}) => {
    const component = {
        model: {
            defaults: {
              tagname:'div', 
              name: "Container",
              attributes: { class: 'footer' },
              components: [
                {
                  type:'text',
                  content:'Nome do cliente © 2022. Todos os direitos reservados.',
                  attributes:{class:'text-footer'}
                },
                {
                  type:'text',
                  content:'Desenvolvimento e performance por ...',
                  attributes:{class:'text-footer'}
                },
              ],
              styles: `
               .container-cubo{
                max-width: 1600px;
               }
               .text-footer{
                font-size:14px;
                color: #fff; 
               }
               .footer{
                display:flex; 
                justify-content: space-between; 
                width: 100%; 
                max-width: 1600px; 
               }
               @media (max-width:992px){
                .footer{
                  flex-direction:column; 
                  align-items: center;
                  text-align: center;
                }
               }
            `,
             },
          },
          view: {
      
          },
};
  return component; 
};