import block from "./block.js";
import component from "./components/index.js"
import columnAbout2 from "./components/columnAbout2.js"
import checkListAbout from "./components/checkListAbout.js";
import checkListItem from "./components/checkListItem.js";
import columnImageAbout2 from "./components/columnImageAbout2.js";
import columnsAbout2 from "./components/columnsAbout2.js";
import containerCheckList from "./components/containerCheckList.js";
import containerAbout2 from "./components/containerAbout2.js";
import decoration from "./components/decorations.js";
import emphasis from "./components/emphasis.js";

export default (editor, opts = {}) => {
    //components
  editor.DomComponents.addType("about2", component(opts));
  editor.DomComponents.addType("columnAbout2", columnAbout2(opts));
  editor.DomComponents.addType("checkListAbout", checkListAbout(opts));
  editor.DomComponents.addType("checkListItem", checkListItem(opts));
  editor.DomComponents.addType("columnImageAbout2", columnImageAbout2(opts));
  editor.DomComponents.addType("columnsAbout2", columnsAbout2(opts));
  editor.DomComponents.addType("containerCheckList", containerCheckList(opts));
  editor.DomComponents.addType("containerAbout2", containerAbout2(opts));
  editor.DomComponents.addType("decoration", decoration(opts));
  editor.DomComponents.addType("emphasis", emphasis(opts));

    //block
  editor.BlockManager.add("about2", block(opts));
};